import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-g-p-t-filter',
  templateUrl: './g-p-t-filter.component.html',
  styleUrls: ['./g-p-t-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GPTFilterComponent implements OnInit {
  filterDateForm = new UntypedFormControl('12months');
  constructor() {}
  @Output()
  changeFilterDate = new EventEmitter<string>();
  ngOnInit() {
    this.filterDateForm.valueChanges.subscribe(value => {
      this.changeFilterDate.emit(value);
    });
  }
}
