<mat-expansion-panel class="shadow-none hover:shadow-none" [togglePosition]="'before'">
  <mat-expansion-panel-header class="px-1 hover:bg-white">
    <mat-panel-title>
      <span class="pr-2 text-base font-normal">
        Comisión Betterplan
      </span>
    </mat-panel-title>
    <mat-panel-description class="flex flex-row justify-end flex-grow-[0.5]">
      @if (goalCategory.code !== 'short-term-invest') {
        <span class="text-base font-normal"
          >{{ totalBpCommission | number: '1.0-2':'es-CL' }}% + IVA</span
          >
        }
        @if (goalCategory.code === 'short-term-invest') {
          <span class="text-base font-normal">
            {{ totalBpCommission | number: '1.0-2':'es-CL' }}%</span
            >
          }
        </mat-panel-description>
      </mat-expansion-panel-header>
      @if (goalCategory.code !== 'short-term-invest') {
        <span
          class="text-base font-normal text-secondary"
          >Sobre el monto administrado.</span
          >
        }
        @if (goalCategory.code === 'short-term-invest') {
          <span
            class="text-base font-normal text-secondary"
            >
            Betterplan nunca te cobrará por la Cuenta Ahorro</span
            >
          }
        </mat-expansion-panel>
        <mat-expansion-panel class="shadow-none hover:shadow-none" [togglePosition]="'before'">
          <mat-expansion-panel-header class="px-1 hover:bg-white">
            <mat-panel-title>
              <span class="block pr-2 text-base font-normal">
                <span class="inline align-middle">
                  Costo fondos
                </span>
                <mat-icon
                  matTooltipPosition="right"
                  (click)="tooltip.toggle()"
                  #tooltip="matTooltip"
          [matTooltip]="
            'El portafolio está invertido en activos financieros que cobran su propia comisión de administración. Tomando el costo de cada fondo y el porcentaje que representa cada uno en el portafolio te mostramos el costo de administración promedio de estos fondos subyacentes. '
          "
                  class="inline text-base align-middle cursor-pointer text-inherit"
                  >
                  help_outline
                </mat-icon>
              </span>
            </mat-panel-title>
            <mat-panel-description class="flex flex-row justify-end flex-grow-[0.5]">
              @if (financialEntity?.uuid != 'vector' && financialEntity?.uuid != 'bp-us') {
                <span
                  class="text-base font-normal"
                  >
                  {{ totalPortfolioCost | number: '1.0-2':'es-CL' }}% anual
                </span>
              }
              @if (financialEntity?.uuid === 'vector' || financialEntity?.uuid === 'bp-us') {
                <span
                  class="text-base font-normal"
                  >
                  {{ totalPortafolioCostVector | number: '1.0-2':'es-CL' }}% anual
                </span>
              }
            </mat-panel-description>
          </mat-expansion-panel-header>
          @if (financialEntity?.uuid === 'vector' || financialEntity?.uuid === 'bp-us') {
            <span
              class="text-base font-normal text-secondary"
              >Sobre el monto administrado. IVA incluido</span
              >
            }
            @if (financialEntity?.uuid != 'vector' && financialEntity?.uuid != 'bp-us') {
              <span
                class="text-base font-normal text-secondary"
                >
                No tiene comisión por depósito ni retiro.
              </span>
            }
          </mat-expansion-panel>
          <mat-expansion-panel class="shadow-none hover:shadow-none" [togglePosition]="'before'">
            <mat-expansion-panel-header class="px-1 hover:bg-white">
              <mat-panel-title>
                <span class="pr-2 text-base font-normal">
                  Otros
                </span>
              </mat-panel-title>
              <mat-panel-description class="flex flex-row justify-end flex-grow-[0.5]">
                @if (financialEntity?.uuid !== 'bp-us') {
                  <span class="text-base font-normal">
                    0%
                  </span>
                }
                @if (financialEntity?.uuid === 'bp-us') {
                  <span class="text-base font-normal">
                    0,005 USD/instr</span
                    >
                  }
                </mat-panel-description>
              </mat-expansion-panel-header>
              @if (financialEntity?.uuid !== 'bp-us') {
                <span class="text-base font-normal text-secondary"
                  >Sin cobros adicionales por retiros o aportes</span
                  >
                }
                @if (financialEntity?.uuid === 'bp-us') {
                  <span class="text-base font-normal text-secondary"
                    >El cobro aplica en cada orden de compra o venta recibida</span
                    >
                  }
                </mat-expansion-panel>
