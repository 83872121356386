import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BlogContentListRequest,
  BlogContentModel,
  BlogContentTypeEnum,
} from 'projects/bp-core/src/lib/proto/blog.pb';
import { BlogGrpcServiceClient } from 'projects/bp-core/src/lib/proto/blog.pbsc';
import { Subscription, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-blog-faqs',
  templateUrl: './blog-faqs.component.html',
  styleUrls: ['./blog-faqs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogFaqsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showTitle = true;
  @Input() legacyStyle = false;
  @Input()
  investmentStrategy: string;
  @Input()
  tabParent: string | null | undefined;
  componentIsReady = false;
  subscription: Subscription;
  fragmentSubscription: Subscription;

  areLinkQuestions = true;
  blogQuestions: BlogContentModel[] | undefined = undefined;
  constructor(
    private blogGrpcServiceClient: BlogGrpcServiceClient,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.fragmentSubscription = this.route.fragment.pipe(distinctUntilChanged()).subscribe({
      next: fragment => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        if (!this.tabParent) this.tabParent = fragment;

        this.cd.detectChanges();

        this.getQuestions();
      },
    });
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    if (this.fragmentSubscription) this.fragmentSubscription.unsubscribe();
  }
  getQuestions() {
    this.componentIsReady = false;
    this.subscription = this.blogGrpcServiceClient
      .getBlogContent(
        new BlogContentListRequest({
          contentType: BlogContentTypeEnum.Faq,
          customContentSlug: this.tabParent ?? undefined, //tab
          customContentSlugExtra: this.investmentStrategy, //strategy
        }),
      )
      .subscribe({
        next: result => {
          if (result.values) {
            this.blogQuestions = result.values!;
            let linkQuestions = 0;
            let textQuestions = 0;
            this.blogQuestions.forEach(bq => {
              if (bq.content?.rendered != '') {
                textQuestions++;
              } else if (bq.link != '') {
                linkQuestions++;
              }
            });
            if (textQuestions >= linkQuestions) {
              this.areLinkQuestions = false;
              if (textQuestions != this.blogQuestions.length) {
                this.blogQuestions = this.blogQuestions.filter(q => q.content?.rendered != '');
              }
            } else {
              this.areLinkQuestions = true;
              if (linkQuestions != this.blogQuestions.length) {
                this.blogQuestions = this.blogQuestions.filter(q => q.link != '');
              }
            }
            this.componentIsReady = true;
            this.cd.detectChanges();
          }
        },
        error: error => {
          console.log(error);
        },
      });
  }
}
