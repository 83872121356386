@if (!dismissible || (dismissible && !dismissed)) {
  <div
    class="fuse-alert-container"
    [@fadeIn]="!dismissed"
    [@fadeOut]="!dismissed"
    >
    <!-- Border -->
    @if (appearance === 'border') {
      <div class="fuse-alert-border"></div>
    }
    <!-- Icon -->
    @if (showIcon) {
      <div class="hidden fuse-alert-icon sm:flex">
        <!-- Custom icon -->
        <div class="fuse-alert-custom-icon">
          <ng-content select="[bpAlertIcon]"></ng-content>
        </div>
        <!-- Default icons -->
        <div class="fuse-alert-default-icon">
          @if (type === 'primary') {
            <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          }
          @if (type === 'accent') {
            <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          }
          @if (type === 'warn') {
            <mat-icon [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
          }
          @if (type === 'basic') {
            <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          }
          @if (type === 'info') {
            <mat-icon [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
          }
          @if (type === 'success') {
            <mat-icon [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          }
          @if (type === 'warning') {
            <mat-icon
              [svgIcon]="'heroicons_solid:exclamation-triangle'"
            ></mat-icon>
          }
          @if (type === 'error') {
            <mat-icon [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
          }
        </div>
      </div>
    }
    <!-- Content -->
    <div class="pr-3 fuse-alert-content">
      <div class="fuse-alert-title">
        <ng-content select="[bpAlertTitle]"></ng-content>
      </div>
      <div class=" fuse-alert-message">
        <ng-content></ng-content>
      </div>
    </div>
    @if (extra && extra !== 'none') {
      <span
        bp-link
        class="self-center flex-shrink-0 ml-auto mr-0 "
        (click)="showMore.emit()"
        >
        Ver más
      </span>
    }
    <!-- Dismiss button -->
    <button class="fuse-alert-dismiss-button" mat-icon-button (click)="dismiss()">
      <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>
}
