import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { PercentPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ITooltip } from '@bp-core/src/lib/models/portal/goal/tooltip.model';
import { environment } from '@environment/environment';
import { fuseAnimations } from '@fuse/animations';
import moment from 'moment';
import am4themes_betterplan from 'projects/bp-advisor/src/bp/shared/chart/betterplan-theme-amchart';
import { TooltipChartComponent } from 'projects/bp-advisor/src/bp/shared/components/tooltip-chart/tooltip-chart.component';
import { IChart } from 'projects/bp-core/src/lib/models/portal/chart.model';
import { ITooltipInfo } from 'projects/bp-core/src/lib/models/portal/goal/tooltip-info.model';

am4core.addLicense(environment.amchartLicence);
am4core.useTheme(am4themes_betterplan);
am4core.options.autoDispose = false;
@Component({
  selector: 'app-g-p-p-e-chart',
  templateUrl: './g-p-p-e-chart.component.html',
  styleUrls: ['./g-p-p-e-chart.component.scss'],
  providers: [PercentPipe],
  animations: fuseAnimations,
})
export class GPPEChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  constructor(
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private percentPipe: PercentPipe,
  ) {}

  private chart: am4charts.XYChart;
  @Input()
  profitabilities: IChart;
  emptyChart = false;
  @ViewChild('chartdivperformanceevolution')
  chartdivperformanceevolution: ElementRef;

  @ViewChild('tool') tool: TooltipChartComponent;

  tooltip: ITooltip = {
    title: '',
    values: [],
  };
  ngOnChanges(changes: SimpleChanges): void {
    let tooltipHtml = `<strong>{date.formatDate('dd-MM-yyyy')} </strong><table>`;

    if (this.chart && this.profitabilities) {
      this.detectEmptyChart();
      this.chart.series.clear();
      this.profitabilities.values.forEach(value => {
        const lineSeries = this.chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.dateX = 'date';
        lineSeries.stroke = am4core.color(value.color);
        lineSeries.fill = am4core.color(value.color);
        lineSeries.dataFields.valueY = value.key?.trim();
        lineSeries.name = value.portfolioTitle ?? ' ';

        const title =
          (value.portfolioTitle ? value.portfolioTitle : value.title) +
          ' ' +
          (value.currencyCode == '--' ? '' : value.currencyCode);
        tooltipHtml = tooltipHtml.concat(
          `<tr class="w-100">
          <td><span class="dot-chart" style="background-color:${
            value.color
          }; margin-right:2px "></span></td>
              <td class=" text-left">${title}: {${value.key?.trim()}}</td>
          </tr>`,
        );

        // this.chart.series.values[0].tooltipHTML = tooltipHtml;

        // <td class="d-sm-none d-block  text-left">${reponsiveTitle}: {${value.key}}</td>
      });

      this.chart.data = this.profitabilities.data;
      tooltipHtml = tooltipHtml.concat('</table>');

      if (this.chart.series.length > 0) {
        if (this.chart.series.values[0].tooltip) {
          this.chart.series.values[0].tooltip.getFillFromObject = false;
          this.chart.series.values[0].tooltip.background.fill = am4core.color('#FFFFFF');
          this.chart.series.values[0].tooltip.autoTextColor = false;
          this.chart.series.values[0].tooltip.label.fill = am4core.color('black');
          //   this.chart.series.values[0].tooltipHTML = tooltipHtml;
        }
      }
      this.addTooltipHtml(this.chart.series.values[0] as am4charts.LineSeries);
    }
  }
  addTooltipHtml(lineSeries: am4charts.LineSeries) {
    if (!lineSeries) {
      return;
    }
    lineSeries.adapter.add('tooltipHTML', (tooltipHTML, target) => {
      let data = target.tooltipDataItem.dataContext as any;
      if (data) {
        let tootipArr: ITooltipInfo[] = [];
        this.profitabilities.values.forEach(p => {
          tootipArr.push({
            label:
              (p.portfolioTitle ? p.portfolioTitle : p.title) +
              ' ' +
              (p.currencyCode == '--' ? '' : p.currencyCode),
            value: data[p.key!]
              ? `${data[p.key!] > 0 ? '+' : ''}${this.percentPipe.transform(
                  data[p.key!] / 100,
                  '1.1-2',
                )!}`
              : '',
            color: p.color,
          });
        });
        //   const balanceValue = this.currencyPipe.transform(
        //     data.balance,
        //     this.goalCurrency?.currencyCode,
        //     this.goalCurrency?.display,
        //     this.goalCurrency?.digitsInfo,
        //   );
        //   const netDepositValue = this.currencyPipe.transform(
        //     data.netDeposit,
        //     this.goalCurrency?.currencyCode,
        //     this.goalCurrency?.display,
        //     this.goalCurrency?.digitsInfo,
        //   );
        let tooltipObj: ITooltip = {
          title: moment(data.date).format('DD MMM yyyy'),
          values: tootipArr,
        };
        this.tooltip = { ...tooltipObj };
        this.cd.detectChanges();
      }
      return `${this.tool.dom.nativeElement.innerHTML}`;
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        const chart = am4core.create(
          this.chartdivperformanceevolution.nativeElement,
          am4charts.XYChart,
        );
        this.chart = chart;
        this.chart.tapToActivate = true;
        this.chart.paddingRight = 0;
        this.chart.paddingLeft = 0;
        this.chart.responsive.enabled = true;
        this.chart.responsive.useDefault = false;
        const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

        let axisTooltip = dateAxis.tooltip!;
        axisTooltip.background.fill = am4core.color('rgba(0, 0, 0, 0)');
        axisTooltip.background.strokeWidth = 0;
        axisTooltip.ignoreBounds = true;
        // axisTooltip.background.cornerRadius = 20;
        axisTooltip.background.pointerLength = 0;
        axisTooltip.dy = -24;

        axisTooltip.html = '<h3 style="color:black">&#8226;</h3>';

        dateAxis.dateFormats.setKey('day', 'dd');
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy[/]');
        dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM[/]');
        //    dateAxis.renderer.grid.template.disabled = true;
        //    dateAxis.renderer.ticks.template.disabled = false;

        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.line.strokeOpacity = 1;
        dateAxis.renderer.line.strokeWidth = 0.5;
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        //  valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 0.5;
        valueAxis.numberFormatter.numberFormat = `#.0!'%'`;
        valueAxis.renderer.padding(0, 0, 0, 0);
        valueAxis.renderer.margin(0, 0, 0, 0);
        //  contributionSeries.defaultState.transitionDuration = 1000;

        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.xAxis = dateAxis;
        this.chart.cursor.lineY.disabled = true;
        //   this.chart.numberFormatter.numberFormat = '$#,###';
        //  this.chart.legend = new am4charts.Legend();
        //      chart.legend.contentAlign = 'left';
        //     chart.legend.position = 'top';
        // this.chart.legend.fontSize = 10;
        this.chart.language.locale = am4lang_es_ES;

        // const marker = chart.legend.markers.template.children.getIndex(0);
        // (marker as any).cornerRadius(12, 12, 12, 12);
        // marker.height = 15;
        // marker.width = 15;
        // this.chart.numberFormatter.numberFormat = '#,###.##%';

        if (this.profitabilities) {
          // console.log('aca');
          /*let tooltipHtml = `
          <strong>{date.formatDate('dd-MM-yyyy')} </strong>
          <table>
          `;
          this.chart.series.clear();
          this.profitabilities.values.forEach(value => {
            const lineSeries = this.chart.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.dateX = 'date';
            lineSeries.stroke = am4core.color(value.color);
            lineSeries.fill = am4core.color(value.color);
            lineSeries.dataFields.valueY = value.key;
            lineSeries.name = value.portfolioTitle ?? '';
            // var reponsiveTitle = value?.title?.replace(' ', '<br>');
            const title = value.portfolioTitle ? value.portfolioTitle : value.title;
            tooltipHtml = tooltipHtml.concat(
              `
                        <tr class="w-100">
                        <td><span class="dot-chart" style="background-color:${value.color}; margin-right:2px "></span></td>
                            <td class=" text-left">${title}: {${value.key}}</td>
                        </tr>
                    `,
            );
            // <td class="d-sm-none d-block  text-left">${reponsiveTitle}: {${value.key}}</td>
          });*/
          this.chart.data = this.profitabilities.data;

          this.detectEmptyChart();
          if (this.emptyChart === true) {
            valueAxis.disabled = true;
            dateAxis.disabled = true;
            //  this.chart.hidden = true;
            this.chart.cursor.disabled = true;
          }
        }

        //   this.chart.scrollbarX = new am4core.Scrollbar();
        this.onResize();
      });
    });
  }
  detectEmptyChart(): void {
    if (this.profitabilities.data.length === 0) {
      if (this.chart.data.length <= 1) {
        // console.log(this.chart.data.length)
        this.emptyChart = true;
      } else {
        this.emptyChart = false;
      }
    }

    // console.log(this.emptyChart)
    const emptyLabel = this.chart.createChild(am4core.Label);
    if (this.emptyChart === true) {
      // const messageContainer = this.chart.createChild(am4core.Container);
      //  messageContainer.fill = am4core.color('red');
      //  messageContainer.x = am4core.percent(30);
      //  messageContainer.y = am4core.percent(25);
      // emptyLabel.y = am4core.percent(25);
      // messageContainer.align = 'center';
      // messageContainer.horizontalCenter = "middle";
      // emptyLabel.show();
      emptyLabel.text = `[bold font-size:15x] Acá observarás la rentabilidad en el tiempo`;
      // emptyLabel.html = `
      //       <h6>Pronto podrás observar la rentabilidad de tu portafolio recomendado</h6>
      // `;
      emptyLabel.x = am4core.percent(50);
      emptyLabel.y = am4core.percent(50);
      emptyLabel.align = 'center';
      emptyLabel.wrap = true;
      emptyLabel.horizontalCenter = 'middle';
      // emptylabel.ontalCenter = 'middle';
      // emptyLabel.verticalCenter = "middle";
      // emptyLabel.height = am4core.percent(100);
      // emptyLabel.width = am4core.percent(100);
      emptyLabel.isMeasured = false;
    } else {
      if (emptyLabel) {
        emptyLabel.dispose();
      }
    }
    // console.log(this.balance)
    // console.log(this.balance)
  }
  /**
   * funcion para cambiar el tamaño de las letras de la gráfica respecto a la pantall
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    let screenWidth;
    if (event) {
      screenWidth = event.target.innerWidth;
    } else {
      screenWidth = window.innerWidth;
    }
    // let fontSizeBase = 13;
    if (this.chart) {
      switch (true) {
        case screenWidth < 576:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'none';
          this.chart.zoomOutButton.disabled = true;
          break;
        case screenWidth < 768:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
        default:
          this.chart.fontSize = 12;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
      }
    }
  }
}
