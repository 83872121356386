import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
})
export class CurrencySelectComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  formControl = new UntypedFormControl(0);
  options: any[] = [
    {
      id: 0,
      text: 'Todo',
    },
    {
      id: 1,
      text: 'Pesos',
    },
    {
      id: 2,
      text: 'Dólares',
    },
  ];
  @Input() initialValue?: number = 0;
  @Output() valueChange = new EventEmitter<{ value: number; labels: string[] }>();
  ngOnChanges(changes: SimpleChanges): void {
    this.formControl.setValue(changes.initialValue.currentValue ?? 0, { emitEvent: false });
  }
  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: number) => {
      // console.log('dateRange', value);

      this.valueChange.emit({
        value,
        labels: this.options.filter(x => x.id === value).map(x => x.text),
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
