import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IProfitavilityValue } from '@bp-core/src/lib/models/portal/goal/profitability-value.model';
import { fuseAnimations } from '@fuse/animations';
import { Int32Value, Timestamp } from '@ngx-grpc/well-known-types';
import { GoalModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { IrrRequest, IrrResponse } from 'projects/bp-core/src/lib/proto/portal-goal.pb';
import { PortalGoalGrpcServiceClient } from 'projects/bp-core/src/lib/proto/portal-goal.pbsc';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-g-p-profitability-by-date',
  templateUrl: './g-p-profitability-by-date.component.html',
  styleUrls: ['./g-p-profitability-by-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPProfitabilityByDateComponent implements OnInit, OnChanges, OnDestroy {
  private _stop = new Subject<void>();
  constructor(
    private portalGoalGrpcServiceClient: PortalGoalGrpcServiceClient,
    private ref: ChangeDetectorRef,
  ) {}
  @Input()
  goalId: number | undefined;
  @Input()
  goals: GoalModel[] | undefined;
  @Input()
  dateRange = 1;
  @Input()
  fromDate: Date | undefined;
  @Input()
  toDate: Date | undefined;
  @Input()
  valueIcon: any;
  title = 'Rentabilidad ponderada por dinero';
  isLoading = false;
  irrAnnualized: string;
  irrAnnualizedUSD: string;
  fromDateFilter: Date;
  toDateFilter: Date;
  irrAccumulated: string;
  irrAccumulatedUSD: string;
  irrCurrencyCode = '';
  irrUSDCurrencyCode = '';
  profitabilitiesAllTime: IProfitavilityValue[];
  profitabilitiesPastYearReturn: IProfitavilityValue[];
  initialFromDate: Date;
  initialToDate: Date;
  fixedFromDate: string;
  fixedToDate: string;
  showDates = false;
  showCurrencyIrr = false;
  currentGoal: GoalModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.goals) {
      if (this.goals?.length == 1) {
        this.currentGoal = this.goals[0];
        this.showCurrencyIrr =
          this.currentGoal?.portfolio?.extraProfitabilityCurrencyCode?.length! > 0 &&
          this.currentGoal?.currency?.currencyCode !=
            this.currentGoal?.portfolio?.extraProfitabilityCurrencyCode;
        this.title = 'Rentabilidad ' + this.currentGoal?.title;
      }
    }

    if (changes.goalId) {
      this._stop.next();
      this.getValues();
    }
    if (changes?.dateRange) {
      if (+this.dateRange !== 7) {
        this.showDates = false;
        switch (+this.dateRange!) {
          case 1:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
          case 2:
            this.toDateFilter = this.initialToDate;
            const dateLastYear = this.toDateFilter.getFullYear() - 1;
            this.fromDateFilter = new Date(this.initialToDate);
            this.fromDateFilter.setFullYear(dateLastYear);
            break;
          case 3:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter?.setDate(this.toDateFilter?.getDate() - 30);
            break;
          case 4:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter?.setMonth(this.toDateFilter?.getMonth() - 3);
            break;
          case 5:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter?.getFullYear(), 0, 1);
            break;
          case 6:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter?.setFullYear(this.toDateFilter?.getFullYear() - 2);
            break;
          default:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
        }
        this.fixedToDate = `${this.toDateFilter?.getDate()} ${this.toDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter?.getFullYear()}`;
        this.fixedFromDate = `${this.fromDateFilter?.getDate()} ${this.fromDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter?.getFullYear()}`;
        this._stop.next();
        this.getValues();
      }
    }

    if (+this.dateRange! == 7 && changes.fromDate) {
      this.showDates = false;
      this.fromDateFilter = changes.fromDate.currentValue!;
      if (this.fromDateFilter) {
        this.fixedFromDate = `${this.fromDateFilter.getDate()} ${this.fromDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getValues();
    }

    if (+this.dateRange! == 7 && changes.toDate) {
      this.showDates = false;
      this.toDateFilter = changes.toDate.currentValue;
      if (this.toDateFilter) {
        this.fixedToDate = `${this.toDateFilter.getDate()} ${this.toDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getValues();
    }
  }
  onChangeFromDateFilter(value: Date) {
    this.fromDateFilter = value;
    this.getValues();
  }
  onChangeToDateFilter(value: Date) {
    this.toDateFilter = value;
    this.getValues();
  }

  getValues() {
    this.isLoading = true;
    const query: any = {};
    const request: IrrRequest = new IrrRequest();
    if (this.fromDateFilter) {
      query.fromDate = this.fromDateFilter;
      request.from = Timestamp.fromDate(this.fromDateFilter);
    }
    if (this.toDateFilter) {
      query.toDate = this.toDateFilter;
      request.to = Timestamp.fromDate(this.toDateFilter);
    }
    if (this.goalId) {
      query.goalId = this.goalId;
      request.goalId = new Int32Value({ value: this.goalId });
    }

    let requestUSD: IrrRequest;
    console.log('request', request);

    let goalIrrs = [this.portalGoalGrpcServiceClient.irr(request)];

    if (this.showCurrencyIrr) {
      request.currencyCode = 'CLP';
      this.irrCurrencyCode = request.currencyCode;
      goalIrrs = [this.portalGoalGrpcServiceClient.irr(request)];

      requestUSD = new IrrRequest({
        currencyCode: this.currentGoal.portfolio?.extraProfitabilityCurrencyCode ?? '--',
        from: request.from,
        to: request.to,
        goalId: request.goalId,
      });

      this.irrUSDCurrencyCode = requestUSD.currencyCode!;

      goalIrrs = goalIrrs.concat(this.portalGoalGrpcServiceClient.irr(requestUSD));
    }

    /* DEBUG*/
    /*this.goalService
      .irr(query)
      .pipe(takeUntil(this._stop))
      .subscribe(irrs => {
        console.log('oroginal iirr', irrs);
      });*/

    combineLatest(goalIrrs)
      .pipe(takeUntil(this._stop))
      .subscribe(
        irrs => {
          const irrClp = irrs[0];
          console.log(irrClp);
          let irrUsd = new IrrResponse();
          if (irrs.length > 1) {
            irrUsd = irrs[1];
          }
          //console.log('old irr', [irrOld]);
          //this.fromDateFilter = dayjs(result.from).toDate();
          //this.toDateFilter = dayjs(result.to).toDate();
          if (irrClp.from && irrClp.to) {
            this.fromDateFilter = irrClp.from?.toDate()!;
            this.toDateFilter = irrClp.to?.toDate()!;

            this.irrAccumulated = irrClp.irrAccumulated!;
            this.irrAnnualized = irrClp.irrAnnualized!;

            this.irrAccumulatedUSD = irrUsd?.irrAccumulated!;
            this.irrAnnualizedUSD = irrUsd?.irrAnnualized!;

            if (this.dateRange == 1 || this.dateRange == 0) {
              //this.initialFromDate = dayjs(result.from).toDate();
              //this.initialToDate = dayjs(result.to).toDate();

              this.initialFromDate = irrClp.from?.toDate()!;
              this.initialToDate = irrClp.to?.toDate()!;
              console.log(this.initialToDate);
              this.fixedToDate = `${this.initialToDate.getDate()} ${this.initialToDate.toLocaleString(
                'es-ES',
                { month: 'short' },
              )} ${this.initialToDate.getFullYear()}`;
              this.fixedFromDate = `${this.initialFromDate.getDate()} ${this.initialFromDate.toLocaleString(
                'es-ES',
                { month: 'short' },
              )} ${this.initialFromDate.getFullYear()}`;
            }

            // TODO: Solucion parche a agrupacion de fondos
            // const dicgoalsProfitabilitiesPastYearReturn = {};
            // const dicgoalsProfitabilitiesAllTime = {};
            // values.forEach((value, index) => {
            //   switch (value.type) {
            //     case 'dailyReturn':
            //       break;
            //     case 'pastMonthReturn':
            //       break;
            //     case 'pastYearReturn':
            //       if (!dicgoalsProfitabilitiesPastYearReturn[value.fundingId]) {
            //         goalsProfitabilitiesPastYearReturn.push(value);
            //         dicgoalsProfitabilitiesPastYearReturn[value.fundingId] = true;
            //       }

            //       break;
            //     case 'allTime':
            //       if (!dicgoalsProfitabilitiesAllTime[value.fundingId]) {
            //         goalsProfitabilitiesAllTime.push(value);
            //         dicgoalsProfitabilitiesAllTime[value.fundingId] = true;
            //       }
            //       break;
            //     case 'earnings':
            //       break;
            //     case 'netDeposit':
            //       break;
            //     case 'balance':
            //       break;
            //     case 'quotas':
            //       break;
            //     case 'yearToDate':
            //       break;
            //     default:
            //       break;
            //   }
            // });

            // this.profitabilitiesPastYearReturn = goalsProfitabilitiesPastYearReturn;
            // this.profitabilitiesAllTime = goalsProfitabilitiesAllTime;

            this.isLoading = false;
            this.showDates = true;
            this.ref.detectChanges();
          }
          // this.balanceValues = [balance, netDeposit, earnings];
        },
        error => {
          /*Swal.fire({
            iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
            customClass: {
              container: 'swal2-fixe',
              confirmButton: 'btn',
              cancelButton: 'btn',
            },
            heightAuto: false,
            buttonsStyling: false,
            showClass: {
              icon: 'swal2-noanimation',
            },
            hideClass: {
              icon: 'swal2-noanimation',
            },
            footer:
              '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
            icon: 'error',
            title: 'Error!',
            text: 'No se pudo cargar la rentabilidad ..',
          });*/
          this.isLoading = false;
          console.error(error);
        },
      );
  }
  ngOnInit() {}
  ngOnDestroy() {
    this._stop.next();
    this._stop.complete();
  }
}
