<h6 mat-dialog-title class="pb-1 my-auto  text-uppercase font-medium">
  {{ data.title }}
</h6>
<mat-divider class="mt-2 mb-3"> </mat-divider>
<mat-dialog-content>
  <ng-container [ngTemplateOutlet]="data.content"></ng-container>
</mat-dialog-content>
<mat-divider class="mb-3"> </mat-divider>
<mat-dialog-actions align="end" class="actions">
  <button mat-button color="primary" [mat-dialog-close]="true">
    Aceptar
  </button>
</mat-dialog-actions>
