import {
  BaseObject,
  color,
  Color,
  ColorSet,
  Component,
  is,
  ITheme,
  Preloader,
  Scrollbar,
  Slice,
  SpriteState,
  Tooltip,
} from '@amcharts/amcharts4/core';

import { PercentSeries } from '@amcharts/amcharts4/.internal/charts/series/PercentSeries';
import {
  Chart,
  Column,
  Column3D,
  FunnelSeries,
  FunnelSlice,
  Legend,
  SankeyDiagram,
  Series,
} from '@amcharts/amcharts4/charts';

export const colors = [
  '#009fe3',
  '#20cb9b',
  '#b7fecd',
  '#4cf29d',
  '#3bb878',
  '#2dda9c',
  '#288983',
  '#173a4b',
  '#afe7fe',
  '#9bdefe',
  '#2066db',
  '#212d76',
  '#191952',
];

export function getBlueColor(position: number) {
  let g = 160;
  let newG = g + position * 14;
  if (newG > 255) newG = 255;
  return `rgb(0 ,${newG} ,227)`;
}
export function getGreenColor(position: number) {
  let g = 206;
  let b = 144;
  let newG = g + position * 14;
  let newB = b + position * 7;
  if (newG > 255) newG = 255;
  if (newB > 255) newB = 255;
  return `rgb(21,${newG},${newB})`;
}
export function getColor(position: number) {
  while (position > colors.length) {
    position = position - colors.length;
  }
  return colors[position];
}
const theme: ITheme = (object: BaseObject) => {
  if (is<ColorSet>(object, 'ColorSet')) {
    const colorsParse: Color[] = [];
    colors.forEach(value => {
      colorsParse.push(color(value));
    });
    object.list = colorsParse;
    object.reuse = false;
    object.stepOptions = {
      lightness: 0.05,
      hue: 0,
    };
    object.passOptions = {};
  }

  if (is<SpriteState<any, any>>(object, 'SpriteState')) {
    object.transitionDuration = 400;
  }

  if (is<Component>(object, 'Component')) {
    object.rangeChangeDuration = 500;
    object.interpolationDuration = 500;
    object.sequencedInterpolation = false;

    if (is<SankeyDiagram>(object, 'SankeyDiagram')) {
      object.sequencedInterpolation = true;
    }

    if (is<FunnelSeries>(object, 'FunnelSeries')) {
      object.sequencedInterpolation = true;
    }
  }

  if (is<Chart>(object, 'Chart')) {
    object.defaultState.transitionDuration = 2000;
    object.hiddenState.transitionDuration = 1000;
    object.fontSize = 12;
  }
  if (is<Legend>(object, 'Legend')) {
    object.useDefaultMarker = true;
  }

  if (is<Tooltip>(object, 'Tooltip')) {
    object.animationDuration = 400;
    object.defaultState.transitionDuration = 400;
    object.hiddenState.transitionDuration = 400;
  }

  if (is<Scrollbar>(object, 'Scrollbar')) {
    object.animationDuration = 500;
  }

  if (is<Series>(object, 'Series')) {
    object.defaultState.transitionDuration = 1000;
    object.hiddenState.transitionDuration = 700;
    object.hiddenState.properties.opacity = 1;
    object.showOnInit = true;
  }

  // if (is<MapSeries>(object, 'MapSeries')) {
  //   object.hiddenState.properties.opacity = 0;
  // }

  if (is<PercentSeries>(object, 'PercentSeries')) {
    object.hiddenState.properties.opacity = 0;
  }

  if (is<FunnelSlice>(object, 'FunnelSlice')) {
    object.defaultState.transitionDuration = 800;
    object.hiddenState.transitionDuration = 1000;
    object.hiddenState.properties.opacity = 1;
  }

  if (is<Slice>(object, 'Slice')) {
    object.defaultState.transitionDuration = 700;
    object.hiddenState.transitionDuration = 1000;
    object.hiddenState.properties.opacity = 1;
  }

  if (is<Preloader>(object, 'Preloader')) {
    object.hiddenState.transitionDuration = 2000;
  }

  if (is<Column>(object, 'Column')) {
    object.defaultState.transitionDuration = 700;
    object.hiddenState.transitionDuration = 1000;
    object.hiddenState.properties.opacity = 1;
  }

  if (is<Column3D>(object, 'Column3D')) {
    object.hiddenState.properties.opacity = 0;
  }
};

export default theme;
