<div bp-goal-detail-card class="flex items-center flex-shrink-0 w-full p-6 filter-info">
  <img
    class="h-20 w-auto mr-6"
    [ngClass]="{ 'rounded-full': imageFullRounded }"
    [src]="imageUrl"
    [alt]="alt"
  />
  <div class="">
    <ng-content></ng-content>
  </div>
</div>
