import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-g-p-transactions',
  templateUrl: './g-p-transactions.component.html',
  styleUrls: ['./g-p-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPTransactionsComponent implements OnInit, OnChanges {
  private _stop = new Subject<void>();
  constructor(private ref: ChangeDetectorRef) {}
  @Input() currency: CurrencyModel | undefined;
  @Input() fixedFromDate: string;
  @Input() fixedToDate: string;
  @Input() isLoading = false;
  @Input() deposits: number;
  @Input() withdrawals: number;
  @Input() countDeposits = 0;
  @Input() countWithdrawals = 0;
  @Input() showDates: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes.currency);
  }

  ngOnInit() {}
}
