import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ITooltip } from 'projects/bp-core/src/lib/models/portal/goal/tooltip.model';

@Component({
  selector: 'bp-tooltip-chart',
  templateUrl: './tooltip-chart.component.html',
  styleUrls: ['./tooltip-chart.component.scss'],
})
export class TooltipChartComponent implements OnInit, OnChanges {
  @ViewChild('dom') dom: ElementRef;

  @Input()
  tooltip: ITooltip;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}
}
