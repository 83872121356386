<mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
  <mat-label>Tipo de operación</mat-label>
  <mat-select [formControl]="formControl" multiple>
    @for (option of options; track option) {
      <mat-option [value]="option.id">
        {{ option.text }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
