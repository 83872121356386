import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IChart } from '@bp-core/src/lib/models/portal/chart.model';
import { IFunding } from '@bp-core/src/lib/models/portal/goal/funding.model';
import { IProfitavilityValue } from '@bp-core/src/lib/models/portal/goal/profitability-value.model';
import { GoalService } from '@bp-core/src/lib/services/portal/goal.service';
import { fuseAnimations } from '@fuse/animations';
import { ColorService } from 'bp/shared/services/color.service';
import dayjs from 'dayjs';
import { GoalModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { IntercomArticleRequest } from 'projects/bp-core/src/lib/proto/intercom.pb';
import { IntercomGrpcServiceClient } from 'projects/bp-core/src/lib/proto/intercom.pbsc';
import { FundingService } from 'projects/bp-core/src/lib/services/portal/funding.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-g-p-profitability-evolution',
  templateUrl: './g-p-profitability-evolution.component.html',
  styleUrls: ['./g-p-profitability-evolution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPProfitabilityEvolutionComponent implements OnInit, OnDestroy, OnChanges {
  private _stop = new Subject<void>();

  constructor(
    private goalService: GoalService,
    private ref: ChangeDetectorRef,
    private fundingService: FundingService,
    private intercomGrpcService: IntercomGrpcServiceClient,
    private colorService: ColorService,
  ) {}
  comparativeIds = new Set<number>();
  profitabilities: IChart;

  isLoading = true;
  fromDateFilter: Date;
  toDateFilter: Date;
  @Input()
  goalId: number | undefined;
  @Input()
  goals: GoalModel[] | undefined;
  @Input()
  dateRange = 1;
  @Input()
  fromDate: Date | undefined;
  @Input()
  toDate: Date | undefined;
  @Output()
  emitValueIcon = new EventEmitter<any>();
  @Output()
  emitInitFromDate = new EventEmitter<Date>();
  @Output()
  emitInitToDate = new EventEmitter<Date>();
  goalsProfitabilitiesAllTime: IProfitavilityValue[] = [];
  goalsProfitabilitiesPastYearReturn: IProfitavilityValue[] = [];
  fundings: IFunding[];
  lessThanAYear = false;
  initialFromDate: Date;
  initialToDate: Date;
  fixedFromDate: string;
  fixedToDate: string;
  showDates = false;
  showPortfolioUSDArticle = false;
  titleOfProfitabilityInDollarsArticle = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.goalId) {
      this._stop.next();
      this.getValues();
    }

    if (changes?.dateRange) {
      this.showDates = false;
      if (+this.dateRange !== 7) {
        switch (+this.dateRange!) {
          case 1:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
          case 2:
            this.toDateFilter = this.initialToDate;
            const dateLastYear = this.toDateFilter?.getFullYear() - 1;
            this.fromDateFilter = new Date(this.initialToDate);
            this.fromDateFilter?.setFullYear(dateLastYear);
            break;
          case 3:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.initialToDate);
            this.fromDateFilter?.setDate(this.toDateFilter?.getDate() - 30);
            break;
          case 4:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.initialToDate);
            this.fromDateFilter?.setMonth(this.toDateFilter?.getMonth() - 3);
            break;
          case 5:
            this.toDateFilter = new Date(this.initialToDate);
            this.fromDateFilter = new Date(this.toDateFilter?.getFullYear(), 0, 1);
            break;
          case 6:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.initialToDate);
            this.fromDateFilter?.setFullYear(this.toDateFilter?.getFullYear() - 2);
            break;
          default:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
        }
        this.fixedToDate = `${this.toDateFilter?.getDate()} ${this.toDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter?.getFullYear()}`;
        this.fixedFromDate = `${this.fromDateFilter?.getDate()} ${this.fromDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter?.getFullYear()}`;
        this._stop.next();
        this.getValues();
      }
    }

    if (+this.dateRange! == 7 && changes.fromDate) {
      this.showDates = false;
      this.fromDateFilter = this.fromDate!;
      if (this.fromDateFilter) {
        this.fixedFromDate = `${this.fromDateFilter.getDate()} ${this.fromDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getValues();
    }

    if (+this.dateRange! == 7 && changes.toDate) {
      this.showDates = false;
      this.toDateFilter = this.toDate!;
      if (this.toDateFilter) {
        this.fixedToDate = `${this.toDateFilter.getDate()} ${this.toDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getValues();
    }
  }

  ngOnInit() {
    this.isLoading = true;
    this.fundingService.list({ showInComparator: true }).subscribe(
      fundings => {
        //console.log('fundings', [fundings]);

        this.fundings = fundings.filter(
          element => element.id != 13 && element.id != 36 && element.id != 38,
        );
        // this.fundings = fundings;
        //this.isLoading = false;
        this.ref.detectChanges();
      },
      error => {
        Swal.fire({
          iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
          customClass: {
            container: 'swal2-fixe',
            confirmButton: 'btn',
            cancelButton: 'btn',
          },
          heightAuto: false,
          buttonsStyling: false,
          showClass: {
            icon: 'swal2-noanimation',
          },
          hideClass: {
            icon: 'swal2-noanimation',
          },
          footer:
            '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
          icon: 'error',
          title: 'Error!',
          text: 'No se pudo cargar los instrumentos financieros ..',
        });
        //this.isLoading = false;
        console.error(error);
      },
    );

    // GET titleOfProfitabilityInDollarsArticle
    this.intercomGrpcService
      .getArticle(
        new IntercomArticleRequest({
          idArticle: '6155985',
        }),
      )
      .subscribe(article => {
        if (article.value?.title) {
          this.titleOfProfitabilityInDollarsArticle = article.value?.title;
          this.ref.detectChanges();
        }
      });
  }
  ngOnDestroy() {
    this._stop.next();
    this._stop.complete();
  }
  onChangeFromDateFilter(value: Date) {
    this.fromDateFilter = value;
    this.getValues();
  }
  onChangeToDateFilter(value: Date) {
    this.toDateFilter = value;
    this.getValues();
  }

  onChangeComparative(ids: number[]) {
    this.comparativeIds.clear();
    ids.forEach(id => {
      if (!this.comparativeIds.has(id)) {
        this.comparativeIds.add(id);
      }
    });
    this._stop.next();
    this.getValues();
  }
  getValues() {
    this.isLoading = true;
    const query: any = {};
    let queryUSD: any = {};

    if (this.fromDateFilter) {
      query.fromDate = this.fromDateFilter;
    }
    if (this.toDateFilter) {
      query.toDate = this.toDateFilter;
    }
    if (this.goalId) {
      query.goalId = this.goalId;
    }

    if (this.comparativeIds.size > 0) {
      query.funds = [...this.comparativeIds];
    }

    let goalProbabilities = [this.goalService.probabilities(query)];

    if (this.goals?.length == 1) {
      if (
        this.goals[0].portfolio?.extraProfitabilityCurrencyCode?.length! > 0 &&
        this.goals[0].currency?.currencyCode !=
          this.goals[0].portfolio?.extraProfitabilityCurrencyCode
      ) {
        query.currencyCode = 'CLP';
        goalProbabilities = [this.goalService.probabilities(query)];

        queryUSD = { ...query };
        queryUSD.currencyCode = this.goals[0].portfolio?.extraProfitabilityCurrencyCode ?? '--';
        queryUSD.funds = [];
        goalProbabilities = goalProbabilities.concat(this.goalService.probabilities(queryUSD));
        this.showPortfolioUSDArticle = true;
      }
    }

    combineLatest(goalProbabilities)
      .pipe(takeUntil(this._stop))
      .subscribe(
        profitabilitiesResult => {
          const profitabilities = profitabilitiesResult[0]!;

          let profitabilitiesUSD: IChart | undefined;
          if (profitabilitiesResult.length > 1) {
            profitabilitiesUSD = profitabilitiesResult[1];
          }

          if (profitabilitiesUSD) {
            profitabilitiesUSD.values.forEach(valueUSDItem => {
              profitabilities.values.splice(1, 0, valueUSDItem);
            });
          }

          profitabilities.values.map((data, index) => {
            if (index > 1) {
              data.color = this.colorService.getGreen(10 - index * 2);
            } else {
              data.color = this.colorService.getBlue(index * 5);
            }
            return data;
          });
          //unificar salidas
          profitabilities.data = profitabilities.data.map((data, index) => {
            const usditemSameDate = profitabilitiesUSD?.data.filter(f => f.date == data.date);
            usditemSameDate?.forEach(item => {
              data = { ...data, ...item };
            });
            return data;
          });

          profitabilities.data =
            profitabilities.data.sort(
              // @ts-ignore
              (a, b) => new Date(a.date) - new Date(b.date),
            ) ?? [];
          this.profitabilities = profitabilities;

          if (this.profitabilities.values.length == 1) {
            const lastYear = new Date();
            lastYear.setFullYear(lastYear.getFullYear() - 1);
            if (Date.parse(profitabilities.data[0].date) > lastYear.getTime()) {
              this.profitabilities.values[0].pastYearReturn = '--';
              this.lessThanAYear = true;
            }
            this.emitValueIcon.emit(this.profitabilities.values[0]);
          }

          if (this.profitabilities.data.length > 0) {
            if (+this.dateRange == 1 || +this.dateRange == 0) {
              this.fromDateFilter = dayjs(this.profitabilities.data[0].date as Date).toDate();
              this.initialFromDate = this.fromDateFilter;
              this.toDateFilter = dayjs(
                this.profitabilities.data[this.profitabilities.data.length - 1].date as Date,
              ).toDate();
              this.initialToDate = this.toDateFilter;
              this.fixedToDate = `${this.toDateFilter.getDate()} ${this.toDateFilter.toLocaleString(
                'es-ES',
                { month: 'short' },
              )} ${this.toDateFilter.getFullYear()}`;
              this.fixedFromDate = `${this.fromDateFilter.getDate()} ${this.fromDateFilter.toLocaleString(
                'es-ES',
                { month: 'short' },
              )} ${this.fromDateFilter.getFullYear()}`;
              this.emitInitFromDate.emit(this.initialFromDate);
              this.emitInitToDate.emit(this.initialToDate);
            }
            this.showDates = true;
          } else {
            this.showDates = false;
          }

          //   console.log(profitabilities);
          this.isLoading = false;

          this.ref.detectChanges();
        },
        error => {
          Swal.fire({
            iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
            customClass: {
              container: 'swal2-fixe',
              confirmButton: 'btn',
              cancelButton: 'btn',
            },
            heightAuto: false,
            buttonsStyling: false,
            showClass: {
              icon: 'swal2-noanimation',
            },
            hideClass: {
              icon: 'swal2-noanimation',
            },
            footer:
              '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
            icon: 'error',
            title: 'Error!',
            text: 'No se pudo cargar la rentabilidad ..',
          });
          this.isLoading = false;
          console.error(error);
        },
      );
    // let whereProfitabilityValue: any = null;
    // const goalsProfitabilitiesPastYearReturn: IProfitavilityValue[] = [];
    // const goalsProfitabilitiesAllTime: IProfitavilityValue[] = [];
    // if (this.fromDateFilter && this.toDateFilter) {
    //   whereProfitabilityValue = {
    //     and: [
    //       { date: { gte: this.fromDateFilter } },
    //       { date: { lte: this.toDateFilter } },
    //       { or: [{ type: 'allTime' }, { type: 'pastYearReturn' }] }
    //     ]
    //   };
    // } else if (this.fromDateFilter) {
    //   whereProfitabilityValue = {
    //     and: [
    //       { date: { gte: this.fromDateFilter } },
    //       {
    //         or: [{ type: 'allTime' }, { type: 'pastYearReturn' }]
    //       }
    //     ]
    //   };
    // } else if (this.toDateFilter) {
    //   whereProfitabilityValue = {
    //     and: [
    //       { date: { lte: this.toDateFilter } },
    //       { or: [{ type: 'allTime' }, { type: 'pastYearReturn' }] }
    //     ]
    //   };
    // } else {
    //   whereProfitabilityValue = {
    //     or: [{ type: 'allTime' }, { type: 'pastYearReturn' }]
    //   };
    // }
    // let limit = 2;
    // if (this.goals) {
    //   limit = 2 * this.goals.length;
    // }
    // combineLatest([
    //   this.goalService.getProfitabilityValues(
    //     this.goalId,
    //     whereProfitabilityValue
    //   ),
    //   this.goalService.getProfitabilityValues(
    //     this.goalId,
    //     whereProfitabilityValue,
    //     limit,
    //     'date DESC'
    //   )
    // ]).subscribe(
    //   ([values, valuesSimple]) => {
    //     // TODO: Solucion parche de agrupacion de metas hasta que se implemente los valores de rentabilidad correctamente
    //     const dictgoalsProfitabilitiesPastYearReturn = {};
    //     const dictgoalsProfitabilitiesAllTime = {};
    //     valuesSimple.forEach(value => {
    //       if (value.type === 'pastYearReturn') {
    //         if (!dictgoalsProfitabilitiesPastYearReturn[value.goalId]) {
    //           goalsProfitabilitiesPastYearReturn.push(value);
    //           dictgoalsProfitabilitiesPastYearReturn[value.goalId] = true;
    //         }
    //       } else if (value.type === 'allTime') {
    //         if (!dictgoalsProfitabilitiesAllTime[value.goalId]) {
    //           goalsProfitabilitiesAllTime.push(value);
    //           dictgoalsProfitabilitiesAllTime[value.goalId] = true;
    //         }
    //       }
    //     });

    //     // alltIme
    //     const allTime = {};
    //     const allTimeConfig = {};

    //     // pastYearReturn
    //     const pastYearReturn = {};
    //     const pastYearReturnConfig = {};
    //     const colorsByGoal = {};
    //     values.forEach((value, index) => {
    //       switch (value.type) {
    //         case 'dailyReturn':
    //           break;
    //         case 'pastMonthReturn':
    //           break;
    //         case 'pastYearReturn':
    //           // if (!pastYearReturnConfig[value.fundingId + '' + value.goalId]) {
    //           //   pastYearReturnConfig[value.fundingId + '' + value.goalId] = {
    //           //     title: `Anual - ${value.funding.title} ${
    //           //       this.goalId ? '' : ' - ' + value.goal.title
    //           //     }`,
    //           //     name: value.fundingId + '' + value.goalId
    //           //   };
    //           // }
    //           // const datenet = new Date(value.date);
    //           // if (!pastYearReturn[datenet.toUTCString()]) {
    //           //   pastYearReturn[datenet.toUTCString()] = {
    //           //     date: value.date
    //           //   };
    //           // }
    //           // pastYearReturn[datenet.toUTCString()][
    //           //   value.fundingId + '' + value.goalId
    //           // ] = value.value;

    //           break;
    //         case 'allTime':
    //           if (
    //             !allTimeConfig[value.fundingId + '' + value.goalId] &&
    //             value.funding &&
    //             value.goal
    //           ) {
    //             allTimeConfig[value.fundingId + '' + value.goalId] = {
    //               title: `${value.funding.title} ${
    //                 this.goalId ? '' : ' - ' + value.goal.title
    //               }`,
    //               name: value.fundingId + '' + value.goalId,
    //               color: getColor(object.keys(allTimeConfig).length)
    //             };
    //             colorsByGoal[value.goalId] = getColor(
    //               object.keys(allTimeConfig).length
    //             );
    //           }
    //           const datebal = new Date(value.date);
    //           if (!allTime[datebal.toUTCString()]) {
    //             allTime[datebal.toUTCString()] = {
    //               date: value.date
    //             };
    //           }
    //           allTime[datebal.toUTCString()][
    //             value.fundingId + '' + value.goalId
    //           ] = value.value;
    //           break;
    //         case 'earnings':
    //           break;
    //         case 'netDeposit':
    //           break;
    //         case 'balance':
    //           break;
    //         case 'quotas':
    //           break;
    //         case 'yearToDate':
    //           break;
    //         default:
    //           break;
    //       }
    //     });

    //     const profitabilities: IChart = {
    //       data: [],
    //       values: []
    //     };

    //     for (const key in pastYearReturnConfig) {
    //       if (pastYearReturnConfig.hasOwnProperty(key)) {
    //         profitabilities.values.push(pastYearReturnConfig[key]);
    //       }
    //     }
    //     for (const key in pastYearReturn) {
    //       if (pastYearReturn.hasOwnProperty(key)) {
    //         profitabilities.data.push(pastYearReturn[key]);
    //       }
    //     }

    //     for (const key in allTimeConfig) {
    //       if (allTimeConfig.hasOwnProperty(key)) {
    //         profitabilities.values.push(allTimeConfig[key]);
    //       }
    //     }
    //     for (const key in allTime) {
    //       if (allTime.hasOwnProperty(key)) {
    //         profitabilities.data.push(allTime[key]);
    //       }
    //     }

    //     for (const key in colorsByGoal) {
    //       if (colorsByGoal.hasOwnProperty(key)) {
    //         if (this.goals) {
    //           const goal = this.goals.find(goal => (goal.id = Number(key)));
    //           if (goal) {
    //             if (!goal.icon) {
    //               goal.icon = { code: null };
    //             }
    //             goal.icon.color = colorsByGoal[key];
    //           }
    //         }

    //         goalsProfitabilitiesPastYearReturn.forEach(prof => {
    //           if (prof.goalId === Number(key)) {
    //             if (!prof.goal.icon) {
    //               prof.goal.icon = { code: null };
    //             }
    //             prof.goal.icon.color = colorsByGoal[key];
    //           }
    //         });

    //         goalsProfitabilitiesAllTime.forEach(prof => {
    //           if (prof.goalId === Number(key)) {
    //             if (!prof.goal.icon) {
    //               prof.goal.icon = { code: null };
    //             }
    //             prof.goal.icon.color = colorsByGoal[key];
    //           }
    //         });
    //       }
    //     }
    //     this.goalsProfitabilitiesPastYearReturn = goalsProfitabilitiesPastYearReturn;
    //     this.goalsProfitabilitiesAllTime = goalsProfitabilitiesAllTime;
    //     console.log(
    //       goalsProfitabilitiesAllTime,
    //       goalsProfitabilitiesPastYearReturn
    //     );
    //     // this.profitabilities = profitabilities;
    //     this.isLoading = false;
    //     this.ref.detectChanges();
    //     // this.balanceValues = [balance, netDeposit, earnings];
    //   },
    //   error => {
    //     Swal.fire({
    //       title: 'Error!',
    //       text: 'No se pudo cargar la rentabilidad ..',
    //       icon: 'error'
    //     });
    //     this.isLoading = false;
    //     console.error(error);
    //   }
    // );
  }
}
