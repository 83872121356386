<div class="flex flex-col w-full">
  <ng-container>
    @if (showTitle && blogQuestions?.length! > 0) {
      <div bp-title-card>
        Preguntas frecuentes
      </div>
    }
    @if (!legacyStyle) {
      @if (!areLinkQuestions && blogQuestions) {
        <mat-accordion class="mt-6 ">
          @for (faq of blogQuestions; track faq) {
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'56px'">
                <mat-panel-title class="text-base font-medium leading-tight">
                  {{ faq.title?.rendered }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="m-0 text-base" [innerHTML]="faq.content?.rendered"></p>
            </mat-expansion-panel>
          }
        </mat-accordion>
      }
    }

    @if (legacyStyle) {
      @if (blogQuestions) {
        @for (faq of blogQuestions; track faq) {
          <mat-expansion-panel
            class="px-1 mx-0 mb-0 bg-transparent shadow-none text-accent legacy"
            togglePosition="before"
            >
            <mat-expansion-panel-header
              class="h-auto px-0 py-2 hover:bg-transparent text-accent legacy"
              [collapsedHeight]="'56px'"
              >
              <mat-panel-title class="text-base font-semibold leading-tight ">
                {{ faq.title?.rendered }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="m-0 text-base" [innerHTML]="faq.content?.rendered"></p>
          </mat-expansion-panel>
        }
      }
    }

    @if (areLinkQuestions && blogQuestions) {
      <ul class="">
        @for (q of blogQuestions; track q) {
          <div>
            <li class="py-2 ">
              <a [href]="q.link" target="_blank">
                <span bp-link>{{ q.title?.rendered }}</span>
              </a>
            </li>
          </div>
        }
      </ul>
    }
  </ng-container>
</div>
