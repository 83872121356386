/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './portal-goal.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as googleProtobuf002 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums003 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums004 from './goal-transaction-type-enums.pb';
import * as file_message005 from './file-message.pb';
import * as common_message006 from './common-message.pb';
import * as goal_transaction_payment_enum007 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum008 from './goal-transaction-origin-type-enum.pb';
import * as goal_transaction009 from './goal-transaction.pb';
import * as portfolio010 from './portfolio.pb';
import * as get_started_common011 from './get-started-common.pb';
import { GRPC_PORTAL_GOAL_GRPC_SERVICE_CLIENT_SETTINGS } from './portal-goal.pbconf';
/**
 * Service client implementation for portal_goal.PortalGoalGrpcService
 */
@Injectable({ providedIn: 'any' })
export class PortalGoalGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/UpdateById
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    updateById: (
      requestData: thisProto.UpdateByIdRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/UpdateById',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateByIdRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetBalanceNetDeposit
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BalanceNetDepositModel>>
     */
    getBalanceNetDeposit: (
      requestData: thisProto.GetBalanceNetDepositRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BalanceNetDepositModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetBalanceNetDeposit',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBalanceNetDepositRequest,
        responseClass: thisProto.BalanceNetDepositModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetBalanceNetDepositGraph
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.BalanceNetDepositGraphDetailModel>>
     */
    getBalanceNetDepositGraph: (
      requestData: thisProto.GetBalanceNetDepositGraphRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<common_message006.BalanceNetDepositGraphDetailModel>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetBalanceNetDepositGraph',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetBalanceNetDepositGraphRequest,
        responseClass: common_message006.BalanceNetDepositGraphDetailModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetCurrentFundings
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetCurrentFundingsResponse>>
     */
    getCurrentFundings: (
      requestData: thisProto.GetCurrentFundingsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetCurrentFundingsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetCurrentFundings',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetCurrentFundingsRequest,
        responseClass: thisProto.GetCurrentFundingsResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/Rescue
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.TransactionRequestModel>>
     */
    rescue: (
      requestData: thisProto.RescueRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.TransactionRequestModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/Rescue',
        requestData,
        requestMetadata,
        requestClass: thisProto.RescueRequest,
        responseClass: common_message006.TransactionRequestModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/Irr
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.IrrResponse>>
     */
    irr: (
      requestData: thisProto.IrrRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.IrrResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/Irr',
        requestData,
        requestMetadata,
        requestClass: thisProto.IrrRequest,
        responseClass: thisProto.IrrResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetMe
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    getMe: (
      requestData: common_message006.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetMe',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseGetRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/ListMe
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.ListGoalResponse>>
     */
    listMe: (
      requestData: common_message006.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.ListGoalResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/ListMe',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseListRequest,
        responseClass: common_message006.ListGoalResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/RequestDeposit
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RequestDepositResponse>>
     */
    requestDeposit: (
      requestData: thisProto.RequestDepositRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RequestDepositResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/RequestDeposit',
        requestData,
        requestMetadata,
        requestClass: thisProto.RequestDepositRequest,
        responseClass: thisProto.RequestDepositResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/ConfirmDeposit
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ConfirmDepositResponse>>
     */
    confirmDeposit: (
      requestData: thisProto.ConfirmDepositRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ConfirmDepositResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/ConfirmDeposit',
        requestData,
        requestMetadata,
        requestClass: thisProto.ConfirmDepositRequest,
        responseClass: thisProto.ConfirmDepositResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/CancelDeposit
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CancelDepositResponse>>
     */
    cancelDeposit: (
      requestData: thisProto.CancelDepositRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CancelDepositResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/CancelDeposit',
        requestData,
        requestMetadata,
        requestClass: thisProto.CancelDepositRequest,
        responseClass: thisProto.CancelDepositResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/Activate
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    activate: (
      requestData: thisProto.ActivateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/Activate',
        requestData,
        requestMetadata,
        requestClass: thisProto.ActivateRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/CreateGoal
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    createGoal: (
      requestData: thisProto.CreateGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/CreateGoal',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateGoalRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetActivities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<goal_transaction009.ListGoalTransactionResponse>>
     */
    getActivities: (
      requestData: thisProto.GetActivitiesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<goal_transaction009.ListGoalTransactionResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetActivities',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetActivitiesRequest,
        responseClass: goal_transaction009.ListGoalTransactionResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/ListGoalTransactionActivities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListGoalTransactionActivitiesResponse>>
     */
    listGoalTransactionActivities: (
      requestData: thisProto.ListGoalTransactionActivitiesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.ListGoalTransactionActivitiesResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_goal.PortalGoalGrpcService/ListGoalTransactionActivities',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListGoalTransactionActivitiesRequest,
        responseClass: thisProto.ListGoalTransactionActivitiesResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/CheckStarredGoal
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CheckStarredGoalResponse>>
     */
    checkStarredGoal: (
      requestData: thisProto.CheckStarredGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CheckStarredGoalResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/CheckStarredGoal',
        requestData,
        requestMetadata,
        requestClass: thisProto.CheckStarredGoalRequest,
        responseClass: thisProto.CheckStarredGoalResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/SetStarredGoal
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    setStarredGoal: (
      requestData: thisProto.BaseGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/SetStarredGoal',
        requestData,
        requestMetadata,
        requestClass: thisProto.BaseGoalRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllProfitabilities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ProfitabilitiesResponse>>
     */
    getAllProfitabilities: (
      requestData: thisProto.GetAllProfitabilitiesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ProfitabilitiesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetAllProfitabilities',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetAllProfitabilitiesRequest,
        responseClass: thisProto.ProfitabilitiesResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/ArchiveGoal
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.GoalModel>>
     */
    archiveGoal: (
      requestData: thisProto.BaseGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.GoalModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/ArchiveGoal',
        requestData,
        requestMetadata,
        requestClass: thisProto.BaseGoalRequest,
        responseClass: common_message006.GoalModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetPortfolioByGoalId
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.PortfolioModel>>
     */
    getPortfolioByGoalId: (
      requestData: thisProto.BaseGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.PortfolioModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetPortfolioByGoalId',
        requestData,
        requestMetadata,
        requestClass: thisProto.BaseGoalRequest,
        responseClass: common_message006.PortfolioModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetPortfolio
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message006.PortfolioModel>>
     */
    getPortfolio: (
      requestData: common_message006.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message006.PortfolioModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetPortfolio',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseGetRequest,
        responseClass: common_message006.PortfolioModel
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllPortfolios
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<portfolio010.ListPortfolioResponse>>
     */
    getAllPortfolios: (
      requestData: common_message006.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<portfolio010.ListPortfolioResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetAllPortfolios',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseListRequest,
        responseClass: portfolio010.ListPortfolioResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetListPortfoliosWithRelations
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<portfolio010.ListPortfolioResponse>>
     */
    getListPortfoliosWithRelations: (
      requestData: thisProto.GetListPortfoliosWithRelationsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<portfolio010.ListPortfolioResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_goal.PortalGoalGrpcService/GetListPortfoliosWithRelations',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetListPortfoliosWithRelationsRequest,
        responseClass: portfolio010.ListPortfolioResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetPortfolioComposition
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetPortfolioCompositionResponse>>
     */
    getPortfolioComposition: (
      requestData: thisProto.BaseGoalRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetPortfolioCompositionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetPortfolioComposition',
        requestData,
        requestMetadata,
        requestClass: thisProto.BaseGoalRequest,
        responseClass: thisProto.GetPortfolioCompositionResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllGoalRiskLevelCalculation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<get_started_common011.ListGoalRiskLevelCalculationResponse>>
     */
    getAllGoalRiskLevelCalculation: (
      requestData: common_message006.BaseListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<get_started_common011.ListGoalRiskLevelCalculationResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_goal.PortalGoalGrpcService/GetAllGoalRiskLevelCalculation',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseListRequest,
        responseClass:
          get_started_common011.ListGoalRiskLevelCalculationResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllRiskLevel
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<get_started_common011.ListRiskLevelsResponse>>
     */
    getAllRiskLevel: (
      requestData: googleProtobuf000.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<get_started_common011.ListRiskLevelsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetAllRiskLevel',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf000.Empty,
        responseClass: get_started_common011.ListRiskLevelsResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllTimeHorizon
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<get_started_common011.ListTimeHorizonsResponse>>
     */
    getAllTimeHorizon: (
      requestData: googleProtobuf000.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<get_started_common011.ListTimeHorizonsResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetAllTimeHorizon',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf000.Empty,
        responseClass: get_started_common011.ListTimeHorizonsResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/GetAllSpreadRanges
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAllSpreadRangesResponse>>
     */
    getAllSpreadRanges: (
      requestData: googleProtobuf000.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAllSpreadRangesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/GetAllSpreadRanges',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf000.Empty,
        responseClass: thisProto.GetAllSpreadRangesResponse
      });
    },
    /**
     * Unary call: /portal_goal.PortalGoalGrpcService/CalculateSpread
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CalculateSpreadResponse>>
     */
    calculateSpread: (
      requestData: thisProto.CalculateSpreadRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CalculateSpreadResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_goal.PortalGoalGrpcService/CalculateSpread',
        requestData,
        requestMetadata,
        requestClass: thisProto.CalculateSpreadRequest,
        responseClass: thisProto.CalculateSpreadResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_PORTAL_GOAL_GRPC_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'portal_goal.PortalGoalGrpcService',
      settings
    );
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/UpdateById
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  updateById(
    requestData: thisProto.UpdateByIdRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .updateById(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetBalanceNetDeposit
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BalanceNetDepositModel>
   */
  getBalanceNetDeposit(
    requestData: thisProto.GetBalanceNetDepositRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BalanceNetDepositModel> {
    return this.$raw
      .getBalanceNetDeposit(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetBalanceNetDepositGraph
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.BalanceNetDepositGraphDetailModel>
   */
  getBalanceNetDepositGraph(
    requestData: thisProto.GetBalanceNetDepositGraphRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.BalanceNetDepositGraphDetailModel> {
    return this.$raw
      .getBalanceNetDepositGraph(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetCurrentFundings
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetCurrentFundingsResponse>
   */
  getCurrentFundings(
    requestData: thisProto.GetCurrentFundingsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetCurrentFundingsResponse> {
    return this.$raw
      .getCurrentFundings(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/Rescue
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.TransactionRequestModel>
   */
  rescue(
    requestData: thisProto.RescueRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.TransactionRequestModel> {
    return this.$raw
      .rescue(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/Irr
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.IrrResponse>
   */
  irr(
    requestData: thisProto.IrrRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.IrrResponse> {
    return this.$raw
      .irr(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetMe
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  getMe(
    requestData: common_message006.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .getMe(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/ListMe
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.ListGoalResponse>
   */
  listMe(
    requestData: common_message006.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.ListGoalResponse> {
    return this.$raw
      .listMe(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/RequestDeposit
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RequestDepositResponse>
   */
  requestDeposit(
    requestData: thisProto.RequestDepositRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RequestDepositResponse> {
    return this.$raw
      .requestDeposit(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/ConfirmDeposit
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ConfirmDepositResponse>
   */
  confirmDeposit(
    requestData: thisProto.ConfirmDepositRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ConfirmDepositResponse> {
    return this.$raw
      .confirmDeposit(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/CancelDeposit
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CancelDepositResponse>
   */
  cancelDeposit(
    requestData: thisProto.CancelDepositRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CancelDepositResponse> {
    return this.$raw
      .cancelDeposit(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/Activate
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  activate(
    requestData: thisProto.ActivateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .activate(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/CreateGoal
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  createGoal(
    requestData: thisProto.CreateGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .createGoal(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetActivities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<goal_transaction009.ListGoalTransactionResponse>
   */
  getActivities(
    requestData: thisProto.GetActivitiesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<goal_transaction009.ListGoalTransactionResponse> {
    return this.$raw
      .getActivities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/ListGoalTransactionActivities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListGoalTransactionActivitiesResponse>
   */
  listGoalTransactionActivities(
    requestData: thisProto.ListGoalTransactionActivitiesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListGoalTransactionActivitiesResponse> {
    return this.$raw
      .listGoalTransactionActivities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/CheckStarredGoal
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CheckStarredGoalResponse>
   */
  checkStarredGoal(
    requestData: thisProto.CheckStarredGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CheckStarredGoalResponse> {
    return this.$raw
      .checkStarredGoal(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/SetStarredGoal
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  setStarredGoal(
    requestData: thisProto.BaseGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .setStarredGoal(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllProfitabilities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ProfitabilitiesResponse>
   */
  getAllProfitabilities(
    requestData: thisProto.GetAllProfitabilitiesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ProfitabilitiesResponse> {
    return this.$raw
      .getAllProfitabilities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/ArchiveGoal
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.GoalModel>
   */
  archiveGoal(
    requestData: thisProto.BaseGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.GoalModel> {
    return this.$raw
      .archiveGoal(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetPortfolioByGoalId
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.PortfolioModel>
   */
  getPortfolioByGoalId(
    requestData: thisProto.BaseGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.PortfolioModel> {
    return this.$raw
      .getPortfolioByGoalId(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetPortfolio
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message006.PortfolioModel>
   */
  getPortfolio(
    requestData: common_message006.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message006.PortfolioModel> {
    return this.$raw
      .getPortfolio(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllPortfolios
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<portfolio010.ListPortfolioResponse>
   */
  getAllPortfolios(
    requestData: common_message006.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<portfolio010.ListPortfolioResponse> {
    return this.$raw
      .getAllPortfolios(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetListPortfoliosWithRelations
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<portfolio010.ListPortfolioResponse>
   */
  getListPortfoliosWithRelations(
    requestData: thisProto.GetListPortfoliosWithRelationsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<portfolio010.ListPortfolioResponse> {
    return this.$raw
      .getListPortfoliosWithRelations(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetPortfolioComposition
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetPortfolioCompositionResponse>
   */
  getPortfolioComposition(
    requestData: thisProto.BaseGoalRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetPortfolioCompositionResponse> {
    return this.$raw
      .getPortfolioComposition(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllGoalRiskLevelCalculation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<get_started_common011.ListGoalRiskLevelCalculationResponse>
   */
  getAllGoalRiskLevelCalculation(
    requestData: common_message006.BaseListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<get_started_common011.ListGoalRiskLevelCalculationResponse> {
    return this.$raw
      .getAllGoalRiskLevelCalculation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllRiskLevel
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<get_started_common011.ListRiskLevelsResponse>
   */
  getAllRiskLevel(
    requestData: googleProtobuf000.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<get_started_common011.ListRiskLevelsResponse> {
    return this.$raw
      .getAllRiskLevel(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllTimeHorizon
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<get_started_common011.ListTimeHorizonsResponse>
   */
  getAllTimeHorizon(
    requestData: googleProtobuf000.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<get_started_common011.ListTimeHorizonsResponse> {
    return this.$raw
      .getAllTimeHorizon(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/GetAllSpreadRanges
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAllSpreadRangesResponse>
   */
  getAllSpreadRanges(
    requestData: googleProtobuf000.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAllSpreadRangesResponse> {
    return this.$raw
      .getAllSpreadRanges(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_goal.PortalGoalGrpcService/CalculateSpread
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CalculateSpreadResponse>
   */
  calculateSpread(
    requestData: thisProto.CalculateSpreadRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CalculateSpreadResponse> {
    return this.$raw
      .calculateSpread(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
