import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { IChart } from 'projects/bp-core/src/lib/models/portal/chart.model';
import { IFunding } from 'projects/bp-core/src/lib/models/portal/goal/funding.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-g-p-p-e-table',
  templateUrl: './g-p-p-e-table.component.html',
  styleUrls: ['./g-p-p-e-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPPETableComponent implements OnInit, OnDestroy, AfterViewInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  @Input()
  lessThanAYear: boolean;
  @Input()
  fundings: IFunding[];
  @Input()
  profitabilities: IChart;
  @Output()
  changeComparative = new EventEmitter<number[]>();
  valueSelected = 'allTime';
  comparativeControl = new FormControl([0]);
  constructor() {}
  ngAfterViewInit(): void {
    console.log(this.profitabilities);
  }

  ngOnInit() {
    this.comparativeControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      //console.log('valor:', value);
      this.changeComparative.emit(value!);
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
