import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IChart } from '@bp-core/src/lib/models/portal/chart.model';
import { ITooltip } from '@bp-core/src/lib/models/portal/goal/tooltip.model';
import { environment } from '@environment/environment';
import { ColorService } from 'bp/shared/services/color.service';
import moment from 'moment';
import am4themes_betterplan from 'projects/bp-advisor/src/bp/shared/chart/betterplan-theme-amchart';
import { TooltipChartComponent } from 'projects/bp-advisor/src/bp/shared/components/tooltip-chart/tooltip-chart.component';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
am4core.addLicense(environment.amchartLicence);

am4core.useTheme(am4themes_betterplan);
am4core.options.autoDispose = false;

@Component({
  selector: 'app-g-p-b-chart-single',
  templateUrl: './g-p-b-chart-single.component.html',
  styleUrls: ['./g-p-b-chart-single.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GPBChartSingleComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  constructor(
    private zone: NgZone,
    private currencyPipe: CurrencyPipe,
    private cd: ChangeDetectorRef,
    private colorService: ColorService,
  ) {}

  private chart: am4charts.XYChart;
  @Input()
  balance: IChart;

  @Input()
  currency: CurrencyModel | undefined;

  @ViewChild('tool') tool: TooltipChartComponent;

  tooltip: ITooltip = {
    title: '',
    values: [],
  };
  balanceColor = this.colorService.getBlue(0);
  // depositColor = '#20cb9b';
  depositColor = this.colorService.getGreen(0);
  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart && this.balance && this.currency) {
      this.chart.data = this.balance.data;

      // console.log(this.balance);
      // console.log(this.balance.values);
    }
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
  addTooltipHtml(lineSeries: am4charts.ColumnSeries) {
    if (!lineSeries) {
      return;
    }

    lineSeries.adapter.add('tooltipHTML', (tooltipHTML, target) => {
      let data = target.tooltipDataItem.dataContext as any;
      if (data) {
        const value = this.currencyPipe.transform(
          data.value,
          this.currency?.currencyCode,
          this.currency?.display,
          this.currency?.digitsInfo,
        );
        let tooltipObj: ITooltip = {
          title: moment(data.date).format('DD MMM yyyy'),
          values: [],
        };
        tooltipObj.values.push({
          label: data.category,
          value: value!,
          color: data.category == 'Balance' ? this.balanceColor : this.depositColor,
        });

        this.tooltip = { ...tooltipObj };
        this.cd.detectChanges();
      }
      return `${this.tool.dom.nativeElement.innerHTML}`;
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        const chart = am4core.create('chartdivperformancebalance', am4charts.XYChart);
        this.chart = chart;
        this.chart.tapToActivate = true;
        this.chart.paddingRight = 0;
        this.chart.paddingLeft = 0;
        this.chart.responsive.enabled = true;
        this.chart.responsive.useDefault = false;
        const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';

        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 0.5;
        //  dateAxis.dateFormats.setKey('day', 'dd');
        // dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy[/]');
        //   dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM[/]');
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        //  valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 0.5;
        valueAxis.min = 0;
        valueAxis.renderer.padding(0, 0, 0, 0);
        valueAxis.renderer.margin(0, 0, 0, 0);
        valueAxis.numberFormatter.numberFormat = this.currency?.chartAxisFormatNumber!; // `'$'#.##!a`;
        valueAxis.numberFormatter.bigNumberPrefixes = [
          { number: 1e3, suffix: 'k' },
          { number: 1e6, suffix: 'M' },
        ];
        //  contributionSeries.defaultState.transitionDuration = 1000;
        // this.chart.legend = new am4charts.Legend();
        // chart.legend.contentAlign = 'left';
        // chart.legend.position = 'top';

        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.xAxis = categoryAxis;
        this.chart.cursor.lineY.disabled = true;
        //   this.chart.numberFormatter.numberFormat = '$#,###';
        this.chart.language.locale = am4lang_es_ES;
        // this.chart.responsive.enabled = true;
        //  this.chart.legend.useDefaultMarker = true;
        //   this.chart.legend.labels.template.fontSize = 12;
        // //  this.chart.fontSize = 12;
        // const marker = chart.legend.markers.template.children.getIndex(0);
        // (marker as any).cornerRadius(12, 12, 12, 12);
        // marker.height = 15;
        // marker.width = 15;
        const columnSeries = this.chart.series.push(new am4charts.ColumnSeries());
        columnSeries.dataFields.categoryX = 'category';
        columnSeries.dataFields.valueY = 'value';
        this.addTooltipHtml(columnSeries);

        //  lineSeries.name = value.title;
        // columnSeries.tooltipText =
        //   `[bold]{categoryX}[/] \n {valueY.formatNumber('` +
        //   this.currency?.chartTooltipFormatNumber +
        //   `')}`;
        //   lineSeries.stacked = true;
        columnSeries.fillOpacity = 0.3;
        columnSeries.columns.template.adapter.add('fill', (fill, target) => {
          let data = target.tooltipDataItem.dataContext as any;
          return data.category == 'Balance'
            ? am4core.color(this.balanceColor)
            : am4core.color(this.depositColor);
        });

        if (this.balance) {
          //  console.log(this.balance);
          this.chart.data = this.balance.data;
        }
        if (this.chart.series.length > 0) {
          this.chart.series.values[0].tooltip!.getFillFromObject = false;
          this.chart.series.values[0].tooltip!.background.fill = am4core.color('#FFFFFF');
          this.chart.series.values[0].tooltip!.autoTextColor = false;
          this.chart.series.values[0].tooltip!.label.fill = am4core.color('black');
        }
        this.onResize();
      });
    });
  }
  /**
   * funcion para cambiar el tamaño de las letras de la gráfica respecto a la pantall
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    let screenWidth;
    if (event) {
      screenWidth = event.target.innerWidth;
    } else {
      screenWidth = window.innerWidth;
    }
    // let fontSizeBase = 13;
    if (this.chart) {
      switch (true) {
        case screenWidth < 576:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'none';
          this.chart.zoomOutButton.disabled = true;
          break;
        case screenWidth < 768:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
        default:
          this.chart.fontSize = 12;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
      }
    }
  }
}
