import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IChart } from '@bp-core/src/lib/models/portal/chart.model';
import { fuseAnimations } from '@fuse/animations';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';

@Component({
  selector: 'app-g-p-balance',
  templateUrl: './g-p-balance.component.html',
  styleUrls: ['./g-p-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPBalanceComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() balance: IChart;
  @Input() balanceSingle: IChart;

  @Input() isLoading = true;
  @Input() hasAllFinancialInstitutions: boolean | undefined;

  @Input() currency: CurrencyModel | undefined;
  @Input() balanceTotalAmount: number;

  @Input() deposits: number;
  @Input() totalCurrentCapital: number | undefined;
  @Input() dateFinalBalance: Date | undefined;
  @Input() withdrawals: number;
  @Input() balanceTotalDate: Date;
  @Input() singleChart = false;
  @Input() balanceInitDate: Date | null;
  @Input() balanceInitAmount = 0;
  @Input() netDeposit = 0;
  @Input() fixedFromDate: string;
  @Input() fixedToDate: string;
  @Input() showDates: boolean;

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {}
}
