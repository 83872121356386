<div #dom>
  <div>
    <p class="text-secondary">
      {{ tooltip.title }}
    </p>
    <div class="flex flex-row">
      <div class="flex flex-col">
        @for (t of tooltip.values; track t) {
          <div class="flex items-center">
            <div
              [ngStyle]="{ 'background-color': t.color }"
              class="w-2 mr-3"
            [ngClass]="{
              'rounded-xl': t.label !== 'Depósitos',
              'h-2': t.label !== 'Depósitos',
              'h-1': t.label === 'Depósitos'
            }"
            ></div>
            {{ t.label }}
          </div>
        }
      </div>
      <div class="flex flex-col">
        @for (t of tooltip.values; track t) {
          <div class="ml-3 text-right">
            {{ t.sign }}
            {{ t.value }}
          </div>
        }
      </div>
    </div>
  </div>
</div>
