@if (componentIsReady && blogNotifications.length > 0) {
  <div class="flex flex-col w-full gap-3 mb-4">
    <!-- border -->
    @for (notification of blogNotifications; track notification) {
      <bp-alert
        [type]="'primary'"
        [appearance]="'border'"
        [extra]="notification.extra"
        (showMore)="showMore(notification)"
        >
        <mat-icon class="icon-size-5" bpAlertIcon [svgIcon]="'heroicons_solid:information-circle'">
        </mat-icon>
        <span bpAlertTitle> {{ notification.title }} </span>
        @if (notification.textSecondary && notification.textSecondary != '') {
          <span>
            {{ notification.textSecondary }}
          </span>
        }
      </bp-alert>
    }
  </div>
}
