<div class="hidden sm:block">
  <div class="justify-center px-1 align-center portfolio-table">
    @for (composition of compositions; track composition) {
      <div class="flex flex-row align-strecth">
        <div
          class="pl-3 my-2 d-flex flex-column"
          style="font-size: 12px;"
          [ngStyle]="{ 'border-left': '6px solid ' + composition?.color }"
          >
          <div class="p-0 subcategory-name">
            {{ composition.subCategoryName }}
          </div>
          <div id="percentage-cont" class="mr-1 composition-percentage text-secondary">
            {{ composition.percentage | number: '1.0-1' }}%
          </div>
        </div>
      </div>
    }
  </div>
</div>

<div class="block w-full sm:hidden">
  <div class="flex flex-col justify-between gap-4">
    @for (composition of compositions; track composition) {
      <div class="flex flex-row justify-between gap-2">
        <div class="flex items-center">
          <div
            class="w-2 h-2 rounded-full flex-0"
            [ngStyle]="{ 'background-color': composition?.color }"
          ></div>
          <div class="ml-3 truncate">{{ composition.subCategoryName }}</div>
        </div>
        <div bp-text-values-card>{{ composition.percentage | number: '1.0-1' }}%</div>
      </div>
    }
  </div>
</div>
