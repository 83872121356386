import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { formatNumber } from '@angular/common';
import { PortfolioCompositionModel } from '@bp-core/src/lib/proto/common-message.pb';

import { environment } from '@environment/environment';
am4core.addLicense(environment.amchartLicence);

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-portfolio-composition-graph',
  templateUrl: './portfolio-composition-graph.component.html',
  styleUrls: ['./portfolio-composition-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioCompositionGraphComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  constructor(private zone: NgZone) {}
  @Input()
  compositions?: PortfolioCompositionModel[];
  @Input()
  title: string;
  private chart: am4charts.PieChart;
  label: any;

  ngOnInit() {
    // console.log(this.compositions);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.compositions && this.chart) {
      this.chart.data = this.compositions;
      if (this.compositions.length > 0 && this.label) {
        if (this.title) {
          this.label.html = this.title;
        } else {
          this.label.html = `
        <div class="flex flex-col gap-1">
        <span> ${this.compositions[0].subCategoryName}</span>
        <span class="text-secondary"> ${formatNumber(
          this.compositions[0].percentage,
          'es-CL',
        )}%</span>
        </div>
        `;
        }
      }
    }
  }
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        this.chart = am4core.create('chartPortfolio', am4charts.PieChart);
        this.chart.tapToActivate = true;
        const pieSeries = this.chart.series.push(new am4charts.PieSeries());
        this.chart.language.locale = am4lang_es_ES;
        // this.chart.language.locale["_decimalSeparator"] = ",";
        // this.chart.language.locale["_thousandSeparator"] = ".";
        // valueAxis.numberFormatter.numberFormat = {

        // };
        pieSeries.dataFields.value = 'percentage';
        pieSeries.dataFields.category = 'subCategoryName';
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        if (pieSeries.tooltip) {
          pieSeries.tooltip.disabled = true;
        }
        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.disabled = true;
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.slices.template.strokeWidth = 0;
        pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        this.label = pieSeries.createChild(am4core.Label);
        this.label.numberFormatter.language = new am4core.Language();
        this.label.numberFormatter.language.locale = am4lang_es_ES;
        this.label.horizontalCenter = 'middle';
        this.label.verticalCenter = 'middle';
        if (this.title) this.label.html = this.title;
        this.label.fontSize = 14;
        //   label.fontWeight = "900";
        this.label.wrap = true;
        this.label.maxWidth = 150;
        this.label.textAlign = 'middle';
        // this.label.numberFormatter.numberFormat = "#.###,##";
        this.label.numberFormatter.language.locale._decimalSeparator = ',';
        pieSeries.interactionsEnabled = true;
        if (this.title) {
          pieSeries.events.on('out', ev => {
            this.label.text = null;
            this.label.html = this.title;
          });
        }
        pieSeries.slices.template.events.on('over', ev => {
          const values = ev.target.dataItem?.dataContext as any;
          this.label.html = `
          <div class="flex flex-col gap-1">
          <span> ${values.subCategoryName}</span>
          <span class="text-secondary"> ${formatNumber(values.percentage, 'es-CL')}%</span>
          </div>
          `;
          pieSeries.slices.each(function(item) {
            if (item.isActive && item != ev.target) {
              item.isActive = false;
            }
          });
        });

        const slice = pieSeries.slices.template;

        //    slice.states.getKey('hover').properties.scale = 1;
        // @ts-ignore
        slice.states.getKey('active').properties.shiftRadius = 0;
        //     pieSeries.labels.template.text = '';
        // pieSeries.labels.template.text = '{value.percent.formatNumber(\'#.0\')}%';
        // pieSeries.labels.template.radius = am4core.percent(-40);
        // pieSeries.labels.template.fill = am4core.color('white');
        //   This creates initial animation
        this.chart.responsive.enabled = true;
        //    pieSeries.hiddenState.properties.opacity = 1;
        //     pieSeries.hiddenState.properties.endAngle = -90;
        //   pieSeries.hiddenState.properties.startAngle = -90;
        // this.chart.legend = new am4charts.Legend();
        // this.chart.legend.position = 'right';
        // this.chart.legend.paddingRight = 100;
        // this.chart.legend.minWidth = 300;
        // this.chart.legend.labels.template.truncate = false;
        // this.chart.legend.labels.template.wrap = true;
        this.chart.innerRadius = am4core.percent(60);
        if (this.compositions) {
          this.chart.data = this.compositions;
          if (this.compositions.length > 0) {
            // console.log(
            //   `[bold]${this.compositions[0].subCategoryName}[/] \n${this.compositions[0].percentage}%`
            // );

            this.label.html = `
            <div class="flex flex-col gap-1">
            <span> ${this.compositions[0].subCategoryName}</span>
            <span class="text-secondary"> ${formatNumber(
              this.compositions[0].percentage,
              'es-CL',
            )}%</span>
            </div>
            `;
            if (this.title) this.label.html = this.title;
          }
        }
      });
    });
  }
}
