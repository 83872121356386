import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';

@Component({
  selector: 'app-g-p-b-table',
  templateUrl: './g-p-b-table.component.html',
  styleUrls: ['./g-p-b-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPBTableComponent implements OnInit {
  @Input()
  balanceTotalAmount: number;
  @Input()
  balanceTotalDate: Date;
  @Input()
  balanceInitAmount: number;
  @Input()
  balanceInitDate: Date | null;
  @Input()
  totalCurrentCapital: number | undefined;
  @Input()
  hasAllFinancialInstitutions: boolean | undefined;
  @Input()
  deposits: number;
  @Input()
  withdrawals: number;
  @Input()
  netDeposit: number;
  @Input()
  dateFinalBalance: Date | undefined;
  @Input()
  currency: CurrencyModel | undefined;

  constructor() {}

  ngOnInit() {}
}
