import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import dayjs from 'dayjs';
import { Spanish } from 'flatpickr/dist/l10n/es';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
// @ts-ignore
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

@Component({
  selector: 'bp-g-p-filter-date',
  templateUrl: './g-p-filter-date.component.html',
  styleUrls: ['./g-p-filter-date.component.scss'],
  animations: fuseAnimations,
})
export class GPFilterDateComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(private formBuilder: UntypedFormBuilder) {
    this.spanish = Spanish;
  }
  get typeForm() {
    return this.form?.get('type')!;
  }
  get fromDateForm() {
    return this.form?.get('fromDate')!;
  }
  get toDateForm() {
    return this.form?.get('toDate')!;
  }
  get goalForm() {
    return this.form?.get('goal')!;
  }
  @Input()
  fromDate: Date;
  @Input()
  toDate: Date;
  form: UntypedFormGroup;
  spanish: any;
  maxToDate: Date = dayjs()
    .add(30, 'year')
    .toDate();
  minToDate: Date = dayjs()
    .add(-30, 'year')
    .toDate();
  maxFromDate: Date = dayjs()
    .add(30, 'year')
    .toDate();
  minFromDate: Date = dayjs()
    .add(-30, 'year')
    .toDate();
  creationDate: Date = dayjs()
    .add(30, 'year')
    .toDate();
  initialDate: Date = dayjs()
    .add(30, 'year')
    .toDate();

  fromDateToDate: Array<Date> = [];
  @Input() initialToDate: Date;
  @Input() initialFromDate: Date;
  @Output()
  emitFromDate = new EventEmitter<Date>();
  @Output()
  emitToDate = new EventEmitter<Date>();
  @Output()
  emitDateRange = new EventEmitter();
  plugingsCalendar = [
    ShortcutButtonsPlugin({
      button: {
        label: 'Limpiar',
      },
      onClick: (index: any, fp: any) => {
        fp.clear();
        fp.close();
      },
    }),
  ];
  dateRange = new UntypedFormControl(1);
  ranges: any[] = [
    {
      id: 1,
      text: 'Desde el comienzo',
    },
    {
      id: 6,
      text: 'Últimos 24 meses',
    },
    {
      id: 2,
      text: 'Últimos 12 meses',
    },
    {
      id: 4,
      text: 'Últimos 3 meses',
    },
    {
      id: 3,
      text: 'Últimos 30 días',
    },
    {
      id: 5,
      text: 'Año actual',
    },
    {
      id: 7,
      text: 'Personalizado',
    },
  ];
  ngOnInit() {
    // this.minFromDate = this.minFromDate;
    this.fromDateForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(300), distinctUntilChanged())
      .subscribe((value: moment.Moment) => {
        // console.log('fromDateForm', value);
        if (!value) return;

        if (moment.isDate(value)) {
          if (this.fromDate !== value) {
            this.minToDate = value;
            this.emitFromDate.emit(value);
            if (moment.isDate(this.toDateForm.value)) {
              this.emitToDate.emit(this.toDateForm.value);
            } else {
              this.emitToDate.emit(this.toDateForm.value.toDate());
            }
          }
          return;
        }

        if (this.fromDate !== value.toDate()) {
          this.minToDate = value.toDate();
          this.emitFromDate.emit(value.toDate());
          if (moment.isDate(this.toDateForm.value)) {
            this.emitToDate.emit(this.toDateForm.value);
          } else {
            this.emitToDate.emit(this.toDateForm.value.toDate());
          }
        }
      });
    this.toDateForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(300), distinctUntilChanged())
      .subscribe((value: moment.Moment) => {
        // console.log('toDateForm', value);
        if (!value) return;

        if (moment.isDate(value)) {
          if (this.toDate !== value) {
            this.maxFromDate = value;
            if (moment.isDate(this.fromDateForm.value)) {
              this.emitFromDate.emit(this.fromDateForm.value);
            } else {
              this.emitFromDate.emit(this.fromDateForm.value?.toDate());
            }
            this.emitToDate.emit(value);
          }
          return;
        }

        if (this.toDate !== value.toDate()) {
          this.maxFromDate = value.toDate();
          if (moment.isDate(this.fromDateForm.value)) {
            this.emitFromDate.emit(this.fromDateForm.value);
          } else {
            this.emitFromDate.emit(this.fromDateForm.value.toDate());
          }
          this.emitToDate.emit(value.toDate());
        }
      });

    this.dateRange.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(300), distinctUntilChanged())
      .subscribe((value: number) => {
        // console.log('dateRange', value);
        if (+value == 7) {
          this.toDateForm.setValue(this.initialToDate);
          this.maxFromDate = this.initialToDate;
        }
        this.emitDateRange.emit(+value);
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.form) {
      const now = dayjs().toDate();
      // let now_last_year = dayjs(now).subtract(1,'year');
      this.form = this.formBuilder.group({
        fromDate: [''],
        toDate: [now],
      });
      // console.log(now)
      this.maxToDate = now;
      this.maxFromDate = now;
    }

    // const now = dayjs().toDate();
    if (changes.initialFromDate?.currentValue) {
      this.fromDateForm.setValue(moment(this.initialFromDate));
      this.minToDate = this.initialFromDate;
      // console.log(this.fromDate)
    }
    // console.log('change toDate', this.toDate);
    // console.log('change fromDate', this.fromDate);
    if (changes.initialToDate?.currentValue) {
      // if(this.toDate.getTime() === this.fromDate.getTime()){
      //   this.toDate = now;
      // }
      this.toDateForm.setValue(moment(this.initialToDate));
      this.maxFromDate = this.initialToDate;
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
