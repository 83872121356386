<span class="mt-3 text-sm ">
  {{ fund.fund?.mname }} :
  <span class="text-primary text-uppercase"> {{ fund.fund?.mnemonic }}</span>
</span>
<mat-divider class="mt-2 mb-3"> </mat-divider>
<div>
  <h6 class="font-bold ">¿Por qué esta clase de activos?</h6>
  @if (fund.subcategoryDescription) {
    <p>
      {{ fund.subcategoryDescription }}
    </p>
  }
  @if (!fund.subcategoryDescription) {
    <p>
      {{ category.categoryDescription }}
    </p>
  }

  <h6 class="mt-4 font-bold ">
    ¿Por qué este <span>{{ fund.fund?.description?.includes('ETF') ? 'ETF' : 'fondo' }}</span
    >?
  </h6>
  <p>
    {{ fund.fund?.shortDescription }}
  </p>
</div>
