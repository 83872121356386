import { Component, Input } from '@angular/core';
import { IWhereMyMoney } from '@bp-core/src/lib/models/portal/goal/whereMyMoney.model';

@Component({
  selector: 'bp-funds-resume',
  templateUrl: './bp-funds-resume.component.html',
  styleUrls: ['./bp-funds-resume.component.scss'],
})
export class BpFundsResumeComponent {
  @Input() fundsArray: IWhereMyMoney[];
  @Input() box: IWhereMyMoney;
  @Input() showBox = true;
  @Input() isCustom = false;
  getFeaturesSplit(feature: string, index: number) {
    let splitfeatures: string[] = [];
    try {
      if (feature.split(':').length > 1) {
        splitfeatures = feature.split(':');
      } else {
        splitfeatures.push(feature);
        splitfeatures.push('');
      }
    } catch (error) {
      console.log('getFeaturesSplit', error);
    }

    try {
      return splitfeatures[index];
    } catch (error) {
      return '';
    }
  }
}
