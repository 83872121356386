import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GoalModel } from '@bp-core/src/lib/proto/common-message.pb';
import { GoalTransactionState } from '@bp-core/src/lib/proto/goal-transaction-enums.pb';
import { GoalTransactionPaymentMethod } from '@bp-core/src/lib/proto/goal-transaction-payment-enum.pb';
import { GoalTransactionType } from '@bp-core/src/lib/proto/goal-transaction-type-enums.pb';
import {
  GoalTransactionActivitiesModel,
  GoalTransactionFundingModel,
} from '@bp-core/src/lib/proto/portal-goal.pb';
import { UserService } from '@bp-core/src/lib/services/portal/user.service';
import { fuseAnimations } from '@fuse/animations';
import { getGreenColor } from 'bp/shared/chart/betterplan-theme-amchart';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';

@Component({
  selector: 'goal-activity',
  templateUrl: './goal-activity.component.html',
  styleUrls: ['./goal-activity.component.scss'],
  animations: fuseAnimations,
})
export class GoalActivityComponent implements OnInit, OnChanges {
  activities: GoalTransactionActivitiesModel[] | undefined = undefined;
  goalTransactionState = GoalTransactionState;
  activitiesDataSource: MatTableDataSource<
    GoalTransactionActivitiesModel
  > = new MatTableDataSource();
  activitiesTableColumns: string[] = ['Fecha', 'meta', 'Operación', 'Descripción', 'Monto'];
  columnsToDisplayWithExpand = ['expand', 'Operación', 'Monto'];
  greenColor = getGreenColor(0);
  activity: GoalTransactionActivitiesModel;
  goalTransactionType = GoalTransactionType;
  expandedElement: GoalTransactionActivitiesModel | null;
  paymentMethod = GoalTransactionPaymentMethod;
  @Input() goalActivities: GoalTransactionActivitiesModel[];
  @Input() currentGoal: GoalModel;
  @Input() showExportToExcel: boolean = false;
  @Input() hasFilters: boolean = false;
  @Output() toggleFilter = new EventEmitter<void>();
  @Output() onClickGoal = new EventEmitter<number>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private bpConfirmationService: BpConfirmationService,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentGoal?.currentValue) {
      this.activitiesTableColumns = ['Fecha', 'Operación', 'Descripción', 'Monto'];
    }
    if (changes.goalActivities?.currentValue) {
      this.activitiesDataSource.data = this.goalActivities || [];
      this.activities = this.goalActivities || [];
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.activitiesDataSource.paginator = this.paginator;
  }

  trackByFn(index: number, item: GoalTransactionActivitiesModel): any {
    return item.id || index;
  }

  getAmountClass(ammount: number) {
    let greenClass = 'text-charts-green';
    let redClass = 'text-brand-red';

    return {
      [greenClass]: ammount > 0,
      [redClass]: ammount < 0,
    };
  }

  onExportToExcel() {
    if (this.goalActivities?.length > 0) {
      this.userService.exportActivitiesToXlxs(this.goalActivities);
    }
  }

  showDetail(row: GoalTransactionActivitiesModel, template: any) {
    row.type;
    this.activity = row;
    this.bpConfirmationService.open({
      title: 'Detalle de la transacción',
      bodyTemplate: template,
      dismissible: true,
      width: '500px',
      actions: {
        confirm: {
          show: false,
        },
      },
    });
  }
  goToGoalFrom(row: GoalTransactionActivitiesModel) {
    // this.router.navigate(['/goals', row.goalFrom?.goalId], {
    //   fragment: 'summary',
    // });
    this.onClickGoal.emit(row.goalFrom?.goalId);
  }
  getAbsoluteValue(number: number): number {
    return Math.abs(number);
  }

  getGoalTitle(row: GoalTransactionActivitiesModel): string {
    // Si es una transferencia entre meta de tipo buy, la meta a mostrar es la de destino
    if (row.type === this.goalTransactionType.TRANSFER_GOAL_BUY) return row.goalTo?.title!;
    return row.goalFrom?.title!;
  }
  getGoalTransactionFundings(activity: GoalTransactionActivitiesModel) {
    return activity.goalTransactionFundings?.filter(f => !f.isBox);
  }
  getBox(activity: GoalTransactionActivitiesModel) {
    return activity.goalTransactionFundings?.find(f => f.isBox);
  }
  getMnemonic(
    activity: GoalTransactionActivitiesModel,
    goalTransactionFunding: GoalTransactionFundingModel,
  ) {
    if (activity.type === this.goalTransactionType.DIVIDEND) {
      return goalTransactionFunding.originMnemonic;
    }
    return goalTransactionFunding.mnemonic;
  }
}
