<div bp-goal-detail-card>
  <div>
    <div bp-title-card class="flex items-center gap-2 truncate">
      {{ title }}
      <mat-icon
        class="icon-size-4 text-inherit "
        [svgIcon]="'mat_outline:help_outline'"
        #tooltipPerformance="matTooltip"
        (click)="tooltipPerformance.toggle()"
        matTooltip="Esta medida de rentabilidad considera tanto el crecimiento de tus activos como tus depósitos y retiros. Es una buena medida para evaluar el retorno de tu dinero, pero no es una medida que te permita fácilmente comparar dos inversiones."
      ></mat-icon>
    </div>
    @if (showDates) {
      <div bp-text-values-card class="truncate ">
        {{ fixedFromDate + ' - ' + fixedToDate }}
      </div>
    }
  </div>
  <div class="flex flex-col justify-between pt-0">
    <!-- Table -->
    <app-g-p-p-b-d-table
      class="mt-4"
      [valueIcon]="valueIcon"
      [irrAccumulated]="irrAccumulated"
      [irrAnnualized]="irrAnnualized"
      [irrAccumulatedUSD]="irrAccumulatedUSD"
      [irrAnnualizedUSD]="irrAnnualizedUSD"
      [irrUSDCurrencyCode]="irrUSDCurrencyCode"
      [irrCurrencyCode]="irrCurrencyCode"
    ></app-g-p-p-b-d-table>

    <div class="flex flex-row gap-4 pt-5">
      <img class="w-18 h-18 rounded-xl" src="assets/images/profitability-image.png" />
      <div class="flex flex-col justify-center">
        <span class="text-muted">Lectura recomendada</span>
        <a
          class="text-primary"
          href="http://help.betterplan.cl/es/articles/4815391-como-se-calcula-la-rentabilidad"
          target="_blank"
          rel="noopener noreferrer"
          >¿Tienes alguna duda sobre como calculamos las diferentes rentabilidades?
        </a>
      </div>
    </div>
  </div>
</div>
