<ng-template #customHeaderLeading>
  <ng-content select="customHeaderLeading"></ng-content>
</ng-template>

<ng-template #customHeaderCenter>
  <ng-content select="customHeaderCenter"></ng-content>
</ng-template>

<ng-template #customHeaderAction>
  <ng-content select="customHeaderAction"></ng-content>
</ng-template>
