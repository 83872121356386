import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IChart } from '@bp-core/src/lib/models/portal/chart.model';
import { fuseAnimations } from '@fuse/animations';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { GetBalanceNetDepositGraphRequest } from 'projects/bp-core/src/lib/proto/portal-goal.pb';
import { PortalGoalGrpcServiceClient } from 'projects/bp-core/src/lib/proto/portal-goal.pbsc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bp-g-p-balance-transactions',
  templateUrl: './g-p-balance-transactions.component.html',
  styleUrls: ['./g-p-balance-transactions.component.scss'],
  animations: fuseAnimations,
})
export class GPBalanceTransactionsComponent implements OnInit, OnChanges, OnDestroy {
  private _stop = new Subject<void>();
  @Input()
  goalId: number | undefined = 0;
  @Input()
  dateRange: number;
  @Input()
  fromDate: Date | undefined;
  @Input()
  toDate: Date | undefined;
  @Input()
  hasAllFinancialInstitutions: boolean | undefined;
  @Output() earnings: EventEmitter<boolean> = new EventEmitter();

  isLoading = true;
  depositsLength: number;
  withdrawalsLength: number;
  deposits: number;
  totalCurrentCapital: number | undefined;
  dateFinalBalance: Date | undefined;
  withdrawals: number;
  balanceTotalDate: Date;
  singleChart = false;
  balanceInitDate: Date | null;
  balanceInitAmount = 0;
  netDeposit = 0;
  balanceTotalAmount: number;
  balance: IChart;
  balanceSingle: IChart;
  fromDateFilter: Date;
  toDateFilter: Date;
  currency: CurrencyModel | undefined;
  fixedFromDate: string;
  fixedToDate: string;
  showDates: boolean;
  initialFromDate: Date;
  initialToDate: Date;

  constructor(
    private ref: ChangeDetectorRef,
    private portalGoalGrpcServiceClient: PortalGoalGrpcServiceClient,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.goalId) {
      this._stop.next();
      this.getBalance();
    }
    if (changes?.dateRange) {
      if (+this.dateRange !== 7) {
        this.showDates = false;
        this.toDateFilter = this.initialToDate;
        switch (+this.dateRange!) {
          case 1:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
          case 2:
            this.toDateFilter = this.initialToDate;
            const dateLastYear = this.toDateFilter?.getFullYear() - 1;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter.setFullYear(dateLastYear);
            break;
          case 3:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter.setDate(this.toDateFilter?.getDate() - 30);
            break;
          case 4:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter.setMonth(this.toDateFilter?.getMonth() - 3);
            break;
          case 5:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter?.getFullYear(), 0, 1);
            break;
          case 6:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = new Date(this.toDateFilter);
            this.fromDateFilter.setFullYear(this.toDateFilter?.getFullYear() - 2);
            break;
          default:
            this.toDateFilter = this.initialToDate;
            this.fromDateFilter = this.initialFromDate;
            break;
        }

        this.fixedToDate = `${this.toDateFilter?.getDate()} ${this.toDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter?.getFullYear()}`;
        this.fixedFromDate = `${this.fromDateFilter?.getDate()} ${this.fromDateFilter?.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter?.getFullYear()}`;
        this._stop.next();
        this.getBalance();
      }
    }

    if (+this.dateRange == 7 && changes.fromDate) {
      this.showDates = false;
      this.fromDateFilter = changes.fromDate.currentValue;
      if (this.fromDateFilter) {
        this.fixedFromDate = `${this.fromDateFilter.getDate()} ${this.fromDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.fromDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getBalance();
    }

    if (+this.dateRange == 7 && changes.toDate) {
      this.showDates = false;
      this.toDateFilter = changes.toDate.currentValue;
      if (this.toDateFilter) {
        this.fixedToDate = `${this.toDateFilter.getDate()} ${this.toDateFilter.toLocaleString(
          'es-ES',
          { month: 'short' },
        )} ${this.toDateFilter.getFullYear()}`;
      }
      this._stop.next();
      this.getBalance();
    }
  }

  ngOnInit(): void {
    if (!this.goalId) {
      this._stop.next();
      this.getBalance();
    }
  }
  ngOnDestroy(): void {
    this._stop.next();
    this._stop.complete();
  }
  onChangeFromDateFilter(value: Date): void {
    this.fromDateFilter = value;
    this.getBalance();
  }
  onChangeToDateFilter(value: Date): void {
    this.toDateFilter = value;
    this.getBalance();
  }

  getBalance() {
    this.isLoading = true;
    const query: any = {};

    if (this.fromDateFilter && !isNaN(this.fromDateFilter.getHours())) {
      //query.from = Timestamp.fromDate(dayjs(this.fromDateFilter).toDate());
      query.from = Timestamp.fromDate(this.fromDateFilter);
    }
    if (this.toDateFilter) {
      //query.to = Timestamp.fromDate(dayjs(this.toDateFilter).toDate());
      query.to = Timestamp.fromDate(this.fromDateFilter);
    }
    if (this.goalId) {
      query.goalId = this.goalId;
    }
    this.currency = new CurrencyModel();
    this.portalGoalGrpcServiceClient
      .getBalanceNetDepositGraph(new GetBalanceNetDepositGraphRequest(query))
      .pipe(takeUntil(this._stop))
      .subscribe({
        next: result => {
          if (result.values?.length === 1) {
            this.singleChart = true;
            this.balanceSingle = {
              data: [
                {
                  category: 'Cantidad Depositada',
                  value: result.values[0].netDeposit,
                },
                {
                  category: 'Balance',
                  value: result.values[0].balance,
                },
              ],
              values: [],
            };
          } else {
            this.singleChart = false;
            this.balance = {
              values: [
                { title: 'Depósitos netos', name: 'netDeposit' },
                { title: 'Balance', name: 'balance' },
              ],
              data:
                result.values?.map(value => ({
                  date: value.date?.toDate(),
                  balance: value.balance,
                  netDeposit: value.netDeposit,
                })) ?? [],
            };
          }
          // this.fromDateFilter = result.fromDateFilter?.toDate() ?? new Date();
          // this.toDateFilter = result.toDateFilter?.toDate() ?? new Date();
          this.currency = result.currency;
          this.balanceInitAmount = result.initialBalance?.value ?? 0;
          this.balanceInitDate = result.dateInitialBalance?.toDate() ?? null;
          this.balanceTotalAmount = result.finalBalance ?? 0;
          this.balanceTotalDate = result.dateFinalBalance?.toDate() ?? new Date();
          this.netDeposit = result.netDeposits ?? 0;
          this.deposits = result.deposits ?? 0;
          this.withdrawals = result.withdrawals ?? 0;
          this.totalCurrentCapital = result.finalBalance ?? 0;
          this.dateFinalBalance = result.dateFinalBalance?.toDate();
          this.depositsLength = result.depositsLength ?? 0;
          this.withdrawalsLength = result.withdrawalsLength ?? 0;
          if (this.totalCurrentCapital && this.hasAllFinancialInstitutions) {
            this.earnings.emit(
              this.totalCurrentCapital - this.netDeposit - this.balanceInitAmount < 0
                ? true
                : false,
            );
          } else {
            this.earnings.emit(
              this.balanceTotalAmount - this.netDeposit - this.balanceInitAmount < 0 ? true : false,
            );
          }
          this.isLoading = false;
          this.ref.markForCheck();
          if (this.dateRange == 1 || this.dateRange == 0) {
            this.initialFromDate = result.fromDateFilter?.toDate() ?? new Date();
            this.initialToDate = result.toDateFilter?.toDate() ?? new Date();
            this.fixedToDate = `${this.initialToDate.getDate()} ${this.initialToDate.toLocaleString(
              'es-ES',
              { month: 'short' },
            )} ${this.initialToDate.getFullYear()}`;
            this.fixedFromDate = `${this.initialFromDate.getDate()} ${this.initialFromDate.toLocaleString(
              'es-ES',
              { month: 'short' },
            )} ${this.initialFromDate.getFullYear()}`;
          }
          this.showDates = true;
        },
        error: error => {
          // Swal.fire({
          //   iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
          //   customClass: {
          //     container: 'swal2-fixe',
          //     confirmButton: 'btn',
          //     cancelButton: 'btn',
          //   },
          //   heightAuto: false,
          //   buttonsStyling: false,
          //   showClass: {
          //     icon: 'swal2-noanimation',
          //   },
          //   hideClass: {
          //     icon: 'swal2-noanimation',
          //   },
          //   footer:
          //     '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
          //   icon: 'error',
          //   title: 'Error!',
          //   text: 'No se pudo cargar la rentabilidad ..',
          // });
          this.isLoading = false;
          this.ref.markForCheck();
          console.error(error);
        },
      });
  }
}
