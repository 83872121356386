import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
// @ts-ignore
import { fuseAnimations } from '@fuse/animations';
import dayjs from 'dayjs';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

@Component({
  selector: 'app-g-p-p-e-filter',
  templateUrl: './g-p-p-e-filter.component.html',
  styleUrls: ['./g-p-p-e-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPPEFilterComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  constructor(private formBuilder: UntypedFormBuilder) {
    this.spanish = Spanish;
  }
  get typeForm() {
    return this.form.get('type')!;
  }
  get fromDateForm() {
    return this.form.get('fromDate')!;
  }
  get toDateForm() {
    return this.form.get('toDate')!;
  }
  get goalForm() {
    return this.form.get('goal')!;
  }
  @Input()
  fromDate: Date;
  @Input()
  toDate: Date;
  form: UntypedFormGroup;
  spanish: any;
  maxToDate: Date = dayjs()
    .add(30, 'year')
    .toDate();
  minToDate: Date = dayjs()
    .add(-30, 'year')
    .toDate();
  maxFromDate: Date = dayjs()
    .add(30, 'year')
    .toDate();
  minFromDate: Date = dayjs()
    .add(-30, 'year')
    .toDate();
  creationDate: Date | null = null;

  @Output()
  changeFromDate = new EventEmitter<Date>();
  @Output()
  changeToDate = new EventEmitter<Date>();
  plugingsCalendar = [
    ShortcutButtonsPlugin({
      button: {
        label: 'Limpiar',
      },
      onClick: (index: any, fp: any) => {
        fp.clear();
        fp.close();
      },
    }),
  ];
  ngOnInit() {
    // let now = dayjs();
    this.form = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    });
    // this.maxToDate = now.toDate();
    // this.maxFromDate = now.format('YYYY-MM-DD');
    this.fromDateForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(300), distinctUntilChanged())
      .subscribe((value: Date) => {
        if (this.fromDate !== value) {
          this.minToDate = value;
          this.changeFromDate.emit(value);
        }
      });
    this.toDateForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(300), distinctUntilChanged())
      .subscribe((value: Date) => {
        if (this.toDate !== value) {
          this.maxFromDate = dayjs(value).toDate();
          this.changeToDate.emit(value);
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.fromDate) {
      this.fromDateForm.setValue(this.fromDate);
      this.minToDate = this.fromDate;
    }
    if (this.form && changes.toDate) {
      this.toDateForm.setValue(this.toDate);
      this.maxFromDate = dayjs(this.toDate).toDate();
    }
  }
}
