/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
/**
 * Message implementation for intercom.IntercomArticleRequest
 */
export class IntercomArticleRequest implements GrpcMessage {
  static id = 'intercom.IntercomArticleRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IntercomArticleRequest();
    IntercomArticleRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IntercomArticleRequest) {
    _instance.idArticle = _instance.idArticle || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IntercomArticleRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.idArticle = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    IntercomArticleRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IntercomArticleRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.idArticle) {
      _writer.writeString(1, _instance.idArticle);
    }
  }

  private _idArticle: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IntercomArticleRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<IntercomArticleRequest.AsObject>) {
    _value = _value || {};
    this.idArticle = _value.idArticle;
    IntercomArticleRequest.refineValues(this);
  }
  get idArticle(): string {
    return this._idArticle;
  }
  set idArticle(value: string) {
    this._idArticle = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IntercomArticleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IntercomArticleRequest.AsObject {
    return {
      idArticle: this.idArticle
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IntercomArticleRequest.AsProtobufJSON {
    return {
      idArticle: this.idArticle
    };
  }
}
export module IntercomArticleRequest {
  /**
   * Standard JavaScript object representation for IntercomArticleRequest
   */
  export interface AsObject {
    idArticle: string;
  }

  /**
   * Protobuf JSON representation for IntercomArticleRequest
   */
  export interface AsProtobufJSON {
    idArticle: string;
  }
}

/**
 * Message implementation for intercom.IntercomArticleResponse
 */
export class IntercomArticleResponse implements GrpcMessage {
  static id = 'intercom.IntercomArticleResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IntercomArticleResponse();
    IntercomArticleResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IntercomArticleResponse) {
    _instance.value = _instance.value || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IntercomArticleResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = new IntercomArticleModel();
          _reader.readMessage(
            _instance.value,
            IntercomArticleModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    IntercomArticleResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IntercomArticleResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.value) {
      _writer.writeMessage(
        1,
        _instance.value as any,
        IntercomArticleModel.serializeBinaryToWriter
      );
    }
  }

  private _value?: IntercomArticleModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IntercomArticleResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<IntercomArticleResponse.AsObject>) {
    _value = _value || {};
    this.value = _value.value
      ? new IntercomArticleModel(_value.value)
      : undefined;
    IntercomArticleResponse.refineValues(this);
  }
  get value(): IntercomArticleModel | undefined {
    return this._value;
  }
  set value(value: IntercomArticleModel | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IntercomArticleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IntercomArticleResponse.AsObject {
    return {
      value: this.value ? this.value.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IntercomArticleResponse.AsProtobufJSON {
    return {
      value: this.value ? this.value.toProtobufJSON(options) : null
    };
  }
}
export module IntercomArticleResponse {
  /**
   * Standard JavaScript object representation for IntercomArticleResponse
   */
  export interface AsObject {
    value?: IntercomArticleModel.AsObject;
  }

  /**
   * Protobuf JSON representation for IntercomArticleResponse
   */
  export interface AsProtobufJSON {
    value: IntercomArticleModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for intercom.IntercomArticleModel
 */
export class IntercomArticleModel implements GrpcMessage {
  static id = 'intercom.IntercomArticleModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IntercomArticleModel();
    IntercomArticleModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IntercomArticleModel) {
    _instance.id = _instance.id || '';
    _instance.type = _instance.type || '';
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.body = _instance.body || '';
    _instance.authorId = _instance.authorId || 0;
    _instance.state = _instance.state || '';
    _instance.createdAt = _instance.createdAt || '';
    _instance.updatedAt = _instance.updatedAt || '';
    _instance.url = _instance.url || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IntercomArticleModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readString();
          break;
        case 3:
          _instance.title = _reader.readString();
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.body = _reader.readString();
          break;
        case 6:
          _instance.authorId = _reader.readInt32();
          break;
        case 7:
          _instance.state = _reader.readString();
          break;
        case 8:
          _instance.createdAt = _reader.readString();
          break;
        case 9:
          _instance.updatedAt = _reader.readString();
          break;
        case 10:
          _instance.url = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    IntercomArticleModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IntercomArticleModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.type) {
      _writer.writeString(2, _instance.type);
    }
    if (_instance.title) {
      _writer.writeString(3, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.body) {
      _writer.writeString(5, _instance.body);
    }
    if (_instance.authorId) {
      _writer.writeInt32(6, _instance.authorId);
    }
    if (_instance.state) {
      _writer.writeString(7, _instance.state);
    }
    if (_instance.createdAt) {
      _writer.writeString(8, _instance.createdAt);
    }
    if (_instance.updatedAt) {
      _writer.writeString(9, _instance.updatedAt);
    }
    if (_instance.url) {
      _writer.writeString(10, _instance.url);
    }
  }

  private _id: string;
  private _type: string;
  private _title: string;
  private _description: string;
  private _body: string;
  private _authorId: number;
  private _state: string;
  private _createdAt: string;
  private _updatedAt: string;
  private _url: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IntercomArticleModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<IntercomArticleModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.type = _value.type;
    this.title = _value.title;
    this.description = _value.description;
    this.body = _value.body;
    this.authorId = _value.authorId;
    this.state = _value.state;
    this.createdAt = _value.createdAt;
    this.updatedAt = _value.updatedAt;
    this.url = _value.url;
    IntercomArticleModel.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get body(): string {
    return this._body;
  }
  set body(value: string) {
    this._body = value;
  }
  get authorId(): number {
    return this._authorId;
  }
  set authorId(value: number) {
    this._authorId = value;
  }
  get state(): string {
    return this._state;
  }
  set state(value: string) {
    this._state = value;
  }
  get createdAt(): string {
    return this._createdAt;
  }
  set createdAt(value: string) {
    this._createdAt = value;
  }
  get updatedAt(): string {
    return this._updatedAt;
  }
  set updatedAt(value: string) {
    this._updatedAt = value;
  }
  get url(): string {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IntercomArticleModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IntercomArticleModel.AsObject {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      body: this.body,
      authorId: this.authorId,
      state: this.state,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      url: this.url
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IntercomArticleModel.AsProtobufJSON {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      body: this.body,
      authorId: this.authorId,
      state: this.state,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      url: this.url
    };
  }
}
export module IntercomArticleModel {
  /**
   * Standard JavaScript object representation for IntercomArticleModel
   */
  export interface AsObject {
    id: string;
    type: string;
    title: string;
    description: string;
    body: string;
    authorId: number;
    state: string;
    createdAt: string;
    updatedAt: string;
    url: string;
  }

  /**
   * Protobuf JSON representation for IntercomArticleModel
   */
  export interface AsProtobufJSON {
    id: string;
    type: string;
    title: string;
    description: string;
    body: string;
    authorId: number;
    state: string;
    createdAt: string;
    updatedAt: string;
    url: string;
  }
}
