@if (componentLoaded) {
  <div>
    <form [formGroup]="form">
      <div>
        <div class="flex flex-row justify-between">
          <span>
            {{ title | titlecase }}
          </span>
          <span></span>
          <!-- <span> {{ sumControl?.getRawValue() + '%' }}</span> -->
        </div>
        <mat-divider class="my-2"> </mat-divider>
        <div formArrayName="funds">
          @for (fund of fundsDetail.customFunds; track fund; let i = $index) {
            <form
              [formGroupName]="i"
              class=""
              >
              <div class="flex flex-row items-center justify-between gap-2 my-2">
                <ul class="ml-3 ">
                  <li
                    [ngStyle]="{ color: fund.color }"
                    class="cursor-pointer"
                    (click)="onShowModal(fund, fundsDetail.category)"
                    >
                    <!-- <span
                    [ngStyle]="{ 'background-color': funds.color }"
                    class="dot-chart"
                    style="width:8px;height:8px; ; margin-right:2px "
                    >
                  </span> -->
                  <span class="text-accent hover:text-primary"> {{ fund.fund.fund?.title }}: </span>
                  <span class="text-sm font-normal ">
                    {{ fund.fund.fund?.mnemonic }}
                  </span>
                </li>
              </ul>
              <mat-form-field
                [subscriptSizing]="'dynamic'"
                class="w-1/5 pr-0 text-right"
                appearance="outline"
                >
                <input
                  (keydown)="onKeydown($event, i)"
                  class="text-sm text-center "
                  type="number"
                  [formControlName]="'value'"
                  (focusout)="onFocusOut($event, i)"
                  matInput
                  onlyNumber
                  max="100"
                  min="0"
                  />
                  <span class="text-sm text-secondary percentage" matSuffix>%</span>
                </mat-form-field>
              </div>
              @if (
                funds
                .at(i)
                ?.get('value')
                ?.hasError('invalidPercentage')
                ) {
                <mat-error
                  >
                  Cómo mínimo debes asignar un 5%
                </mat-error>
              }
              @if (
                funds
                .at(i)
                ?.get('value')
                ?.hasError('max') ||
                funds
                .at(i)
                ?.get('value')
                ?.hasError('min') ||
                funds
                .at(i)
                ?.get('value')
                ?.hasError('required')
                ) {
                <mat-error
                  >
                  Rango debe ser de 0 a 100
                </mat-error>
              }
            </form>
          }
        </div>
      </div>
    </form>
  </div>
}
