<!-- TABLA -->
<div class="flex flex-row justify-between mb-2">
  <span class="flex items-center font-medium">
    Portafolio
  </span>
  <div class="flex justify-end">
    <mat-button-toggle-group [value]="valueSelected">
      <mat-button-toggle
        value="allTime"
        (click)="$event.preventDefault(); valueSelected = 'allTime'"
        >Acumulada</mat-button-toggle
        >
        @if (!lessThanAYear) {
          <mat-button-toggle
            value="pastYearReturn"
            @fadeIn
            (click)="$event.preventDefault(); valueSelected = 'pastYearReturn'"
            >Anualizada</mat-button-toggle
            >
          }
          @if (lessThanAYear) {
            <mat-button-toggle
              (click)="$event.preventDefault(); valueSelected = 'pastYearReturn'"
              @fadeIn
              value="pastYearReturn"
              matTooltip="La rentabilidad anualizada indica el beneficio porcentual si el plazo de la inversión hubiese sido de un año. Podrás ver dicho cálculo una vez que tu meta lleve más de un año con inversiones activas"
              >Anualizada</mat-button-toggle
              >
            }
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-2">
        @for (item of profitabilities?.values; track item) {
          <div class="flex flex-row justify-between gap-2">
            <div class="flex flex-row items-center">
              <div class="w-4 h-4 mr-2 rounded-full" [style.backgroundColor]="item?.color"></div>
              <span class="text"
                >{{
                item?.portfolioTitle ?? item?.title
                }}
                {{ item?.currencyCode == '--' ? '' : item?.currencyCode }}
              </span>
            </div>
            <div bp-text-values-card class="flex items-center justify-end px-0">
              @if (valueSelected === 'allTime') {
                {{ item?.alltime }}
              }
              @if (valueSelected === 'pastYearReturn') {
                {{ item?.pastYearReturn }}
              }
            </div>
          </div>
        }
      </div>

      <!-- AÑADIR COMPARACION -->
      <div class="w-full mt-4 form-group">
        <mat-label>Añadir comparación:</mat-label>
        <mat-form-field class="w-full mb-0" [subscriptSizing]="'dynamic'">
          <mat-select placeholder="Seleccionar fondos" [formControl]="comparativeControl" multiple>
            @for (funding of fundings; track funding) {
              <mat-option [value]="funding.id">{{
                funding.title
              }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
