@for (fund of fundsArray; track fund) {
  <mat-expansion-panel
    class="shadow-none hover:shadow-none"
    [togglePosition]="'before'"
    >
    <mat-expansion-panel-header class="px-1 hover:bg-white">
      <mat-panel-title>
        <span class="pr-2 text-base font-normal">
          {{ fund.funding.title }}
        </span>
        <!-- Self aware panel -->
      </mat-panel-title>
      <mat-panel-description class="flex flex-row justify-end flex-grow-[0.5]">
        <div class="flex items-center justify-end w-full gap-2 text-base font-normal">
          <span> {{ fund.percentage | number: '1.0-1':'es-CL' }}% </span>
          <div class="flex w-20 bg-gray-100 md:w-28">
            <span
              class="flex h-4 col-span-3 rounded-s-sm"
              [style.backgroundColor]="fund.color"
              [style.width]="fund.percentage + '%'"
            ></span>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex flex-col gap-2">
      @for (compositionItem of fund.funding?.fundingCompositions; track compositionItem) {
        <div
          class="flex flex-row items-center justify-between gap-1 text-secondary"
          >
          <span>
            {{ compositionItem?.subcategory?.name }}
          </span>
          <span> {{ compositionItem.percentage | number: '1.0-1':'es-CL' }}% </span>
        </div>
      }
      <mat-divider></mat-divider>
      <div>
        <span class="font-medium"> {{ fund.funding?.mname }} ({{ fund?.funding?.mnemonic }}) </span>
        <p class="pt-2">
          {{ fund.funding?.description }}
        </p>
      </div>
      @for (feature of fund?.funding?.featuresjson; track feature) {
        <div class="flex gap-2">
          <i class="mt-1 fas fa-check text-primary"></i>
          <span class="px-0 col-10">
            <span class="underline">{{ getFeaturesSplit(feature, 0) }}</span>
            :
            <span>{{ getFeaturesSplit(feature, 1) }}</span>
          </span>
        </div>
      }
    </div>
  </mat-expansion-panel>
}
