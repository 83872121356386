<div bp-goal-detail-card [ngClass]="{ 'border-0': noBorder, 'mb-0': noBorder }">
  <!-- Encabezado -->
  <div bp-title-card class="truncate">
    {{ title }}
  </div>
  <div class="flex flex-row justify-between">
    @if (link) {
      <button mat-button class="hidden md:block" (click)="cardClicked()">
        Ver detalle
      </button>
    }
    @if (subtitle) {
      <span>{{ subtitle }}</span>
    }
  </div>

  <ng-content></ng-content>

  @if (link) {
    <div class="block px-4 py-3 md:hidden ">
      <div class="row">
        <div class="col">
          <button class="p-0 btn btn-link ">
            Ver detalle
          </button>
        </div>
      </div>
    </div>
  }
</div>
