import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'operation-type-select',
  templateUrl: './operation-type-select.component.html',
  styleUrls: ['./operation-type-select.component.scss'],
})
export class OperationTypeSelectComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  historyValues: string[] = [''];
  formControl = new UntypedFormControl('');
  options: any[] = [
    {
      id: '',
      text: 'Todo',
    },
    {
      id: 'BUY,DEPOSIT',
      text: 'Depósito',
    },
    {
      id: 'SALE,RESCUE',
      text: 'Retiro',
    },
    {
      id: 'transferGoalBuy,transferGoalSale',
      text: 'Transferencia entre metas',
    },
    {
      id: 'changePortfolioSale,changePortfolioBuy',
      text: 'Cambio de portafolio',
    },
    {
      id: 'DIVIDEND',
      text: 'Dividendos',
    },
  ];
  @Input() initialValue?: string[] = [''];
  @Output() valueChange = new EventEmitter<{ value: string[]; labels: string[] }>();
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      if (this.initialValue && this.initialValue.length > 0) {
        this.formControl.setValue(this.initialValue, { emitEvent: false });
        this.historyValues = this.initialValue;
      } else {
        this.formControl.setValue([''], { emitEvent: false });
        this.historyValues = [''];
      }
    }
  }
  ngOnInit() {
    if (this.initialValue && this.initialValue.length > 0) {
      this.formControl.setValue(this.initialValue, { emitEvent: false });
      this.historyValues = this.initialValue;
    } else {
      this.formControl.setValue([''], { emitEvent: false });
      this.historyValues = [''];
    }

    this.formControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((values: string[]) => {
        if (!values.length) {
          this.historyValues = [''];
          this.formControl.setValue(['']);
        }

        let diff = this.differenceArray(values, this.historyValues);
        if (diff.length > 0) {
          if (diff[0] !== '') {
            values = values.filter(x => x !== '');
          } else {
            values = [''];
          }
          this.historyValues = values;

          this.formControl.setValue(values, { emitEvent: false });
          this.valueChange.emit({
            value: values,
            labels: this.options.filter(x => values.includes(x.id) && x.id !== '').map(x => x.text),
          });

          return;
        }
        // console.log('dateRange', value);
      });

    // this.setInitialValue();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }

  /**
   * Devuelve la diferencia entre dos arreglos bidireccionales
   *
   * @private
   * @param {string[]} array1
   * @param {string[]} array2
   * @return {*}  {string[]}
   * @memberof OperationTypeSelectComponent
   */
  private differenceArray(array1: string[], array2: string[]): string[] {
    let diff1 = array1.filter(x => !array2.includes(x));
    let diff2 = array2.filter(x => !array1.includes(x));
    return diff1.concat(diff2);
  }
}
