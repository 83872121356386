<div class="grid grid-cols-2">
  <div class="">
    Descripción
  </div>
  <div class="text-right ">
    Monto
  </div>
</div>

<div class="grid grid-cols-2 gap-1 mt-2">
  <div class="text-secondary">{{ countDeposits }} Depósitos</div>
  <div>
    <div
      class="px-0 text-right num"
      [class.text-green-800]="deposits > 0"
      [class.text-warn-800]="deposits < 0"
    >
      {{ deposits | currency: currencyDisplay?.display:'symbol':currencyDisplay?.digitsInfo }}
    </div>
  </div>

  <div class="text-secondary">{{ countWithdrawals }} Retiros</div>
  <div>
    <div
      class="px-0 text-right num"
      [class.text-green-800]="withdrawals > 0"
      [class.text-warn-800]="withdrawals < 0"
    >
      {{ withdrawals | currency: currencyDisplay?.display:'symbol':currencyDisplay?.digitsInfo }}
    </div>
  </div>
</div>
