import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { environment } from '@environment/environment';
am4core.addLicense(environment.amchartLicence);

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'bp-bonds-stocks-graph',
  templateUrl: './bonds-stocks-graph.component.html',
  styleUrls: ['./bonds-stocks-graph.component.scss'],
})
export class BondsStocksGraphComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  constructor(private zone: NgZone) {}
  @Input()
  newGoal: boolean = false;
  @Input()
  compositions: any[] | undefined;
  @Input()
  isLoading: boolean;
  @Input()
  setLabel: boolean = true;
  @Input()
  width: string = '100%';
  @Input()
  height: string = '300px';
  private chart: am4charts.PieChart;
  label: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.compositions && this.chart) {
      this.setHtmlLabel();
    }
  }
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  setHtmlLabel() {
    if (this.compositions && this.chart) {
      // set order data to the chart (bonos/acciones)
      this.chart.data = this.compositions.sort((a, b) => {
        const nameA = a.subCategoryName;
        const nameB = b.subCategoryName;

        if (nameA && nameB) {
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        } else {
          return 0;
        }
      });

      // Set the labels
      if (this.compositions.length > 1) {
        this.label.html = `<span class="flex flex-row flex-wrap justify-center" >`;
        var compIndex = 1;

        // We use for here to reverse the array order on the labels
        for (let i = this.chart.data.length - 1; i >= 0; i--) {
          let composition = this.chart.data[i];
          let borderSettings = 'flex-col border-r pr-2';

          if (compIndex == 1) {
            borderSettings = 'flex-col border-r-[0.5px] pr-2';
          } else if (compIndex == 2) {
            borderSettings = 'flex-col border-l-[0.5px] pl-2';
          } else if (compIndex == 3) {
            borderSettings = 'flex-col border-t-[0.5px] w-1/2 mt-2';
          } else if (compIndex == 4) {
            borderSettings = 'flex-col border-t-[0.5px] border-l-[0.5px] pl-2 ';
          }

          // this.label.html += `
          //   <span class="flex text-center ${borderSettings} ">
          //   <span>${formatNumber(composition.percentage, 'es-CL')}%</span>
          //   <span class="text-secondary">${composition.subCategoryName![0].toUpperCase() +
          //     composition.subCategoryName!.substr(1).toLowerCase()}</span>
          //   </span>
          // `;

          this.label.html += `
          <span class=" flex text-center ${borderSettings} ">
              <span class="">${composition.subCategoryName![0].toUpperCase() +
                composition.subCategoryName!.substr(1).toLowerCase()}</span>
          <span class="text-secondary">${formatNumber(composition.percentage, 'es-CL')}%</span>
          </span>
        `;

          compIndex += 1;
        }

        this.label.html += `</span>`;
      } else if (this.compositions.length === 1) {
        this.chart.data = this.compositions;
        let singleName = this.compositions[0].subCategoryName!;
        let spltSingleName = singleName.split(' ');
        spltSingleName.forEach(
          (c, i) => (spltSingleName[i] = c.charAt(0).toUpperCase() + c.slice(1)),
        );

        this.label.html = `<span class="w-full flex flex-row items-strech" >
            <span class="flex flex-col text-center">
            <span >${spltSingleName.join(' ')}</span>
              <span lass="text-secondary" >${formatNumber(
                this.compositions[0].percentage,
                'es-CL',
              )}%</span>

            </span>
            `;
      } else {
        this.label.html = `<span class="w-full flex flex-row items-strech" ></span`;
      }
    }

    if (!this.setLabel) this.label.html = '';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        this.chart = am4core.create('chartBondAndStocksPortfolio', am4charts.PieChart);
        this.chart.tapToActivate = true;
        const pieSeries = this.chart.series.push(new am4charts.PieSeries());
        this.chart.language.locale = am4lang_es_ES;
        pieSeries.dataFields.value = 'percentage';
        pieSeries.dataFields.category = 'subCategoryName';
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        if (pieSeries.tooltip) {
          pieSeries.tooltip.disabled = true;
        }
        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.disabled = true;
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.slices.template.strokeWidth = 0;
        pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        this.label = pieSeries.createChild(am4core.Label);
        this.label.numberFormatter.language = new am4core.Language();
        this.label.numberFormatter.language.locale = am4lang_es_ES;
        this.label.horizontalCenter = 'middle';
        this.label.verticalCenter = 'middle';
        this.label.text = '';
        this.label.fontSize = 14;
        //   label.fontWeight = "900";
        this.label.wrap = true;
        // this.label.maxWidth = 150;
        this.label.textAlign = 'middle';
        // this.label.numberFormatter.numberFormat = "#.###,##";
        this.label.numberFormatter.language.locale._decimalSeparator = ',';
        pieSeries.interactionsEnabled = true;

        const slice = pieSeries.slices.template;
        // @ts-ignore
        slice.states.getKey('active').properties.shiftRadius = 0;
        //   This creates initial animation
        this.chart.responsive.enabled = true;
        this.chart.innerRadius = am4core.percent(60);

        this.setHtmlLabel();
      });
    });
  }
  ngOnInit(): void {}
}
