/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as googleProtobuf002 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums003 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums004 from './goal-transaction-type-enums.pb';
import * as file_message005 from './file-message.pb';
import * as common_message006 from './common-message.pb';
import * as goal_transaction_payment_enum007 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum008 from './goal-transaction-origin-type-enum.pb';
import * as goal_transaction009 from './goal-transaction.pb';
import * as portfolio010 from './portfolio.pb';
import * as get_started_common011 from './get-started-common.pb';
export enum DepositTypeEnum {
  FINTOC = 0,
  AMH = 1,
  TRANSFER = 2,
  REFERRAL = 3,
  CHECK = 4
}
export enum DepositBankEnum {
  DUMMY = 0,
  BANCO_CHILE = 1,
  SANTANDER = 2,
  BCI = 3,
  BANCO_ESTADO = 7,
  SCOTIABANK = 9,
  ITAU = 12
}
export enum RescueTypeEnum {
  ANOTHER_GOAL = 0,
  TO_MY_BANK_ACCOUNT = 1
}
export enum GoalTypeEnum {
  SAVING_ACCOUNT = 0,
  DOLLAR_SAVING_ACCOUNT = 1,
  DOLLAR_WALLET = 2
}
/**
 * Message implementation for portal_goal.BaseGoalRequest
 */
export class BaseGoalRequest implements GrpcMessage {
  static id = 'portal_goal.BaseGoalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BaseGoalRequest();
    BaseGoalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BaseGoalRequest) {
    _instance.goalId = _instance.goalId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BaseGoalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BaseGoalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BaseGoalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeInt32(1, _instance.goalId);
    }
  }

  private _goalId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BaseGoalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BaseGoalRequest.AsObject>) {
    _value = _value || {};
    this.goalId = _value.goalId;
    BaseGoalRequest.refineValues(this);
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BaseGoalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BaseGoalRequest.AsObject {
    return {
      goalId: this.goalId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BaseGoalRequest.AsProtobufJSON {
    return {
      goalId: this.goalId
    };
  }
}
export module BaseGoalRequest {
  /**
   * Standard JavaScript object representation for BaseGoalRequest
   */
  export interface AsObject {
    goalId: number;
  }

  /**
   * Protobuf JSON representation for BaseGoalRequest
   */
  export interface AsProtobufJSON {
    goalId: number;
  }
}

/**
 * Message implementation for portal_goal.GetListPortfoliosWithRelationsRequest
 */
export class GetListPortfoliosWithRelationsRequest implements GrpcMessage {
  static id = 'portal_goal.GetListPortfoliosWithRelationsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetListPortfoliosWithRelationsRequest();
    GetListPortfoliosWithRelationsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetListPortfoliosWithRelationsRequest) {
    _instance.simple = _instance.simple || false;
    _instance.baseListRequest = _instance.baseListRequest || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetListPortfoliosWithRelationsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.simple = _reader.readBool();
          break;
        case 2:
          _instance.baseListRequest = new common_message006.BaseListRequest();
          _reader.readMessage(
            _instance.baseListRequest,
            common_message006.BaseListRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetListPortfoliosWithRelationsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetListPortfoliosWithRelationsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.simple) {
      _writer.writeBool(1, _instance.simple);
    }
    if (_instance.baseListRequest) {
      _writer.writeMessage(
        2,
        _instance.baseListRequest as any,
        common_message006.BaseListRequest.serializeBinaryToWriter
      );
    }
  }

  private _simple: boolean;
  private _baseListRequest?: common_message006.BaseListRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetListPortfoliosWithRelationsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetListPortfoliosWithRelationsRequest.AsObject>
  ) {
    _value = _value || {};
    this.simple = _value.simple;
    this.baseListRequest = _value.baseListRequest
      ? new common_message006.BaseListRequest(_value.baseListRequest)
      : undefined;
    GetListPortfoliosWithRelationsRequest.refineValues(this);
  }
  get simple(): boolean {
    return this._simple;
  }
  set simple(value: boolean) {
    this._simple = value;
  }
  get baseListRequest(): common_message006.BaseListRequest | undefined {
    return this._baseListRequest;
  }
  set baseListRequest(value: common_message006.BaseListRequest | undefined) {
    this._baseListRequest = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetListPortfoliosWithRelationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetListPortfoliosWithRelationsRequest.AsObject {
    return {
      simple: this.simple,
      baseListRequest: this.baseListRequest
        ? this.baseListRequest.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetListPortfoliosWithRelationsRequest.AsProtobufJSON {
    return {
      simple: this.simple,
      baseListRequest: this.baseListRequest
        ? this.baseListRequest.toProtobufJSON(options)
        : null
    };
  }
}
export module GetListPortfoliosWithRelationsRequest {
  /**
   * Standard JavaScript object representation for GetListPortfoliosWithRelationsRequest
   */
  export interface AsObject {
    simple: boolean;
    baseListRequest?: common_message006.BaseListRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for GetListPortfoliosWithRelationsRequest
   */
  export interface AsProtobufJSON {
    simple: boolean;
    baseListRequest: common_message006.BaseListRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.ConfirmDepositRequest
 */
export class ConfirmDepositRequest implements GrpcMessage {
  static id = 'portal_goal.ConfirmDepositRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfirmDepositRequest();
    ConfirmDepositRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfirmDepositRequest) {
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfirmDepositRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfirmDepositRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfirmDepositRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.transactionRequestId) {
      _writer.writeInt32(1, _instance.transactionRequestId);
    }
  }

  private _transactionRequestId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfirmDepositRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfirmDepositRequest.AsObject>) {
    _value = _value || {};
    this.transactionRequestId = _value.transactionRequestId;
    ConfirmDepositRequest.refineValues(this);
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfirmDepositRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfirmDepositRequest.AsObject {
    return {
      transactionRequestId: this.transactionRequestId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfirmDepositRequest.AsProtobufJSON {
    return {
      transactionRequestId: this.transactionRequestId
    };
  }
}
export module ConfirmDepositRequest {
  /**
   * Standard JavaScript object representation for ConfirmDepositRequest
   */
  export interface AsObject {
    transactionRequestId: number;
  }

  /**
   * Protobuf JSON representation for ConfirmDepositRequest
   */
  export interface AsProtobufJSON {
    transactionRequestId: number;
  }
}

/**
 * Message implementation for portal_goal.ConfirmDepositResponse
 */
export class ConfirmDepositResponse implements GrpcMessage {
  static id = 'portal_goal.ConfirmDepositResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfirmDepositResponse();
    ConfirmDepositResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfirmDepositResponse) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfirmDepositResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfirmDepositResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfirmDepositResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfirmDepositResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfirmDepositResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    ConfirmDepositResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfirmDepositResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfirmDepositResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfirmDepositResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module ConfirmDepositResponse {
  /**
   * Standard JavaScript object representation for ConfirmDepositResponse
   */
  export interface AsObject {
    ok: boolean;
  }

  /**
   * Protobuf JSON representation for ConfirmDepositResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
  }
}

/**
 * Message implementation for portal_goal.CancelDepositRequest
 */
export class CancelDepositRequest implements GrpcMessage {
  static id = 'portal_goal.CancelDepositRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelDepositRequest();
    CancelDepositRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelDepositRequest) {
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelDepositRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelDepositRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelDepositRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.transactionRequestId) {
      _writer.writeInt32(1, _instance.transactionRequestId);
    }
  }

  private _transactionRequestId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelDepositRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelDepositRequest.AsObject>) {
    _value = _value || {};
    this.transactionRequestId = _value.transactionRequestId;
    CancelDepositRequest.refineValues(this);
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelDepositRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelDepositRequest.AsObject {
    return {
      transactionRequestId: this.transactionRequestId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelDepositRequest.AsProtobufJSON {
    return {
      transactionRequestId: this.transactionRequestId
    };
  }
}
export module CancelDepositRequest {
  /**
   * Standard JavaScript object representation for CancelDepositRequest
   */
  export interface AsObject {
    transactionRequestId: number;
  }

  /**
   * Protobuf JSON representation for CancelDepositRequest
   */
  export interface AsProtobufJSON {
    transactionRequestId: number;
  }
}

/**
 * Message implementation for portal_goal.CancelDepositResponse
 */
export class CancelDepositResponse implements GrpcMessage {
  static id = 'portal_goal.CancelDepositResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelDepositResponse();
    CancelDepositResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelDepositResponse) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelDepositResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelDepositResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelDepositResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelDepositResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelDepositResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    CancelDepositResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelDepositResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelDepositResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelDepositResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module CancelDepositResponse {
  /**
   * Standard JavaScript object representation for CancelDepositResponse
   */
  export interface AsObject {
    ok: boolean;
  }

  /**
   * Protobuf JSON representation for CancelDepositResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
  }
}

/**
 * Message implementation for portal_goal.RequestDepositRequest
 */
export class RequestDepositRequest implements GrpcMessage {
  static id = 'portal_goal.RequestDepositRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestDepositRequest();
    RequestDepositRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestDepositRequest) {
    _instance.amount = _instance.amount || 0;
    _instance.depositGoals = _instance.depositGoals || [];
    _instance.currencyId = _instance.currencyId || 0;
    _instance.type = _instance.type || 0;
    _instance.bank = _instance.bank || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestDepositRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          const messageInitializer2 = new DepositGoalRequest();
          _reader.readMessage(
            messageInitializer2,
            DepositGoalRequest.deserializeBinaryFromReader
          );
          (_instance.depositGoals = _instance.depositGoals || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.currencyId = _reader.readInt32();
          break;
        case 4:
          _instance.type = _reader.readEnum();
          break;
        case 5:
          _instance.bank = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    RequestDepositRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestDepositRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.depositGoals && _instance.depositGoals.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.depositGoals as any,
        DepositGoalRequest.serializeBinaryToWriter
      );
    }
    if (_instance.currencyId) {
      _writer.writeInt32(3, _instance.currencyId);
    }
    if (_instance.type) {
      _writer.writeEnum(4, _instance.type);
    }
    if (_instance.bank) {
      _writer.writeEnum(5, _instance.bank);
    }
  }

  private _amount: number;
  private _depositGoals?: DepositGoalRequest[];
  private _currencyId: number;
  private _type: DepositTypeEnum;
  private _bank: DepositBankEnum;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestDepositRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestDepositRequest.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.depositGoals = (_value.depositGoals || []).map(
      m => new DepositGoalRequest(m)
    );
    this.currencyId = _value.currencyId;
    this.type = _value.type;
    this.bank = _value.bank;
    RequestDepositRequest.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get depositGoals(): DepositGoalRequest[] | undefined {
    return this._depositGoals;
  }
  set depositGoals(value: DepositGoalRequest[] | undefined) {
    this._depositGoals = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get type(): DepositTypeEnum {
    return this._type;
  }
  set type(value: DepositTypeEnum) {
    this._type = value;
  }
  get bank(): DepositBankEnum {
    return this._bank;
  }
  set bank(value: DepositBankEnum) {
    this._bank = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestDepositRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestDepositRequest.AsObject {
    return {
      amount: this.amount,
      depositGoals: (this.depositGoals || []).map(m => m.toObject()),
      currencyId: this.currencyId,
      type: this.type,
      bank: this.bank
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestDepositRequest.AsProtobufJSON {
    return {
      amount: this.amount,
      depositGoals: (this.depositGoals || []).map(m =>
        m.toProtobufJSON(options)
      ),
      currencyId: this.currencyId,
      type:
        DepositTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      bank:
        DepositBankEnum[
          this.bank === null || this.bank === undefined ? 0 : this.bank
        ]
    };
  }
}
export module RequestDepositRequest {
  /**
   * Standard JavaScript object representation for RequestDepositRequest
   */
  export interface AsObject {
    amount: number;
    depositGoals?: DepositGoalRequest.AsObject[];
    currencyId: number;
    type: DepositTypeEnum;
    bank: DepositBankEnum;
  }

  /**
   * Protobuf JSON representation for RequestDepositRequest
   */
  export interface AsProtobufJSON {
    amount: number;
    depositGoals: DepositGoalRequest.AsProtobufJSON[] | null;
    currencyId: number;
    type: string;
    bank: string;
  }
}

/**
 * Message implementation for portal_goal.RequestDepositResponse
 */
export class RequestDepositResponse implements GrpcMessage {
  static id = 'portal_goal.RequestDepositResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestDepositResponse();
    RequestDepositResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestDepositResponse) {
    _instance.tokenUrl = _instance.tokenUrl || '';
    _instance.transactionRequestId = _instance.transactionRequestId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestDepositResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tokenUrl = _reader.readString();
          break;
        case 2:
          _instance.transactionRequestId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    RequestDepositResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestDepositResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.tokenUrl) {
      _writer.writeString(1, _instance.tokenUrl);
    }
    if (_instance.transactionRequestId) {
      _writer.writeInt32(2, _instance.transactionRequestId);
    }
  }

  private _tokenUrl: string;
  private _transactionRequestId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestDepositResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestDepositResponse.AsObject>) {
    _value = _value || {};
    this.tokenUrl = _value.tokenUrl;
    this.transactionRequestId = _value.transactionRequestId;
    RequestDepositResponse.refineValues(this);
  }
  get tokenUrl(): string {
    return this._tokenUrl;
  }
  set tokenUrl(value: string) {
    this._tokenUrl = value;
  }
  get transactionRequestId(): number {
    return this._transactionRequestId;
  }
  set transactionRequestId(value: number) {
    this._transactionRequestId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestDepositResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestDepositResponse.AsObject {
    return {
      tokenUrl: this.tokenUrl,
      transactionRequestId: this.transactionRequestId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestDepositResponse.AsProtobufJSON {
    return {
      tokenUrl: this.tokenUrl,
      transactionRequestId: this.transactionRequestId
    };
  }
}
export module RequestDepositResponse {
  /**
   * Standard JavaScript object representation for RequestDepositResponse
   */
  export interface AsObject {
    tokenUrl: string;
    transactionRequestId: number;
  }

  /**
   * Protobuf JSON representation for RequestDepositResponse
   */
  export interface AsProtobufJSON {
    tokenUrl: string;
    transactionRequestId: number;
  }
}

/**
 * Message implementation for portal_goal.DepositGoalRequest
 */
export class DepositGoalRequest implements GrpcMessage {
  static id = 'portal_goal.DepositGoalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DepositGoalRequest();
    DepositGoalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DepositGoalRequest) {
    _instance.amount = _instance.amount || 0;
    _instance.goalId = _instance.goalId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DepositGoalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.goalId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    DepositGoalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DepositGoalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.goalId) {
      _writer.writeInt32(2, _instance.goalId);
    }
  }

  private _amount: number;
  private _goalId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DepositGoalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DepositGoalRequest.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.goalId = _value.goalId;
    DepositGoalRequest.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DepositGoalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DepositGoalRequest.AsObject {
    return {
      amount: this.amount,
      goalId: this.goalId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DepositGoalRequest.AsProtobufJSON {
    return {
      amount: this.amount,
      goalId: this.goalId
    };
  }
}
export module DepositGoalRequest {
  /**
   * Standard JavaScript object representation for DepositGoalRequest
   */
  export interface AsObject {
    amount: number;
    goalId: number;
  }

  /**
   * Protobuf JSON representation for DepositGoalRequest
   */
  export interface AsProtobufJSON {
    amount: number;
    goalId: number;
  }
}

/**
 * Message implementation for portal_goal.IrrRequest
 */
export class IrrRequest implements GrpcMessage {
  static id = 'portal_goal.IrrRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IrrRequest();
    IrrRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IrrRequest) {
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.goalId = _instance.goalId || undefined;
    _instance.currencyCode = _instance.currencyCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IrrRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.from = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.to = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.goalId = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.currencyCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    IrrRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: IrrRequest, _writer: BinaryWriter) {
    if (_instance.from) {
      _writer.writeMessage(
        1,
        _instance.from as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        2,
        _instance.to as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeMessage(
        3,
        _instance.goalId as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.currencyCode) {
      _writer.writeString(4, _instance.currencyCode);
    }
  }

  private _from?: googleProtobuf001.Timestamp;
  private _to?: googleProtobuf001.Timestamp;
  private _goalId?: googleProtobuf002.Int32Value;
  private _currencyCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IrrRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<IrrRequest.AsObject>) {
    _value = _value || {};
    this.from = _value.from
      ? new googleProtobuf001.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf001.Timestamp(_value.to)
      : undefined;
    this.goalId = _value.goalId
      ? new googleProtobuf002.Int32Value(_value.goalId)
      : undefined;
    this.currencyCode = _value.currencyCode;
    IrrRequest.refineValues(this);
  }
  get from(): googleProtobuf001.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf001.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf001.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf001.Timestamp | undefined) {
    this._to = value;
  }
  get goalId(): googleProtobuf002.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf002.Int32Value | undefined) {
    this._goalId = value;
  }
  get currencyCode(): string {
    return this._currencyCode;
  }
  set currencyCode(value: string) {
    this._currencyCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IrrRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IrrRequest.AsObject {
    return {
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      goalId: this.goalId ? this.goalId.toObject() : undefined,
      currencyCode: this.currencyCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IrrRequest.AsProtobufJSON {
    return {
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null,
      currencyCode: this.currencyCode
    };
  }
}
export module IrrRequest {
  /**
   * Standard JavaScript object representation for IrrRequest
   */
  export interface AsObject {
    from?: googleProtobuf001.Timestamp.AsObject;
    to?: googleProtobuf001.Timestamp.AsObject;
    goalId?: googleProtobuf002.Int32Value.AsObject;
    currencyCode: string;
  }

  /**
   * Protobuf JSON representation for IrrRequest
   */
  export interface AsProtobufJSON {
    from: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    to: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalId: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    currencyCode: string;
  }
}

/**
 * Message implementation for portal_goal.IrrResponse
 */
export class IrrResponse implements GrpcMessage {
  static id = 'portal_goal.IrrResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new IrrResponse();
    IrrResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: IrrResponse) {
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.irrAccumulated = _instance.irrAccumulated || '';
    _instance.irrAnnualized = _instance.irrAnnualized || '';
    _instance.irrAccumulatedDbl = _instance.irrAccumulatedDbl || 0;
    _instance.irrAnnualizedDbl = _instance.irrAnnualizedDbl || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: IrrResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.from = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.to = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.irrAccumulated = _reader.readString();
          break;
        case 4:
          _instance.irrAnnualized = _reader.readString();
          break;
        case 5:
          _instance.irrAccumulatedDbl = _reader.readDouble();
          break;
        case 6:
          _instance.irrAnnualizedDbl = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    IrrResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: IrrResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.from) {
      _writer.writeMessage(
        1,
        _instance.from as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        2,
        _instance.to as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.irrAccumulated) {
      _writer.writeString(3, _instance.irrAccumulated);
    }
    if (_instance.irrAnnualized) {
      _writer.writeString(4, _instance.irrAnnualized);
    }
    if (_instance.irrAccumulatedDbl) {
      _writer.writeDouble(5, _instance.irrAccumulatedDbl);
    }
    if (_instance.irrAnnualizedDbl) {
      _writer.writeDouble(6, _instance.irrAnnualizedDbl);
    }
  }

  private _from?: googleProtobuf001.Timestamp;
  private _to?: googleProtobuf001.Timestamp;
  private _irrAccumulated: string;
  private _irrAnnualized: string;
  private _irrAccumulatedDbl: number;
  private _irrAnnualizedDbl: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of IrrResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<IrrResponse.AsObject>) {
    _value = _value || {};
    this.from = _value.from
      ? new googleProtobuf001.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf001.Timestamp(_value.to)
      : undefined;
    this.irrAccumulated = _value.irrAccumulated;
    this.irrAnnualized = _value.irrAnnualized;
    this.irrAccumulatedDbl = _value.irrAccumulatedDbl;
    this.irrAnnualizedDbl = _value.irrAnnualizedDbl;
    IrrResponse.refineValues(this);
  }
  get from(): googleProtobuf001.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf001.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf001.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf001.Timestamp | undefined) {
    this._to = value;
  }
  get irrAccumulated(): string {
    return this._irrAccumulated;
  }
  set irrAccumulated(value: string) {
    this._irrAccumulated = value;
  }
  get irrAnnualized(): string {
    return this._irrAnnualized;
  }
  set irrAnnualized(value: string) {
    this._irrAnnualized = value;
  }
  get irrAccumulatedDbl(): number {
    return this._irrAccumulatedDbl;
  }
  set irrAccumulatedDbl(value: number) {
    this._irrAccumulatedDbl = value;
  }
  get irrAnnualizedDbl(): number {
    return this._irrAnnualizedDbl;
  }
  set irrAnnualizedDbl(value: number) {
    this._irrAnnualizedDbl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    IrrResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): IrrResponse.AsObject {
    return {
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      irrAccumulated: this.irrAccumulated,
      irrAnnualized: this.irrAnnualized,
      irrAccumulatedDbl: this.irrAccumulatedDbl,
      irrAnnualizedDbl: this.irrAnnualizedDbl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): IrrResponse.AsProtobufJSON {
    return {
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      irrAccumulated: this.irrAccumulated,
      irrAnnualized: this.irrAnnualized,
      irrAccumulatedDbl: this.irrAccumulatedDbl,
      irrAnnualizedDbl: this.irrAnnualizedDbl
    };
  }
}
export module IrrResponse {
  /**
   * Standard JavaScript object representation for IrrResponse
   */
  export interface AsObject {
    from?: googleProtobuf001.Timestamp.AsObject;
    to?: googleProtobuf001.Timestamp.AsObject;
    irrAccumulated: string;
    irrAnnualized: string;
    irrAccumulatedDbl: number;
    irrAnnualizedDbl: number;
  }

  /**
   * Protobuf JSON representation for IrrResponse
   */
  export interface AsProtobufJSON {
    from: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    to: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    irrAccumulated: string;
    irrAnnualized: string;
    irrAccumulatedDbl: number;
    irrAnnualizedDbl: number;
  }
}

/**
 * Message implementation for portal_goal.UpdateByIdRequest
 */
export class UpdateByIdRequest implements GrpcMessage {
  static id = 'portal_goal.UpdateByIdRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateByIdRequest();
    UpdateByIdRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateByIdRequest) {
    _instance.title = _instance.title || '';
    _instance.years = _instance.years || 0;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
    _instance.icon = _instance.icon || undefined;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.starred = _instance.starred || false;
    _instance.id = _instance.id || 0;
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.investmentStrategyId = _instance.investmentStrategyId || 0;
    _instance.recommendedRiskLevelId = _instance.recommendedRiskLevelId || 0;
    _instance.noPortfolioTransactions =
      _instance.noPortfolioTransactions || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateByIdRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 14:
          _instance.title = _reader.readString();
          break;
        case 1:
          _instance.years = _reader.readInt32();
          break;
        case 2:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 3:
          _instance.monthlyContribution = _reader.readInt32();
          break;
        case 10:
          _instance.icon = new UpdateByIdRequest.ICON();
          _reader.readMessage(
            _instance.icon,
            UpdateByIdRequest.ICON.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.targetAmount = _reader.readInt32();
          break;
        case 17:
          _instance.starred = _reader.readBool();
          break;
        case 18:
          _instance.id = _reader.readInt32();
          break;
        case 22:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 23:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 24:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 25:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 26:
          _instance.investmentStrategyId = _reader.readInt32();
          break;
        case 27:
          _instance.recommendedRiskLevelId = _reader.readInt32();
          break;
        case 28:
          _instance.noPortfolioTransactions = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateByIdRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateByIdRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.title) {
      _writer.writeString(14, _instance.title);
    }
    if (_instance.years) {
      _writer.writeInt32(1, _instance.years);
    }
    if (_instance.initialInvestment) {
      _writer.writeInt32(2, _instance.initialInvestment);
    }
    if (_instance.monthlyContribution) {
      _writer.writeInt32(3, _instance.monthlyContribution);
    }
    if (_instance.icon) {
      _writer.writeMessage(
        10,
        _instance.icon as any,
        UpdateByIdRequest.ICON.serializeBinaryToWriter
      );
    }
    if (_instance.targetAmount) {
      _writer.writeInt32(11, _instance.targetAmount);
    }
    if (_instance.starred) {
      _writer.writeBool(17, _instance.starred);
    }
    if (_instance.id) {
      _writer.writeInt32(18, _instance.id);
    }
    if (_instance.goalCategoryId) {
      _writer.writeInt32(22, _instance.goalCategoryId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(23, _instance.riskLevelId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(24, _instance.financialEntityId);
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(25, _instance.portfolioId);
    }
    if (_instance.investmentStrategyId) {
      _writer.writeInt32(26, _instance.investmentStrategyId);
    }
    if (_instance.recommendedRiskLevelId) {
      _writer.writeInt32(27, _instance.recommendedRiskLevelId);
    }
    if (_instance.noPortfolioTransactions) {
      _writer.writeBool(28, _instance.noPortfolioTransactions);
    }
  }

  private _title: string;
  private _years: number;
  private _initialInvestment: number;
  private _monthlyContribution: number;
  private _icon?: UpdateByIdRequest.ICON;
  private _targetAmount: number;
  private _starred: boolean;
  private _id: number;
  private _goalCategoryId: number;
  private _riskLevelId: number;
  private _financialEntityId: number;
  private _portfolioId: number;
  private _investmentStrategyId: number;
  private _recommendedRiskLevelId: number;
  private _noPortfolioTransactions: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateByIdRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateByIdRequest.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.years = _value.years;
    this.initialInvestment = _value.initialInvestment;
    this.monthlyContribution = _value.monthlyContribution;
    this.icon = _value.icon
      ? new UpdateByIdRequest.ICON(_value.icon)
      : undefined;
    this.targetAmount = _value.targetAmount;
    this.starred = _value.starred;
    this.id = _value.id;
    this.goalCategoryId = _value.goalCategoryId;
    this.riskLevelId = _value.riskLevelId;
    this.financialEntityId = _value.financialEntityId;
    this.portfolioId = _value.portfolioId;
    this.investmentStrategyId = _value.investmentStrategyId;
    this.recommendedRiskLevelId = _value.recommendedRiskLevelId;
    this.noPortfolioTransactions = _value.noPortfolioTransactions;
    UpdateByIdRequest.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get years(): number {
    return this._years;
  }
  set years(value: number) {
    this._years = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }
  get icon(): UpdateByIdRequest.ICON | undefined {
    return this._icon;
  }
  set icon(value: UpdateByIdRequest.ICON | undefined) {
    this._icon = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get starred(): boolean {
    return this._starred;
  }
  set starred(value: boolean) {
    this._starred = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get investmentStrategyId(): number {
    return this._investmentStrategyId;
  }
  set investmentStrategyId(value: number) {
    this._investmentStrategyId = value;
  }
  get recommendedRiskLevelId(): number {
    return this._recommendedRiskLevelId;
  }
  set recommendedRiskLevelId(value: number) {
    this._recommendedRiskLevelId = value;
  }
  get noPortfolioTransactions(): boolean {
    return this._noPortfolioTransactions;
  }
  set noPortfolioTransactions(value: boolean) {
    this._noPortfolioTransactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateByIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateByIdRequest.AsObject {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      icon: this.icon ? this.icon.toObject() : undefined,
      targetAmount: this.targetAmount,
      starred: this.starred,
      id: this.id,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      financialEntityId: this.financialEntityId,
      portfolioId: this.portfolioId,
      investmentStrategyId: this.investmentStrategyId,
      recommendedRiskLevelId: this.recommendedRiskLevelId,
      noPortfolioTransactions: this.noPortfolioTransactions
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateByIdRequest.AsProtobufJSON {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      icon: this.icon ? this.icon.toProtobufJSON(options) : null,
      targetAmount: this.targetAmount,
      starred: this.starred,
      id: this.id,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      financialEntityId: this.financialEntityId,
      portfolioId: this.portfolioId,
      investmentStrategyId: this.investmentStrategyId,
      recommendedRiskLevelId: this.recommendedRiskLevelId,
      noPortfolioTransactions: this.noPortfolioTransactions
    };
  }
}
export module UpdateByIdRequest {
  /**
   * Standard JavaScript object representation for UpdateByIdRequest
   */
  export interface AsObject {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    icon?: UpdateByIdRequest.ICON.AsObject;
    targetAmount: number;
    starred: boolean;
    id: number;
    goalCategoryId: number;
    riskLevelId: number;
    financialEntityId: number;
    portfolioId: number;
    investmentStrategyId: number;
    recommendedRiskLevelId: number;
    noPortfolioTransactions: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateByIdRequest
   */
  export interface AsProtobufJSON {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    icon: UpdateByIdRequest.ICON.AsProtobufJSON | null;
    targetAmount: number;
    starred: boolean;
    id: number;
    goalCategoryId: number;
    riskLevelId: number;
    financialEntityId: number;
    portfolioId: number;
    investmentStrategyId: number;
    recommendedRiskLevelId: number;
    noPortfolioTransactions: boolean;
  }

  /**
   * Message implementation for portal_goal.UpdateByIdRequest.ICON
   */
  export class ICON implements GrpcMessage {
    static id = 'portal_goal.UpdateByIdRequest.ICON';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ICON();
      ICON.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ICON) {
      _instance.code = _instance.code || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: ICON, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.code = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      ICON.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: ICON, _writer: BinaryWriter) {
      if (_instance.code) {
        _writer.writeString(1, _instance.code);
      }
    }

    private _code: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ICON to deeply clone from
     */
    constructor(_value?: RecursivePartial<ICON.AsObject>) {
      _value = _value || {};
      this.code = _value.code;
      ICON.refineValues(this);
    }
    get code(): string {
      return this._code;
    }
    set code(value: string) {
      this._code = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ICON.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ICON.AsObject {
      return {
        code: this.code
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ICON.AsProtobufJSON {
      return {
        code: this.code
      };
    }
  }
  export module ICON {
    /**
     * Standard JavaScript object representation for ICON
     */
    export interface AsObject {
      code: string;
    }

    /**
     * Protobuf JSON representation for ICON
     */
    export interface AsProtobufJSON {
      code: string;
    }
  }
}

/**
 * Message implementation for portal_goal.GetBalanceNetDepositRequest
 */
export class GetBalanceNetDepositRequest implements GrpcMessage {
  static id = 'portal_goal.GetBalanceNetDepositRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBalanceNetDepositRequest();
    GetBalanceNetDepositRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBalanceNetDepositRequest) {
    _instance.goalId = _instance.goalId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBalanceNetDepositRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 3:
          _instance.goalId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetBalanceNetDepositRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBalanceNetDepositRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeInt32(3, _instance.goalId);
    }
  }

  private _goalId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBalanceNetDepositRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBalanceNetDepositRequest.AsObject>) {
    _value = _value || {};
    this.goalId = _value.goalId;
    GetBalanceNetDepositRequest.refineValues(this);
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBalanceNetDepositRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBalanceNetDepositRequest.AsObject {
    return {
      goalId: this.goalId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBalanceNetDepositRequest.AsProtobufJSON {
    return {
      goalId: this.goalId
    };
  }
}
export module GetBalanceNetDepositRequest {
  /**
   * Standard JavaScript object representation for GetBalanceNetDepositRequest
   */
  export interface AsObject {
    goalId: number;
  }

  /**
   * Protobuf JSON representation for GetBalanceNetDepositRequest
   */
  export interface AsProtobufJSON {
    goalId: number;
  }
}

/**
 * Message implementation for portal_goal.BalanceNetDepositModel
 */
export class BalanceNetDepositModel implements GrpcMessage {
  static id = 'portal_goal.BalanceNetDepositModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BalanceNetDepositModel();
    BalanceNetDepositModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BalanceNetDepositModel) {
    _instance.balance = _instance.balance || 0;
    _instance.netDeposits = _instance.netDeposits || 0;
    _instance.deposits = _instance.deposits || 0;
    _instance.withdrawals = _instance.withdrawals || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.depositsLength = _instance.depositsLength || 0;
    _instance.withdrawalsLength = _instance.withdrawalsLength || 0;
    _instance.depositsInProcess = _instance.depositsInProcess || 0;
    _instance.withdrawalsInProcess = _instance.withdrawalsInProcess || 0;
    _instance.depositsLengthInProcess = _instance.depositsLengthInProcess || 0;
    _instance.withdrawalsLengthInProcess =
      _instance.withdrawalsLengthInProcess || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BalanceNetDepositModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.balance = _reader.readInt32();
          break;
        case 3:
          _instance.netDeposits = _reader.readInt32();
          break;
        case 4:
          _instance.deposits = _reader.readInt32();
          break;
        case 5:
          _instance.withdrawals = _reader.readInt32();
          break;
        case 6:
          _instance.currency = new common_message006.CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            common_message006.CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.depositsLength = _reader.readInt32();
          break;
        case 8:
          _instance.withdrawalsLength = _reader.readInt32();
          break;
        case 9:
          _instance.depositsInProcess = _reader.readInt32();
          break;
        case 10:
          _instance.withdrawalsInProcess = _reader.readInt32();
          break;
        case 11:
          _instance.depositsLengthInProcess = _reader.readInt32();
          break;
        case 12:
          _instance.withdrawalsLengthInProcess = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BalanceNetDepositModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BalanceNetDepositModel,
    _writer: BinaryWriter
  ) {
    if (_instance.balance) {
      _writer.writeInt32(2, _instance.balance);
    }
    if (_instance.netDeposits) {
      _writer.writeInt32(3, _instance.netDeposits);
    }
    if (_instance.deposits) {
      _writer.writeInt32(4, _instance.deposits);
    }
    if (_instance.withdrawals) {
      _writer.writeInt32(5, _instance.withdrawals);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        6,
        _instance.currency as any,
        common_message006.CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.depositsLength) {
      _writer.writeInt32(7, _instance.depositsLength);
    }
    if (_instance.withdrawalsLength) {
      _writer.writeInt32(8, _instance.withdrawalsLength);
    }
    if (_instance.depositsInProcess) {
      _writer.writeInt32(9, _instance.depositsInProcess);
    }
    if (_instance.withdrawalsInProcess) {
      _writer.writeInt32(10, _instance.withdrawalsInProcess);
    }
    if (_instance.depositsLengthInProcess) {
      _writer.writeInt32(11, _instance.depositsLengthInProcess);
    }
    if (_instance.withdrawalsLengthInProcess) {
      _writer.writeInt32(12, _instance.withdrawalsLengthInProcess);
    }
  }

  private _balance: number;
  private _netDeposits: number;
  private _deposits: number;
  private _withdrawals: number;
  private _currency?: common_message006.CurrencyModel;
  private _depositsLength: number;
  private _withdrawalsLength: number;
  private _depositsInProcess: number;
  private _withdrawalsInProcess: number;
  private _depositsLengthInProcess: number;
  private _withdrawalsLengthInProcess: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BalanceNetDepositModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BalanceNetDepositModel.AsObject>) {
    _value = _value || {};
    this.balance = _value.balance;
    this.netDeposits = _value.netDeposits;
    this.deposits = _value.deposits;
    this.withdrawals = _value.withdrawals;
    this.currency = _value.currency
      ? new common_message006.CurrencyModel(_value.currency)
      : undefined;
    this.depositsLength = _value.depositsLength;
    this.withdrawalsLength = _value.withdrawalsLength;
    this.depositsInProcess = _value.depositsInProcess;
    this.withdrawalsInProcess = _value.withdrawalsInProcess;
    this.depositsLengthInProcess = _value.depositsLengthInProcess;
    this.withdrawalsLengthInProcess = _value.withdrawalsLengthInProcess;
    BalanceNetDepositModel.refineValues(this);
  }
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = value;
  }
  get netDeposits(): number {
    return this._netDeposits;
  }
  set netDeposits(value: number) {
    this._netDeposits = value;
  }
  get deposits(): number {
    return this._deposits;
  }
  set deposits(value: number) {
    this._deposits = value;
  }
  get withdrawals(): number {
    return this._withdrawals;
  }
  set withdrawals(value: number) {
    this._withdrawals = value;
  }
  get currency(): common_message006.CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: common_message006.CurrencyModel | undefined) {
    this._currency = value;
  }
  get depositsLength(): number {
    return this._depositsLength;
  }
  set depositsLength(value: number) {
    this._depositsLength = value;
  }
  get withdrawalsLength(): number {
    return this._withdrawalsLength;
  }
  set withdrawalsLength(value: number) {
    this._withdrawalsLength = value;
  }
  get depositsInProcess(): number {
    return this._depositsInProcess;
  }
  set depositsInProcess(value: number) {
    this._depositsInProcess = value;
  }
  get withdrawalsInProcess(): number {
    return this._withdrawalsInProcess;
  }
  set withdrawalsInProcess(value: number) {
    this._withdrawalsInProcess = value;
  }
  get depositsLengthInProcess(): number {
    return this._depositsLengthInProcess;
  }
  set depositsLengthInProcess(value: number) {
    this._depositsLengthInProcess = value;
  }
  get withdrawalsLengthInProcess(): number {
    return this._withdrawalsLengthInProcess;
  }
  set withdrawalsLengthInProcess(value: number) {
    this._withdrawalsLengthInProcess = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BalanceNetDepositModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BalanceNetDepositModel.AsObject {
    return {
      balance: this.balance,
      netDeposits: this.netDeposits,
      deposits: this.deposits,
      withdrawals: this.withdrawals,
      currency: this.currency ? this.currency.toObject() : undefined,
      depositsLength: this.depositsLength,
      withdrawalsLength: this.withdrawalsLength,
      depositsInProcess: this.depositsInProcess,
      withdrawalsInProcess: this.withdrawalsInProcess,
      depositsLengthInProcess: this.depositsLengthInProcess,
      withdrawalsLengthInProcess: this.withdrawalsLengthInProcess
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BalanceNetDepositModel.AsProtobufJSON {
    return {
      balance: this.balance,
      netDeposits: this.netDeposits,
      deposits: this.deposits,
      withdrawals: this.withdrawals,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      depositsLength: this.depositsLength,
      withdrawalsLength: this.withdrawalsLength,
      depositsInProcess: this.depositsInProcess,
      withdrawalsInProcess: this.withdrawalsInProcess,
      depositsLengthInProcess: this.depositsLengthInProcess,
      withdrawalsLengthInProcess: this.withdrawalsLengthInProcess
    };
  }
}
export module BalanceNetDepositModel {
  /**
   * Standard JavaScript object representation for BalanceNetDepositModel
   */
  export interface AsObject {
    balance: number;
    netDeposits: number;
    deposits: number;
    withdrawals: number;
    currency?: common_message006.CurrencyModel.AsObject;
    depositsLength: number;
    withdrawalsLength: number;
    depositsInProcess: number;
    withdrawalsInProcess: number;
    depositsLengthInProcess: number;
    withdrawalsLengthInProcess: number;
  }

  /**
   * Protobuf JSON representation for BalanceNetDepositModel
   */
  export interface AsProtobufJSON {
    balance: number;
    netDeposits: number;
    deposits: number;
    withdrawals: number;
    currency: common_message006.CurrencyModel.AsProtobufJSON | null;
    depositsLength: number;
    withdrawalsLength: number;
    depositsInProcess: number;
    withdrawalsInProcess: number;
    depositsLengthInProcess: number;
    withdrawalsLengthInProcess: number;
  }
}

/**
 * Message implementation for portal_goal.GetBalanceNetDepositGraphRequest
 */
export class GetBalanceNetDepositGraphRequest implements GrpcMessage {
  static id = 'portal_goal.GetBalanceNetDepositGraphRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBalanceNetDepositGraphRequest();
    GetBalanceNetDepositGraphRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBalanceNetDepositGraphRequest) {
    _instance.from = _instance.from || undefined;
    _instance.to = _instance.to || undefined;
    _instance.goalId = _instance.goalId || 0;
    _instance.ownerId = _instance.ownerId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBalanceNetDepositGraphRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.from = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.from,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.to = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.to,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.goalId = _reader.readInt32();
          break;
        case 4:
          _instance.ownerId = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.ownerId,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBalanceNetDepositGraphRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBalanceNetDepositGraphRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.from) {
      _writer.writeMessage(
        1,
        _instance.from as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.to) {
      _writer.writeMessage(
        2,
        _instance.to as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeInt32(3, _instance.goalId);
    }
    if (_instance.ownerId) {
      _writer.writeMessage(
        4,
        _instance.ownerId as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _from?: googleProtobuf001.Timestamp;
  private _to?: googleProtobuf001.Timestamp;
  private _goalId: number;
  private _ownerId?: googleProtobuf002.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBalanceNetDepositGraphRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetBalanceNetDepositGraphRequest.AsObject>
  ) {
    _value = _value || {};
    this.from = _value.from
      ? new googleProtobuf001.Timestamp(_value.from)
      : undefined;
    this.to = _value.to
      ? new googleProtobuf001.Timestamp(_value.to)
      : undefined;
    this.goalId = _value.goalId;
    this.ownerId = _value.ownerId
      ? new googleProtobuf002.Int32Value(_value.ownerId)
      : undefined;
    GetBalanceNetDepositGraphRequest.refineValues(this);
  }
  get from(): googleProtobuf001.Timestamp | undefined {
    return this._from;
  }
  set from(value: googleProtobuf001.Timestamp | undefined) {
    this._from = value;
  }
  get to(): googleProtobuf001.Timestamp | undefined {
    return this._to;
  }
  set to(value: googleProtobuf001.Timestamp | undefined) {
    this._to = value;
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get ownerId(): googleProtobuf002.Int32Value | undefined {
    return this._ownerId;
  }
  set ownerId(value: googleProtobuf002.Int32Value | undefined) {
    this._ownerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBalanceNetDepositGraphRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBalanceNetDepositGraphRequest.AsObject {
    return {
      from: this.from ? this.from.toObject() : undefined,
      to: this.to ? this.to.toObject() : undefined,
      goalId: this.goalId,
      ownerId: this.ownerId ? this.ownerId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBalanceNetDepositGraphRequest.AsProtobufJSON {
    return {
      from: this.from ? this.from.toProtobufJSON(options) : null,
      to: this.to ? this.to.toProtobufJSON(options) : null,
      goalId: this.goalId,
      ownerId: this.ownerId ? this.ownerId.toProtobufJSON(options) : null
    };
  }
}
export module GetBalanceNetDepositGraphRequest {
  /**
   * Standard JavaScript object representation for GetBalanceNetDepositGraphRequest
   */
  export interface AsObject {
    from?: googleProtobuf001.Timestamp.AsObject;
    to?: googleProtobuf001.Timestamp.AsObject;
    goalId: number;
    ownerId?: googleProtobuf002.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetBalanceNetDepositGraphRequest
   */
  export interface AsProtobufJSON {
    from: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    to: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalId: number;
    ownerId: googleProtobuf002.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.GetCurrentFundingsRequest
 */
export class GetCurrentFundingsRequest implements GrpcMessage {
  static id = 'portal_goal.GetCurrentFundingsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCurrentFundingsRequest();
    GetCurrentFundingsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCurrentFundingsRequest) {
    _instance.goalId = _instance.goalId || 0;
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCurrentFundingsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalId = _reader.readInt32();
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetCurrentFundingsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCurrentFundingsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeInt32(1, _instance.goalId);
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _goalId: number;
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCurrentFundingsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCurrentFundingsRequest.AsObject>) {
    _value = _value || {};
    this.goalId = _value.goalId;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    GetCurrentFundingsRequest.refineValues(this);
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCurrentFundingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCurrentFundingsRequest.AsObject {
    return {
      goalId: this.goalId,
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCurrentFundingsRequest.AsProtobufJSON {
    return {
      goalId: this.goalId,
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module GetCurrentFundingsRequest {
  /**
   * Standard JavaScript object representation for GetCurrentFundingsRequest
   */
  export interface AsObject {
    goalId: number;
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetCurrentFundingsRequest
   */
  export interface AsProtobufJSON {
    goalId: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.GetCurrentFundingsResponse
 */
export class GetCurrentFundingsResponse implements GrpcMessage {
  static id = 'portal_goal.GetCurrentFundingsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetCurrentFundingsResponse();
    GetCurrentFundingsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetCurrentFundingsResponse) {
    _instance.values = _instance.values || [];
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetCurrentFundingsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.FundingProfitabilityValueModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.FundingProfitabilityValueModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetCurrentFundingsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetCurrentFundingsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.FundingProfitabilityValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message006.FundingProfitabilityValueModel[];
  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetCurrentFundingsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetCurrentFundingsResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.FundingProfitabilityValueModel(m)
    );
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    GetCurrentFundingsResponse.refineValues(this);
  }
  get values(): common_message006.FundingProfitabilityValueModel[] | undefined {
    return this._values;
  }
  set values(
    value: common_message006.FundingProfitabilityValueModel[] | undefined
  ) {
    this._values = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetCurrentFundingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetCurrentFundingsResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetCurrentFundingsResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module GetCurrentFundingsResponse {
  /**
   * Standard JavaScript object representation for GetCurrentFundingsResponse
   */
  export interface AsObject {
    values?: common_message006.FundingProfitabilityValueModel.AsObject[];
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetCurrentFundingsResponse
   */
  export interface AsProtobufJSON {
    values:
      | common_message006.FundingProfitabilityValueModel.AsProtobufJSON[]
      | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.RescueRequest
 */
export class RescueRequest implements GrpcMessage {
  static id = 'portal_goal.RescueRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RescueRequest();
    RescueRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RescueRequest) {
    _instance.amount = _instance.amount || 0;
    _instance.fromGoalId = _instance.fromGoalId || 0;
    _instance.type = _instance.type || 0;
    _instance.allMoney = _instance.allMoney || false;
    _instance.toGoalId = _instance.toGoalId || undefined;
    _instance.bankAccount = _instance.bankAccount || undefined;
    _instance.feedback = _instance.feedback || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RescueRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.fromGoalId = _reader.readInt32();
          break;
        case 3:
          _instance.type = _reader.readEnum();
          break;
        case 4:
          _instance.allMoney = _reader.readBool();
          break;
        case 5:
          _instance.toGoalId = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.toGoalId,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.bankAccount = new common_message006.UserBankAccountModel();
          _reader.readMessage(
            _instance.bankAccount,
            common_message006.UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.feedback = new common_message006.FeedbackModel();
          _reader.readMessage(
            _instance.feedback,
            common_message006.FeedbackModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RescueRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RescueRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.fromGoalId) {
      _writer.writeInt32(2, _instance.fromGoalId);
    }
    if (_instance.type) {
      _writer.writeEnum(3, _instance.type);
    }
    if (_instance.allMoney) {
      _writer.writeBool(4, _instance.allMoney);
    }
    if (_instance.toGoalId) {
      _writer.writeMessage(
        5,
        _instance.toGoalId as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.bankAccount) {
      _writer.writeMessage(
        6,
        _instance.bankAccount as any,
        common_message006.UserBankAccountModel.serializeBinaryToWriter
      );
    }
    if (_instance.feedback) {
      _writer.writeMessage(
        7,
        _instance.feedback as any,
        common_message006.FeedbackModel.serializeBinaryToWriter
      );
    }
  }

  private _amount: number;
  private _fromGoalId: number;
  private _type: RescueTypeEnum;
  private _allMoney: boolean;
  private _toGoalId?: googleProtobuf002.Int32Value;
  private _bankAccount?: common_message006.UserBankAccountModel;
  private _feedback?: common_message006.FeedbackModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RescueRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RescueRequest.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.fromGoalId = _value.fromGoalId;
    this.type = _value.type;
    this.allMoney = _value.allMoney;
    this.toGoalId = _value.toGoalId
      ? new googleProtobuf002.Int32Value(_value.toGoalId)
      : undefined;
    this.bankAccount = _value.bankAccount
      ? new common_message006.UserBankAccountModel(_value.bankAccount)
      : undefined;
    this.feedback = _value.feedback
      ? new common_message006.FeedbackModel(_value.feedback)
      : undefined;
    RescueRequest.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get fromGoalId(): number {
    return this._fromGoalId;
  }
  set fromGoalId(value: number) {
    this._fromGoalId = value;
  }
  get type(): RescueTypeEnum {
    return this._type;
  }
  set type(value: RescueTypeEnum) {
    this._type = value;
  }
  get allMoney(): boolean {
    return this._allMoney;
  }
  set allMoney(value: boolean) {
    this._allMoney = value;
  }
  get toGoalId(): googleProtobuf002.Int32Value | undefined {
    return this._toGoalId;
  }
  set toGoalId(value: googleProtobuf002.Int32Value | undefined) {
    this._toGoalId = value;
  }
  get bankAccount(): common_message006.UserBankAccountModel | undefined {
    return this._bankAccount;
  }
  set bankAccount(value: common_message006.UserBankAccountModel | undefined) {
    this._bankAccount = value;
  }
  get feedback(): common_message006.FeedbackModel | undefined {
    return this._feedback;
  }
  set feedback(value: common_message006.FeedbackModel | undefined) {
    this._feedback = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RescueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RescueRequest.AsObject {
    return {
      amount: this.amount,
      fromGoalId: this.fromGoalId,
      type: this.type,
      allMoney: this.allMoney,
      toGoalId: this.toGoalId ? this.toGoalId.toObject() : undefined,
      bankAccount: this.bankAccount ? this.bankAccount.toObject() : undefined,
      feedback: this.feedback ? this.feedback.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RescueRequest.AsProtobufJSON {
    return {
      amount: this.amount,
      fromGoalId: this.fromGoalId,
      type:
        RescueTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      allMoney: this.allMoney,
      toGoalId: this.toGoalId ? this.toGoalId.toProtobufJSON(options) : null,
      bankAccount: this.bankAccount
        ? this.bankAccount.toProtobufJSON(options)
        : null,
      feedback: this.feedback ? this.feedback.toProtobufJSON(options) : null
    };
  }
}
export module RescueRequest {
  /**
   * Standard JavaScript object representation for RescueRequest
   */
  export interface AsObject {
    amount: number;
    fromGoalId: number;
    type: RescueTypeEnum;
    allMoney: boolean;
    toGoalId?: googleProtobuf002.Int32Value.AsObject;
    bankAccount?: common_message006.UserBankAccountModel.AsObject;
    feedback?: common_message006.FeedbackModel.AsObject;
  }

  /**
   * Protobuf JSON representation for RescueRequest
   */
  export interface AsProtobufJSON {
    amount: number;
    fromGoalId: number;
    type: string;
    allMoney: boolean;
    toGoalId: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    bankAccount: common_message006.UserBankAccountModel.AsProtobufJSON | null;
    feedback: common_message006.FeedbackModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.ActivateRequest
 */
export class ActivateRequest implements GrpcMessage {
  static id = 'portal_goal.ActivateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ActivateRequest();
    ActivateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ActivateRequest) {
    _instance.goalType = _instance.goalType || 0;
    _instance.starred = _instance.starred || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ActivateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalType = _reader.readEnum();
          break;
        case 2:
          _instance.starred = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ActivateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ActivateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalType) {
      _writer.writeEnum(1, _instance.goalType);
    }
    if (_instance.starred) {
      _writer.writeBool(2, _instance.starred);
    }
  }

  private _goalType: GoalTypeEnum;
  private _starred: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ActivateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ActivateRequest.AsObject>) {
    _value = _value || {};
    this.goalType = _value.goalType;
    this.starred = _value.starred;
    ActivateRequest.refineValues(this);
  }
  get goalType(): GoalTypeEnum {
    return this._goalType;
  }
  set goalType(value: GoalTypeEnum) {
    this._goalType = value;
  }
  get starred(): boolean {
    return this._starred;
  }
  set starred(value: boolean) {
    this._starred = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ActivateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ActivateRequest.AsObject {
    return {
      goalType: this.goalType,
      starred: this.starred
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ActivateRequest.AsProtobufJSON {
    return {
      goalType:
        GoalTypeEnum[
          this.goalType === null || this.goalType === undefined
            ? 0
            : this.goalType
        ],
      starred: this.starred
    };
  }
}
export module ActivateRequest {
  /**
   * Standard JavaScript object representation for ActivateRequest
   */
  export interface AsObject {
    goalType: GoalTypeEnum;
    starred: boolean;
  }

  /**
   * Protobuf JSON representation for ActivateRequest
   */
  export interface AsProtobufJSON {
    goalType: string;
    starred: boolean;
  }
}

/**
 * Message implementation for portal_goal.CreateGoalRequest
 */
export class CreateGoalRequest implements GrpcMessage {
  static id = 'portal_goal.CreateGoalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateGoalRequest();
    CreateGoalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateGoalRequest) {
    _instance.title = _instance.title || '';
    _instance.years = _instance.years || 0;
    _instance.initialInvestment = _instance.initialInvestment || 0;
    _instance.monthlyContribution = _instance.monthlyContribution || 0;
    _instance.icon = _instance.icon || undefined;
    _instance.targetAmount = _instance.targetAmount || 0;
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.starred = _instance.starred || false;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.displayCurrencyId = _instance.displayCurrencyId || 0;
    _instance.recommendedRiskLevelId = _instance.recommendedRiskLevelId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateGoalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.title = _reader.readString();
          break;
        case 2:
          _instance.years = _reader.readInt32();
          break;
        case 3:
          _instance.initialInvestment = _reader.readInt32();
          break;
        case 4:
          _instance.monthlyContribution = _reader.readInt32();
          break;
        case 5:
          _instance.icon = new CreateGoalRequest.Icon();
          _reader.readMessage(
            _instance.icon,
            CreateGoalRequest.Icon.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.targetAmount = _reader.readInt32();
          break;
        case 7:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 8:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 9:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 10:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 11:
          _instance.starred = _reader.readBool();
          break;
        case 12:
          _instance.currencyId = _reader.readInt32();
          break;
        case 13:
          _instance.displayCurrencyId = _reader.readInt32();
          break;
        case 14:
          _instance.recommendedRiskLevelId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateGoalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateGoalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.title) {
      _writer.writeString(1, _instance.title);
    }
    if (_instance.years) {
      _writer.writeInt32(2, _instance.years);
    }
    if (_instance.initialInvestment) {
      _writer.writeInt32(3, _instance.initialInvestment);
    }
    if (_instance.monthlyContribution) {
      _writer.writeInt32(4, _instance.monthlyContribution);
    }
    if (_instance.icon) {
      _writer.writeMessage(
        5,
        _instance.icon as any,
        CreateGoalRequest.Icon.serializeBinaryToWriter
      );
    }
    if (_instance.targetAmount) {
      _writer.writeInt32(6, _instance.targetAmount);
    }
    if (_instance.goalCategoryId) {
      _writer.writeInt32(7, _instance.goalCategoryId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(8, _instance.riskLevelId);
    }
    if (_instance.portfolioId) {
      _writer.writeInt32(9, _instance.portfolioId);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(10, _instance.financialEntityId);
    }
    if (_instance.starred) {
      _writer.writeBool(11, _instance.starred);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(12, _instance.currencyId);
    }
    if (_instance.displayCurrencyId) {
      _writer.writeInt32(13, _instance.displayCurrencyId);
    }
    if (_instance.recommendedRiskLevelId) {
      _writer.writeInt32(14, _instance.recommendedRiskLevelId);
    }
  }

  private _title: string;
  private _years: number;
  private _initialInvestment: number;
  private _monthlyContribution: number;
  private _icon?: CreateGoalRequest.Icon;
  private _targetAmount: number;
  private _goalCategoryId: number;
  private _riskLevelId: number;
  private _portfolioId: number;
  private _financialEntityId: number;
  private _starred: boolean;
  private _currencyId: number;
  private _displayCurrencyId: number;
  private _recommendedRiskLevelId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateGoalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateGoalRequest.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.years = _value.years;
    this.initialInvestment = _value.initialInvestment;
    this.monthlyContribution = _value.monthlyContribution;
    this.icon = _value.icon
      ? new CreateGoalRequest.Icon(_value.icon)
      : undefined;
    this.targetAmount = _value.targetAmount;
    this.goalCategoryId = _value.goalCategoryId;
    this.riskLevelId = _value.riskLevelId;
    this.portfolioId = _value.portfolioId;
    this.financialEntityId = _value.financialEntityId;
    this.starred = _value.starred;
    this.currencyId = _value.currencyId;
    this.displayCurrencyId = _value.displayCurrencyId;
    this.recommendedRiskLevelId = _value.recommendedRiskLevelId;
    CreateGoalRequest.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get years(): number {
    return this._years;
  }
  set years(value: number) {
    this._years = value;
  }
  get initialInvestment(): number {
    return this._initialInvestment;
  }
  set initialInvestment(value: number) {
    this._initialInvestment = value;
  }
  get monthlyContribution(): number {
    return this._monthlyContribution;
  }
  set monthlyContribution(value: number) {
    this._monthlyContribution = value;
  }
  get icon(): CreateGoalRequest.Icon | undefined {
    return this._icon;
  }
  set icon(value: CreateGoalRequest.Icon | undefined) {
    this._icon = value;
  }
  get targetAmount(): number {
    return this._targetAmount;
  }
  set targetAmount(value: number) {
    this._targetAmount = value;
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get starred(): boolean {
    return this._starred;
  }
  set starred(value: boolean) {
    this._starred = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get displayCurrencyId(): number {
    return this._displayCurrencyId;
  }
  set displayCurrencyId(value: number) {
    this._displayCurrencyId = value;
  }
  get recommendedRiskLevelId(): number {
    return this._recommendedRiskLevelId;
  }
  set recommendedRiskLevelId(value: number) {
    this._recommendedRiskLevelId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateGoalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateGoalRequest.AsObject {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      icon: this.icon ? this.icon.toObject() : undefined,
      targetAmount: this.targetAmount,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      portfolioId: this.portfolioId,
      financialEntityId: this.financialEntityId,
      starred: this.starred,
      currencyId: this.currencyId,
      displayCurrencyId: this.displayCurrencyId,
      recommendedRiskLevelId: this.recommendedRiskLevelId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateGoalRequest.AsProtobufJSON {
    return {
      title: this.title,
      years: this.years,
      initialInvestment: this.initialInvestment,
      monthlyContribution: this.monthlyContribution,
      icon: this.icon ? this.icon.toProtobufJSON(options) : null,
      targetAmount: this.targetAmount,
      goalCategoryId: this.goalCategoryId,
      riskLevelId: this.riskLevelId,
      portfolioId: this.portfolioId,
      financialEntityId: this.financialEntityId,
      starred: this.starred,
      currencyId: this.currencyId,
      displayCurrencyId: this.displayCurrencyId,
      recommendedRiskLevelId: this.recommendedRiskLevelId
    };
  }
}
export module CreateGoalRequest {
  /**
   * Standard JavaScript object representation for CreateGoalRequest
   */
  export interface AsObject {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    icon?: CreateGoalRequest.Icon.AsObject;
    targetAmount: number;
    goalCategoryId: number;
    riskLevelId: number;
    portfolioId: number;
    financialEntityId: number;
    starred: boolean;
    currencyId: number;
    displayCurrencyId: number;
    recommendedRiskLevelId: number;
  }

  /**
   * Protobuf JSON representation for CreateGoalRequest
   */
  export interface AsProtobufJSON {
    title: string;
    years: number;
    initialInvestment: number;
    monthlyContribution: number;
    icon: CreateGoalRequest.Icon.AsProtobufJSON | null;
    targetAmount: number;
    goalCategoryId: number;
    riskLevelId: number;
    portfolioId: number;
    financialEntityId: number;
    starred: boolean;
    currencyId: number;
    displayCurrencyId: number;
    recommendedRiskLevelId: number;
  }

  /**
   * Message implementation for portal_goal.CreateGoalRequest.Icon
   */
  export class Icon implements GrpcMessage {
    static id = 'portal_goal.CreateGoalRequest.Icon';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Icon();
      Icon.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Icon) {
      _instance.code = _instance.code || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Icon, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.code = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Icon.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Icon, _writer: BinaryWriter) {
      if (_instance.code) {
        _writer.writeString(1, _instance.code);
      }
    }

    private _code: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Icon to deeply clone from
     */
    constructor(_value?: RecursivePartial<Icon.AsObject>) {
      _value = _value || {};
      this.code = _value.code;
      Icon.refineValues(this);
    }
    get code(): string {
      return this._code;
    }
    set code(value: string) {
      this._code = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Icon.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Icon.AsObject {
      return {
        code: this.code
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Icon.AsProtobufJSON {
      return {
        code: this.code
      };
    }
  }
  export module Icon {
    /**
     * Standard JavaScript object representation for Icon
     */
    export interface AsObject {
      code: string;
    }

    /**
     * Protobuf JSON representation for Icon
     */
    export interface AsProtobufJSON {
      code: string;
    }
  }
}

/**
 * Message implementation for portal_goal.GetActivitiesRequest
 */
export class GetActivitiesRequest implements GrpcMessage {
  static id = 'portal_goal.GetActivitiesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetActivitiesRequest();
    GetActivitiesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetActivitiesRequest) {
    _instance.limit = _instance.limit || undefined;
    _instance.skip = _instance.skip || undefined;
    _instance.where = _instance.where || undefined;
    _instance.order = _instance.order || '';
    _instance.include = _instance.include || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetActivitiesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.limit = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.limit,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.skip = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.skip,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.where = new GetActivitiesRequest.Where();
          _reader.readMessage(
            _instance.where,
            GetActivitiesRequest.Where.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.order = _reader.readString();
          break;
        case 5:
          _instance.include = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetActivitiesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetActivitiesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.limit) {
      _writer.writeMessage(
        1,
        _instance.limit as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.skip) {
      _writer.writeMessage(
        2,
        _instance.skip as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.where) {
      _writer.writeMessage(
        3,
        _instance.where as any,
        GetActivitiesRequest.Where.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeString(4, _instance.order);
    }
    if (_instance.include) {
      _writer.writeString(5, _instance.include);
    }
  }

  private _limit?: googleProtobuf002.Int32Value;
  private _skip?: googleProtobuf002.Int32Value;
  private _where?: GetActivitiesRequest.Where;
  private _order: string;
  private _include: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetActivitiesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetActivitiesRequest.AsObject>) {
    _value = _value || {};
    this.limit = _value.limit
      ? new googleProtobuf002.Int32Value(_value.limit)
      : undefined;
    this.skip = _value.skip
      ? new googleProtobuf002.Int32Value(_value.skip)
      : undefined;
    this.where = _value.where
      ? new GetActivitiesRequest.Where(_value.where)
      : undefined;
    this.order = _value.order;
    this.include = _value.include;
    GetActivitiesRequest.refineValues(this);
  }
  get limit(): googleProtobuf002.Int32Value | undefined {
    return this._limit;
  }
  set limit(value: googleProtobuf002.Int32Value | undefined) {
    this._limit = value;
  }
  get skip(): googleProtobuf002.Int32Value | undefined {
    return this._skip;
  }
  set skip(value: googleProtobuf002.Int32Value | undefined) {
    this._skip = value;
  }
  get where(): GetActivitiesRequest.Where | undefined {
    return this._where;
  }
  set where(value: GetActivitiesRequest.Where | undefined) {
    this._where = value;
  }
  get order(): string {
    return this._order;
  }
  set order(value: string) {
    this._order = value;
  }
  get include(): string {
    return this._include;
  }
  set include(value: string) {
    this._include = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetActivitiesRequest.AsObject {
    return {
      limit: this.limit ? this.limit.toObject() : undefined,
      skip: this.skip ? this.skip.toObject() : undefined,
      where: this.where ? this.where.toObject() : undefined,
      order: this.order,
      include: this.include
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetActivitiesRequest.AsProtobufJSON {
    return {
      limit: this.limit ? this.limit.toProtobufJSON(options) : null,
      skip: this.skip ? this.skip.toProtobufJSON(options) : null,
      where: this.where ? this.where.toProtobufJSON(options) : null,
      order: this.order,
      include: this.include
    };
  }
}
export module GetActivitiesRequest {
  /**
   * Standard JavaScript object representation for GetActivitiesRequest
   */
  export interface AsObject {
    limit?: googleProtobuf002.Int32Value.AsObject;
    skip?: googleProtobuf002.Int32Value.AsObject;
    where?: GetActivitiesRequest.Where.AsObject;
    order: string;
    include: string;
  }

  /**
   * Protobuf JSON representation for GetActivitiesRequest
   */
  export interface AsProtobufJSON {
    limit: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    skip: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    where: GetActivitiesRequest.Where.AsProtobufJSON | null;
    order: string;
    include: string;
  }

  /**
   * Message implementation for portal_goal.GetActivitiesRequest.Date
   */
  export class Date implements GrpcMessage {
    static id = 'portal_goal.GetActivitiesRequest.Date';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Date();
      Date.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Date) {
      _instance.gte = _instance.gte || '';
      _instance.lte = _instance.lte || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Date, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.gte = _reader.readString();
            break;
          case 2:
            _instance.lte = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Date.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Date, _writer: BinaryWriter) {
      if (_instance.gte) {
        _writer.writeString(1, _instance.gte);
      }
      if (_instance.lte) {
        _writer.writeString(2, _instance.lte);
      }
    }

    private _gte: string;
    private _lte: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Date to deeply clone from
     */
    constructor(_value?: RecursivePartial<Date.AsObject>) {
      _value = _value || {};
      this.gte = _value.gte;
      this.lte = _value.lte;
      Date.refineValues(this);
    }
    get gte(): string {
      return this._gte;
    }
    set gte(value: string) {
      this._gte = value;
    }
    get lte(): string {
      return this._lte;
    }
    set lte(value: string) {
      this._lte = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Date.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Date.AsObject {
      return {
        gte: this.gte,
        lte: this.lte
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Date.AsProtobufJSON {
      return {
        gte: this.gte,
        lte: this.lte
      };
    }
  }
  export module Date {
    /**
     * Standard JavaScript object representation for Date
     */
    export interface AsObject {
      gte: string;
      lte: string;
    }

    /**
     * Protobuf JSON representation for Date
     */
    export interface AsProtobufJSON {
      gte: string;
      lte: string;
    }
  }

  /**
   * Message implementation for portal_goal.GetActivitiesRequest.And
   */
  export class And implements GrpcMessage {
    static id = 'portal_goal.GetActivitiesRequest.And';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new And();
      And.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: And) {
      _instance.goalId = _instance.goalId || '';
      _instance.type = _instance.type || '';
      _instance.currencyid = _instance.currencyid || '';
      _instance.date = _instance.date || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: And, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.goalId = _reader.readString();
            break;
          case 2:
            _instance.type = _reader.readString();
            break;
          case 3:
            _instance.currencyid = _reader.readString();
            break;
          case 4:
            _instance.date = new GetActivitiesRequest.Date();
            _reader.readMessage(
              _instance.date,
              GetActivitiesRequest.Date.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      And.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: And, _writer: BinaryWriter) {
      if (_instance.goalId) {
        _writer.writeString(1, _instance.goalId);
      }
      if (_instance.type) {
        _writer.writeString(2, _instance.type);
      }
      if (_instance.currencyid) {
        _writer.writeString(3, _instance.currencyid);
      }
      if (_instance.date) {
        _writer.writeMessage(
          4,
          _instance.date as any,
          GetActivitiesRequest.Date.serializeBinaryToWriter
        );
      }
    }

    private _goalId: string;
    private _type: string;
    private _currencyid: string;
    private _date?: GetActivitiesRequest.Date;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of And to deeply clone from
     */
    constructor(_value?: RecursivePartial<And.AsObject>) {
      _value = _value || {};
      this.goalId = _value.goalId;
      this.type = _value.type;
      this.currencyid = _value.currencyid;
      this.date = _value.date
        ? new GetActivitiesRequest.Date(_value.date)
        : undefined;
      And.refineValues(this);
    }
    get goalId(): string {
      return this._goalId;
    }
    set goalId(value: string) {
      this._goalId = value;
    }
    get type(): string {
      return this._type;
    }
    set type(value: string) {
      this._type = value;
    }
    get currencyid(): string {
      return this._currencyid;
    }
    set currencyid(value: string) {
      this._currencyid = value;
    }
    get date(): GetActivitiesRequest.Date | undefined {
      return this._date;
    }
    set date(value: GetActivitiesRequest.Date | undefined) {
      this._date = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      And.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): And.AsObject {
      return {
        goalId: this.goalId,
        type: this.type,
        currencyid: this.currencyid,
        date: this.date ? this.date.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): And.AsProtobufJSON {
      return {
        goalId: this.goalId,
        type: this.type,
        currencyid: this.currencyid,
        date: this.date ? this.date.toProtobufJSON(options) : null
      };
    }
  }
  export module And {
    /**
     * Standard JavaScript object representation for And
     */
    export interface AsObject {
      goalId: string;
      type: string;
      currencyid: string;
      date?: GetActivitiesRequest.Date.AsObject;
    }

    /**
     * Protobuf JSON representation for And
     */
    export interface AsProtobufJSON {
      goalId: string;
      type: string;
      currencyid: string;
      date: GetActivitiesRequest.Date.AsProtobufJSON | null;
    }
  }

  /**
   * Message implementation for portal_goal.GetActivitiesRequest.Where
   */
  export class Where implements GrpcMessage {
    static id = 'portal_goal.GetActivitiesRequest.Where';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Where();
      Where.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Where) {
      _instance.and = _instance.and || [];
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Where,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            const messageInitializer1 = new GetActivitiesRequest.And();
            _reader.readMessage(
              messageInitializer1,
              GetActivitiesRequest.And.deserializeBinaryFromReader
            );
            (_instance.and = _instance.and || []).push(messageInitializer1);
            break;
          default:
            _reader.skipField();
        }
      }

      Where.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Where, _writer: BinaryWriter) {
      if (_instance.and && _instance.and.length) {
        _writer.writeRepeatedMessage(
          1,
          _instance.and as any,
          GetActivitiesRequest.And.serializeBinaryToWriter
        );
      }
    }

    private _and?: GetActivitiesRequest.And[];

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Where to deeply clone from
     */
    constructor(_value?: RecursivePartial<Where.AsObject>) {
      _value = _value || {};
      this.and = (_value.and || []).map(m => new GetActivitiesRequest.And(m));
      Where.refineValues(this);
    }
    get and(): GetActivitiesRequest.And[] | undefined {
      return this._and;
    }
    set and(value: GetActivitiesRequest.And[] | undefined) {
      this._and = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Where.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Where.AsObject {
      return {
        and: (this.and || []).map(m => m.toObject())
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Where.AsProtobufJSON {
      return {
        and: (this.and || []).map(m => m.toProtobufJSON(options))
      };
    }
  }
  export module Where {
    /**
     * Standard JavaScript object representation for Where
     */
    export interface AsObject {
      and?: GetActivitiesRequest.And.AsObject[];
    }

    /**
     * Protobuf JSON representation for Where
     */
    export interface AsProtobufJSON {
      and: GetActivitiesRequest.And.AsProtobufJSON[] | null;
    }
  }
}

/**
 * Message implementation for portal_goal.ListGoalTransactionActivitiesRequest
 */
export class ListGoalTransactionActivitiesRequest implements GrpcMessage {
  static id = 'portal_goal.ListGoalTransactionActivitiesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionActivitiesRequest();
    ListGoalTransactionActivitiesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionActivitiesRequest) {
    _instance.limit = _instance.limit || undefined;
    _instance.skip = _instance.skip || undefined;
    _instance.where = _instance.where || undefined;
    _instance.order = _instance.order || '';
    _instance.include = _instance.include || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionActivitiesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.limit = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.limit,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.skip = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.skip,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.where = new ListGoalTransactionActivitiesRequest.Where();
          _reader.readMessage(
            _instance.where,
            ListGoalTransactionActivitiesRequest.Where
              .deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.order = _reader.readString();
          break;
        case 5:
          _instance.include = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionActivitiesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionActivitiesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.limit) {
      _writer.writeMessage(
        1,
        _instance.limit as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.skip) {
      _writer.writeMessage(
        2,
        _instance.skip as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.where) {
      _writer.writeMessage(
        3,
        _instance.where as any,
        ListGoalTransactionActivitiesRequest.Where.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeString(4, _instance.order);
    }
    if (_instance.include) {
      _writer.writeString(5, _instance.include);
    }
  }

  private _limit?: googleProtobuf002.Int32Value;
  private _skip?: googleProtobuf002.Int32Value;
  private _where?: ListGoalTransactionActivitiesRequest.Where;
  private _order: string;
  private _include: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionActivitiesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalTransactionActivitiesRequest.AsObject>
  ) {
    _value = _value || {};
    this.limit = _value.limit
      ? new googleProtobuf002.Int32Value(_value.limit)
      : undefined;
    this.skip = _value.skip
      ? new googleProtobuf002.Int32Value(_value.skip)
      : undefined;
    this.where = _value.where
      ? new ListGoalTransactionActivitiesRequest.Where(_value.where)
      : undefined;
    this.order = _value.order;
    this.include = _value.include;
    ListGoalTransactionActivitiesRequest.refineValues(this);
  }
  get limit(): googleProtobuf002.Int32Value | undefined {
    return this._limit;
  }
  set limit(value: googleProtobuf002.Int32Value | undefined) {
    this._limit = value;
  }
  get skip(): googleProtobuf002.Int32Value | undefined {
    return this._skip;
  }
  set skip(value: googleProtobuf002.Int32Value | undefined) {
    this._skip = value;
  }
  get where(): ListGoalTransactionActivitiesRequest.Where | undefined {
    return this._where;
  }
  set where(value: ListGoalTransactionActivitiesRequest.Where | undefined) {
    this._where = value;
  }
  get order(): string {
    return this._order;
  }
  set order(value: string) {
    this._order = value;
  }
  get include(): string {
    return this._include;
  }
  set include(value: string) {
    this._include = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionActivitiesRequest.AsObject {
    return {
      limit: this.limit ? this.limit.toObject() : undefined,
      skip: this.skip ? this.skip.toObject() : undefined,
      where: this.where ? this.where.toObject() : undefined,
      order: this.order,
      include: this.include
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionActivitiesRequest.AsProtobufJSON {
    return {
      limit: this.limit ? this.limit.toProtobufJSON(options) : null,
      skip: this.skip ? this.skip.toProtobufJSON(options) : null,
      where: this.where ? this.where.toProtobufJSON(options) : null,
      order: this.order,
      include: this.include
    };
  }
}
export module ListGoalTransactionActivitiesRequest {
  /**
   * Standard JavaScript object representation for ListGoalTransactionActivitiesRequest
   */
  export interface AsObject {
    limit?: googleProtobuf002.Int32Value.AsObject;
    skip?: googleProtobuf002.Int32Value.AsObject;
    where?: ListGoalTransactionActivitiesRequest.Where.AsObject;
    order: string;
    include: string;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionActivitiesRequest
   */
  export interface AsProtobufJSON {
    limit: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    skip: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    where: ListGoalTransactionActivitiesRequest.Where.AsProtobufJSON | null;
    order: string;
    include: string;
  }

  /**
   * Message implementation for portal_goal.ListGoalTransactionActivitiesRequest.Date
   */
  export class Date implements GrpcMessage {
    static id = 'portal_goal.ListGoalTransactionActivitiesRequest.Date';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Date();
      Date.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Date) {
      _instance.gte = _instance.gte || '';
      _instance.lte = _instance.lte || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Date, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.gte = _reader.readString();
            break;
          case 2:
            _instance.lte = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Date.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Date, _writer: BinaryWriter) {
      if (_instance.gte) {
        _writer.writeString(1, _instance.gte);
      }
      if (_instance.lte) {
        _writer.writeString(2, _instance.lte);
      }
    }

    private _gte: string;
    private _lte: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Date to deeply clone from
     */
    constructor(_value?: RecursivePartial<Date.AsObject>) {
      _value = _value || {};
      this.gte = _value.gte;
      this.lte = _value.lte;
      Date.refineValues(this);
    }
    get gte(): string {
      return this._gte;
    }
    set gte(value: string) {
      this._gte = value;
    }
    get lte(): string {
      return this._lte;
    }
    set lte(value: string) {
      this._lte = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Date.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Date.AsObject {
      return {
        gte: this.gte,
        lte: this.lte
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Date.AsProtobufJSON {
      return {
        gte: this.gte,
        lte: this.lte
      };
    }
  }
  export module Date {
    /**
     * Standard JavaScript object representation for Date
     */
    export interface AsObject {
      gte: string;
      lte: string;
    }

    /**
     * Protobuf JSON representation for Date
     */
    export interface AsProtobufJSON {
      gte: string;
      lte: string;
    }
  }

  /**
   * Message implementation for portal_goal.ListGoalTransactionActivitiesRequest.And
   */
  export class And implements GrpcMessage {
    static id = 'portal_goal.ListGoalTransactionActivitiesRequest.And';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new And();
      And.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: And) {
      _instance.goalId = _instance.goalId || '';
      _instance.type = _instance.type || '';
      _instance.currencyid = _instance.currencyid || '';
      _instance.date = _instance.date || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: And, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.goalId = _reader.readString();
            break;
          case 2:
            _instance.type = _reader.readString();
            break;
          case 3:
            _instance.currencyid = _reader.readString();
            break;
          case 4:
            _instance.date = new ListGoalTransactionActivitiesRequest.Date();
            _reader.readMessage(
              _instance.date,
              ListGoalTransactionActivitiesRequest.Date
                .deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      And.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: And, _writer: BinaryWriter) {
      if (_instance.goalId) {
        _writer.writeString(1, _instance.goalId);
      }
      if (_instance.type) {
        _writer.writeString(2, _instance.type);
      }
      if (_instance.currencyid) {
        _writer.writeString(3, _instance.currencyid);
      }
      if (_instance.date) {
        _writer.writeMessage(
          4,
          _instance.date as any,
          ListGoalTransactionActivitiesRequest.Date.serializeBinaryToWriter
        );
      }
    }

    private _goalId: string;
    private _type: string;
    private _currencyid: string;
    private _date?: ListGoalTransactionActivitiesRequest.Date;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of And to deeply clone from
     */
    constructor(_value?: RecursivePartial<And.AsObject>) {
      _value = _value || {};
      this.goalId = _value.goalId;
      this.type = _value.type;
      this.currencyid = _value.currencyid;
      this.date = _value.date
        ? new ListGoalTransactionActivitiesRequest.Date(_value.date)
        : undefined;
      And.refineValues(this);
    }
    get goalId(): string {
      return this._goalId;
    }
    set goalId(value: string) {
      this._goalId = value;
    }
    get type(): string {
      return this._type;
    }
    set type(value: string) {
      this._type = value;
    }
    get currencyid(): string {
      return this._currencyid;
    }
    set currencyid(value: string) {
      this._currencyid = value;
    }
    get date(): ListGoalTransactionActivitiesRequest.Date | undefined {
      return this._date;
    }
    set date(value: ListGoalTransactionActivitiesRequest.Date | undefined) {
      this._date = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      And.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): And.AsObject {
      return {
        goalId: this.goalId,
        type: this.type,
        currencyid: this.currencyid,
        date: this.date ? this.date.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): And.AsProtobufJSON {
      return {
        goalId: this.goalId,
        type: this.type,
        currencyid: this.currencyid,
        date: this.date ? this.date.toProtobufJSON(options) : null
      };
    }
  }
  export module And {
    /**
     * Standard JavaScript object representation for And
     */
    export interface AsObject {
      goalId: string;
      type: string;
      currencyid: string;
      date?: ListGoalTransactionActivitiesRequest.Date.AsObject;
    }

    /**
     * Protobuf JSON representation for And
     */
    export interface AsProtobufJSON {
      goalId: string;
      type: string;
      currencyid: string;
      date: ListGoalTransactionActivitiesRequest.Date.AsProtobufJSON | null;
    }
  }

  /**
   * Message implementation for portal_goal.ListGoalTransactionActivitiesRequest.Where
   */
  export class Where implements GrpcMessage {
    static id = 'portal_goal.ListGoalTransactionActivitiesRequest.Where';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Where();
      Where.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Where) {
      _instance.and = _instance.and || [];
      _instance.ownerId = _instance.ownerId || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Where,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            const messageInitializer1 = new ListGoalTransactionActivitiesRequest.And();
            _reader.readMessage(
              messageInitializer1,
              ListGoalTransactionActivitiesRequest.And
                .deserializeBinaryFromReader
            );
            (_instance.and = _instance.and || []).push(messageInitializer1);
            break;
          case 2:
            _instance.ownerId = new googleProtobuf002.Int32Value();
            _reader.readMessage(
              _instance.ownerId,
              googleProtobuf002.Int32Value.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      Where.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Where, _writer: BinaryWriter) {
      if (_instance.and && _instance.and.length) {
        _writer.writeRepeatedMessage(
          1,
          _instance.and as any,
          ListGoalTransactionActivitiesRequest.And.serializeBinaryToWriter
        );
      }
      if (_instance.ownerId) {
        _writer.writeMessage(
          2,
          _instance.ownerId as any,
          googleProtobuf002.Int32Value.serializeBinaryToWriter
        );
      }
    }

    private _and?: ListGoalTransactionActivitiesRequest.And[];
    private _ownerId?: googleProtobuf002.Int32Value;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Where to deeply clone from
     */
    constructor(_value?: RecursivePartial<Where.AsObject>) {
      _value = _value || {};
      this.and = (_value.and || []).map(
        m => new ListGoalTransactionActivitiesRequest.And(m)
      );
      this.ownerId = _value.ownerId
        ? new googleProtobuf002.Int32Value(_value.ownerId)
        : undefined;
      Where.refineValues(this);
    }
    get and(): ListGoalTransactionActivitiesRequest.And[] | undefined {
      return this._and;
    }
    set and(value: ListGoalTransactionActivitiesRequest.And[] | undefined) {
      this._and = value;
    }
    get ownerId(): googleProtobuf002.Int32Value | undefined {
      return this._ownerId;
    }
    set ownerId(value: googleProtobuf002.Int32Value | undefined) {
      this._ownerId = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Where.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Where.AsObject {
      return {
        and: (this.and || []).map(m => m.toObject()),
        ownerId: this.ownerId ? this.ownerId.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Where.AsProtobufJSON {
      return {
        and: (this.and || []).map(m => m.toProtobufJSON(options)),
        ownerId: this.ownerId ? this.ownerId.toProtobufJSON(options) : null
      };
    }
  }
  export module Where {
    /**
     * Standard JavaScript object representation for Where
     */
    export interface AsObject {
      and?: ListGoalTransactionActivitiesRequest.And.AsObject[];
      ownerId?: googleProtobuf002.Int32Value.AsObject;
    }

    /**
     * Protobuf JSON representation for Where
     */
    export interface AsProtobufJSON {
      and: ListGoalTransactionActivitiesRequest.And.AsProtobufJSON[] | null;
      ownerId: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for portal_goal.GoalTransactionActivitiesModel
 */
export class GoalTransactionActivitiesModel implements GrpcMessage {
  static id = 'portal_goal.GoalTransactionActivitiesModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalTransactionActivitiesModel();
    GoalTransactionActivitiesModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalTransactionActivitiesModel) {
    _instance.id = _instance.id || undefined;
    _instance.amount = _instance.amount || undefined;
    _instance.amountRequested = _instance.amountRequested || 0;
    _instance.amountToProcess = _instance.amountToProcess || undefined;
    _instance.cost = _instance.cost || undefined;
    _instance.date = _instance.date || undefined;
    _instance.type = _instance.type || 0;
    _instance.state = _instance.state || 0;
    _instance.isProcessed = _instance.isProcessed || false;
    _instance.goalTransactionFundings = _instance.goalTransactionFundings || [];
    _instance.goalTo = _instance.goalTo || undefined;
    _instance.goalFrom = _instance.goalFrom || undefined;
    _instance.portfolioTo = _instance.portfolioTo || undefined;
    _instance.portfolioFrom = _instance.portfolioFrom || undefined;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currency = _instance.currency || undefined;
    _instance.financialEntity = _instance.financialEntity || undefined;
    _instance.paymentMethod = _instance.paymentMethod || 0;
    _instance.userBankAccountId = _instance.userBankAccountId || 0;
    _instance.userBankAccount = _instance.userBankAccount || undefined;
    _instance.reasonTransaction = _instance.reasonTransaction || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalTransactionActivitiesModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf002.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf002.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.amount = new googleProtobuf002.DoubleValue();
          _reader.readMessage(
            _instance.amount,
            googleProtobuf002.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.amountRequested = _reader.readDouble();
          break;
        case 4:
          _instance.amountToProcess = new googleProtobuf002.DoubleValue();
          _reader.readMessage(
            _instance.amountToProcess,
            googleProtobuf002.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.cost = new googleProtobuf002.DoubleValue();
          _reader.readMessage(
            _instance.cost,
            googleProtobuf002.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.type = _reader.readEnum();
          break;
        case 8:
          _instance.state = _reader.readEnum();
          break;
        case 9:
          _instance.isProcessed = _reader.readBool();
          break;
        case 10:
          const messageInitializer10 = new GoalTransactionFundingModel();
          _reader.readMessage(
            messageInitializer10,
            GoalTransactionFundingModel.deserializeBinaryFromReader
          );
          (_instance.goalTransactionFundings =
            _instance.goalTransactionFundings || []).push(messageInitializer10);
          break;
        case 11:
          _instance.goalTo = new GoalTransferModel();
          _reader.readMessage(
            _instance.goalTo,
            GoalTransferModel.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.goalFrom = new GoalTransferModel();
          _reader.readMessage(
            _instance.goalFrom,
            GoalTransferModel.deserializeBinaryFromReader
          );
          break;
        case 13:
          _instance.portfolioTo = new PortfolioChangeModel();
          _reader.readMessage(
            _instance.portfolioTo,
            PortfolioChangeModel.deserializeBinaryFromReader
          );
          break;
        case 14:
          _instance.portfolioFrom = new PortfolioChangeModel();
          _reader.readMessage(
            _instance.portfolioFrom,
            PortfolioChangeModel.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.currencyId = _reader.readInt32();
          break;
        case 16:
          _instance.currency = new common_message006.CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            common_message006.CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.financialEntity = new common_message006.FinancialEntityModel();
          _reader.readMessage(
            _instance.financialEntity,
            common_message006.FinancialEntityModel.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.paymentMethod = _reader.readEnum();
          break;
        case 19:
          _instance.userBankAccountId = _reader.readInt32();
          break;
        case 20:
          _instance.userBankAccount = new common_message006.UserBankAccountModel();
          _reader.readMessage(
            _instance.userBankAccount,
            common_message006.UserBankAccountModel.deserializeBinaryFromReader
          );
          break;
        case 21:
          _instance.reasonTransaction = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalTransactionActivitiesModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalTransactionActivitiesModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf002.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.amount) {
      _writer.writeMessage(
        2,
        _instance.amount as any,
        googleProtobuf002.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.amountRequested) {
      _writer.writeDouble(3, _instance.amountRequested);
    }
    if (_instance.amountToProcess) {
      _writer.writeMessage(
        4,
        _instance.amountToProcess as any,
        googleProtobuf002.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.cost) {
      _writer.writeMessage(
        5,
        _instance.cost as any,
        googleProtobuf002.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        6,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeEnum(7, _instance.type);
    }
    if (_instance.state) {
      _writer.writeEnum(8, _instance.state);
    }
    if (_instance.isProcessed) {
      _writer.writeBool(9, _instance.isProcessed);
    }
    if (
      _instance.goalTransactionFundings &&
      _instance.goalTransactionFundings.length
    ) {
      _writer.writeRepeatedMessage(
        10,
        _instance.goalTransactionFundings as any,
        GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalTo) {
      _writer.writeMessage(
        11,
        _instance.goalTo as any,
        GoalTransferModel.serializeBinaryToWriter
      );
    }
    if (_instance.goalFrom) {
      _writer.writeMessage(
        12,
        _instance.goalFrom as any,
        GoalTransferModel.serializeBinaryToWriter
      );
    }
    if (_instance.portfolioTo) {
      _writer.writeMessage(
        13,
        _instance.portfolioTo as any,
        PortfolioChangeModel.serializeBinaryToWriter
      );
    }
    if (_instance.portfolioFrom) {
      _writer.writeMessage(
        14,
        _instance.portfolioFrom as any,
        PortfolioChangeModel.serializeBinaryToWriter
      );
    }
    if (_instance.currencyId) {
      _writer.writeInt32(15, _instance.currencyId);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        16,
        _instance.currency as any,
        common_message006.CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.financialEntity) {
      _writer.writeMessage(
        17,
        _instance.financialEntity as any,
        common_message006.FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.paymentMethod) {
      _writer.writeEnum(18, _instance.paymentMethod);
    }
    if (_instance.userBankAccountId) {
      _writer.writeInt32(19, _instance.userBankAccountId);
    }
    if (_instance.userBankAccount) {
      _writer.writeMessage(
        20,
        _instance.userBankAccount as any,
        common_message006.UserBankAccountModel.serializeBinaryToWriter
      );
    }
    if (_instance.reasonTransaction) {
      _writer.writeString(21, _instance.reasonTransaction);
    }
  }

  private _id?: googleProtobuf002.Int32Value;
  private _amount?: googleProtobuf002.DoubleValue;
  private _amountRequested: number;
  private _amountToProcess?: googleProtobuf002.DoubleValue;
  private _cost?: googleProtobuf002.DoubleValue;
  private _date?: googleProtobuf001.Timestamp;
  private _type: goal_transaction_type_enums004.GoalTransactionType;
  private _state: goal_transaction_enums003.GoalTransactionState;
  private _isProcessed: boolean;
  private _goalTransactionFundings?: GoalTransactionFundingModel[];
  private _goalTo?: GoalTransferModel;
  private _goalFrom?: GoalTransferModel;
  private _portfolioTo?: PortfolioChangeModel;
  private _portfolioFrom?: PortfolioChangeModel;
  private _currencyId: number;
  private _currency?: common_message006.CurrencyModel;
  private _financialEntity?: common_message006.FinancialEntityModel;
  private _paymentMethod: goal_transaction_payment_enum007.GoalTransactionPaymentMethod;
  private _userBankAccountId: number;
  private _userBankAccount?: common_message006.UserBankAccountModel;
  private _reasonTransaction: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalTransactionActivitiesModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GoalTransactionActivitiesModel.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf002.Int32Value(_value.id)
      : undefined;
    this.amount = _value.amount
      ? new googleProtobuf002.DoubleValue(_value.amount)
      : undefined;
    this.amountRequested = _value.amountRequested;
    this.amountToProcess = _value.amountToProcess
      ? new googleProtobuf002.DoubleValue(_value.amountToProcess)
      : undefined;
    this.cost = _value.cost
      ? new googleProtobuf002.DoubleValue(_value.cost)
      : undefined;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.type = _value.type;
    this.state = _value.state;
    this.isProcessed = _value.isProcessed;
    this.goalTransactionFundings = (_value.goalTransactionFundings || []).map(
      m => new GoalTransactionFundingModel(m)
    );
    this.goalTo = _value.goalTo
      ? new GoalTransferModel(_value.goalTo)
      : undefined;
    this.goalFrom = _value.goalFrom
      ? new GoalTransferModel(_value.goalFrom)
      : undefined;
    this.portfolioTo = _value.portfolioTo
      ? new PortfolioChangeModel(_value.portfolioTo)
      : undefined;
    this.portfolioFrom = _value.portfolioFrom
      ? new PortfolioChangeModel(_value.portfolioFrom)
      : undefined;
    this.currencyId = _value.currencyId;
    this.currency = _value.currency
      ? new common_message006.CurrencyModel(_value.currency)
      : undefined;
    this.financialEntity = _value.financialEntity
      ? new common_message006.FinancialEntityModel(_value.financialEntity)
      : undefined;
    this.paymentMethod = _value.paymentMethod;
    this.userBankAccountId = _value.userBankAccountId;
    this.userBankAccount = _value.userBankAccount
      ? new common_message006.UserBankAccountModel(_value.userBankAccount)
      : undefined;
    this.reasonTransaction = _value.reasonTransaction;
    GoalTransactionActivitiesModel.refineValues(this);
  }
  get id(): googleProtobuf002.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf002.Int32Value | undefined) {
    this._id = value;
  }
  get amount(): googleProtobuf002.DoubleValue | undefined {
    return this._amount;
  }
  set amount(value: googleProtobuf002.DoubleValue | undefined) {
    this._amount = value;
  }
  get amountRequested(): number {
    return this._amountRequested;
  }
  set amountRequested(value: number) {
    this._amountRequested = value;
  }
  get amountToProcess(): googleProtobuf002.DoubleValue | undefined {
    return this._amountToProcess;
  }
  set amountToProcess(value: googleProtobuf002.DoubleValue | undefined) {
    this._amountToProcess = value;
  }
  get cost(): googleProtobuf002.DoubleValue | undefined {
    return this._cost;
  }
  set cost(value: googleProtobuf002.DoubleValue | undefined) {
    this._cost = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get type(): goal_transaction_type_enums004.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums004.GoalTransactionType) {
    this._type = value;
  }
  get state(): goal_transaction_enums003.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums003.GoalTransactionState) {
    this._state = value;
  }
  get isProcessed(): boolean {
    return this._isProcessed;
  }
  set isProcessed(value: boolean) {
    this._isProcessed = value;
  }
  get goalTransactionFundings(): GoalTransactionFundingModel[] | undefined {
    return this._goalTransactionFundings;
  }
  set goalTransactionFundings(
    value: GoalTransactionFundingModel[] | undefined
  ) {
    this._goalTransactionFundings = value;
  }
  get goalTo(): GoalTransferModel | undefined {
    return this._goalTo;
  }
  set goalTo(value: GoalTransferModel | undefined) {
    this._goalTo = value;
  }
  get goalFrom(): GoalTransferModel | undefined {
    return this._goalFrom;
  }
  set goalFrom(value: GoalTransferModel | undefined) {
    this._goalFrom = value;
  }
  get portfolioTo(): PortfolioChangeModel | undefined {
    return this._portfolioTo;
  }
  set portfolioTo(value: PortfolioChangeModel | undefined) {
    this._portfolioTo = value;
  }
  get portfolioFrom(): PortfolioChangeModel | undefined {
    return this._portfolioFrom;
  }
  set portfolioFrom(value: PortfolioChangeModel | undefined) {
    this._portfolioFrom = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currency(): common_message006.CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: common_message006.CurrencyModel | undefined) {
    this._currency = value;
  }
  get financialEntity(): common_message006.FinancialEntityModel | undefined {
    return this._financialEntity;
  }
  set financialEntity(
    value: common_message006.FinancialEntityModel | undefined
  ) {
    this._financialEntity = value;
  }
  get paymentMethod(): goal_transaction_payment_enum007.GoalTransactionPaymentMethod {
    return this._paymentMethod;
  }
  set paymentMethod(
    value: goal_transaction_payment_enum007.GoalTransactionPaymentMethod
  ) {
    this._paymentMethod = value;
  }
  get userBankAccountId(): number {
    return this._userBankAccountId;
  }
  set userBankAccountId(value: number) {
    this._userBankAccountId = value;
  }
  get userBankAccount(): common_message006.UserBankAccountModel | undefined {
    return this._userBankAccount;
  }
  set userBankAccount(
    value: common_message006.UserBankAccountModel | undefined
  ) {
    this._userBankAccount = value;
  }
  get reasonTransaction(): string {
    return this._reasonTransaction;
  }
  set reasonTransaction(value: string) {
    this._reasonTransaction = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalTransactionActivitiesModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalTransactionActivitiesModel.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      amount: this.amount ? this.amount.toObject() : undefined,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess
        ? this.amountToProcess.toObject()
        : undefined,
      cost: this.cost ? this.cost.toObject() : undefined,
      date: this.date ? this.date.toObject() : undefined,
      type: this.type,
      state: this.state,
      isProcessed: this.isProcessed,
      goalTransactionFundings: (this.goalTransactionFundings || []).map(m =>
        m.toObject()
      ),
      goalTo: this.goalTo ? this.goalTo.toObject() : undefined,
      goalFrom: this.goalFrom ? this.goalFrom.toObject() : undefined,
      portfolioTo: this.portfolioTo ? this.portfolioTo.toObject() : undefined,
      portfolioFrom: this.portfolioFrom
        ? this.portfolioFrom.toObject()
        : undefined,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toObject() : undefined,
      financialEntity: this.financialEntity
        ? this.financialEntity.toObject()
        : undefined,
      paymentMethod: this.paymentMethod,
      userBankAccountId: this.userBankAccountId,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toObject()
        : undefined,
      reasonTransaction: this.reasonTransaction
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalTransactionActivitiesModel.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      amount: this.amount ? this.amount.toProtobufJSON(options) : null,
      amountRequested: this.amountRequested,
      amountToProcess: this.amountToProcess
        ? this.amountToProcess.toProtobufJSON(options)
        : null,
      cost: this.cost ? this.cost.toProtobufJSON(options) : null,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      type:
        goal_transaction_type_enums004.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      state:
        goal_transaction_enums003.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      isProcessed: this.isProcessed,
      goalTransactionFundings: (this.goalTransactionFundings || []).map(m =>
        m.toProtobufJSON(options)
      ),
      goalTo: this.goalTo ? this.goalTo.toProtobufJSON(options) : null,
      goalFrom: this.goalFrom ? this.goalFrom.toProtobufJSON(options) : null,
      portfolioTo: this.portfolioTo
        ? this.portfolioTo.toProtobufJSON(options)
        : null,
      portfolioFrom: this.portfolioFrom
        ? this.portfolioFrom.toProtobufJSON(options)
        : null,
      currencyId: this.currencyId,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      financialEntity: this.financialEntity
        ? this.financialEntity.toProtobufJSON(options)
        : null,
      paymentMethod:
        goal_transaction_payment_enum007.GoalTransactionPaymentMethod[
          this.paymentMethod === null || this.paymentMethod === undefined
            ? 0
            : this.paymentMethod
        ],
      userBankAccountId: this.userBankAccountId,
      userBankAccount: this.userBankAccount
        ? this.userBankAccount.toProtobufJSON(options)
        : null,
      reasonTransaction: this.reasonTransaction
    };
  }
}
export module GoalTransactionActivitiesModel {
  /**
   * Standard JavaScript object representation for GoalTransactionActivitiesModel
   */
  export interface AsObject {
    id?: googleProtobuf002.Int32Value.AsObject;
    amount?: googleProtobuf002.DoubleValue.AsObject;
    amountRequested: number;
    amountToProcess?: googleProtobuf002.DoubleValue.AsObject;
    cost?: googleProtobuf002.DoubleValue.AsObject;
    date?: googleProtobuf001.Timestamp.AsObject;
    type: goal_transaction_type_enums004.GoalTransactionType;
    state: goal_transaction_enums003.GoalTransactionState;
    isProcessed: boolean;
    goalTransactionFundings?: GoalTransactionFundingModel.AsObject[];
    goalTo?: GoalTransferModel.AsObject;
    goalFrom?: GoalTransferModel.AsObject;
    portfolioTo?: PortfolioChangeModel.AsObject;
    portfolioFrom?: PortfolioChangeModel.AsObject;
    currencyId: number;
    currency?: common_message006.CurrencyModel.AsObject;
    financialEntity?: common_message006.FinancialEntityModel.AsObject;
    paymentMethod: goal_transaction_payment_enum007.GoalTransactionPaymentMethod;
    userBankAccountId: number;
    userBankAccount?: common_message006.UserBankAccountModel.AsObject;
    reasonTransaction: string;
  }

  /**
   * Protobuf JSON representation for GoalTransactionActivitiesModel
   */
  export interface AsProtobufJSON {
    id: googleProtobuf002.Int32Value.AsProtobufJSON | null;
    amount: googleProtobuf002.DoubleValue.AsProtobufJSON | null;
    amountRequested: number;
    amountToProcess: googleProtobuf002.DoubleValue.AsProtobufJSON | null;
    cost: googleProtobuf002.DoubleValue.AsProtobufJSON | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    type: string;
    state: string;
    isProcessed: boolean;
    goalTransactionFundings:
      | GoalTransactionFundingModel.AsProtobufJSON[]
      | null;
    goalTo: GoalTransferModel.AsProtobufJSON | null;
    goalFrom: GoalTransferModel.AsProtobufJSON | null;
    portfolioTo: PortfolioChangeModel.AsProtobufJSON | null;
    portfolioFrom: PortfolioChangeModel.AsProtobufJSON | null;
    currencyId: number;
    currency: common_message006.CurrencyModel.AsProtobufJSON | null;
    financialEntity: common_message006.FinancialEntityModel.AsProtobufJSON | null;
    paymentMethod: string;
    userBankAccountId: number;
    userBankAccount: common_message006.UserBankAccountModel.AsProtobufJSON | null;
    reasonTransaction: string;
  }
}

/**
 * Message implementation for portal_goal.GoalTransactionFundingModel
 */
export class GoalTransactionFundingModel implements GrpcMessage {
  static id = 'portal_goal.GoalTransactionFundingModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalTransactionFundingModel();
    GoalTransactionFundingModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalTransactionFundingModel) {
    _instance.amount = _instance.amount || 0;
    _instance.percentage = _instance.percentage || 0;
    _instance.date = _instance.date || undefined;
    _instance.type = _instance.type || 0;
    _instance.price = _instance.price || 0;
    _instance.quotas = _instance.quotas || 0;
    _instance.mnemonic = _instance.mnemonic || '';
    _instance.isBox = _instance.isBox || false;
    _instance.currency = _instance.currency || undefined;
    _instance.originMnemonic = _instance.originMnemonic || '';
    _instance.id = _instance.id || 0;
    _instance.transactionId = _instance.transactionId || 0;
    _instance.transactionPaymentMethod =
      _instance.transactionPaymentMethod || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalTransactionFundingModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.amount = _reader.readDouble();
          break;
        case 2:
          _instance.percentage = _reader.readDouble();
          break;
        case 3:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.type = _reader.readEnum();
          break;
        case 5:
          _instance.price = _reader.readDouble();
          break;
        case 6:
          _instance.quotas = _reader.readDouble();
          break;
        case 7:
          _instance.mnemonic = _reader.readString();
          break;
        case 8:
          _instance.isBox = _reader.readBool();
          break;
        case 9:
          _instance.currency = new common_message006.CurrencyModel();
          _reader.readMessage(
            _instance.currency,
            common_message006.CurrencyModel.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.originMnemonic = _reader.readString();
          break;
        case 11:
          _instance.id = _reader.readInt32();
          break;
        case 12:
          _instance.transactionId = _reader.readInt32();
          break;
        case 13:
          _instance.transactionPaymentMethod = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalTransactionFundingModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalTransactionFundingModel,
    _writer: BinaryWriter
  ) {
    if (_instance.amount) {
      _writer.writeDouble(1, _instance.amount);
    }
    if (_instance.percentage) {
      _writer.writeDouble(2, _instance.percentage);
    }
    if (_instance.date) {
      _writer.writeMessage(
        3,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.type) {
      _writer.writeEnum(4, _instance.type);
    }
    if (_instance.price) {
      _writer.writeDouble(5, _instance.price);
    }
    if (_instance.quotas) {
      _writer.writeDouble(6, _instance.quotas);
    }
    if (_instance.mnemonic) {
      _writer.writeString(7, _instance.mnemonic);
    }
    if (_instance.isBox) {
      _writer.writeBool(8, _instance.isBox);
    }
    if (_instance.currency) {
      _writer.writeMessage(
        9,
        _instance.currency as any,
        common_message006.CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.originMnemonic) {
      _writer.writeString(10, _instance.originMnemonic);
    }
    if (_instance.id) {
      _writer.writeInt32(11, _instance.id);
    }
    if (_instance.transactionId) {
      _writer.writeInt32(12, _instance.transactionId);
    }
    if (_instance.transactionPaymentMethod) {
      _writer.writeEnum(13, _instance.transactionPaymentMethod);
    }
  }

  private _amount: number;
  private _percentage: number;
  private _date?: googleProtobuf001.Timestamp;
  private _type: goal_transaction_type_enums004.GoalTransactionType;
  private _price: number;
  private _quotas: number;
  private _mnemonic: string;
  private _isBox: boolean;
  private _currency?: common_message006.CurrencyModel;
  private _originMnemonic: string;
  private _id: number;
  private _transactionId: number;
  private _transactionPaymentMethod: goal_transaction_payment_enum007.GoalTransactionPaymentMethod;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalTransactionFundingModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalTransactionFundingModel.AsObject>) {
    _value = _value || {};
    this.amount = _value.amount;
    this.percentage = _value.percentage;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.type = _value.type;
    this.price = _value.price;
    this.quotas = _value.quotas;
    this.mnemonic = _value.mnemonic;
    this.isBox = _value.isBox;
    this.currency = _value.currency
      ? new common_message006.CurrencyModel(_value.currency)
      : undefined;
    this.originMnemonic = _value.originMnemonic;
    this.id = _value.id;
    this.transactionId = _value.transactionId;
    this.transactionPaymentMethod = _value.transactionPaymentMethod;
    GoalTransactionFundingModel.refineValues(this);
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get type(): goal_transaction_type_enums004.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums004.GoalTransactionType) {
    this._type = value;
  }
  get price(): number {
    return this._price;
  }
  set price(value: number) {
    this._price = value;
  }
  get quotas(): number {
    return this._quotas;
  }
  set quotas(value: number) {
    this._quotas = value;
  }
  get mnemonic(): string {
    return this._mnemonic;
  }
  set mnemonic(value: string) {
    this._mnemonic = value;
  }
  get isBox(): boolean {
    return this._isBox;
  }
  set isBox(value: boolean) {
    this._isBox = value;
  }
  get currency(): common_message006.CurrencyModel | undefined {
    return this._currency;
  }
  set currency(value: common_message006.CurrencyModel | undefined) {
    this._currency = value;
  }
  get originMnemonic(): string {
    return this._originMnemonic;
  }
  set originMnemonic(value: string) {
    this._originMnemonic = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get transactionId(): number {
    return this._transactionId;
  }
  set transactionId(value: number) {
    this._transactionId = value;
  }
  get transactionPaymentMethod(): goal_transaction_payment_enum007.GoalTransactionPaymentMethod {
    return this._transactionPaymentMethod;
  }
  set transactionPaymentMethod(
    value: goal_transaction_payment_enum007.GoalTransactionPaymentMethod
  ) {
    this._transactionPaymentMethod = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalTransactionFundingModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalTransactionFundingModel.AsObject {
    return {
      amount: this.amount,
      percentage: this.percentage,
      date: this.date ? this.date.toObject() : undefined,
      type: this.type,
      price: this.price,
      quotas: this.quotas,
      mnemonic: this.mnemonic,
      isBox: this.isBox,
      currency: this.currency ? this.currency.toObject() : undefined,
      originMnemonic: this.originMnemonic,
      id: this.id,
      transactionId: this.transactionId,
      transactionPaymentMethod: this.transactionPaymentMethod
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalTransactionFundingModel.AsProtobufJSON {
    return {
      amount: this.amount,
      percentage: this.percentage,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      type:
        goal_transaction_type_enums004.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      price: this.price,
      quotas: this.quotas,
      mnemonic: this.mnemonic,
      isBox: this.isBox,
      currency: this.currency ? this.currency.toProtobufJSON(options) : null,
      originMnemonic: this.originMnemonic,
      id: this.id,
      transactionId: this.transactionId,
      transactionPaymentMethod:
        goal_transaction_payment_enum007.GoalTransactionPaymentMethod[
          this.transactionPaymentMethod === null ||
          this.transactionPaymentMethod === undefined
            ? 0
            : this.transactionPaymentMethod
        ]
    };
  }
}
export module GoalTransactionFundingModel {
  /**
   * Standard JavaScript object representation for GoalTransactionFundingModel
   */
  export interface AsObject {
    amount: number;
    percentage: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    type: goal_transaction_type_enums004.GoalTransactionType;
    price: number;
    quotas: number;
    mnemonic: string;
    isBox: boolean;
    currency?: common_message006.CurrencyModel.AsObject;
    originMnemonic: string;
    id: number;
    transactionId: number;
    transactionPaymentMethod: goal_transaction_payment_enum007.GoalTransactionPaymentMethod;
  }

  /**
   * Protobuf JSON representation for GoalTransactionFundingModel
   */
  export interface AsProtobufJSON {
    amount: number;
    percentage: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    type: string;
    price: number;
    quotas: number;
    mnemonic: string;
    isBox: boolean;
    currency: common_message006.CurrencyModel.AsProtobufJSON | null;
    originMnemonic: string;
    id: number;
    transactionId: number;
    transactionPaymentMethod: string;
  }
}

/**
 * Message implementation for portal_goal.GoalTransferModel
 */
export class GoalTransferModel implements GrpcMessage {
  static id = 'portal_goal.GoalTransferModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalTransferModel();
    GoalTransferModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalTransferModel) {
    _instance.goalId = _instance.goalId || 0;
    _instance.title = _instance.title || '';
    _instance.goalCategory = _instance.goalCategory || undefined;
    _instance.imageLarge = _instance.imageLarge || '';
    _instance.imageSmall = _instance.imageSmall || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalTransferModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalId = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.goalCategory = new GoalCategoryTransferModel();
          _reader.readMessage(
            _instance.goalCategory,
            GoalCategoryTransferModel.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.imageLarge = _reader.readString();
          break;
        case 5:
          _instance.imageSmall = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalTransferModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalTransferModel,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeInt32(1, _instance.goalId);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.goalCategory) {
      _writer.writeMessage(
        3,
        _instance.goalCategory as any,
        GoalCategoryTransferModel.serializeBinaryToWriter
      );
    }
    if (_instance.imageLarge) {
      _writer.writeString(4, _instance.imageLarge);
    }
    if (_instance.imageSmall) {
      _writer.writeString(5, _instance.imageSmall);
    }
  }

  private _goalId: number;
  private _title: string;
  private _goalCategory?: GoalCategoryTransferModel;
  private _imageLarge: string;
  private _imageSmall: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalTransferModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalTransferModel.AsObject>) {
    _value = _value || {};
    this.goalId = _value.goalId;
    this.title = _value.title;
    this.goalCategory = _value.goalCategory
      ? new GoalCategoryTransferModel(_value.goalCategory)
      : undefined;
    this.imageLarge = _value.imageLarge;
    this.imageSmall = _value.imageSmall;
    GoalTransferModel.refineValues(this);
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get goalCategory(): GoalCategoryTransferModel | undefined {
    return this._goalCategory;
  }
  set goalCategory(value: GoalCategoryTransferModel | undefined) {
    this._goalCategory = value;
  }
  get imageLarge(): string {
    return this._imageLarge;
  }
  set imageLarge(value: string) {
    this._imageLarge = value;
  }
  get imageSmall(): string {
    return this._imageSmall;
  }
  set imageSmall(value: string) {
    this._imageSmall = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalTransferModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalTransferModel.AsObject {
    return {
      goalId: this.goalId,
      title: this.title,
      goalCategory: this.goalCategory
        ? this.goalCategory.toObject()
        : undefined,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalTransferModel.AsProtobufJSON {
    return {
      goalId: this.goalId,
      title: this.title,
      goalCategory: this.goalCategory
        ? this.goalCategory.toProtobufJSON(options)
        : null,
      imageLarge: this.imageLarge,
      imageSmall: this.imageSmall
    };
  }
}
export module GoalTransferModel {
  /**
   * Standard JavaScript object representation for GoalTransferModel
   */
  export interface AsObject {
    goalId: number;
    title: string;
    goalCategory?: GoalCategoryTransferModel.AsObject;
    imageLarge: string;
    imageSmall: string;
  }

  /**
   * Protobuf JSON representation for GoalTransferModel
   */
  export interface AsProtobufJSON {
    goalId: number;
    title: string;
    goalCategory: GoalCategoryTransferModel.AsProtobufJSON | null;
    imageLarge: string;
    imageSmall: string;
  }
}

/**
 * Message implementation for portal_goal.GoalCategoryTransferModel
 */
export class GoalCategoryTransferModel implements GrpcMessage {
  static id = 'portal_goal.GoalCategoryTransferModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GoalCategoryTransferModel();
    GoalCategoryTransferModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GoalCategoryTransferModel) {
    _instance.goalCategoryId = _instance.goalCategoryId || 0;
    _instance.title = _instance.title || '';
    _instance.image = _instance.image || undefined;
    _instance.code = _instance.code || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GoalCategoryTransferModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalCategoryId = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.image = new GoalCategoryTransferModel.Image();
          _reader.readMessage(
            _instance.image,
            GoalCategoryTransferModel.Image.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.code = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GoalCategoryTransferModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GoalCategoryTransferModel,
    _writer: BinaryWriter
  ) {
    if (_instance.goalCategoryId) {
      _writer.writeInt32(1, _instance.goalCategoryId);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.image) {
      _writer.writeMessage(
        3,
        _instance.image as any,
        GoalCategoryTransferModel.Image.serializeBinaryToWriter
      );
    }
    if (_instance.code) {
      _writer.writeString(4, _instance.code);
    }
  }

  private _goalCategoryId: number;
  private _title: string;
  private _image?: GoalCategoryTransferModel.Image;
  private _code: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GoalCategoryTransferModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<GoalCategoryTransferModel.AsObject>) {
    _value = _value || {};
    this.goalCategoryId = _value.goalCategoryId;
    this.title = _value.title;
    this.image = _value.image
      ? new GoalCategoryTransferModel.Image(_value.image)
      : undefined;
    this.code = _value.code;
    GoalCategoryTransferModel.refineValues(this);
  }
  get goalCategoryId(): number {
    return this._goalCategoryId;
  }
  set goalCategoryId(value: number) {
    this._goalCategoryId = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get image(): GoalCategoryTransferModel.Image | undefined {
    return this._image;
  }
  set image(value: GoalCategoryTransferModel.Image | undefined) {
    this._image = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GoalCategoryTransferModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GoalCategoryTransferModel.AsObject {
    return {
      goalCategoryId: this.goalCategoryId,
      title: this.title,
      image: this.image ? this.image.toObject() : undefined,
      code: this.code
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GoalCategoryTransferModel.AsProtobufJSON {
    return {
      goalCategoryId: this.goalCategoryId,
      title: this.title,
      image: this.image ? this.image.toProtobufJSON(options) : null,
      code: this.code
    };
  }
}
export module GoalCategoryTransferModel {
  /**
   * Standard JavaScript object representation for GoalCategoryTransferModel
   */
  export interface AsObject {
    goalCategoryId: number;
    title: string;
    image?: GoalCategoryTransferModel.Image.AsObject;
    code: string;
  }

  /**
   * Protobuf JSON representation for GoalCategoryTransferModel
   */
  export interface AsProtobufJSON {
    goalCategoryId: number;
    title: string;
    image: GoalCategoryTransferModel.Image.AsProtobufJSON | null;
    code: string;
  }

  /**
   * Message implementation for portal_goal.GoalCategoryTransferModel.Image
   */
  export class Image implements GrpcMessage {
    static id = 'portal_goal.GoalCategoryTransferModel.Image';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Image();
      Image.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Image) {
      _instance.src = _instance.src || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Image,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.src = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Image.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Image, _writer: BinaryWriter) {
      if (_instance.src) {
        _writer.writeString(1, _instance.src);
      }
    }

    private _src: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Image to deeply clone from
     */
    constructor(_value?: RecursivePartial<Image.AsObject>) {
      _value = _value || {};
      this.src = _value.src;
      Image.refineValues(this);
    }
    get src(): string {
      return this._src;
    }
    set src(value: string) {
      this._src = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Image.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Image.AsObject {
      return {
        src: this.src
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Image.AsProtobufJSON {
      return {
        src: this.src
      };
    }
  }
  export module Image {
    /**
     * Standard JavaScript object representation for Image
     */
    export interface AsObject {
      src: string;
    }

    /**
     * Protobuf JSON representation for Image
     */
    export interface AsProtobufJSON {
      src: string;
    }
  }
}

/**
 * Message implementation for portal_goal.PortfolioChangeModel
 */
export class PortfolioChangeModel implements GrpcMessage {
  static id = 'portal_goal.PortfolioChangeModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PortfolioChangeModel();
    PortfolioChangeModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PortfolioChangeModel) {
    _instance.portfolioId = _instance.portfolioId || 0;
    _instance.title = _instance.title || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PortfolioChangeModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.portfolioId = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    PortfolioChangeModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PortfolioChangeModel,
    _writer: BinaryWriter
  ) {
    if (_instance.portfolioId) {
      _writer.writeInt32(1, _instance.portfolioId);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
  }

  private _portfolioId: number;
  private _title: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PortfolioChangeModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<PortfolioChangeModel.AsObject>) {
    _value = _value || {};
    this.portfolioId = _value.portfolioId;
    this.title = _value.title;
    PortfolioChangeModel.refineValues(this);
  }
  get portfolioId(): number {
    return this._portfolioId;
  }
  set portfolioId(value: number) {
    this._portfolioId = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PortfolioChangeModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PortfolioChangeModel.AsObject {
    return {
      portfolioId: this.portfolioId,
      title: this.title
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PortfolioChangeModel.AsProtobufJSON {
    return {
      portfolioId: this.portfolioId,
      title: this.title
    };
  }
}
export module PortfolioChangeModel {
  /**
   * Standard JavaScript object representation for PortfolioChangeModel
   */
  export interface AsObject {
    portfolioId: number;
    title: string;
  }

  /**
   * Protobuf JSON representation for PortfolioChangeModel
   */
  export interface AsProtobufJSON {
    portfolioId: number;
    title: string;
  }
}

/**
 * Message implementation for portal_goal.ListGoalTransactionActivitiesResponse
 */
export class ListGoalTransactionActivitiesResponse implements GrpcMessage {
  static id = 'portal_goal.ListGoalTransactionActivitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionActivitiesResponse();
    ListGoalTransactionActivitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionActivitiesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
    _instance.totalRows = _instance.totalRows || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionActivitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GoalTransactionActivitiesModel();
          _reader.readMessage(
            messageInitializer1,
            GoalTransactionActivitiesModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        case 6:
          _instance.totalRows = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionActivitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionActivitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        GoalTransactionActivitiesModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
    if (_instance.totalRows) {
      _writer.writeInt32(6, _instance.totalRows);
    }
  }

  private _values?: GoalTransactionActivitiesModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;
  private _totalRows: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionActivitiesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalTransactionActivitiesResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new GoalTransactionActivitiesModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    this.totalRows = _value.totalRows;
    ListGoalTransactionActivitiesResponse.refineValues(this);
  }
  get values(): GoalTransactionActivitiesModel[] | undefined {
    return this._values;
  }
  set values(value: GoalTransactionActivitiesModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }
  get totalRows(): number {
    return this._totalRows;
  }
  set totalRows(value: number) {
    this._totalRows = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionActivitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionActivitiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount,
      totalRows: this.totalRows
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionActivitiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount,
      totalRows: this.totalRows
    };
  }
}
export module ListGoalTransactionActivitiesResponse {
  /**
   * Standard JavaScript object representation for ListGoalTransactionActivitiesResponse
   */
  export interface AsObject {
    values?: GoalTransactionActivitiesModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    totalRows: number;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionActivitiesResponse
   */
  export interface AsProtobufJSON {
    values: GoalTransactionActivitiesModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    totalRows: number;
  }
}

/**
 * Message implementation for portal_goal.CheckStarredGoalRequest
 */
export class CheckStarredGoalRequest implements GrpcMessage {
  static id = 'portal_goal.CheckStarredGoalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CheckStarredGoalRequest();
    CheckStarredGoalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CheckStarredGoalRequest) {
    _instance.financialEntityId = _instance.financialEntityId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CheckStarredGoalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.financialEntityId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CheckStarredGoalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CheckStarredGoalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.financialEntityId) {
      _writer.writeInt32(1, _instance.financialEntityId);
    }
  }

  private _financialEntityId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CheckStarredGoalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CheckStarredGoalRequest.AsObject>) {
    _value = _value || {};
    this.financialEntityId = _value.financialEntityId;
    CheckStarredGoalRequest.refineValues(this);
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CheckStarredGoalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CheckStarredGoalRequest.AsObject {
    return {
      financialEntityId: this.financialEntityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CheckStarredGoalRequest.AsProtobufJSON {
    return {
      financialEntityId: this.financialEntityId
    };
  }
}
export module CheckStarredGoalRequest {
  /**
   * Standard JavaScript object representation for CheckStarredGoalRequest
   */
  export interface AsObject {
    financialEntityId: number;
  }

  /**
   * Protobuf JSON representation for CheckStarredGoalRequest
   */
  export interface AsProtobufJSON {
    financialEntityId: number;
  }
}

/**
 * Message implementation for portal_goal.ProfitabilitiesResponse
 */
export class ProfitabilitiesResponse implements GrpcMessage {
  static id = 'portal_goal.ProfitabilitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfitabilitiesResponse();
    ProfitabilitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfitabilitiesResponse) {
    _instance.values = _instance.values || [];
    _instance.data = _instance.data || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProfitabilitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ProfitabilitiesResponse.Data();
          _reader.readMessage(
            messageInitializer1,
            ProfitabilitiesResponse.Data.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          const messageInitializer2 = new ProfitabilitiesResponse.ProfitabilityData();
          _reader.readMessage(
            messageInitializer2,
            ProfitabilitiesResponse.ProfitabilityData
              .deserializeBinaryFromReader
          );
          (_instance.data = _instance.data || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    ProfitabilitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProfitabilitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        ProfitabilitiesResponse.Data.serializeBinaryToWriter
      );
    }
    if (_instance.data && _instance.data.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.data as any,
        ProfitabilitiesResponse.ProfitabilityData.serializeBinaryToWriter
      );
    }
  }

  private _values?: ProfitabilitiesResponse.Data[];
  private _data?: ProfitabilitiesResponse.ProfitabilityData[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfitabilitiesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfitabilitiesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new ProfitabilitiesResponse.Data(m)
    );
    this.data = (_value.data || []).map(
      m => new ProfitabilitiesResponse.ProfitabilityData(m)
    );
    ProfitabilitiesResponse.refineValues(this);
  }
  get values(): ProfitabilitiesResponse.Data[] | undefined {
    return this._values;
  }
  set values(value: ProfitabilitiesResponse.Data[] | undefined) {
    this._values = value;
  }
  get data(): ProfitabilitiesResponse.ProfitabilityData[] | undefined {
    return this._data;
  }
  set data(value: ProfitabilitiesResponse.ProfitabilityData[] | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfitabilitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfitabilitiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      data: (this.data || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProfitabilitiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      data: (this.data || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ProfitabilitiesResponse {
  /**
   * Standard JavaScript object representation for ProfitabilitiesResponse
   */
  export interface AsObject {
    values?: ProfitabilitiesResponse.Data.AsObject[];
    data?: ProfitabilitiesResponse.ProfitabilityData.AsObject[];
  }

  /**
   * Protobuf JSON representation for ProfitabilitiesResponse
   */
  export interface AsProtobufJSON {
    values: ProfitabilitiesResponse.Data.AsProtobufJSON[] | null;
    data: ProfitabilitiesResponse.ProfitabilityData.AsProtobufJSON[] | null;
  }

  /**
   * Message implementation for portal_goal.ProfitabilitiesResponse.Data
   */
  export class Data implements GrpcMessage {
    static id = 'portal_goal.ProfitabilitiesResponse.Data';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Data();
      Data.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Data) {
      _instance.goalTitle = _instance.goalTitle || '';
      _instance.currencyCode = _instance.currencyCode || '';
      _instance.portfolioTitle = _instance.portfolioTitle || '';
      _instance.key = _instance.key || '';
      _instance.type = _instance.type || '';
      _instance.icon = _instance.icon || undefined;
      _instance.goalCategory = _instance.goalCategory || undefined;
      _instance.pastYearReturn = _instance.pastYearReturn || '';
      _instance.alltime = _instance.alltime || '';
      _instance.allTimeNumber = _instance.allTimeNumber || 0;
      _instance.description = _instance.description || '';
      _instance.mnemonic = _instance.mnemonic || '';
      _instance.name = _instance.name || '';
      _instance.pastYearReturnNumber = _instance.pastYearReturnNumber || 0;
      _instance.yahooMnemonic = _instance.yahooMnemonic || '';
      _instance.title = _instance.title || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Data, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.goalTitle = _reader.readString();
            break;
          case 2:
            _instance.currencyCode = _reader.readString();
            break;
          case 3:
            _instance.portfolioTitle = _reader.readString();
            break;
          case 4:
            _instance.key = _reader.readString();
            break;
          case 5:
            _instance.type = _reader.readString();
            break;
          case 6:
            _instance.icon = new ProfitabilitiesResponse.Data.Icon();
            _reader.readMessage(
              _instance.icon,
              ProfitabilitiesResponse.Data.Icon.deserializeBinaryFromReader
            );
            break;
          case 7:
            _instance.goalCategory = new common_message006.GoalCategoryModel();
            _reader.readMessage(
              _instance.goalCategory,
              common_message006.GoalCategoryModel.deserializeBinaryFromReader
            );
            break;
          case 8:
            _instance.pastYearReturn = _reader.readString();
            break;
          case 9:
            _instance.alltime = _reader.readString();
            break;
          case 10:
            _instance.allTimeNumber = _reader.readDouble();
            break;
          case 11:
            _instance.description = _reader.readString();
            break;
          case 12:
            _instance.mnemonic = _reader.readString();
            break;
          case 13:
            _instance.name = _reader.readString();
            break;
          case 14:
            _instance.pastYearReturnNumber = _reader.readDouble();
            break;
          case 15:
            _instance.yahooMnemonic = _reader.readString();
            break;
          case 16:
            _instance.title = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Data.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Data, _writer: BinaryWriter) {
      if (_instance.goalTitle) {
        _writer.writeString(1, _instance.goalTitle);
      }
      if (_instance.currencyCode) {
        _writer.writeString(2, _instance.currencyCode);
      }
      if (_instance.portfolioTitle) {
        _writer.writeString(3, _instance.portfolioTitle);
      }
      if (_instance.key) {
        _writer.writeString(4, _instance.key);
      }
      if (_instance.type) {
        _writer.writeString(5, _instance.type);
      }
      if (_instance.icon) {
        _writer.writeMessage(
          6,
          _instance.icon as any,
          ProfitabilitiesResponse.Data.Icon.serializeBinaryToWriter
        );
      }
      if (_instance.goalCategory) {
        _writer.writeMessage(
          7,
          _instance.goalCategory as any,
          common_message006.GoalCategoryModel.serializeBinaryToWriter
        );
      }
      if (_instance.pastYearReturn) {
        _writer.writeString(8, _instance.pastYearReturn);
      }
      if (_instance.alltime) {
        _writer.writeString(9, _instance.alltime);
      }
      if (_instance.allTimeNumber) {
        _writer.writeDouble(10, _instance.allTimeNumber);
      }
      if (_instance.description) {
        _writer.writeString(11, _instance.description);
      }
      if (_instance.mnemonic) {
        _writer.writeString(12, _instance.mnemonic);
      }
      if (_instance.name) {
        _writer.writeString(13, _instance.name);
      }
      if (_instance.pastYearReturnNumber) {
        _writer.writeDouble(14, _instance.pastYearReturnNumber);
      }
      if (_instance.yahooMnemonic) {
        _writer.writeString(15, _instance.yahooMnemonic);
      }
      if (_instance.title) {
        _writer.writeString(16, _instance.title);
      }
    }

    private _goalTitle: string;
    private _currencyCode: string;
    private _portfolioTitle: string;
    private _key: string;
    private _type: string;
    private _icon?: ProfitabilitiesResponse.Data.Icon;
    private _goalCategory?: common_message006.GoalCategoryModel;
    private _pastYearReturn: string;
    private _alltime: string;
    private _allTimeNumber: number;
    private _description: string;
    private _mnemonic: string;
    private _name: string;
    private _pastYearReturnNumber: number;
    private _yahooMnemonic: string;
    private _title: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Data to deeply clone from
     */
    constructor(_value?: RecursivePartial<Data.AsObject>) {
      _value = _value || {};
      this.goalTitle = _value.goalTitle;
      this.currencyCode = _value.currencyCode;
      this.portfolioTitle = _value.portfolioTitle;
      this.key = _value.key;
      this.type = _value.type;
      this.icon = _value.icon
        ? new ProfitabilitiesResponse.Data.Icon(_value.icon)
        : undefined;
      this.goalCategory = _value.goalCategory
        ? new common_message006.GoalCategoryModel(_value.goalCategory)
        : undefined;
      this.pastYearReturn = _value.pastYearReturn;
      this.alltime = _value.alltime;
      this.allTimeNumber = _value.allTimeNumber;
      this.description = _value.description;
      this.mnemonic = _value.mnemonic;
      this.name = _value.name;
      this.pastYearReturnNumber = _value.pastYearReturnNumber;
      this.yahooMnemonic = _value.yahooMnemonic;
      this.title = _value.title;
      Data.refineValues(this);
    }
    get goalTitle(): string {
      return this._goalTitle;
    }
    set goalTitle(value: string) {
      this._goalTitle = value;
    }
    get currencyCode(): string {
      return this._currencyCode;
    }
    set currencyCode(value: string) {
      this._currencyCode = value;
    }
    get portfolioTitle(): string {
      return this._portfolioTitle;
    }
    set portfolioTitle(value: string) {
      this._portfolioTitle = value;
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get type(): string {
      return this._type;
    }
    set type(value: string) {
      this._type = value;
    }
    get icon(): ProfitabilitiesResponse.Data.Icon | undefined {
      return this._icon;
    }
    set icon(value: ProfitabilitiesResponse.Data.Icon | undefined) {
      this._icon = value;
    }
    get goalCategory(): common_message006.GoalCategoryModel | undefined {
      return this._goalCategory;
    }
    set goalCategory(value: common_message006.GoalCategoryModel | undefined) {
      this._goalCategory = value;
    }
    get pastYearReturn(): string {
      return this._pastYearReturn;
    }
    set pastYearReturn(value: string) {
      this._pastYearReturn = value;
    }
    get alltime(): string {
      return this._alltime;
    }
    set alltime(value: string) {
      this._alltime = value;
    }
    get allTimeNumber(): number {
      return this._allTimeNumber;
    }
    set allTimeNumber(value: number) {
      this._allTimeNumber = value;
    }
    get description(): string {
      return this._description;
    }
    set description(value: string) {
      this._description = value;
    }
    get mnemonic(): string {
      return this._mnemonic;
    }
    set mnemonic(value: string) {
      this._mnemonic = value;
    }
    get name(): string {
      return this._name;
    }
    set name(value: string) {
      this._name = value;
    }
    get pastYearReturnNumber(): number {
      return this._pastYearReturnNumber;
    }
    set pastYearReturnNumber(value: number) {
      this._pastYearReturnNumber = value;
    }
    get yahooMnemonic(): string {
      return this._yahooMnemonic;
    }
    set yahooMnemonic(value: string) {
      this._yahooMnemonic = value;
    }
    get title(): string {
      return this._title;
    }
    set title(value: string) {
      this._title = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Data.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Data.AsObject {
      return {
        goalTitle: this.goalTitle,
        currencyCode: this.currencyCode,
        portfolioTitle: this.portfolioTitle,
        key: this.key,
        type: this.type,
        icon: this.icon ? this.icon.toObject() : undefined,
        goalCategory: this.goalCategory
          ? this.goalCategory.toObject()
          : undefined,
        pastYearReturn: this.pastYearReturn,
        alltime: this.alltime,
        allTimeNumber: this.allTimeNumber,
        description: this.description,
        mnemonic: this.mnemonic,
        name: this.name,
        pastYearReturnNumber: this.pastYearReturnNumber,
        yahooMnemonic: this.yahooMnemonic,
        title: this.title
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Data.AsProtobufJSON {
      return {
        goalTitle: this.goalTitle,
        currencyCode: this.currencyCode,
        portfolioTitle: this.portfolioTitle,
        key: this.key,
        type: this.type,
        icon: this.icon ? this.icon.toProtobufJSON(options) : null,
        goalCategory: this.goalCategory
          ? this.goalCategory.toProtobufJSON(options)
          : null,
        pastYearReturn: this.pastYearReturn,
        alltime: this.alltime,
        allTimeNumber: this.allTimeNumber,
        description: this.description,
        mnemonic: this.mnemonic,
        name: this.name,
        pastYearReturnNumber: this.pastYearReturnNumber,
        yahooMnemonic: this.yahooMnemonic,
        title: this.title
      };
    }
  }
  export module Data {
    /**
     * Standard JavaScript object representation for Data
     */
    export interface AsObject {
      goalTitle: string;
      currencyCode: string;
      portfolioTitle: string;
      key: string;
      type: string;
      icon?: ProfitabilitiesResponse.Data.Icon.AsObject;
      goalCategory?: common_message006.GoalCategoryModel.AsObject;
      pastYearReturn: string;
      alltime: string;
      allTimeNumber: number;
      description: string;
      mnemonic: string;
      name: string;
      pastYearReturnNumber: number;
      yahooMnemonic: string;
      title: string;
    }

    /**
     * Protobuf JSON representation for Data
     */
    export interface AsProtobufJSON {
      goalTitle: string;
      currencyCode: string;
      portfolioTitle: string;
      key: string;
      type: string;
      icon: ProfitabilitiesResponse.Data.Icon.AsProtobufJSON | null;
      goalCategory: common_message006.GoalCategoryModel.AsProtobufJSON | null;
      pastYearReturn: string;
      alltime: string;
      allTimeNumber: number;
      description: string;
      mnemonic: string;
      name: string;
      pastYearReturnNumber: number;
      yahooMnemonic: string;
      title: string;
    }

    /**
     * Message implementation for portal_goal.ProfitabilitiesResponse.Data.Icon
     */
    export class Icon implements GrpcMessage {
      static id = 'portal_goal.ProfitabilitiesResponse.Data.Icon';

      /**
       * Deserialize binary data to message
       * @param instance message instance
       */
      static deserializeBinary(bytes: ByteSource) {
        const instance = new Icon();
        Icon.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
        return instance;
      }

      /**
       * Check all the properties and set default protobuf values if necessary
       * @param _instance message instance
       */
      static refineValues(_instance: Icon) {
        _instance.code = _instance.code || '';
      }

      /**
       * Deserializes / reads binary message into message instance using provided binary reader
       * @param _instance message instance
       * @param _reader binary reader instance
       */
      static deserializeBinaryFromReader(
        _instance: Icon,
        _reader: BinaryReader
      ) {
        while (_reader.nextField()) {
          if (_reader.isEndGroup()) break;

          switch (_reader.getFieldNumber()) {
            case 1:
              _instance.code = _reader.readString();
              break;
            default:
              _reader.skipField();
          }
        }

        Icon.refineValues(_instance);
      }

      /**
       * Serializes a message to binary format using provided binary reader
       * @param _instance message instance
       * @param _writer binary writer instance
       */
      static serializeBinaryToWriter(_instance: Icon, _writer: BinaryWriter) {
        if (_instance.code) {
          _writer.writeString(1, _instance.code);
        }
      }

      private _code: string;

      /**
       * Message constructor. Initializes the properties and applies default Protobuf values if necessary
       * @param _value initial values object or instance of Icon to deeply clone from
       */
      constructor(_value?: RecursivePartial<Icon.AsObject>) {
        _value = _value || {};
        this.code = _value.code;
        Icon.refineValues(this);
      }
      get code(): string {
        return this._code;
      }
      set code(value: string) {
        this._code = value;
      }

      /**
       * Serialize message to binary data
       * @param instance message instance
       */
      serializeBinary() {
        const writer = new BinaryWriter();
        Icon.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
      }

      /**
       * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
       */
      toObject(): Icon.AsObject {
        return {
          code: this.code
        };
      }

      /**
       * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
       */
      toJSON() {
        return this.toObject();
      }

      /**
       * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
       * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
       * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
       */
      toProtobufJSON(
        // @ts-ignore
        options?: ToProtobufJSONOptions
      ): Icon.AsProtobufJSON {
        return {
          code: this.code
        };
      }
    }
    export module Icon {
      /**
       * Standard JavaScript object representation for Icon
       */
      export interface AsObject {
        code: string;
      }

      /**
       * Protobuf JSON representation for Icon
       */
      export interface AsProtobufJSON {
        code: string;
      }
    }
  }

  /**
   * Message implementation for portal_goal.ProfitabilitiesResponse.ProfitabilityEntry
   */
  export class ProfitabilityEntry implements GrpcMessage {
    static id = 'portal_goal.ProfitabilitiesResponse.ProfitabilityEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ProfitabilityEntry();
      ProfitabilityEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ProfitabilityEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ProfitabilityEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readDouble();
            break;
          default:
            _reader.skipField();
        }
      }

      ProfitabilityEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ProfitabilityEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeDouble(2, _instance.value);
      }
    }

    private _key: string;
    private _value: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ProfitabilityEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ProfitabilityEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      ProfitabilityEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): number {
      return this._value;
    }
    set value(value: number) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ProfitabilityEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ProfitabilityEntry.AsObject {
      return {
        key: this.key,
        value: this.value
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ProfitabilityEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value
      };
    }
  }
  export module ProfitabilityEntry {
    /**
     * Standard JavaScript object representation for ProfitabilityEntry
     */
    export interface AsObject {
      key: string;
      value: number;
    }

    /**
     * Protobuf JSON representation for ProfitabilityEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: number;
    }
  }

  /**
   * Message implementation for portal_goal.ProfitabilitiesResponse.ProfitabilityData
   */
  export class ProfitabilityData implements GrpcMessage {
    static id = 'portal_goal.ProfitabilitiesResponse.ProfitabilityData';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ProfitabilityData();
      ProfitabilityData.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ProfitabilityData) {
      _instance.date = _instance.date || '';
      _instance.values = _instance.values || {};
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ProfitabilityData,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.date = _reader.readString();
            break;
          case 2:
            const msg_2 = {} as any;
            _reader.readMessage(
              msg_2,
              ProfitabilitiesResponse.ProfitabilityData.ValuesEntry
                .deserializeBinaryFromReader
            );
            _instance.values = _instance.values || {};
            _instance.values[msg_2.key] = msg_2.value;
            break;
          default:
            _reader.skipField();
        }
      }

      ProfitabilityData.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ProfitabilityData,
      _writer: BinaryWriter
    ) {
      if (_instance.date) {
        _writer.writeString(1, _instance.date);
      }
      if (!!_instance.values) {
        const keys_2 = Object.keys(_instance.values as any);

        if (keys_2.length) {
          const repeated_2 = keys_2
            .map(key => ({ key: key, value: (_instance.values as any)[key] }))
            .reduce((r, v) => [...r, v], [] as any[]);

          _writer.writeRepeatedMessage(
            2,
            repeated_2,
            ProfitabilitiesResponse.ProfitabilityData.ValuesEntry
              .serializeBinaryToWriter
          );
        }
      }
    }

    private _date: string;
    private _values: { [prop: string]: number };

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ProfitabilityData to deeply clone from
     */
    constructor(_value?: RecursivePartial<ProfitabilityData.AsObject>) {
      _value = _value || {};
      this.date = _value.date;
      (this.values = _value!.values
        ? Object.keys(_value!.values).reduce(
            (r, k) => ({ ...r, [k]: _value!.values![k] }),
            {}
          )
        : {}),
        ProfitabilityData.refineValues(this);
    }
    get date(): string {
      return this._date;
    }
    set date(value: string) {
      this._date = value;
    }
    get values(): { [prop: string]: number } {
      return this._values;
    }
    set values(value: { [prop: string]: number }) {
      this._values = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ProfitabilityData.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ProfitabilityData.AsObject {
      return {
        date: this.date,
        values: this.values
          ? Object.keys(this.values).reduce(
              (r, k) => ({ ...r, [k]: this.values![k] }),
              {}
            )
          : {}
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ProfitabilityData.AsProtobufJSON {
      return {
        date: this.date,
        values: this.values
          ? Object.keys(this.values).reduce(
              (r, k) => ({ ...r, [k]: this.values![k] }),
              {}
            )
          : {}
      };
    }
  }
  export module ProfitabilityData {
    /**
     * Standard JavaScript object representation for ProfitabilityData
     */
    export interface AsObject {
      date: string;
      values: { [prop: string]: number };
    }

    /**
     * Protobuf JSON representation for ProfitabilityData
     */
    export interface AsProtobufJSON {
      date: string;
      values: { [prop: string]: number };
    }

    /**
     * Message implementation for portal_goal.ProfitabilitiesResponse.ProfitabilityData.ValuesEntry
     */
    export class ValuesEntry implements GrpcMessage {
      static id =
        'portal_goal.ProfitabilitiesResponse.ProfitabilityData.ValuesEntry';

      /**
       * Deserialize binary data to message
       * @param instance message instance
       */
      static deserializeBinary(bytes: ByteSource) {
        const instance = new ValuesEntry();
        ValuesEntry.deserializeBinaryFromReader(
          instance,
          new BinaryReader(bytes)
        );
        return instance;
      }

      /**
       * Check all the properties and set default protobuf values if necessary
       * @param _instance message instance
       */
      static refineValues(_instance: ValuesEntry) {
        _instance.key = _instance.key || '';
        _instance.value = _instance.value || 0;
      }

      /**
       * Deserializes / reads binary message into message instance using provided binary reader
       * @param _instance message instance
       * @param _reader binary reader instance
       */
      static deserializeBinaryFromReader(
        _instance: ValuesEntry,
        _reader: BinaryReader
      ) {
        while (_reader.nextField()) {
          if (_reader.isEndGroup()) break;

          switch (_reader.getFieldNumber()) {
            case 1:
              _instance.key = _reader.readString();
              break;
            case 2:
              _instance.value = _reader.readDouble();
              break;
            default:
              _reader.skipField();
          }
        }

        ValuesEntry.refineValues(_instance);
      }

      /**
       * Serializes a message to binary format using provided binary reader
       * @param _instance message instance
       * @param _writer binary writer instance
       */
      static serializeBinaryToWriter(
        _instance: ValuesEntry,
        _writer: BinaryWriter
      ) {
        if (_instance.key) {
          _writer.writeString(1, _instance.key);
        }
        if (_instance.value) {
          _writer.writeDouble(2, _instance.value);
        }
      }

      private _key: string;
      private _value: number;

      /**
       * Message constructor. Initializes the properties and applies default Protobuf values if necessary
       * @param _value initial values object or instance of ValuesEntry to deeply clone from
       */
      constructor(_value?: RecursivePartial<ValuesEntry.AsObject>) {
        _value = _value || {};
        this.key = _value.key;
        this.value = _value.value;
        ValuesEntry.refineValues(this);
      }
      get key(): string {
        return this._key;
      }
      set key(value: string) {
        this._key = value;
      }
      get value(): number {
        return this._value;
      }
      set value(value: number) {
        this._value = value;
      }

      /**
       * Serialize message to binary data
       * @param instance message instance
       */
      serializeBinary() {
        const writer = new BinaryWriter();
        ValuesEntry.serializeBinaryToWriter(this, writer);
        return writer.getResultBuffer();
      }

      /**
       * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
       */
      toObject(): ValuesEntry.AsObject {
        return {
          key: this.key,
          value: this.value
        };
      }

      /**
       * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
       */
      toJSON() {
        return this.toObject();
      }

      /**
       * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
       * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
       * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
       */
      toProtobufJSON(
        // @ts-ignore
        options?: ToProtobufJSONOptions
      ): ValuesEntry.AsProtobufJSON {
        return {
          key: this.key,
          value: this.value
        };
      }
    }
    export module ValuesEntry {
      /**
       * Standard JavaScript object representation for ValuesEntry
       */
      export interface AsObject {
        key: string;
        value: number;
      }

      /**
       * Protobuf JSON representation for ValuesEntry
       */
      export interface AsProtobufJSON {
        key: string;
        value: number;
      }
    }
  }
}

/**
 * Message implementation for portal_goal.GetAllProfitabilitiesRequest
 */
export class GetAllProfitabilitiesRequest implements GrpcMessage {
  static id = 'portal_goal.GetAllProfitabilitiesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllProfitabilitiesRequest();
    GetAllProfitabilitiesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllProfitabilitiesRequest) {
    _instance.goalid = _instance.goalid || 0;
    _instance.currencyCode = _instance.currencyCode || '';
    _instance.fromDate = _instance.fromDate || '';
    _instance.toDate = _instance.toDate || '';
    _instance.funds = _instance.funds || [];
    _instance.fundingCurrencyCode = _instance.fundingCurrencyCode || '';
    _instance.adjustDates = _instance.adjustDates || undefined;
    _instance.onlyFunds = _instance.onlyFunds || undefined;
    _instance.onlyResume = _instance.onlyResume || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllProfitabilitiesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalid = _reader.readInt32();
          break;
        case 2:
          _instance.currencyCode = _reader.readString();
          break;
        case 3:
          _instance.fromDate = _reader.readString();
          break;
        case 4:
          _instance.toDate = _reader.readString();
          break;
        case 5:
          (_instance.funds = _instance.funds || []).push(
            ...(_reader.readPackedInt32() || [])
          );
          break;
        case 6:
          _instance.fundingCurrencyCode = _reader.readString();
          break;
        case 7:
          _instance.adjustDates = new googleProtobuf002.BoolValue();
          _reader.readMessage(
            _instance.adjustDates,
            googleProtobuf002.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.onlyFunds = new googleProtobuf002.BoolValue();
          _reader.readMessage(
            _instance.onlyFunds,
            googleProtobuf002.BoolValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.onlyResume = new googleProtobuf002.BoolValue();
          _reader.readMessage(
            _instance.onlyResume,
            googleProtobuf002.BoolValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllProfitabilitiesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllProfitabilitiesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalid) {
      _writer.writeInt32(1, _instance.goalid);
    }
    if (_instance.currencyCode) {
      _writer.writeString(2, _instance.currencyCode);
    }
    if (_instance.fromDate) {
      _writer.writeString(3, _instance.fromDate);
    }
    if (_instance.toDate) {
      _writer.writeString(4, _instance.toDate);
    }
    if (_instance.funds && _instance.funds.length) {
      _writer.writePackedInt32(5, _instance.funds);
    }
    if (_instance.fundingCurrencyCode) {
      _writer.writeString(6, _instance.fundingCurrencyCode);
    }
    if (_instance.adjustDates) {
      _writer.writeMessage(
        7,
        _instance.adjustDates as any,
        googleProtobuf002.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.onlyFunds) {
      _writer.writeMessage(
        8,
        _instance.onlyFunds as any,
        googleProtobuf002.BoolValue.serializeBinaryToWriter
      );
    }
    if (_instance.onlyResume) {
      _writer.writeMessage(
        9,
        _instance.onlyResume as any,
        googleProtobuf002.BoolValue.serializeBinaryToWriter
      );
    }
  }

  private _goalid: number;
  private _currencyCode: string;
  private _fromDate: string;
  private _toDate: string;
  private _funds: number[];
  private _fundingCurrencyCode: string;
  private _adjustDates?: googleProtobuf002.BoolValue;
  private _onlyFunds?: googleProtobuf002.BoolValue;
  private _onlyResume?: googleProtobuf002.BoolValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllProfitabilitiesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetAllProfitabilitiesRequest.AsObject>
  ) {
    _value = _value || {};
    this.goalid = _value.goalid;
    this.currencyCode = _value.currencyCode;
    this.fromDate = _value.fromDate;
    this.toDate = _value.toDate;
    this.funds = (_value.funds || []).slice();
    this.fundingCurrencyCode = _value.fundingCurrencyCode;
    this.adjustDates = _value.adjustDates
      ? new googleProtobuf002.BoolValue(_value.adjustDates)
      : undefined;
    this.onlyFunds = _value.onlyFunds
      ? new googleProtobuf002.BoolValue(_value.onlyFunds)
      : undefined;
    this.onlyResume = _value.onlyResume
      ? new googleProtobuf002.BoolValue(_value.onlyResume)
      : undefined;
    GetAllProfitabilitiesRequest.refineValues(this);
  }
  get goalid(): number {
    return this._goalid;
  }
  set goalid(value: number) {
    this._goalid = value;
  }
  get currencyCode(): string {
    return this._currencyCode;
  }
  set currencyCode(value: string) {
    this._currencyCode = value;
  }
  get fromDate(): string {
    return this._fromDate;
  }
  set fromDate(value: string) {
    this._fromDate = value;
  }
  get toDate(): string {
    return this._toDate;
  }
  set toDate(value: string) {
    this._toDate = value;
  }
  get funds(): number[] {
    return this._funds;
  }
  set funds(value: number[]) {
    this._funds = value;
  }
  get fundingCurrencyCode(): string {
    return this._fundingCurrencyCode;
  }
  set fundingCurrencyCode(value: string) {
    this._fundingCurrencyCode = value;
  }
  get adjustDates(): googleProtobuf002.BoolValue | undefined {
    return this._adjustDates;
  }
  set adjustDates(value: googleProtobuf002.BoolValue | undefined) {
    this._adjustDates = value;
  }
  get onlyFunds(): googleProtobuf002.BoolValue | undefined {
    return this._onlyFunds;
  }
  set onlyFunds(value: googleProtobuf002.BoolValue | undefined) {
    this._onlyFunds = value;
  }
  get onlyResume(): googleProtobuf002.BoolValue | undefined {
    return this._onlyResume;
  }
  set onlyResume(value: googleProtobuf002.BoolValue | undefined) {
    this._onlyResume = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllProfitabilitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllProfitabilitiesRequest.AsObject {
    return {
      goalid: this.goalid,
      currencyCode: this.currencyCode,
      fromDate: this.fromDate,
      toDate: this.toDate,
      funds: (this.funds || []).slice(),
      fundingCurrencyCode: this.fundingCurrencyCode,
      adjustDates: this.adjustDates ? this.adjustDates.toObject() : undefined,
      onlyFunds: this.onlyFunds ? this.onlyFunds.toObject() : undefined,
      onlyResume: this.onlyResume ? this.onlyResume.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllProfitabilitiesRequest.AsProtobufJSON {
    return {
      goalid: this.goalid,
      currencyCode: this.currencyCode,
      fromDate: this.fromDate,
      toDate: this.toDate,
      funds: (this.funds || []).slice(),
      fundingCurrencyCode: this.fundingCurrencyCode,
      adjustDates: this.adjustDates
        ? this.adjustDates.toProtobufJSON(options)
        : null,
      onlyFunds: this.onlyFunds ? this.onlyFunds.toProtobufJSON(options) : null,
      onlyResume: this.onlyResume
        ? this.onlyResume.toProtobufJSON(options)
        : null
    };
  }
}
export module GetAllProfitabilitiesRequest {
  /**
   * Standard JavaScript object representation for GetAllProfitabilitiesRequest
   */
  export interface AsObject {
    goalid: number;
    currencyCode: string;
    fromDate: string;
    toDate: string;
    funds: number[];
    fundingCurrencyCode: string;
    adjustDates?: googleProtobuf002.BoolValue.AsObject;
    onlyFunds?: googleProtobuf002.BoolValue.AsObject;
    onlyResume?: googleProtobuf002.BoolValue.AsObject;
  }

  /**
   * Protobuf JSON representation for GetAllProfitabilitiesRequest
   */
  export interface AsProtobufJSON {
    goalid: number;
    currencyCode: string;
    fromDate: string;
    toDate: string;
    funds: number[];
    fundingCurrencyCode: string;
    adjustDates: googleProtobuf002.BoolValue.AsProtobufJSON | null;
    onlyFunds: googleProtobuf002.BoolValue.AsProtobufJSON | null;
    onlyResume: googleProtobuf002.BoolValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.GetPortfolioCompositionResponse
 */
export class GetPortfolioCompositionResponse implements GrpcMessage {
  static id = 'portal_goal.GetPortfolioCompositionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPortfolioCompositionResponse();
    GetPortfolioCompositionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPortfolioCompositionResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPortfolioCompositionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.PortfolioCompositionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.PortfolioCompositionModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetPortfolioCompositionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPortfolioCompositionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.PortfolioCompositionModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message006.PortfolioCompositionModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPortfolioCompositionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetPortfolioCompositionResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.PortfolioCompositionModel(m)
    );
    GetPortfolioCompositionResponse.refineValues(this);
  }
  get values(): common_message006.PortfolioCompositionModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.PortfolioCompositionModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPortfolioCompositionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPortfolioCompositionResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPortfolioCompositionResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetPortfolioCompositionResponse {
  /**
   * Standard JavaScript object representation for GetPortfolioCompositionResponse
   */
  export interface AsObject {
    values?: common_message006.PortfolioCompositionModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetPortfolioCompositionResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.PortfolioCompositionModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_goal.CheckStarredGoalResponse
 */
export class CheckStarredGoalResponse implements GrpcMessage {
  static id = 'portal_goal.CheckStarredGoalResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CheckStarredGoalResponse();
    CheckStarredGoalResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CheckStarredGoalResponse) {
    _instance.hasResult = _instance.hasResult || false;
    _instance.goalModel = _instance.goalModel || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CheckStarredGoalResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.hasResult = _reader.readBool();
          break;
        case 2:
          _instance.goalModel = new common_message006.GoalModel();
          _reader.readMessage(
            _instance.goalModel,
            common_message006.GoalModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CheckStarredGoalResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CheckStarredGoalResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.hasResult) {
      _writer.writeBool(1, _instance.hasResult);
    }
    if (_instance.goalModel) {
      _writer.writeMessage(
        2,
        _instance.goalModel as any,
        common_message006.GoalModel.serializeBinaryToWriter
      );
    }
  }

  private _hasResult: boolean;
  private _goalModel?: common_message006.GoalModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CheckStarredGoalResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CheckStarredGoalResponse.AsObject>) {
    _value = _value || {};
    this.hasResult = _value.hasResult;
    this.goalModel = _value.goalModel
      ? new common_message006.GoalModel(_value.goalModel)
      : undefined;
    CheckStarredGoalResponse.refineValues(this);
  }
  get hasResult(): boolean {
    return this._hasResult;
  }
  set hasResult(value: boolean) {
    this._hasResult = value;
  }
  get goalModel(): common_message006.GoalModel | undefined {
    return this._goalModel;
  }
  set goalModel(value: common_message006.GoalModel | undefined) {
    this._goalModel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CheckStarredGoalResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CheckStarredGoalResponse.AsObject {
    return {
      hasResult: this.hasResult,
      goalModel: this.goalModel ? this.goalModel.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CheckStarredGoalResponse.AsProtobufJSON {
    return {
      hasResult: this.hasResult,
      goalModel: this.goalModel ? this.goalModel.toProtobufJSON(options) : null
    };
  }
}
export module CheckStarredGoalResponse {
  /**
   * Standard JavaScript object representation for CheckStarredGoalResponse
   */
  export interface AsObject {
    hasResult: boolean;
    goalModel?: common_message006.GoalModel.AsObject;
  }

  /**
   * Protobuf JSON representation for CheckStarredGoalResponse
   */
  export interface AsProtobufJSON {
    hasResult: boolean;
    goalModel: common_message006.GoalModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_goal.GetAllSpreadRangesResponse
 */
export class GetAllSpreadRangesResponse implements GrpcMessage {
  static id = 'portal_goal.GetAllSpreadRangesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllSpreadRangesResponse();
    GetAllSpreadRangesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllSpreadRangesResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllSpreadRangesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.SpreadRangeModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.SpreadRangeModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllSpreadRangesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllSpreadRangesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.SpreadRangeModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message006.SpreadRangeModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllSpreadRangesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAllSpreadRangesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.SpreadRangeModel(m)
    );
    GetAllSpreadRangesResponse.refineValues(this);
  }
  get values(): common_message006.SpreadRangeModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.SpreadRangeModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllSpreadRangesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllSpreadRangesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllSpreadRangesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetAllSpreadRangesResponse {
  /**
   * Standard JavaScript object representation for GetAllSpreadRangesResponse
   */
  export interface AsObject {
    values?: common_message006.SpreadRangeModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetAllSpreadRangesResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.SpreadRangeModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_goal.CalculateSpreadRequest
 */
export class CalculateSpreadRequest implements GrpcMessage {
  static id = 'portal_goal.CalculateSpreadRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateSpreadRequest();
    CalculateSpreadRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateSpreadRequest) {
    _instance.currencyId = _instance.currencyId || 0;
    _instance.amount = _instance.amount || 0;
    _instance.isBuy = _instance.isBuy || false;
    _instance.goalCategoryCode = _instance.goalCategoryCode || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateSpreadRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.currencyId = _reader.readInt32();
          break;
        case 2:
          _instance.amount = _reader.readDouble();
          break;
        case 3:
          _instance.isBuy = _reader.readBool();
          break;
        case 4:
          _instance.goalCategoryCode = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateSpreadRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateSpreadRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.currencyId) {
      _writer.writeInt32(1, _instance.currencyId);
    }
    if (_instance.amount) {
      _writer.writeDouble(2, _instance.amount);
    }
    if (_instance.isBuy) {
      _writer.writeBool(3, _instance.isBuy);
    }
    if (_instance.goalCategoryCode) {
      _writer.writeString(4, _instance.goalCategoryCode);
    }
  }

  private _currencyId: number;
  private _amount: number;
  private _isBuy: boolean;
  private _goalCategoryCode: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateSpreadRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculateSpreadRequest.AsObject>) {
    _value = _value || {};
    this.currencyId = _value.currencyId;
    this.amount = _value.amount;
    this.isBuy = _value.isBuy;
    this.goalCategoryCode = _value.goalCategoryCode;
    CalculateSpreadRequest.refineValues(this);
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get isBuy(): boolean {
    return this._isBuy;
  }
  set isBuy(value: boolean) {
    this._isBuy = value;
  }
  get goalCategoryCode(): string {
    return this._goalCategoryCode;
  }
  set goalCategoryCode(value: string) {
    this._goalCategoryCode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateSpreadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateSpreadRequest.AsObject {
    return {
      currencyId: this.currencyId,
      amount: this.amount,
      isBuy: this.isBuy,
      goalCategoryCode: this.goalCategoryCode
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateSpreadRequest.AsProtobufJSON {
    return {
      currencyId: this.currencyId,
      amount: this.amount,
      isBuy: this.isBuy,
      goalCategoryCode: this.goalCategoryCode
    };
  }
}
export module CalculateSpreadRequest {
  /**
   * Standard JavaScript object representation for CalculateSpreadRequest
   */
  export interface AsObject {
    currencyId: number;
    amount: number;
    isBuy: boolean;
    goalCategoryCode: string;
  }

  /**
   * Protobuf JSON representation for CalculateSpreadRequest
   */
  export interface AsProtobufJSON {
    currencyId: number;
    amount: number;
    isBuy: boolean;
    goalCategoryCode: string;
  }
}

/**
 * Message implementation for portal_goal.CalculateSpreadResponse
 */
export class CalculateSpreadResponse implements GrpcMessage {
  static id = 'portal_goal.CalculateSpreadResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateSpreadResponse();
    CalculateSpreadResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateSpreadResponse) {
    _instance.spread = _instance.spread || 0;
    _instance.spreadCurrencyAmount = _instance.spreadCurrencyAmount || 0;
    _instance.amountResult = _instance.amountResult || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.isUpdated = _instance.isUpdated || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateSpreadResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.spread = _reader.readInt32();
          break;
        case 2:
          _instance.spreadCurrencyAmount = _reader.readDouble();
          break;
        case 3:
          _instance.amountResult = _reader.readDouble();
          break;
        case 4:
          _instance.currencyId = _reader.readInt32();
          break;
        case 5:
          _instance.isUpdated = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateSpreadResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateSpreadResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.spread) {
      _writer.writeInt32(1, _instance.spread);
    }
    if (_instance.spreadCurrencyAmount) {
      _writer.writeDouble(2, _instance.spreadCurrencyAmount);
    }
    if (_instance.amountResult) {
      _writer.writeDouble(3, _instance.amountResult);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(4, _instance.currencyId);
    }
    if (_instance.isUpdated) {
      _writer.writeBool(5, _instance.isUpdated);
    }
  }

  private _spread: number;
  private _spreadCurrencyAmount: number;
  private _amountResult: number;
  private _currencyId: number;
  private _isUpdated: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateSpreadResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculateSpreadResponse.AsObject>) {
    _value = _value || {};
    this.spread = _value.spread;
    this.spreadCurrencyAmount = _value.spreadCurrencyAmount;
    this.amountResult = _value.amountResult;
    this.currencyId = _value.currencyId;
    this.isUpdated = _value.isUpdated;
    CalculateSpreadResponse.refineValues(this);
  }
  get spread(): number {
    return this._spread;
  }
  set spread(value: number) {
    this._spread = value;
  }
  get spreadCurrencyAmount(): number {
    return this._spreadCurrencyAmount;
  }
  set spreadCurrencyAmount(value: number) {
    this._spreadCurrencyAmount = value;
  }
  get amountResult(): number {
    return this._amountResult;
  }
  set amountResult(value: number) {
    this._amountResult = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get isUpdated(): boolean {
    return this._isUpdated;
  }
  set isUpdated(value: boolean) {
    this._isUpdated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateSpreadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateSpreadResponse.AsObject {
    return {
      spread: this.spread,
      spreadCurrencyAmount: this.spreadCurrencyAmount,
      amountResult: this.amountResult,
      currencyId: this.currencyId,
      isUpdated: this.isUpdated
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateSpreadResponse.AsProtobufJSON {
    return {
      spread: this.spread,
      spreadCurrencyAmount: this.spreadCurrencyAmount,
      amountResult: this.amountResult,
      currencyId: this.currencyId,
      isUpdated: this.isUpdated
    };
  }
}
export module CalculateSpreadResponse {
  /**
   * Standard JavaScript object representation for CalculateSpreadResponse
   */
  export interface AsObject {
    spread: number;
    spreadCurrencyAmount: number;
    amountResult: number;
    currencyId: number;
    isUpdated: boolean;
  }

  /**
   * Protobuf JSON representation for CalculateSpreadResponse
   */
  export interface AsProtobufJSON {
    spread: number;
    spreadCurrencyAmount: number;
    amountResult: number;
    currencyId: number;
    isUpdated: boolean;
  }
}
