/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './intercom.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
import { GRPC_INTERCOM_GRPC_SERVICE_CLIENT_SETTINGS } from './intercom.pbconf';
/**
 * Service client implementation for intercom.IntercomGrpcService
 */
@Injectable({ providedIn: 'any' })
export class IntercomGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /intercom.IntercomGrpcService/GetArticle
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.IntercomArticleResponse>>
     */
    getArticle: (
      requestData: thisProto.IntercomArticleRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.IntercomArticleResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/intercom.IntercomGrpcService/GetArticle',
        requestData,
        requestMetadata,
        requestClass: thisProto.IntercomArticleRequest,
        responseClass: thisProto.IntercomArticleResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_INTERCOM_GRPC_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'intercom.IntercomGrpcService',
      settings
    );
  }

  /**
   * Unary call @/intercom.IntercomGrpcService/GetArticle
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.IntercomArticleResponse>
   */
  getArticle(
    requestData: thisProto.IntercomArticleRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.IntercomArticleResponse> {
    return this.$raw
      .getArticle(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
