import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CurrencyPipe } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISimulateForecastResult } from '@bp-core/src/lib/models/portal/goal/simulate-forescast-result';
import { ITooltip } from '@bp-core/src/lib/models/portal/goal/tooltip.model';
import { GoalModel } from '@bp-core/src/lib/proto/common-message.pb';
import moment from 'moment';
import { TooltipChartComponent } from '../tooltip-chart/tooltip-chart.component';
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'bp-simulation-chart',
  templateUrl: './simulation-chart.component.html',
  styleUrls: ['./simulation-chart.component.scss'],
  providers: [CurrencyPipe],
})
export class SimulationChartComponent implements OnInit, AfterContentInit, OnChanges {
  @Input()
  width: string;
  @Input()
  height: string;
  @Input()
  goal: GoalModel;
  @Input()
  simulation: ISimulateForecastResult;
  targetAmount = 0;
  isValid = true;
  private chart: am4charts.XYChart;
  rangeGoalChart: am4charts.ValueAxisDataItem;
  proyectionGoalChart: am4charts.ValueAxisDataItem;
  valueAxisChart: am4charts.ValueAxis<am4charts.AxisRenderer>;
  balanceColor: string = '#4f70be';
  depositColor: string = '#2b2e33';
  @ViewChild('tool') tool: TooltipChartComponent;
  tooltip: ITooltip = {
    title: '',
    values: [],
  };
  constructor(
    private zone: NgZone,
    private currencyPipe: CurrencyPipe,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.simulation?.currentValue) {
      console.log(changes.simulation);
      this.simulation = changes.simulation?.currentValue;
    }
    if (this.chart) {
      this.generateChart();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    let screenWidth;
    if (event) {
      screenWidth = event.target.innerWidth;
    } else {
      screenWidth = window.innerWidth;
    }
    // let fontSizeBase = 13;
    if (this.chart) {
      switch (true) {
        case screenWidth < 576:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'none';
          this.chart.zoomOutButton.disabled = true;
          break;
        case screenWidth < 768:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
        default:
          this.chart.fontSize = 12;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
      }
    }
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        const chart = am4core.create('chartProfitabily', am4charts.XYChart);
        this.chart = chart;
        this.chart.tapToActivate = true;
        this.chart.paddingRight = 0;
        this.chart.paddingLeft = 0;
        this.chart.height = am4core.percent(100);
        this.chart.width = am4core.percent(100);
        const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        // cambiar color de los labels de los axis a gris

        // console.log(dateAxis.startLocation, dateAxis.endLocation);
        // dateAxis.startLocation = 1;
        dateAxis.renderer.labels.template.opacity = 0;
        // dateAxis.renderer.minGridDistance = 0;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.line.width = 0;

        dateAxis.renderer.line.disabled = true;

        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        dateAxis.startIndex = 0;
        dateAxis.renderer.cellStartLocation = 0;
        dateAxis.cursorTooltipEnabled = false;

        //   dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.ticks.template.disabled = true;
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        this.valueAxisChart = valueAxis;
        valueAxis.renderer.labels.template.fill = am4core.color('#8d949d');

        if (valueAxis.tooltip) {
          valueAxis.tooltip.disabled = true;
        }

        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.line.disabled = true;
        valueAxis.renderer.labels.template.location = 1;
        valueAxis.startIndex = 1;
        valueAxis.renderer.grid.template.location = 1;

        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.padding(0, 0, 0, 0);
        valueAxis.renderer.margin(0, 0, 0, 0);
        console.log(this.simulation.values[0].contribution);
        valueAxis.min = this.simulation.values[0].contribution;
        valueAxis.strictMinMax = true;
        // this.rangeGoalChart = valueAxis.axisRanges.create();
        // this.rangeGoalChart.value = 0;
        //   this.rangeGoalChart.endValue = 0;
        // this.rangeGoalChart.label.text = 'Meta';
        // this.rangeGoalChart.label.inside = true;
        // this.rangeGoalChart.label.fill = am4core.color('black');
        // maleRange.label.dx = -200;
        // this.rangeGoalChart.label.fontWeight = '600';
        // this.rangeGoalChart.label.align = 'left';
        // this.rangeGoalChart.label.verticalCenter = "top";
        // this.rangeGoalChart.label.horizontalCenter = "middle";
        // this.rangeGoalChart.label.verticalCenter = 'bottom';
        // this.rangeGoalChart.grid.strokeOpacity = 0.3;
        // this.rangeGoalChart.grid.stroke = am4core.color('black');
        // this.proyectionGoalChart = valueAxis.axisRanges.create();
        // this.proyectionGoalChart.value = 0;
        // //   this.rangeGoalChart.endValue = 0;
        // this.proyectionGoalChart.label.text = 'Proyección';
        // this.proyectionGoalChart.label.inside = true;
        // this.proyectionGoalChart.label.fill = am4core.color('black');
        // // maleRange.label.dx = -200;
        // // this.rangeGoalChart.label.fontWeight = '600';
        // this.proyectionGoalChart.label.align = 'left';
        // // this.rangeGoalChart.label.verticalCenter = "top";
        // // this.rangeGoalChart.label.horizontalCenter = "middle";
        // this.proyectionGoalChart.label.verticalCenter = 'bottom';
        // this.proyectionGoalChart.grid.strokeOpacity = 0.3;
        // this.proyectionGoalChart.grid.stroke = am4core.color('black');
        const maximumProfitabilitySeries = this.chart.series.push(new am4charts.LineSeries());
        maximumProfitabilitySeries.dataFields.dateX = 'date';
        maximumProfitabilitySeries.dataFields.openValueY = 'minimum';
        maximumProfitabilitySeries.dataFields.valueY = 'maximum';
        //   maximumProfitabilitySeries.tooltipText = 'open: {openValueY.value} close: {valueY.value}';
        maximumProfitabilitySeries.sequencedInterpolation = true;
        maximumProfitabilitySeries.fillOpacity = 0.3;
        maximumProfitabilitySeries.name = 'Escenario Optimista';

        maximumProfitabilitySeries.strokeWidth = 0;
        maximumProfitabilitySeries.fill = am4core.color('#b8e3ff');
        const interfaceColors = new am4core.InterfaceColorSet();

        const normalProfitabilitySeries = this.chart.series.push(new am4charts.LineSeries());
        normalProfitabilitySeries.dataFields.dateX = 'date';
        normalProfitabilitySeries.dataFields.valueY = 'normal';
        //  normalProfitabilitySeries.defaultState.transitionDuration = 1000;
        //   seriesCenter.stroke = this.chart.colors.getIndex(3);
        normalProfitabilitySeries.strokeWidth = 2;
        normalProfitabilitySeries.name = 'Balance Esperado';
        normalProfitabilitySeries.stroke = am4core.color('#4f70be');
        normalProfitabilitySeries.fill = am4core.color('#4f70be');

        const minimumProfitabilitySeries = this.chart.series.push(new am4charts.LineSeries());
        minimumProfitabilitySeries.dataFields.dateX = 'date';
        minimumProfitabilitySeries.dataFields.valueY = 'minimum';
        minimumProfitabilitySeries.sequencedInterpolation = true;
        //     minimumProfitabilitySeries.defaultState.transitionDuration = 1000;
        minimumProfitabilitySeries.fill = am4core.color('#b8e3ff');
        minimumProfitabilitySeries.strokeWidth = 0;

        minimumProfitabilitySeries.name = 'Escenario Pesimista';
        // minimumProfitabilitySeries.strokeWidth = 2;
        // minimumProfitabilitySeries.tensionX = 0.8;
        minimumProfitabilitySeries.tooltipText = '';
        const contributionSeries = this.chart.series.push(new am4charts.LineSeries());
        contributionSeries.dataFields.dateX = 'date';
        contributionSeries.dataFields.valueY = 'contribution';
        contributionSeries.name = 'Depósitos Totales';
        contributionSeries.strokeWidth = 2;
        contributionSeries.stroke = am4core.color('black');
        contributionSeries.fill = am4core.color('black');

        if (normalProfitabilitySeries.tooltip) {
          normalProfitabilitySeries.tooltip.getFillFromObject = false;
          normalProfitabilitySeries.tooltip.background.fill = am4core.color('white');
          // normalProfitabilitySeries.cursorTooltipEnabled = false;
          normalProfitabilitySeries.tooltip.autoTextColor = false;
          normalProfitabilitySeries.tooltip.label.fill = am4core.color('black');
        }
        this.addTooltipHtml(normalProfitabilitySeries);

        // normalProfitabilitySeries.tooltipHTML =
        //   `
        // <span>{dateX.formatDate('MMMM-yyyy')}</span>
        // <p></p>
        // <table>
        //     <tr>
        //         <td align="left"><span class="dot-chart-normal"></span>
        //             Balance esperado</td>
        //         <td class="pl-2 text-right">{normal.formatNumber('` +
        //   this.goal.currency?.chartTooltipFormatNumber +
        //   `')}</td>
        //     </tr>
        //     <tr>
        //         <td align="left" class="ml-2"> <span class="dot-chart-contribution"></span> Depósitos</td>
        //         <td class="pl-2"><div class="text-right">{contribution.formatNumber('` +
        //   this.goal.currency?.chartTooltipFormatNumber +
        //   `')}</div></td>
        //     </tr>
        // </table>
        // `;
        //  contributionSeries.defaultState.transitionDuration = 1000;

        // this.chart.legend = new am4charts.Legend();
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.xAxis = dateAxis;
        this.chart.cursor.lineY.disabled = true;
        this.chart.language.locale = am4lang_es_ES;
        valueAxis.numberFormatter.numberFormat = this.goal.currency?.chartAxisFormatNumber ?? ''; // `'$'#.##!a`;
        this.chart.numberFormatter.bigNumberPrefixes = [
          // { number: 1e3, suffix: 'k' },
          { number: 1e6, suffix: 'M' },
        ];
        // chart.legend.contentAlign = 'center';
        // chart.legend.position = 'bottom';
        // const marker = chart.legend.markers.template.children.getIndex(0);
        // (marker as any).cornerRadius(12, 12, 12, 12);
        // marker.height = 15;
        // marker.width = 15;

        // this.chart.responsive.enabled = true;
        // this.chart.responsive.useDefault = false;
        // this.chart.responsive.rules.push({
        //   relevant(target) {
        //     if (target.pixelWidth <= am4core.ResponsiveBreakpoints.M) {
        //       return true;
        //     }
        //     return false;
        //   },
        //   // @ts-ignore
        //   state(target, stateId) {
        //     if (target instanceof am4charts.Cursor) {
        //       const state = target.states.create(stateId);
        //       state.properties.clickable = true;
        //       state.properties.draggable = true;
        //       state.properties.resizable = false;
        //       state.properties.hoverable = true;
        //       state.properties.interactionsEnabled = true;

        //       return state;
        //     }
        //     return null;
        //   },
        // });

        this.calculateTargetAmount();
        //   this.chart.scrollbarX = new am4core.Scrollbar();
        this.onResize();
      });
    }, 200);
  }
  addTooltipHtml(lineSeries: am4charts.LineSeries) {
    if (!lineSeries) {
      return;
    }
    lineSeries.adapter.add('tooltipHTML', (tooltipHTML, target) => {
      let data = target.tooltipDataItem.dataContext as any;
      if (data) {
        const balanceValue = this.currencyPipe.transform(
          data.normal,
          this.goal.currency?.currencyCode,
          this.goal.currency?.display,
          this.goal.currency?.digitsInfo,
        );
        const netDepositValue = this.currencyPipe.transform(
          data.contribution,
          this.goal.currency?.currencyCode,
          this.goal.currency?.display,
          this.goal.currency?.digitsInfo,
        );
        let tooltipObj: ITooltip = {
          title: moment(data.date).format('DD MMM yyyy'),
          values: [
            {
              label: 'Balance esperado',
              value: balanceValue!,
              color: this.balanceColor,
            },
            {
              label: 'Depósitos',
              value: netDepositValue!,
              color: this.depositColor,
            },
          ],
        };
        this.tooltip = { ...tooltipObj };
        this.cd.detectChanges();
      }
      return ` ${this.tool.dom.nativeElement.innerHTML}`;
    });
  }
  ngOnInit(): void {}
  calculateTargetAmount() {
    // this.targetAmount =
    //   this.goal.monthlyContribution * this.goal.years * 12 +
    //   this.goal.initialInvestment;
    // this.goal.targetAmount = this.targetAmount;
    this.targetAmount = this.goal.targetAmount ?? 0;
    if (this.targetAmount < 0) {
      this.isValid = false;
      this.targetAmount = 0;
    } else {
      this.isValid = true;
    }

    this.generateChart();
    // this.targetAmountForm.setValue(this.targetAmount);
    if (this.targetAmount === 0) {
      this.isValid = false;
    }
  }
  generateChart() {
    //this.selectPortfolio();

    this.goal.initialInvestment = Number(this.goal.initialInvestment);
    this.goal.targetAmount = Number(this.goal.targetAmount);

    this.chart.data = this.simulation?.values;

    // this.proyectionGoalChart.value = this.simulation?.expectedCapital;
    // this.rangeGoalChart.value = this.goal.targetAmount;
    // if (this.rangeGoalChart.value <= this.proyectionGoalChart?.value) {
    //   this.rangeGoalChart.label.verticalCenter = 'top';
    //   this.proyectionGoalChart.label.verticalCenter = 'bottom';
    // } else {
    //   this.rangeGoalChart.label.verticalCenter = 'bottom';
    //   this.proyectionGoalChart.label.verticalCenter = 'top';
    // }
    // this.bestCaseCapital = this.simulation.bestCaseCapital;
    // this.worstCaseCapital = this.simulation.worstCaseCapital;
    // this.expectedCapital = this.simulation.expectedCapital;
    // this.contributionCapital = this.simulation.contributionCapital;
    this.valueAxisChart.max = this.simulation?.maxChart;
  }
}
