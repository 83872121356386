import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bp-card-link',
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.scss'],
})
export class CardLinkComponent implements OnInit {
  @Input() title: string;
  @Input() link: string;
  @Input() subtitle: string;

  @Input() noBorder: boolean = false;

  @Output() onClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  cardClicked(): void {
    this.onClick.emit(true);
  }
}
