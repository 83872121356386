<div class="w-full max-w-full mx-auto sm:max-w-[80%]">
  <div class="w-full max-w-full mx-auto sm:max-w-3/4">
    <div class="mb-6 text-left">
      <header class="mb-6 ">
        <div class="text-3xl font-bold ">{{ title }}</div>
        @if (subtitle != '') {
          <div class="mt-2 leading-tight text-secondary">{{ subtitle }}</div>
        }
      </header>
      <div class="flex flex-col w-full gap-4">
        <div bp-card>
          <div class="flex items-center gap-2 mb-4">
            <div bp-title-card>
              {{ !isCustomPortfolio ? investmentStrategy.title : 'Flexifolio' }}
            </div>
          </div>
          @if (!isCustomPortfolio) {
            <p>{{ investmentStrategy.description }}</p>
          }
          @if (!isCustomPortfolio) {
            <mat-divider class="my-4"></mat-divider>
          }

          <ng-container>
            <div
              class="flex items-start justify-between"
              [ngClass]="{
                'items-center md:items-start':
                  riskLevelRecommended && riskLevelRecommended.id === riskLevelSelected.id
              }"
              >
              <span>Nivel de riesgo</span>
              <div class="text-right">
                <div class="flex flex-col justify-end gap-0 md:gap-2 md:flex-row">
                  @if (riskLevelRecommended && riskLevelRecommended.id === riskLevelSelected.id) {
                    <span
                      @fadeIn
                      bp-tag
                      >Recomendado</span
                      >
                    }
                    <div bp-text-values-card>{{ riskLevelSelected?.title }}</div>
                  </div>
                  @if (canChangeRiskLevel) {
                    <div bp-link (click)="riskLevelChange.emit()">
                      Ajustar riesgo
                    </div>
                  }
                </div>
              </div>
            </ng-container>
            @if (canGoToSimulator) {
              <mat-divider class="my-4"></mat-divider>
              <ng-container>
                <div class="flex items-center justify-between">
                  <span>Simulación de escenarios</span>
                  <div bp-link class="text-right" (click)="simulator.emit()">Ver simulación</div>
                </div>
              </ng-container>
            }

            @if (isCustomPortfolio) {
              <mat-divider class="my-4"></mat-divider>
              <div class="flex items-center justify-between">
                <span>Acciones / Bonos</span>
                <div bp-text-values-card class="text-right">
                  {{ customPortfolioResult?.stockBondsPercentages?.stockPercentage }}% -
                  {{ customPortfolioResult?.stockBondsPercentages?.bondsPercentage }}%
                </div>
              </div>
            }
          </div>
          <div bp-card>
            <div class="flex flex-col gap-4">
              <span bp-title-card>Fondos portafolio</span>
              <bp-funds-resume
                [fundsArray]="fundsArray"
                [isCustom]="isCustomPortfolio"
              ></bp-funds-resume>
            </div>
          </div>
          <!-- <div bp-card>
          <div bp-title-card class="mb-4">Información</div>
          <bp-funds-costs
            [financialEntity]="financialEntity"
            [goalCategory]="goalCategory"
            [fundsArray]="fundsArray"
          ></bp-funds-costs>
        </div> -->
        <div bp-card>
          <div class="flex flex-col gap-2">
            <div class="flex items-center justify-between w-full">
              <span>
                Quiero que me envíen los folletos al mail
              </span>

              <mat-slide-toggle
                class="text-base "
                [(ngModel)]="sendEmailToggle"
                [color]="'primary'"
                [labelPosition]="'before'"
                >
              </mat-slide-toggle>
            </div>
            <div class="flex items-center justify-between w-full">
              <span>
                <span class="inline align-middle">
                  Meta favorita
                </span>
                <mat-icon
                  (click)="showFavoriteGoalTooltip()"
                  class="inline text-base align-middle cursor-pointer text-inherit"
                  >
                  help_outline
                </mat-icon>
              </span>
              <mat-slide-toggle
                class="text-base "
                [(ngModel)]="starredGoalToggle"
                [color]="'primary'"
                [labelPosition]="'before'"
                >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div
          class="fixed bottom-0 left-0 z-50 w-full px-6 py-1 sm:relative sm:bottom-auto sm:left-auto sm:z-auto sm:p-0 "
          >
          <button
            mat-flat-button
            class="w-full shadow-md sm:shadow-none "
            color="primary"
            (click)="next.emit({ sendEmailToggle, starredGoalToggle })"
            >
            Estoy de acuerdo, continuar
          </button>
        </div>

        <div>
          <span class="font-semibold">Importante: </span> Betterplan, en su rol de asesor financiero
          independiente, te recomienda los mejores fondos y portafolios, pero por regulación el
          cliente debe aceptar la recomendación.
        </div>
        <div class="h-5 sm:h-0"></div>
      </div>
    </div>
  </div>
</div>
