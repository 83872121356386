import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-info-card',
  templateUrl: './image-info-card.component.html',
  styleUrls: ['./image-info-card.component.scss'],
})
export class ImageInfoCardComponent {
  @Input() imageUrl: string;
  @Input() imageFullRounded: boolean;
  @Input() alt: string;
}
