<form [formGroup]="form" novalidate>
  <div class="d-lg-flex">
    <div class="form-group mb-0">
      <div class="inner-addon clickable right-addon">
        <input
          class="form-control"
          type="text"
          mwlFlatpickr
          formControlName="fromDate"
          dateFormat="Y-m-d"
          altFormat="d-m-Y"
          placeholder="00/00/2020"
        />
        <!-- [disableMobile]="true"
          [altInput]="true"
          [plugins]="plugingsCalendar"
          [convertModelValue]="true"
          [enableTime]="false"
          [locale]="spanish"
          [maxDate]="maxFromDate"
          [minDate]="minFromDate" -->
        <div class="inner-addon-item">
          <i class="fas fa-calendar-alt"></i>
        </div>
      </div>
    </div>
    <div class="date-separator my-2 my-lg-0">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div class="form-group mb-0">
      <div class="inner-addon clickable right-addon">
        <input
          class="form-control"
          type="text"
          mwlFlatpickr
          formControlName="toDate"
          dateFormat="Y-m-d"
          altFormat="d-m-Y"
          placeholder="00/00/2020"
        />
        <!-- [disableMobile]="true"
        [altInput]="true"
        [plugins]="plugingsCalendar"
        [convertModelValue]="true"
        [enableTime]="false"
        [locale]="spanish"
        [maxDate]="maxToDate"
        [minDate]="minToDate" -->

        <div class="inner-addon-item">
          <i class="fas fa-calendar-alt"></i>
        </div>
      </div>
    </div>
  </div>
</form>
