import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';

@Component({
  selector: 'app-g-p-t-table',
  templateUrl: './g-p-t-table.component.html',
  styleUrls: ['./g-p-t-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPTTableComponent implements OnInit {
  @Input()
  currencyDisplay: CurrencyModel | undefined;
  @Input()
  deposits: number;
  @Input()
  withdrawals: number;
  @Input()
  countDeposits = 0;
  @Input()
  countWithdrawals = 0;
  constructor() {}

  ngOnInit() {}
}
