<mat-progress-bar class="progress progress-bar-animated " mode="indeterminate"></mat-progress-bar>
<div class="flex flex-col justify-center flex-auto min-w-0 pb-20">
  <div class="w-full pt-8 mx-auto max-w-240">
    <div class="flex flex-row justify-center px-6">
      <div @fadeIn class="flex flex-col items-center w-full gap-4 max-w-100">
        <div
          class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600"
        >
          <mat-icon
            class="icon-size-16 text-primary-500 dark:text-primary-300"
            [svgIcon]="icon"
          ></mat-icon>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
