<!-- TABLA -->
@if (!isEmpty) {
  <div class="flex flex-row justify-between mb-2">
    <span class="flex items-center font-medium">
      Descripción
    </span>
    <div class="flex justify-end">
      <mat-button-toggle-group [value]="valueSelected">
        <mat-button-toggle
          value="accumulated"
          (click)="$event.preventDefault(); valueSelected = 'accumulated'"
          >Acumulada</mat-button-toggle
          >
          @if (irrAnnualized != '--' && irrAccumulatedUSD != '--') {
            <mat-button-toggle
              value="pastYearReturn"
              @fadeIn
              (click)="$event.preventDefault(); valueSelected = 'pastYearReturn'"
              >Anualizada</mat-button-toggle
              >
            }
            @if (irrAnnualized == '--' || irrAccumulatedUSD == '--') {
              <mat-button-toggle
                (click)="$event.preventDefault(); valueSelected = 'pastYearReturn'"
                @fadeIn
                value="pastYearReturn"
                matTooltip="La rentabilidad anualizada indica el beneficio porcentual si el plazo de la inversión hubiese sido de un año. Podrás ver dicho cálculo una vez que tu meta lleve más de un año con inversiones activas"
                >Anualizada</mat-button-toggle
                >
              }
            </mat-button-toggle-group>
          </div>
        </div>
      }

      @if (isEmpty) {
        <div>
          <p class="font-medium text-center">
            Pronto verás el retorno de tu portafolio
          </p>
        </div>
      }

      <div class="grid grid-cols-1 ">
        <div class="flex flex-row justify-between gap-1">
          <div class="flex flex-row">
            <div class="flex items-center gap-2 text">
              <div>
                Tasa interna de retorno (TIR)
                @if (irrCurrencyCode.length != 0) {
                  en {{ irrCurrencyCode }}
                }
              </div>
              <mat-icon
                class="icon-size-4 text-inherit "
                [svgIcon]="'mat_outline:help_outline'"
                matTooltip="La tasa interna de retorno busca incorporar los efectos que el monto y momento en que se realizaron los depósitos y retiros tuvieron sobre el rendimiento de tu dinero."
              ></mat-icon>
            </div>
          </div>
          <div bp-text-values-card class="flex items-center justify-end px-0">
            @if (valueSelected === 'accumulated') {
              {{ irrAccumulated }}
            }

            @if (valueSelected === 'pastYearReturn') {
              {{ irrAnnualized }}
            }
          </div>
        </div>
        <!-- USD -->
        <div class="flex flex-row justify-between gap-1">
          @if (irrAccumulatedUSD) {
            <div class="flex flex-row">
              <span class="flex items-center gap-2 text">
                <div>Tasa interna de retorno (TIR) en {{ irrUSDCurrencyCode }}</div>
                <mat-icon
                  class="icon-size-4 text-inherit "
                  [svgIcon]="'mat_outline:help_outline'"
                  matTooltip="La tasa interna de retorno busca incorporar los efectos que el monto y momento en que se realizaron los depósitos y retiros tuvieron sobre el rendimiento de tu dinero."
                ></mat-icon>
              </span>
            </div>
          }
          @if (irrAccumulatedUSD) {
            <div bp-text-values-card class="flex items-center justify-end px-0">
              @if (valueSelected === 'accumulated') {
                {{ irrAccumulatedUSD }}
              }
              @if (valueSelected === 'pastYearReturn') {
                {{ irrAnnualizedUSD }}
              }
            </div>
          }
        </div>
      </div>
