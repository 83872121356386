import { Injectable } from '@angular/core';
import { IFunding } from '@bp-core/src/lib/models/portal/goal/funding.model';
import { IPortafolioFunding } from '@bp-core/src/lib/models/portal/goal/portfolio-funding.model';
import { IWhereMyMoney } from '@bp-core/src/lib/models/portal/goal/whereMyMoney.model';
import {
  FundingModel,
  PortfolioCompositionModel,
  PortfolioModel,
} from '@bp-core/src/lib/proto/common-message.pb';
import { ICustomCategoryFund } from 'projects/bp-core/src/lib/models/portal/goal/custom-category-fund.model';
import { ICustomFund } from 'projects/bp-core/src/lib/models/portal/goal/fund-custom.model';
import { IDualFundingPercentages } from 'projects/bp-core/src/lib/models/portal/goal/funding-percentages.model';
import { ListSubCategoryWithFundsByFinancialEntityResponseDetail } from 'projects/bp-core/src/lib/proto/funding.pb';
import { ColorService } from './color.service';
@Injectable({
  providedIn: 'root',
})
export class GoalFundingService {
  constructor(private colorService: ColorService) {}
  /**
   *
   *
   * @param {IPortafolioFunding[]} funds from portfolio
   * @return {*}  {number} return stock percentage
   * @memberof GoalFundingService
   */
  getFundingStockPercentage(funds: IPortafolioFunding[]): number {
    let stockPercentage = 0;

    funds.forEach(fund => {
      fund?.funding?.compositions?.forEach(composition => {
        if (
          composition?.subcategory?.category?.isdisplayedingroupfunding &&
          composition?.subcategory?.category.groupdisplayuuid == 'acciones'
        ) {
          stockPercentage += (composition.percentage * fund.percentage) / 100;
        }
      });
    });
    return stockPercentage;
  }
  /**
   *
   *
   * @param {ICustomFund[]} funds from custom funding portfolio not created yet
   * @return {*}  {number} return stock percentage
   * @memberof GoalFundingService
   */
  getCustomFundingStockPercentage(funds: ICustomFund[]): IDualFundingPercentages {
    let stockPercentage = 0;
    let bondPercentage = 0;
    let notAssigned = 0;
    let boxPercentage = 1;

    funds.forEach(fund => {
      fund?.fund.fund?.fundingCompositions?.forEach(composition => {
        if (
          composition?.subcategory?.category?.isDisplayedInGroupFunding &&
          composition?.subcategory?.category.groupDisplayUuid == 'acciones'
        ) {
          stockPercentage += (composition.percentage! * fund.userPercentage!) / 100;
        } else {
          bondPercentage += (composition.percentage! * fund.userPercentage!) / 100;
        }
      });
    });
    notAssigned = 100 - stockPercentage - bondPercentage - boxPercentage;
    let stockPercentageTmp =
      (stockPercentage * 100) / (stockPercentage + bondPercentage + notAssigned);
    let bondPercentageTmp =
      (bondPercentage * 100) / (stockPercentage + bondPercentage + notAssigned);
    stockPercentage = stockPercentageTmp;
    bondPercentage = bondPercentageTmp;

    if (notAssigned == 0 && Math.round(stockPercentage) + Math.round(bondPercentage) != 100)
      bondPercentage += 1;
    return {
      percentage1: Math.round(stockPercentage),
      percentage2: Math.round(bondPercentage),
      notAssignedPercentage: Math.round(notAssigned),
    };
  }
  //   /**
  //    *
  //    *  function that return the risk value of provide stock percentage (Only on custom Portfolios)
  //    * @param {string} versionRiskLevel
  //    * @param {number} stockPercentage
  //    * @return {*}  {(Promise<IRiskLevel | undefined>)}
  //    * @memberof GoalFundingService
  //    */
  //   async getRiskLevelByEntityCustom(
  //     versionRiskLevel: string,
  //     stockPercentage: number,
  //   ): Promise<IRiskLevel | undefined> {
  //     if (!this.riskLevels) this.riskLevels = await lastValueFrom(this.riskLevelService.list());
  //     if (this.riskLevels) {
  //       let riskLevelsFiltered = this.riskLevels.filter(risk => risk.version == versionRiskLevel);
  //       if (stockPercentage == 0) return riskLevelsFiltered.find(risk => risk.position == 1);
  //       if (this.between(stockPercentage, 0, 25))
  //         return riskLevelsFiltered.find(risk => risk.position == 2);
  //       if (this.between(stockPercentage, 25, 50))
  //         return riskLevelsFiltered.find(risk => risk.position == 3);
  //       if (this.between(stockPercentage, 50, 75))
  //         return riskLevelsFiltered.find(risk => risk.position == 4);
  //       if (this.between(stockPercentage, 75, 100))
  //         return riskLevelsFiltered.find(risk => risk.position == 5);
  //     }
  //     return undefined;
  //   }
  /**
   *
   * function that return if value is inside a range
   * @param {number} x
   * @param {number} min
   * @param {number} max
   * @return {*}  {boolean}
   * @memberof GoalFundingService
   */
  between(x: number, min: number, max: number): boolean {
    return x > min && x <= max;
  }
  getNationalInternationalCustomFundingPercentage(funds: ICustomFund[]): IDualFundingPercentages {
    let nationalPercentage = 0;
    let internationalPercentage = 0;
    let notAssigned = 0;
    let boxPercentage = 1;

    funds.forEach(fund => {
      fund?.fund.fund?.fundingCompositions?.forEach(composition => {
        if (
          composition?.subcategory?.category?.isDisplayedInGroupFunding &&
          composition?.subcategory?.country?.isoCode == 'CL'
        ) {
          nationalPercentage += (composition.percentage! * fund.userPercentage!) / 100;
        } else {
          internationalPercentage += (composition.percentage! * fund.userPercentage!) / 100;
        }
      });
    });
    notAssigned = 100 - nationalPercentage - internationalPercentage - boxPercentage;
    nationalPercentage =
      (nationalPercentage * 100) / (nationalPercentage + internationalPercentage + notAssigned);

    if (Math.round(nationalPercentage + internationalPercentage + notAssigned) != 100)
      nationalPercentage += 1;

    return {
      percentage1: Math.round(nationalPercentage),
      percentage2: Math.round(internationalPercentage),
      notAssignedPercentage: Math.round(notAssigned),
    };
  }
  setWhereMyMoneyColors(whereMyMoney: IWhereMyMoney[]) {
    const iss = this.fundingIsStock(whereMyMoney[0].funding);
    let stockCount = 0;
    let bondCount = 0;
    whereMyMoney.forEach(c => {
      if (c.funding.isBox) {
        c.color = this.colorService.getBlue(0);
        return;
      }
      let isStock = this.fundingIsStock(c.funding);
      c.color = isStock
        ? this.colorService.getGreen(stockCount)
        : this.colorService.getBlue(bondCount);
      if (isStock) {
        stockCount++;
      } else {
        bondCount++;
      }
    });

    return whereMyMoney;
  }

  /**
   *
   *
   * @param {FundingModel} funding from portfolio
   * @return {*}  {boolean} return if funding is stock
   * @memberof GoalFundingService
   */
  fundingIsStock(funding: FundingModel | IFunding | undefined): boolean {
    let stockPercentage = 0;
    let bondPercentage = 0;
    if (funding instanceof FundingModel) {
      // Get the sum of the percentage of the stock subcategories
      stockPercentage =
        funding.fundingCompositions?.reduce((acc, curr) => {
          if (curr.subcategory?.category?.groupDisplayUuid == 'acciones') {
            return acc + curr.percentage;
          }
          return acc;
        }, 0) ?? 0;

      // Get the sum of the percentage of the bond subcategories
      bondPercentage =
        funding.fundingCompositions?.reduce((acc, curr) => {
          if (curr.subcategory?.category?.groupDisplayUuid == 'bonos') {
            return acc + curr.percentage;
          }
          return acc;
        }, 0) ?? 0;
    } else {
      // Get the sum of the percentage of the stock subcategories
      stockPercentage =
        funding?.compositions?.reduce((acc, curr) => {
          if (curr.subcategory?.category?.groupdisplayuuid == 'acciones') {
            return acc + curr.percentage;
          }
          return acc;
        }, 0) ?? 0;

      // Get the sum of the percentage of the bond subcategories
      bondPercentage =
        funding?.compositions?.reduce((acc, curr) => {
          if (curr.subcategory?.category?.groupdisplayuuid == 'bonos') {
            return acc + curr.percentage;
          }
          return acc;
        }, 0) ?? 0;
    }

    return stockPercentage > bondPercentage;
  }

  orderWhereMyMoney(a: IWhereMyMoney, b: IWhereMyMoney) {
    let aIsStock = this.fundingIsStock(a.funding);
    let bIsStock = this.fundingIsStock(b.funding);

    if (aIsStock && bIsStock) {
      return b.percentage - a.percentage;
    } else if (aIsStock) {
      return -1;
    } else if (bIsStock) {
      return 1;
    } else {
      return b.percentage - a.percentage;
    }
  }

  orderCustomFunds(a: ICustomFund, b: ICustomFund) {
    let aIsStock = this.fundingIsStock(a.fund.fund);
    let bIsStock = this.fundingIsStock(b.fund.fund);

    if (aIsStock && bIsStock) {
      return b.userPercentage! - a.userPercentage!;
    } else if (aIsStock) {
      return -1;
    } else if (bIsStock) {
      return 1;
    } else {
      return b.userPercentage! - a.userPercentage!;
    }
  }

  orderPortfolioFunds(a: IPortafolioFunding, b: IPortafolioFunding) {
    let aIsStock = this.fundingIsStock(a.funding);
    let bIsStock = this.fundingIsStock(b.funding);

    if (aIsStock && bIsStock) {
      return b.percentage - a.percentage;
    } else if (aIsStock) {
      return -1;
    } else if (bIsStock) {
      return 1;
    } else {
      return b.percentage - a.percentage;
    }
  }

  orderFundingComposition(a: PortfolioCompositionModel, b: PortfolioCompositionModel) {
    if (
      a.subcategory?.category?.groupDisplayUuid === 'acciones' &&
      b.subcategory?.category?.groupDisplayUuid === 'acciones'
    ) {
      return b.percentage - a.percentage;
    } else if (a.subcategory?.category?.groupDisplayUuid === 'acciones') {
      return -1;
    } else if (b.subcategory?.category?.groupDisplayUuid === 'acciones') {
      return 1;
    } else {
      return b.percentage - a.percentage;
    }
  }
  setFundingColors(compositions: PortfolioCompositionModel[]) {
    let stockCount = 0;
    let bondCount = 0;
    compositions.forEach(c => {
      let isStock = c.subcategory?.category?.groupDisplayUuid == 'acciones';
      c.color = isStock
        ? this.colorService.getGreen(stockCount)
        : this.colorService.getBlue(bondCount);
      if (isStock) {
        stockCount++;
      } else {
        bondCount++;
      }
    });

    return compositions;
  }
  setPortfolioFundingColors(funds: IPortafolioFunding[]) {
    let stockCount = 0;
    let bondCount = 0;
    funds.forEach(f => {
      let isStock = f.funding?.compositions?.find(
        c => c.subcategory?.category?.groupdisplayuuid == 'acciones',
      );
      f.color = isStock
        ? this.colorService.getGreen(stockCount)
        : this.colorService.getBlue(bondCount);
      if (isStock) {
        stockCount++;
      } else {
        bondCount++;
      }
    });

    return funds;
  }
  getSubCategoryFundsWithCustomFunds(
    portfolio: PortfolioModel,
    array?: ListSubCategoryWithFundsByFinancialEntityResponseDetail[],
  ) {
    let newArrayWithDefault: ICustomCategoryFund[] = [];
    if (array) {
      var acc = -1;
      var stockCount = -1;
      var bondCount = -1;
      var needsBalance = { fundId: 0, categoryIndex: -1 };
      array.forEach((category, index) => {
        let duplicateIndex = newArrayWithDefault.findIndex(
          value => value.category.categoryDisplayName == category.categoryDisplayName,
        );

        let customFunds: ICustomFund[] = category.customFunds?.map(customFund => {
          acc++;
          const searchObject = portfolio?.portfolioFunding!.find(
            f => f.fundingId == customFund.fund?.id,
          );
          if (searchObject?.percentage! < 5 && searchObject?.percentage! > 0)
            needsBalance = { fundId: customFund.fund?.id!, categoryIndex: index };
          const isStock = customFund.fund?.fundingCompositions?.find(
            f => f.subcategory?.category?.groupDisplayUuid == 'acciones',
          );
          if (isStock) {
            stockCount++;
          } else {
            bondCount++;
          }
          return {
            fund: customFund,
            defaultPercentage: searchObject ? searchObject.percentage : 0,
            userPercentage: searchObject ? searchObject.percentage : 0,
            color: isStock
              ? this.colorService.getGreen(stockCount)
              : this.colorService.getBlue(bondCount),
            category: category,
            groupCategoryDisplayOrder: acc, // quitar
          };
        })!;
        if (duplicateIndex == -1) {
          newArrayWithDefault.push({ category, customFunds });
        } else {
          //esta duplicada la categoría
          newArrayWithDefault[duplicateIndex].customFunds = [
            ...newArrayWithDefault[duplicateIndex].customFunds,
            ...customFunds,
          ];
        }
        //   newArrayWithDefault.push({ category, customFunds });
      });
      // esto ocurre si existe un fondo que tiene menos del 5%, se debe quitar al que mas % tiene en todas las categorías
      if (needsBalance.fundId != 0) {
        let needsBalanceFundIndex = newArrayWithDefault[
          needsBalance.categoryIndex
        ].customFunds?.findIndex(fund => fund.fund.fund?.id == needsBalance.fundId);
        let missingPercentage =
          5 -
          newArrayWithDefault[needsBalance.categoryIndex].customFunds[needsBalanceFundIndex]
            .userPercentage!;
        newArrayWithDefault[needsBalance.categoryIndex].customFunds[
          needsBalanceFundIndex
        ].userPercentage = newArrayWithDefault[needsBalance.categoryIndex].customFunds[
          needsBalanceFundIndex
        ].defaultPercentage = 5;

        var max = -Infinity;
        var maxFundIndex = -1;
        var maxCategoryIndex = -1;

        newArrayWithDefault.forEach((category, categoryIndex) => {
          category.customFunds.forEach((fund, fundIndex) => {
            if (max < +fund.userPercentage!) {
              max = +fund.userPercentage!;
              maxFundIndex = fundIndex;
              maxCategoryIndex = categoryIndex;
            }
          });
        });
        if (maxCategoryIndex != -1 && maxFundIndex != -1) {
          newArrayWithDefault[maxCategoryIndex].customFunds[maxFundIndex].userPercentage =
            newArrayWithDefault[maxCategoryIndex].customFunds[maxFundIndex].userPercentage! -
            missingPercentage;
          newArrayWithDefault[maxCategoryIndex].customFunds[maxFundIndex].defaultPercentage =
            newArrayWithDefault[maxCategoryIndex].customFunds[maxFundIndex].defaultPercentage! -
            missingPercentage;
        }
      }
    }
    return newArrayWithDefault;
  }
}
