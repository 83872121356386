import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { PortfolioCompositionModel } from '@bp-core/src/lib/proto/common-message.pb';

@Component({
  selector: 'app-g-d-p-t-list',
  templateUrl: './g-d-p-t-list.component.html',
  styleUrls: ['./g-d-p-t-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GDPTListComponent implements OnInit, OnChanges {
  @Input()
  compositions?: PortfolioCompositionModel[];
  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {
    // if (this.compositions && this.compositions.length > 0) {
    //   let biggest = this.compositions[0].percentage;
    //   // console.log(this.compositions);
    //   this.compositions.forEach(element => {
    //     if (biggest < element.percentage) {
    //       biggest = element.percentage;
    //     }
    //   });
    // //   this.compositions.forEach(element => {
    // //     // element.percentage = element.percentage * 2;
    // //     if (biggest === element.percentage) {
    // //       element.newWidth = 95;
    // //     } else {
    // //       element.newWidth = (element.percentage * 95) / biggest;
    // //     }
    // //   });
    // }
  }
}
