import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bp-card-icon',
  templateUrl: './card-icon.component.html',
  styleUrls: ['./card-icon.component.scss'],
})
export class CardIconComponent implements OnInit {
  @Input() icon: string;
  @Input() id: string;
  @Input() isCursorDefault: boolean;
  @Output() onClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  cardClicked(): void {
    this.onClick.emit(true);
  }
}
