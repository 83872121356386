<div bp-goal-detail-card>
  <div>
    <div bp-title-card class="truncate ">
      Tus movimientos
    </div>
    @if (showDates) {
      <div bp-text-values-card class="truncate ">
        {{ fixedFromDate + ' - ' + fixedToDate }}
      </div>
    }
  </div>
  <div class="pt-4">
    <!-- Table -->
    <app-g-p-t-table
      [currencyDisplay]="currency"
      [deposits]="deposits"
      [withdrawals]="withdrawals"
      [countDeposits]="countDeposits"
      [countWithdrawals]="countWithdrawals"
      >
    </app-g-p-t-table>
  </div>
</div>
