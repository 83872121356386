import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ICustomCategoryFund } from '@bp-core/src/lib/models/portal/goal/custom-category-fund.model';
import { ICustomFund } from '@bp-core/src/lib/models/portal/goal/fund-custom.model';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';
import { ListSubCategoryWithFundsByFinancialEntityResponseDetail } from 'projects/bp-core/src/lib/proto/funding.pb';
import { Subscription } from 'rxjs';
import { CustomPortfolioModalComponent } from '../custom-portfolio-modal/custom-portfolio-modal.component';

@Component({
  selector: 'bp-custom-portfolio-card',
  templateUrl: './custom-portfolio-card.component.html',
  styleUrls: ['./custom-portfolio-card.component.scss'],
})
export class CustomPortfolioCardComponent implements OnInit {
  @Input() title: string;
  @Input() fundsDetail: ICustomCategoryFund;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  @Output() private onFundsDetailChange = new EventEmitter<ICustomCategoryFund>();

  subscribers: Subscription[];

  componentLoaded = false;
  form: FormGroup;
  sum: number = 0;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private bpConfirmationService: BpConfirmationService,
  ) {}

  ngOnInit(): void {
    this.fillForm();
  }
  fillForm() {
    this.form = this.fb.group({ sum: [0], funds: this.fb.array([]) });
    this.fundsDetail.customFunds.forEach((fund, i) => {
      const id = fund.fund.fund?.id;
      const value = fund.userPercentage;
      this.funds.push(
        this.fb.group({
          value: this.fb.control(value, [
            Validators.required,
            Validators.max(100),
            Validators.min(0),
            this.validateSmallPercentage.bind(this),
          ]),
          id: id,
        }),
      );
      this.sum += value!;
    });
    this.sumControl?.setValue(this.sum);
    this.funds.valueChanges.subscribe(value => {
      const sum = value.reduce((accumulator: number, object: any, i: number) => {
        this.fundsDetail.customFunds[i].userPercentage = object.value;
        return accumulator + object.value;
      }, 0);
      this.onFundsDetailChange.emit(this.fundsDetail);
      this.sumControl?.setValue(sum);
    });
    this.onFormGroupChange.emit(this.form);
    this.componentLoaded = true;
  }
  get funds() {
    return this.form.get('funds') as FormArray;
  }
  fundsStatus() {
    return this.funds.status;
  }
  maxMinErrorStatus() {
    let flag = false;
    this.funds.controls.forEach(c => {
      if (
        c.get('value')?.hasError('max') ||
        c.get('value')?.hasError('min') ||
        c.get('value')?.hasError('required')
      )
        flag = true;
    });
    return flag;
  }
  get sumControl() {
    return this.form.get('sum');
  }
  validateSmallPercentage(control: AbstractControl) {
    if (control.value < 5 && control.value > 0) {
      return { invalidPercentage: true };
    } else {
      return null;
    }
  }

  onShowModal(
    fund: ICustomFund,
    category: ListSubCategoryWithFundsByFinancialEntityResponseDetail,
  ) {
    this.bpConfirmationService.open({
      title: fund.fund.fund?.title,
      width: '600px',
      factoryBodyComponent: {
        component: CustomPortfolioModalComponent,
        inputs: { fund: fund.fund, category: category },
      },
    });
  }

  /**
   *
   * this function has the objetive to remake the arrow down functionality lost
   * @param {KeyboardEvent} event
   * @param {number} index
   * @memberof CustomPortfolioCardComponent
   */
  onKeydown(event: KeyboardEvent, index: number) {
    if (event.key === 'ArrowDown') {
      let fg = this.funds.get(`${index}`) as FormGroup;
      if (fg.value.value > 0) fg.patchValue({ value: fg.value.value - 1 });
    }
  }
  onFocusOut(event: any, index: number) {
    let fg = this.funds.get(`${index}`) as FormGroup;
    if (event.target?.value == '') {
      fg.patchValue({ value: fg.value.value - 1 });
      fg.patchValue({ value: 0 });
    }
  }
}
