import { Component, Input, OnInit } from '@angular/core';
import {
  CustomFunding,
  ListSubCategoryWithFundsByFinancialEntityResponseDetail,
} from 'projects/bp-core/src/lib/proto/funding.pb';

@Component({
  selector: 'bp-custom-portfolio-modal',
  templateUrl: './custom-portfolio-modal.component.html',
  styleUrls: ['./custom-portfolio-modal.component.scss'],
})
export class CustomPortfolioModalComponent implements OnInit {
  @Input() fund: CustomFunding;
  @Input() category: ListSubCategoryWithFundsByFinancialEntityResponseDetail;

  constructor() {}

  ngOnInit(): void {
    console.log(this.fund);
  }
}
