<div bp-card>
  <div class="flex flex-row justify-start p-0">
    <div class="flex items-center justify-center">
      <img [src]="icon" alt="" title="" class="w-18 h-18" />
    </div>
    <div class="flex flex-row items-center justify-center">
      <div class="px-4 pt-4 pb-4 ">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
