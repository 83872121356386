@if (filterChips.length) {
  <div
    class="flex w-full gap-2 pb-3 overflow-x-auto flex-nowrap sm:hidden"
    >
    @for (tag of filterChips; track tag) {
      <mat-chip class="self-start w-auto">
        {{ tag }}
        <button matChipRemove (click)="removeChip(tag)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    }
  </div>
}
