import { Component, Input, OnInit } from '@angular/core';
import { IWhereMyMoney } from '@bp-core/src/lib/models/portal/goal/whereMyMoney.model';
import { FinancialEntityModel, GoalCategoryModel } from '@bp-core/src/lib/proto/common-message.pb';
import * as _ from 'lodash';

@Component({
  selector: 'bp-funds-costs',
  templateUrl: './bp-funds-costs.component.html',
  styleUrls: ['./bp-funds-costs.component.scss'],
})
export class BpFundsCostsComponent implements OnInit {
  totalPortfolioCost = 0;
  totalBpCommission = 0;
  BetterplanAnualCost = 1;
  @Input() fundsArray: IWhereMyMoney[];
  @Input() financialEntity: FinancialEntityModel;

  @Input() goalCategory: GoalCategoryModel;
  ngOnInit(): void {
    this.calculate();
  }
  get totalPortafolioCostVector() {
    if (this.financialEntity?.uuid == 'vector' || this.financialEntity?.uuid == 'bp-us') {
      return this.totalPortfolioCost - this.BetterplanAnualCost;
    }
    return this.totalPortfolioCost;
  }
  private calculate() {
    this.totalPortfolioCost = 0;
    this.BetterplanAnualCost = 1;
    let compositionGroup = {};

    this.fundsArray.forEach(funding => {
      if (funding?.funding?.cost) {
        this.totalPortfolioCost =
          this.totalPortfolioCost + funding.funding.cost * (funding.percentage / 100);
      }
      if (funding?.funding?.bpComission) {
        this.totalBpCommission =
          this.totalBpCommission + funding.funding.bpComission * (funding.percentage / 100);
      }

      const comp = _.map(funding?.funding?.fundingCompositions, _.clone);
      compositionGroup = _.reduce(
        comp,
        (prev, current) => {
          current.percentage = (current.percentage * funding.percentage) / 100;
          if (prev[current.subcategoryId]) {
            prev[current.subcategoryId].percentage =
              current.percentage + prev[current.subcategoryId].percentage;
          } else {
            prev[current.subcategoryId] = current;
          }
          return prev;
        },
        compositionGroup,
      );
    });
  }
}
