/**
 * Global options.
 */
export var options = {
  verbose: true,
  commercialLicense: false,
  classNamePrefix: "amcharts-",
  autoSetClassName: false,
  minPolylineStep: 0.5,
  onlyShowOnViewport: false,
  queue: false,
  autoDispose: false,
  licenses: [],
  suppressErrors: false,
  suppressWarnings: false,
  animationsEnabled: true,
  nonce: "",
  deferredDelay: 100,
  disableHoverOnTransform: "never",
  pixelPerfectPrecision: 0
};
