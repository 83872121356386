import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BpActivitiesGroupFilter } from 'bp/shared/models/activities-filters-info';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-activities-group-filter',
  templateUrl: './activities-group-filter.component.html',
  styleUrls: ['./activities-group-filter.component.scss'],
})
export class ActivitiesGroupFilterComponent implements OnInit, OnChanges {
  selectedFromDate:
    | { value?: number; dateFrom?: string; initialDate?: string; labels?: string[] }
    | undefined;
  selectedType: { value?: string[]; labels?: string[] } | undefined;
  selectedCurrency: {
    value: number | undefined;
    labels?: string[];
  };
  goalIds:
    | {
        value: number[];
        labels?: string[];
      }
    | undefined;
  @Input() removedChip?: string;
  @Input() groupFilterInfo?: BpActivitiesGroupFilter;
  @Output() sendGroupFilterOutput = new EventEmitter<BpActivitiesGroupFilter>();
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupFilterInfo?.currentValue) {
      this.groupFilterInfo = cloneDeep(changes.groupFilterInfo?.currentValue);
      this.selectedFromDate = this.groupFilterInfo?.timePeriod;
      this.selectedCurrency = {
        value: this.groupFilterInfo?.currency?.value,
        labels: this.groupFilterInfo?.currency?.labels ?? [],
      };
      this.selectedType = {
        value: this.groupFilterInfo?.operationType?.value,
        labels: this.groupFilterInfo?.operationType?.labels ?? [],
      };
      this.goalIds = {
        value: this.groupFilterInfo?.goals?.value ?? [0],
        labels: this.groupFilterInfo?.goals?.labels ?? [],
      };
    }
  }

  ngOnInit(): void {}
  onTimePeriodChange(period: {
    value: number;
    dateFrom: string;
    initialDate?: string;
    labels?: string[];
  }) {
    this.selectedFromDate = period;
    this.sendGroupFilterInfo();
  }
  onCurrencyChange(currency: { value: number; labels: string[] }) {
    this.selectedCurrency = {
      value: currency.value > 0 ? currency.value : undefined,
      labels: currency.labels,
    };
    this.sendGroupFilterInfo();
  }
  onTypeChange(type: { value: string[]; labels?: string[] }) {
    this.selectedType = type;
    this.sendGroupFilterInfo();
  }
  onGoalChange(goalIds: { value: number[]; labels?: string[] }) {
    this.goalIds = goalIds;
    this.sendGroupFilterInfo();
  }
  private sendGroupFilterInfo() {
    let groupFilterInfo: BpActivitiesGroupFilter = {};
    if (this.groupFilterInfo?.timePeriod) {
      groupFilterInfo.timePeriod = {
        value: this.selectedFromDate?.value,
        dateFrom: this.selectedFromDate?.dateFrom,
        initialDate: this.selectedFromDate?.initialDate,
        labels: this.selectedFromDate?.labels,
      };
    }
    if (this.groupFilterInfo?.currency) {
      groupFilterInfo.currency = this.selectedCurrency;
    }

    if (this.groupFilterInfo?.operationType) {
      groupFilterInfo.operationType = this.selectedType;
    }
    if (this.groupFilterInfo?.goals) {
      groupFilterInfo.goals = {
        goals: this.groupFilterInfo?.goals?.goals ?? [],
        value: this.goalIds?.value ?? [0],
        labels: this.goalIds?.labels ?? [],
      };
    }
    this.sendGroupFilterOutput.emit(groupFilterInfo);
  }
}
