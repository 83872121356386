import { Injectable } from '@angular/core';
import chroma, { Color } from 'chroma-js';
import { BRAND_COLORS, CHARTS_COLORS } from '../../../brand_colors';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private baseBlue: Color = chroma(CHARTS_COLORS.baseBlue);
  private baseGreen: Color = chroma(CHARTS_COLORS.baseGreen);
  private baseRed: Color = chroma(BRAND_COLORS.baseRed);

  private maxIndex: number = 30;

  getBlue(index: number): string {
    return this.baseBlue.brighten(1 * (index / this.maxIndex)).css();
  }

  getGreen(index: number): string {
    return this.baseGreen.brighten(1 * (index / this.maxIndex)).css();
  }
  getRed(index: number): string {
    return this.baseRed.brighten(1 * (index / this.maxIndex)).css();
  }

  getLightColor(color: string, factor: number): string {
    return chroma(color)
      .luminance(factor)
      .css();
  }
}
