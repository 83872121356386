/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
/**
 * Message implementation for portfolio.AddCustomPortfolioFundingComposition
 */
export class AddCustomPortfolioFundingComposition implements GrpcMessage {
  static id = 'portfolio.AddCustomPortfolioFundingComposition';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomPortfolioFundingComposition();
    AddCustomPortfolioFundingComposition.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomPortfolioFundingComposition) {
    _instance.fundingId = _instance.fundingId || 0;
    _instance.percentage = _instance.percentage || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomPortfolioFundingComposition,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.fundingId = _reader.readInt32();
          break;
        case 2:
          _instance.percentage = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomPortfolioFundingComposition.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomPortfolioFundingComposition,
    _writer: BinaryWriter
  ) {
    if (_instance.fundingId) {
      _writer.writeInt32(1, _instance.fundingId);
    }
    if (_instance.percentage) {
      _writer.writeInt32(2, _instance.percentage);
    }
  }

  private _fundingId: number;
  private _percentage: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomPortfolioFundingComposition to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddCustomPortfolioFundingComposition.AsObject>
  ) {
    _value = _value || {};
    this.fundingId = _value.fundingId;
    this.percentage = _value.percentage;
    AddCustomPortfolioFundingComposition.refineValues(this);
  }
  get fundingId(): number {
    return this._fundingId;
  }
  set fundingId(value: number) {
    this._fundingId = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomPortfolioFundingComposition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomPortfolioFundingComposition.AsObject {
    return {
      fundingId: this.fundingId,
      percentage: this.percentage
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomPortfolioFundingComposition.AsProtobufJSON {
    return {
      fundingId: this.fundingId,
      percentage: this.percentage
    };
  }
}
export module AddCustomPortfolioFundingComposition {
  /**
   * Standard JavaScript object representation for AddCustomPortfolioFundingComposition
   */
  export interface AsObject {
    fundingId: number;
    percentage: number;
  }

  /**
   * Protobuf JSON representation for AddCustomPortfolioFundingComposition
   */
  export interface AsProtobufJSON {
    fundingId: number;
    percentage: number;
  }
}

/**
 * Message implementation for portfolio.AddCustomPortfolioRequest
 */
export class AddCustomPortfolioRequest implements GrpcMessage {
  static id = 'portfolio.AddCustomPortfolioRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomPortfolioRequest();
    AddCustomPortfolioRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomPortfolioRequest) {
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.uuid = _instance.uuid || '';
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.investmentStrategyId = _instance.investmentStrategyId || 0;
    _instance.funds = _instance.funds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomPortfolioRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.title = _reader.readString();
          break;
        case 2:
          _instance.description = _reader.readString();
          break;
        case 3:
          _instance.uuid = _reader.readString();
          break;
        case 4:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 5:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 6:
          _instance.investmentStrategyId = _reader.readInt32();
          break;
        case 7:
          const messageInitializer7 = new AddCustomPortfolioFundingComposition();
          _reader.readMessage(
            messageInitializer7,
            AddCustomPortfolioFundingComposition.deserializeBinaryFromReader
          );
          (_instance.funds = _instance.funds || []).push(messageInitializer7);
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomPortfolioRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomPortfolioRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.title) {
      _writer.writeString(1, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(2, _instance.description);
    }
    if (_instance.uuid) {
      _writer.writeString(3, _instance.uuid);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(4, _instance.financialEntityId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(5, _instance.riskLevelId);
    }
    if (_instance.investmentStrategyId) {
      _writer.writeInt32(6, _instance.investmentStrategyId);
    }
    if (_instance.funds && _instance.funds.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.funds as any,
        AddCustomPortfolioFundingComposition.serializeBinaryToWriter
      );
    }
  }

  private _title: string;
  private _description: string;
  private _uuid: string;
  private _financialEntityId: number;
  private _riskLevelId: number;
  private _investmentStrategyId: number;
  private _funds?: AddCustomPortfolioFundingComposition[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomPortfolioRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCustomPortfolioRequest.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.description = _value.description;
    this.uuid = _value.uuid;
    this.financialEntityId = _value.financialEntityId;
    this.riskLevelId = _value.riskLevelId;
    this.investmentStrategyId = _value.investmentStrategyId;
    this.funds = (_value.funds || []).map(
      m => new AddCustomPortfolioFundingComposition(m)
    );
    AddCustomPortfolioRequest.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get investmentStrategyId(): number {
    return this._investmentStrategyId;
  }
  set investmentStrategyId(value: number) {
    this._investmentStrategyId = value;
  }
  get funds(): AddCustomPortfolioFundingComposition[] | undefined {
    return this._funds;
  }
  set funds(value: AddCustomPortfolioFundingComposition[] | undefined) {
    this._funds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomPortfolioRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomPortfolioRequest.AsObject {
    return {
      title: this.title,
      description: this.description,
      uuid: this.uuid,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      investmentStrategyId: this.investmentStrategyId,
      funds: (this.funds || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomPortfolioRequest.AsProtobufJSON {
    return {
      title: this.title,
      description: this.description,
      uuid: this.uuid,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      investmentStrategyId: this.investmentStrategyId,
      funds: (this.funds || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module AddCustomPortfolioRequest {
  /**
   * Standard JavaScript object representation for AddCustomPortfolioRequest
   */
  export interface AsObject {
    title: string;
    description: string;
    uuid: string;
    financialEntityId: number;
    riskLevelId: number;
    investmentStrategyId: number;
    funds?: AddCustomPortfolioFundingComposition.AsObject[];
  }

  /**
   * Protobuf JSON representation for AddCustomPortfolioRequest
   */
  export interface AsProtobufJSON {
    title: string;
    description: string;
    uuid: string;
    financialEntityId: number;
    riskLevelId: number;
    investmentStrategyId: number;
    funds: AddCustomPortfolioFundingComposition.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portfolio.AddCustomPortfolioResponse
 */
export class AddCustomPortfolioResponse implements GrpcMessage {
  static id = 'portfolio.AddCustomPortfolioResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCustomPortfolioResponse();
    AddCustomPortfolioResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCustomPortfolioResponse) {
    _instance.status = _instance.status || 0;
    _instance.message = _instance.message || '';
    _instance.portfolio = _instance.portfolio || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCustomPortfolioResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readInt32();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.portfolio = new common_message006.PortfolioModel();
          _reader.readMessage(
            _instance.portfolio,
            common_message006.PortfolioModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddCustomPortfolioResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCustomPortfolioResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeInt32(1, _instance.status);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.portfolio) {
      _writer.writeMessage(
        3,
        _instance.portfolio as any,
        common_message006.PortfolioModel.serializeBinaryToWriter
      );
    }
  }

  private _status: number;
  private _message: string;
  private _portfolio?: common_message006.PortfolioModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCustomPortfolioResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCustomPortfolioResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    this.message = _value.message;
    this.portfolio = _value.portfolio
      ? new common_message006.PortfolioModel(_value.portfolio)
      : undefined;
    AddCustomPortfolioResponse.refineValues(this);
  }
  get status(): number {
    return this._status;
  }
  set status(value: number) {
    this._status = value;
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get portfolio(): common_message006.PortfolioModel | undefined {
    return this._portfolio;
  }
  set portfolio(value: common_message006.PortfolioModel | undefined) {
    this._portfolio = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCustomPortfolioResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCustomPortfolioResponse.AsObject {
    return {
      status: this.status,
      message: this.message,
      portfolio: this.portfolio ? this.portfolio.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCustomPortfolioResponse.AsProtobufJSON {
    return {
      status: this.status,
      message: this.message,
      portfolio: this.portfolio ? this.portfolio.toProtobufJSON(options) : null
    };
  }
}
export module AddCustomPortfolioResponse {
  /**
   * Standard JavaScript object representation for AddCustomPortfolioResponse
   */
  export interface AsObject {
    status: number;
    message: string;
    portfolio?: common_message006.PortfolioModel.AsObject;
  }

  /**
   * Protobuf JSON representation for AddCustomPortfolioResponse
   */
  export interface AsProtobufJSON {
    status: number;
    message: string;
    portfolio: common_message006.PortfolioModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portfolio.AddPortfolioRequest
 */
export class AddPortfolioRequest implements GrpcMessage {
  static id = 'portfolio.AddPortfolioRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddPortfolioRequest();
    AddPortfolioRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddPortfolioRequest) {
    _instance.maxRangeYear = _instance.maxRangeYear || 0;
    _instance.minRangeYear = _instance.minRangeYear || 0;
    _instance.title = _instance.title || '';
    _instance.description = _instance.description || '';
    _instance.financialEntityId = _instance.financialEntityId || 0;
    _instance.riskLevelId = _instance.riskLevelId || 0;
    _instance.isDefault = _instance.isDefault || false;
    _instance.profitability = _instance.profitability || [];
    _instance.investmentStrategyId = _instance.investmentStrategyId || 0;
    _instance.version = _instance.version || '';
    _instance.extraProfitabilityCurrencyCode =
      _instance.extraProfitabilityCurrencyCode || '';
    _instance.estimatedProfitability = _instance.estimatedProfitability || '';
    _instance.portfolioFunding = _instance.portfolioFunding || [];
    _instance.bpComission = _instance.bpComission || 0;
    _instance.uuid = _instance.uuid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddPortfolioRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.maxRangeYear = _reader.readDouble();
          break;
        case 2:
          _instance.minRangeYear = _reader.readDouble();
          break;
        case 3:
          _instance.title = _reader.readString();
          break;
        case 4:
          _instance.description = _reader.readString();
          break;
        case 5:
          _instance.financialEntityId = _reader.readInt32();
          break;
        case 6:
          _instance.riskLevelId = _reader.readInt32();
          break;
        case 7:
          _instance.isDefault = _reader.readBool();
          break;
        case 8:
          const messageInitializer8 = new common_message006.PortfolioProfitabilityModel();
          _reader.readMessage(
            messageInitializer8,
            common_message006.PortfolioProfitabilityModel
              .deserializeBinaryFromReader
          );
          (_instance.profitability = _instance.profitability || []).push(
            messageInitializer8
          );
          break;
        case 9:
          _instance.investmentStrategyId = _reader.readInt32();
          break;
        case 10:
          _instance.version = _reader.readString();
          break;
        case 11:
          _instance.extraProfitabilityCurrencyCode = _reader.readString();
          break;
        case 12:
          _instance.estimatedProfitability = _reader.readString();
          break;
        case 13:
          const messageInitializer13 = new common_message006.PortfolioFundingModel();
          _reader.readMessage(
            messageInitializer13,
            common_message006.PortfolioFundingModel.deserializeBinaryFromReader
          );
          (_instance.portfolioFunding = _instance.portfolioFunding || []).push(
            messageInitializer13
          );
          break;
        case 14:
          _instance.bpComission = _reader.readDouble();
          break;
        case 15:
          _instance.uuid = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddPortfolioRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddPortfolioRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.maxRangeYear) {
      _writer.writeDouble(1, _instance.maxRangeYear);
    }
    if (_instance.minRangeYear) {
      _writer.writeDouble(2, _instance.minRangeYear);
    }
    if (_instance.title) {
      _writer.writeString(3, _instance.title);
    }
    if (_instance.description) {
      _writer.writeString(4, _instance.description);
    }
    if (_instance.financialEntityId) {
      _writer.writeInt32(5, _instance.financialEntityId);
    }
    if (_instance.riskLevelId) {
      _writer.writeInt32(6, _instance.riskLevelId);
    }
    if (_instance.isDefault) {
      _writer.writeBool(7, _instance.isDefault);
    }
    if (_instance.profitability && _instance.profitability.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.profitability as any,
        common_message006.PortfolioProfitabilityModel.serializeBinaryToWriter
      );
    }
    if (_instance.investmentStrategyId) {
      _writer.writeInt32(9, _instance.investmentStrategyId);
    }
    if (_instance.version) {
      _writer.writeString(10, _instance.version);
    }
    if (_instance.extraProfitabilityCurrencyCode) {
      _writer.writeString(11, _instance.extraProfitabilityCurrencyCode);
    }
    if (_instance.estimatedProfitability) {
      _writer.writeString(12, _instance.estimatedProfitability);
    }
    if (_instance.portfolioFunding && _instance.portfolioFunding.length) {
      _writer.writeRepeatedMessage(
        13,
        _instance.portfolioFunding as any,
        common_message006.PortfolioFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.bpComission) {
      _writer.writeDouble(14, _instance.bpComission);
    }
    if (_instance.uuid) {
      _writer.writeString(15, _instance.uuid);
    }
  }

  private _maxRangeYear: number;
  private _minRangeYear: number;
  private _title: string;
  private _description: string;
  private _financialEntityId: number;
  private _riskLevelId: number;
  private _isDefault: boolean;
  private _profitability?: common_message006.PortfolioProfitabilityModel[];
  private _investmentStrategyId: number;
  private _version: string;
  private _extraProfitabilityCurrencyCode: string;
  private _estimatedProfitability: string;
  private _portfolioFunding?: common_message006.PortfolioFundingModel[];
  private _bpComission: number;
  private _uuid: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddPortfolioRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddPortfolioRequest.AsObject>) {
    _value = _value || {};
    this.maxRangeYear = _value.maxRangeYear;
    this.minRangeYear = _value.minRangeYear;
    this.title = _value.title;
    this.description = _value.description;
    this.financialEntityId = _value.financialEntityId;
    this.riskLevelId = _value.riskLevelId;
    this.isDefault = _value.isDefault;
    this.profitability = (_value.profitability || []).map(
      m => new common_message006.PortfolioProfitabilityModel(m)
    );
    this.investmentStrategyId = _value.investmentStrategyId;
    this.version = _value.version;
    this.extraProfitabilityCurrencyCode = _value.extraProfitabilityCurrencyCode;
    this.estimatedProfitability = _value.estimatedProfitability;
    this.portfolioFunding = (_value.portfolioFunding || []).map(
      m => new common_message006.PortfolioFundingModel(m)
    );
    this.bpComission = _value.bpComission;
    this.uuid = _value.uuid;
    AddPortfolioRequest.refineValues(this);
  }
  get maxRangeYear(): number {
    return this._maxRangeYear;
  }
  set maxRangeYear(value: number) {
    this._maxRangeYear = value;
  }
  get minRangeYear(): number {
    return this._minRangeYear;
  }
  set minRangeYear(value: number) {
    this._minRangeYear = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get financialEntityId(): number {
    return this._financialEntityId;
  }
  set financialEntityId(value: number) {
    this._financialEntityId = value;
  }
  get riskLevelId(): number {
    return this._riskLevelId;
  }
  set riskLevelId(value: number) {
    this._riskLevelId = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }
  get profitability():
    | common_message006.PortfolioProfitabilityModel[]
    | undefined {
    return this._profitability;
  }
  set profitability(
    value: common_message006.PortfolioProfitabilityModel[] | undefined
  ) {
    this._profitability = value;
  }
  get investmentStrategyId(): number {
    return this._investmentStrategyId;
  }
  set investmentStrategyId(value: number) {
    this._investmentStrategyId = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get extraProfitabilityCurrencyCode(): string {
    return this._extraProfitabilityCurrencyCode;
  }
  set extraProfitabilityCurrencyCode(value: string) {
    this._extraProfitabilityCurrencyCode = value;
  }
  get estimatedProfitability(): string {
    return this._estimatedProfitability;
  }
  set estimatedProfitability(value: string) {
    this._estimatedProfitability = value;
  }
  get portfolioFunding():
    | common_message006.PortfolioFundingModel[]
    | undefined {
    return this._portfolioFunding;
  }
  set portfolioFunding(
    value: common_message006.PortfolioFundingModel[] | undefined
  ) {
    this._portfolioFunding = value;
  }
  get bpComission(): number {
    return this._bpComission;
  }
  set bpComission(value: number) {
    this._bpComission = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddPortfolioRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddPortfolioRequest.AsObject {
    return {
      maxRangeYear: this.maxRangeYear,
      minRangeYear: this.minRangeYear,
      title: this.title,
      description: this.description,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      isDefault: this.isDefault,
      profitability: (this.profitability || []).map(m => m.toObject()),
      investmentStrategyId: this.investmentStrategyId,
      version: this.version,
      extraProfitabilityCurrencyCode: this.extraProfitabilityCurrencyCode,
      estimatedProfitability: this.estimatedProfitability,
      portfolioFunding: (this.portfolioFunding || []).map(m => m.toObject()),
      bpComission: this.bpComission,
      uuid: this.uuid
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddPortfolioRequest.AsProtobufJSON {
    return {
      maxRangeYear: this.maxRangeYear,
      minRangeYear: this.minRangeYear,
      title: this.title,
      description: this.description,
      financialEntityId: this.financialEntityId,
      riskLevelId: this.riskLevelId,
      isDefault: this.isDefault,
      profitability: (this.profitability || []).map(m =>
        m.toProtobufJSON(options)
      ),
      investmentStrategyId: this.investmentStrategyId,
      version: this.version,
      extraProfitabilityCurrencyCode: this.extraProfitabilityCurrencyCode,
      estimatedProfitability: this.estimatedProfitability,
      portfolioFunding: (this.portfolioFunding || []).map(m =>
        m.toProtobufJSON(options)
      ),
      bpComission: this.bpComission,
      uuid: this.uuid
    };
  }
}
export module AddPortfolioRequest {
  /**
   * Standard JavaScript object representation for AddPortfolioRequest
   */
  export interface AsObject {
    maxRangeYear: number;
    minRangeYear: number;
    title: string;
    description: string;
    financialEntityId: number;
    riskLevelId: number;
    isDefault: boolean;
    profitability?: common_message006.PortfolioProfitabilityModel.AsObject[];
    investmentStrategyId: number;
    version: string;
    extraProfitabilityCurrencyCode: string;
    estimatedProfitability: string;
    portfolioFunding?: common_message006.PortfolioFundingModel.AsObject[];
    bpComission: number;
    uuid: string;
  }

  /**
   * Protobuf JSON representation for AddPortfolioRequest
   */
  export interface AsProtobufJSON {
    maxRangeYear: number;
    minRangeYear: number;
    title: string;
    description: string;
    financialEntityId: number;
    riskLevelId: number;
    isDefault: boolean;
    profitability:
      | common_message006.PortfolioProfitabilityModel.AsProtobufJSON[]
      | null;
    investmentStrategyId: number;
    version: string;
    extraProfitabilityCurrencyCode: string;
    estimatedProfitability: string;
    portfolioFunding:
      | common_message006.PortfolioFundingModel.AsProtobufJSON[]
      | null;
    bpComission: number;
    uuid: string;
  }
}

/**
 * Message implementation for portfolio.ListPortfolioResponse
 */
export class ListPortfolioResponse implements GrpcMessage {
  static id = 'portfolio.ListPortfolioResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListPortfolioResponse();
    ListPortfolioResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListPortfolioResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListPortfolioResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.PortfolioModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.PortfolioModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListPortfolioResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListPortfolioResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.PortfolioModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message006.PortfolioModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListPortfolioResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListPortfolioResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.PortfolioModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListPortfolioResponse.refineValues(this);
  }
  get values(): common_message006.PortfolioModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.PortfolioModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListPortfolioResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListPortfolioResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListPortfolioResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListPortfolioResponse {
  /**
   * Standard JavaScript object representation for ListPortfolioResponse
   */
  export interface AsObject {
    values?: common_message006.PortfolioModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListPortfolioResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.PortfolioModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}
