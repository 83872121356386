import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { IQuestionsAnswers } from 'projects/bp-core/src/lib/models/portal/goal/questions-answers';
import { IQuestionsAnswersLink } from 'projects/bp-core/src/lib/models/portal/goal/questions-answers-link';
import {
  BlogContentListRequest,
  BlogContentModel,
  BlogContentTypeEnum,
} from 'projects/bp-core/src/lib/proto/blog.pb';
import { BlogGrpcServiceClient } from 'projects/bp-core/src/lib/proto/blog.pbsc';
import { Subscription, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'bp-faq-link',
  templateUrl: './faq-link.component.html',
  styleUrls: ['./faq-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class FaqLinkComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  questionsAndAnswersLink: IQuestionsAnswersLink[];
  @Input()
  questionsAndAnswers: IQuestionsAnswers[];
  @Input()
  investmentStrategy: string;
  @Input()
  tabParent: string | null | undefined;
  @Input()
  class: string | null | undefined;

  componentIsReady = false;
  subscription: Subscription;
  fragmentSubscription: Subscription;

  areLinkQuestions = true;
  tab: string | null;
  blogQuestions: BlogContentModel[];
  constructor(
    private blogGrpcServiceClient: BlogGrpcServiceClient,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.fragmentSubscription = this.route.fragment.pipe(distinctUntilChanged()).subscribe({
      next: fragment => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.tab = fragment;
        if (!this.tab) this.tab = this.router.url.slice(1);
        if (this.tabParent) {
          this.investmentStrategy = this.tabParent;
        }
        this.cd.detectChanges();

        this.getQuestions();
      },
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    if (this.fragmentSubscription) this.fragmentSubscription.unsubscribe();
  }

  ngOnInit(): void {}
  getQuestions() {
    this.componentIsReady = false;
    this.subscription = this.blogGrpcServiceClient
      .getBlogContent(
        new BlogContentListRequest({
          contentType: BlogContentTypeEnum.Faq,
          customContentSlug: this.tab!, //tab
          customContentSlugExtra: this.investmentStrategy, //strategy
        }),
      )
      .subscribe({
        next: result => {
          if (result.values) {
            this.blogQuestions = result.values!;
            let linkQuestions = 0;
            let textQuestions = 0;
            this.blogQuestions.forEach(bq => {
              if (bq.content?.rendered != '') {
                textQuestions++;
              } else if (bq.link != '') {
                linkQuestions++;
              }
            });
            if (textQuestions >= linkQuestions) {
              this.areLinkQuestions = false;
              if (textQuestions != this.blogQuestions.length) {
                this.blogQuestions = this.blogQuestions.filter(q => q.content?.rendered != '');
              }
            } else {
              this.areLinkQuestions = true;
              if (linkQuestions != this.blogQuestions.length) {
                this.blogQuestions = this.blogQuestions.filter(q => q.link != '');
              }
            }
            this.componentIsReady = true;
            this.cd.detectChanges();
          }
        },
        error: error => {
          console.log(error);
        },
      });
  }
}
