/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './blog.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
import { GRPC_BLOG_GRPC_SERVICE_CLIENT_SETTINGS } from './blog.pbconf';
/**
 * Service client implementation for blog.BlogGrpcService
 */
@Injectable({ providedIn: 'any' })
export class BlogGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /blog.BlogGrpcService/GetBlogContent
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.BlogPostResponse>>
     */
    getBlogContent: (
      requestData: thisProto.BlogContentListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.BlogPostResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/blog.BlogGrpcService/GetBlogContent',
        requestData,
        requestMetadata,
        requestClass: thisProto.BlogContentListRequest,
        responseClass: thisProto.BlogPostResponse
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_BLOG_GRPC_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('blog.BlogGrpcService', settings);
  }

  /**
   * Unary call @/blog.BlogGrpcService/GetBlogContent
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.BlogPostResponse>
   */
  getBlogContent(
    requestData: thisProto.BlogContentListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.BlogPostResponse> {
    return this.$raw
      .getBlogContent(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
