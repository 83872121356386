/**
 * A module defining functionality for circular axis grid elements.
 */
import { __extends } from "tslib";
/**
 * ============================================================================
 * IMPORTS
 * ============================================================================
 * @hidden
 */
import { Grid } from "./Grid";
import { registry } from "../../core/Registry";
/**
 * ============================================================================
 * MAIN CLASS
 * ============================================================================
 * @hidden
 */
/**
 * Creates a circular grid element for circular-type axis.
 *
 * @see {@link IGridCircularEvents} for a list of available events
 * @see {@link IGridCircularAdapters} for a list of available Adapters
 * @todo Review: container is better, as we'll be able to attach something to the GridCircular, also with 3d charts we might need some additional elements
 */
var GridCircular = /** @class */function (_super) {
  __extends(GridCircular, _super);
  /**
   * Constructor
   */
  function GridCircular() {
    var _this = _super.call(this) || this;
    _this.className = "GridCircular";
    _this.pixelPerfect = false;
    _this.applyTheme();
    return _this;
  }
  Object.defineProperty(GridCircular.prototype, "innerRadius", {
    /**
     * @return Inner radius
     */
    get: function () {
      return this.getPropertyValue("innerRadius");
    },
    /**
     * Inner radius of the circular grid. (absolute or relative)
     *
     * @param value Inner radius
     */
    set: function (value) {
      this.setPercentProperty("innerRadius", value, true, false, 10, false);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(GridCircular.prototype, "radius", {
    /**
     * @return Outer radius
     */
    get: function () {
      return this.getPropertyValue("radius");
    },
    /**
     * Outer radius of the circular grid. (absolute or relative)
     *
     * @param value Outer radius
     */
    set: function (value) {
      this.setPercentProperty("radius", value, true, false, 10, false);
    },
    enumerable: true,
    configurable: true
  });
  return GridCircular;
}(Grid);
export { GridCircular };
/**
 * Register class in system, so that it can be instantiated using its name from
 * anywhere.
 *
 * @ignore
 */
registry.registeredClasses["GridCircular"] = GridCircular;
