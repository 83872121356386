<ul class="list-group">
  <li class="px-0 py-1 list-group-item">
    <div class="flex justify-between w-full ">
      <div class="items-center">
        Balance Final
        <p class="mb-0 text-secondary">
          ({{
          totalCurrentCapital && hasAllFinancialInstitutions
          ? (dateFinalBalance | date: 'longDate')
          : (balanceTotalDate | date: 'longDate')
          }})
        </p>
      </div>

      <span class="text-right num">
        {{
        totalCurrentCapital && hasAllFinancialInstitutions
        ? (totalCurrentCapital
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        : (balanceTotalAmount
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        }}
      </span>
    </div>
  </li>
  @if (balanceInitAmount != 0) {
    <li class="px-0 list-group-item">
      <div class="flex justify-between w-full">
        <h5 class="mb-1 name">
          Balance Inicial
          <span class="special">({{ balanceInitDate | date: 'longDate' }})</span>
        </h5>
        <span class="ml-2 text-right num">
          {{
          balanceInitAmount
          | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo
          }}
        </span>
      </div>
    </li>
  }
  <li class="px-0 py-1 list-group-item">
    <div class="flex justify-between w-full">
      <div class="mb-1 name">Depósitos netos</div>
      <span class="text-right num">
        {{ netDeposit | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo }}
      </span>
    </div>
  </li>
  <li class="px-0 list-group-item sub text-secondary">
    <div class="flex justify-between w-full">
      <h5 class="mb-1 name">Depósitos</h5>
      <span class="ml-2 text-right num">{{
        deposits | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo
      }}</span>
    </div>
  </li>
  <li class="px-0 list-group-item sub text-secondary">
    <div class="flex justify-between w-full">
      <h5 class="mb-1 name">Retiros</h5>
      <span class="ml-2 text-right num">{{
        withdrawals | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo
      }}</span>
    </div>
  </li>
  <li class="px-0 pt-2 font-medium list-group-item total text-dark">
    <div class="flex justify-between w-full">
      <h5 class="mb-1 name">Ganancia / Pérdida</h5>
      <span class="text-right num">
        {{
        totalCurrentCapital && hasAllFinancialInstitutions
        ? (totalCurrentCapital - (balanceInitAmount != 0 ? balanceInitAmount : netDeposit)
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        : (balanceTotalAmount - (balanceInitAmount != 0 ? balanceInitAmount : netDeposit)
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        }}
        <!-- CALCULO ORIGINAL CONSIDERABA LOS DEPOSITOS PARA DETERMINAR LAS PERDIDAS/GANANCIAS -->
        <!-- {{
        totalCurrentCapital && hasAllFinancialInstitutions
        ? (totalCurrentCapital - netDeposit - balanceInitAmount
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        : (balanceTotalAmount - netDeposit - balanceInitAmount
        | currency: currency?.currencyCode:currency?.display:currency?.digitsInfo)
        }} -->
      </span>
    </div>
  </li>
</ul>
