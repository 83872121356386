import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bp-explanation-modal',
  templateUrl: './explanation-modal.component.html',
  styleUrls: ['./explanation-modal.component.scss'],
})
export class ExplanationModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; content: TemplateRef<any> }) {}

  ngOnInit(): void {}
}
