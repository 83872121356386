import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BlogContentListRequest,
  BlogContentTypeEnum,
  BlogNotificationModel,
} from '@bp-core/src/lib/proto/blog.pb';
import { BlogGrpcServiceClient } from '@bp-core/src/lib/proto/blog.pbsc';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
enum Extra {
  Popup = 'popup',
  link = 'link',
  none = 'none',
}
@Component({
  selector: 'app-persistent-notifications',
  templateUrl: './persistent-notifications.component.html',
  styleUrls: ['./persistent-notifications.component.scss'],
})
export class PersistentNotificationsComponent implements OnInit, OnChanges, OnDestroy {
  componentIsReady = false;
  subscription: Subscription;
  fragmentSubscription: Subscription;
  tab: string | null;
  blogNotifications: BlogNotificationModel[];
  showOptions = false;
  option2 = true;
  option3 = false;

  @Input()
  investmentStrategy: string;
  @Input()
  tabParent: string | null | undefined;
  @Input()
  id?: number;
  constructor(
    private blogGrpcServiceClient: BlogGrpcServiceClient,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private bpConfirmService: BpConfirmationService,
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    if (this.fragmentSubscription) this.fragmentSubscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fragmentSubscription = this.route.fragment.pipe(distinctUntilChanged()).subscribe({
      next: fragment => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.tab = fragment;
        if (!this.tab) this.tab = this.router.url.slice(1);
        if (this.tabParent) {
          this.investmentStrategy = this.tabParent;
        }
        this.cd.detectChanges();

        this.getPersistantNotifications();
      },
    });
  }

  ngOnInit(): void {}

  /**
   * Muestra el contenido extra de la notificación
   *
   * @param {BlogNotificationModel} notification
   * @return {*}
   * @memberof PersistentNotificationsComponent
   */
  showMore(notification: BlogNotificationModel) {
    // Si es un popup
    if (notification.extra == Extra.Popup) {
      this.bpConfirmService.open({
        title: notification.popupTitle,
        message: notification.popupBody,
      });
      return;
    }
    // Si es un link
    if (notification.extra == Extra.link) {
      // Si es un link con variables (Goal)
      if (notification.portalLink.includes('{{') && this.id) {
        const path = notification.portalLink.replace('{{id}}', this.id.toString());
        this.router.navigateByUrl(path);
        return;
      }
      this.router.navigateByUrl(notification.portalLink);
    }
  }
  /**
   * Obtiene las notificaciones persistentes desde el blog
   *
   * @private
   * @memberof PersistentNotificationsComponent
   */
  private getPersistantNotifications() {
    this.componentIsReady = false;
    this.subscription = this.blogGrpcServiceClient
      .getBlogContent(
        new BlogContentListRequest({
          contentType: BlogContentTypeEnum.Notification,
          customContentSlug: this.tab!, //tab
          customContentSlugExtra: this.investmentStrategy, //strategy
        }),
      )
      .subscribe({
        next: response => {
          this.blogNotifications = response.notification ?? [];
          this.componentIsReady = true;
          this.cd.detectChanges();
        },
        error: error => {
          console.log(error);
        },
      });
  }
}
