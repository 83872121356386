/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
export enum BlogContentTypeEnum {
  Post = 0,
  Footer = 1,
  Category = 2,
  Custom = 3,
  Faq = 4,
  Team = 5,
  TypeTeam = 6,
  Notification = 7,
  LearnPosts = 8,
  ExplorePostsAhorro = 9,
  ExplorePostsAhorroDolar = 10,
  ExplorePostsAhorroPlus = 11,
  ExplorePostsAhorroDolarPlus = 12
}
/**
 * Message implementation for blog.BlogContentListRequest
 */
export class BlogContentListRequest implements GrpcMessage {
  static id = 'blog.BlogContentListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogContentListRequest();
    BlogContentListRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogContentListRequest) {
    _instance.predicate = _instance.predicate || undefined;
    _instance.page = _instance.page || undefined;
    _instance.size = _instance.size || undefined;
    _instance.order = _instance.order || undefined;
    _instance.contentType = _instance.contentType || 0;
    _instance.customContentSlug = _instance.customContentSlug || '';
    _instance.customContentSlugExtra = _instance.customContentSlugExtra || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogContentListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.predicate = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.predicate,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.page = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.page,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.size = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.size,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.order = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.order,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.contentType = _reader.readEnum();
          break;
        case 6:
          _instance.customContentSlug = _reader.readString();
          break;
        case 7:
          _instance.customContentSlugExtra = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BlogContentListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogContentListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.predicate) {
      _writer.writeMessage(
        1,
        _instance.predicate as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.page) {
      _writer.writeMessage(
        2,
        _instance.page as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.size) {
      _writer.writeMessage(
        3,
        _instance.size as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.order) {
      _writer.writeMessage(
        4,
        _instance.order as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.contentType) {
      _writer.writeEnum(5, _instance.contentType);
    }
    if (_instance.customContentSlug) {
      _writer.writeString(6, _instance.customContentSlug);
    }
    if (_instance.customContentSlugExtra) {
      _writer.writeString(7, _instance.customContentSlugExtra);
    }
  }

  private _predicate?: googleProtobuf000.StringValue;
  private _page?: googleProtobuf000.Int32Value;
  private _size?: googleProtobuf000.Int32Value;
  private _order?: googleProtobuf000.StringValue;
  private _contentType: BlogContentTypeEnum;
  private _customContentSlug: string;
  private _customContentSlugExtra: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogContentListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogContentListRequest.AsObject>) {
    _value = _value || {};
    this.predicate = _value.predicate
      ? new googleProtobuf000.StringValue(_value.predicate)
      : undefined;
    this.page = _value.page
      ? new googleProtobuf000.Int32Value(_value.page)
      : undefined;
    this.size = _value.size
      ? new googleProtobuf000.Int32Value(_value.size)
      : undefined;
    this.order = _value.order
      ? new googleProtobuf000.StringValue(_value.order)
      : undefined;
    this.contentType = _value.contentType;
    this.customContentSlug = _value.customContentSlug;
    this.customContentSlugExtra = _value.customContentSlugExtra;
    BlogContentListRequest.refineValues(this);
  }
  get predicate(): googleProtobuf000.StringValue | undefined {
    return this._predicate;
  }
  set predicate(value: googleProtobuf000.StringValue | undefined) {
    this._predicate = value;
  }
  get page(): googleProtobuf000.Int32Value | undefined {
    return this._page;
  }
  set page(value: googleProtobuf000.Int32Value | undefined) {
    this._page = value;
  }
  get size(): googleProtobuf000.Int32Value | undefined {
    return this._size;
  }
  set size(value: googleProtobuf000.Int32Value | undefined) {
    this._size = value;
  }
  get order(): googleProtobuf000.StringValue | undefined {
    return this._order;
  }
  set order(value: googleProtobuf000.StringValue | undefined) {
    this._order = value;
  }
  get contentType(): BlogContentTypeEnum {
    return this._contentType;
  }
  set contentType(value: BlogContentTypeEnum) {
    this._contentType = value;
  }
  get customContentSlug(): string {
    return this._customContentSlug;
  }
  set customContentSlug(value: string) {
    this._customContentSlug = value;
  }
  get customContentSlugExtra(): string {
    return this._customContentSlugExtra;
  }
  set customContentSlugExtra(value: string) {
    this._customContentSlugExtra = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogContentListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogContentListRequest.AsObject {
    return {
      predicate: this.predicate ? this.predicate.toObject() : undefined,
      page: this.page ? this.page.toObject() : undefined,
      size: this.size ? this.size.toObject() : undefined,
      order: this.order ? this.order.toObject() : undefined,
      contentType: this.contentType,
      customContentSlug: this.customContentSlug,
      customContentSlugExtra: this.customContentSlugExtra
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogContentListRequest.AsProtobufJSON {
    return {
      predicate: this.predicate ? this.predicate.toProtobufJSON(options) : null,
      page: this.page ? this.page.toProtobufJSON(options) : null,
      size: this.size ? this.size.toProtobufJSON(options) : null,
      order: this.order ? this.order.toProtobufJSON(options) : null,
      contentType:
        BlogContentTypeEnum[
          this.contentType === null || this.contentType === undefined
            ? 0
            : this.contentType
        ],
      customContentSlug: this.customContentSlug,
      customContentSlugExtra: this.customContentSlugExtra
    };
  }
}
export module BlogContentListRequest {
  /**
   * Standard JavaScript object representation for BlogContentListRequest
   */
  export interface AsObject {
    predicate?: googleProtobuf000.StringValue.AsObject;
    page?: googleProtobuf000.Int32Value.AsObject;
    size?: googleProtobuf000.Int32Value.AsObject;
    order?: googleProtobuf000.StringValue.AsObject;
    contentType: BlogContentTypeEnum;
    customContentSlug: string;
    customContentSlugExtra: string;
  }

  /**
   * Protobuf JSON representation for BlogContentListRequest
   */
  export interface AsProtobufJSON {
    predicate: googleProtobuf000.StringValue.AsProtobufJSON | null;
    page: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    size: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    order: googleProtobuf000.StringValue.AsProtobufJSON | null;
    contentType: string;
    customContentSlug: string;
    customContentSlugExtra: string;
  }
}

/**
 * Message implementation for blog.BlogPostResponse
 */
export class BlogPostResponse implements GrpcMessage {
  static id = 'blog.BlogPostResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogPostResponse();
    BlogPostResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogPostResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
    _instance.team = _instance.team || [];
    _instance.typeTeam = _instance.typeTeam || [];
    _instance.notification = _instance.notification || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogPostResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BlogContentModel();
          _reader.readMessage(
            messageInitializer1,
            BlogContentModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        case 6:
          const messageInitializer6 = new BlogTeamModel();
          _reader.readMessage(
            messageInitializer6,
            BlogTeamModel.deserializeBinaryFromReader
          );
          (_instance.team = _instance.team || []).push(messageInitializer6);
          break;
        case 7:
          const messageInitializer7 = new BlogTypeTeamModel();
          _reader.readMessage(
            messageInitializer7,
            BlogTypeTeamModel.deserializeBinaryFromReader
          );
          (_instance.typeTeam = _instance.typeTeam || []).push(
            messageInitializer7
          );
          break;
        case 8:
          const messageInitializer8 = new BlogNotificationModel();
          _reader.readMessage(
            messageInitializer8,
            BlogNotificationModel.deserializeBinaryFromReader
          );
          (_instance.notification = _instance.notification || []).push(
            messageInitializer8
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BlogPostResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogPostResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        BlogContentModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
    if (_instance.team && _instance.team.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.team as any,
        BlogTeamModel.serializeBinaryToWriter
      );
    }
    if (_instance.typeTeam && _instance.typeTeam.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.typeTeam as any,
        BlogTypeTeamModel.serializeBinaryToWriter
      );
    }
    if (_instance.notification && _instance.notification.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.notification as any,
        BlogNotificationModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: BlogContentModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;
  private _team?: BlogTeamModel[];
  private _typeTeam?: BlogTypeTeamModel[];
  private _notification?: BlogNotificationModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogPostResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogPostResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new BlogContentModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    this.team = (_value.team || []).map(m => new BlogTeamModel(m));
    this.typeTeam = (_value.typeTeam || []).map(m => new BlogTypeTeamModel(m));
    this.notification = (_value.notification || []).map(
      m => new BlogNotificationModel(m)
    );
    BlogPostResponse.refineValues(this);
  }
  get values(): BlogContentModel[] | undefined {
    return this._values;
  }
  set values(value: BlogContentModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }
  get team(): BlogTeamModel[] | undefined {
    return this._team;
  }
  set team(value: BlogTeamModel[] | undefined) {
    this._team = value;
  }
  get typeTeam(): BlogTypeTeamModel[] | undefined {
    return this._typeTeam;
  }
  set typeTeam(value: BlogTypeTeamModel[] | undefined) {
    this._typeTeam = value;
  }
  get notification(): BlogNotificationModel[] | undefined {
    return this._notification;
  }
  set notification(value: BlogNotificationModel[] | undefined) {
    this._notification = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogPostResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogPostResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount,
      team: (this.team || []).map(m => m.toObject()),
      typeTeam: (this.typeTeam || []).map(m => m.toObject()),
      notification: (this.notification || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogPostResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount,
      team: (this.team || []).map(m => m.toProtobufJSON(options)),
      typeTeam: (this.typeTeam || []).map(m => m.toProtobufJSON(options)),
      notification: (this.notification || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module BlogPostResponse {
  /**
   * Standard JavaScript object representation for BlogPostResponse
   */
  export interface AsObject {
    values?: BlogContentModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    team?: BlogTeamModel.AsObject[];
    typeTeam?: BlogTypeTeamModel.AsObject[];
    notification?: BlogNotificationModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for BlogPostResponse
   */
  export interface AsProtobufJSON {
    values: BlogContentModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    team: BlogTeamModel.AsProtobufJSON[] | null;
    typeTeam: BlogTypeTeamModel.AsProtobufJSON[] | null;
    notification: BlogNotificationModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for blog.BlogContentModel
 */
export class BlogContentModel implements GrpcMessage {
  static id = 'blog.BlogContentModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogContentModel();
    BlogContentModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogContentModel) {
    _instance.id = _instance.id || 0;
    _instance.date = _instance.date || undefined;
    _instance.dateGmt = _instance.dateGmt || undefined;
    _instance.slug = _instance.slug || '';
    _instance.status = _instance.status || '';
    _instance.type = _instance.type || '';
    _instance.link = _instance.link || '';
    _instance.title = _instance.title || undefined;
    _instance.content = _instance.content || undefined;
    _instance.excerpt = _instance.excerpt || undefined;
    _instance.jetpackFeaturedMediaUrl = _instance.jetpackFeaturedMediaUrl || '';
    _instance.categories = _instance.categories || [];
    _instance.categoriesData = _instance.categoriesData || [];
    _instance.acf = _instance.acf || undefined;
    _instance.file = _instance.file || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogContentModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.dateGmt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateGmt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.slug = _reader.readString();
          break;
        case 5:
          _instance.status = _reader.readString();
          break;
        case 6:
          _instance.type = _reader.readString();
          break;
        case 7:
          _instance.link = _reader.readString();
          break;
        case 8:
          _instance.title = new Content();
          _reader.readMessage(
            _instance.title,
            Content.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.content = new Content();
          _reader.readMessage(
            _instance.content,
            Content.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.excerpt = new Content();
          _reader.readMessage(
            _instance.excerpt,
            Content.deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.jetpackFeaturedMediaUrl = _reader.readString();
          break;
        case 12:
          (_instance.categories = _instance.categories || []).push(
            ...(_reader.readPackedInt32() || [])
          );
          break;
        case 13:
          const messageInitializer13 = new BlogCategoryModel();
          _reader.readMessage(
            messageInitializer13,
            BlogCategoryModel.deserializeBinaryFromReader
          );
          (_instance.categoriesData = _instance.categoriesData || []).push(
            messageInitializer13
          );
          break;
        case 14:
          _instance.acf = new BlogContentModel.Acf();
          _reader.readMessage(
            _instance.acf,
            BlogContentModel.Acf.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.file = new BlogContentModel.FileBlog();
          _reader.readMessage(
            _instance.file,
            BlogContentModel.FileBlog.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BlogContentModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogContentModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.dateGmt) {
      _writer.writeMessage(
        3,
        _instance.dateGmt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.slug) {
      _writer.writeString(4, _instance.slug);
    }
    if (_instance.status) {
      _writer.writeString(5, _instance.status);
    }
    if (_instance.type) {
      _writer.writeString(6, _instance.type);
    }
    if (_instance.link) {
      _writer.writeString(7, _instance.link);
    }
    if (_instance.title) {
      _writer.writeMessage(
        8,
        _instance.title as any,
        Content.serializeBinaryToWriter
      );
    }
    if (_instance.content) {
      _writer.writeMessage(
        9,
        _instance.content as any,
        Content.serializeBinaryToWriter
      );
    }
    if (_instance.excerpt) {
      _writer.writeMessage(
        10,
        _instance.excerpt as any,
        Content.serializeBinaryToWriter
      );
    }
    if (_instance.jetpackFeaturedMediaUrl) {
      _writer.writeString(11, _instance.jetpackFeaturedMediaUrl);
    }
    if (_instance.categories && _instance.categories.length) {
      _writer.writePackedInt32(12, _instance.categories);
    }
    if (_instance.categoriesData && _instance.categoriesData.length) {
      _writer.writeRepeatedMessage(
        13,
        _instance.categoriesData as any,
        BlogCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.acf) {
      _writer.writeMessage(
        14,
        _instance.acf as any,
        BlogContentModel.Acf.serializeBinaryToWriter
      );
    }
    if (_instance.file) {
      _writer.writeMessage(
        15,
        _instance.file as any,
        BlogContentModel.FileBlog.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _date?: googleProtobuf001.Timestamp;
  private _dateGmt?: googleProtobuf001.Timestamp;
  private _slug: string;
  private _status: string;
  private _type: string;
  private _link: string;
  private _title?: Content;
  private _content?: Content;
  private _excerpt?: Content;
  private _jetpackFeaturedMediaUrl: string;
  private _categories: number[];
  private _categoriesData?: BlogCategoryModel[];
  private _acf?: BlogContentModel.Acf;
  private _file?: BlogContentModel.FileBlog;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogContentModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogContentModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.dateGmt = _value.dateGmt
      ? new googleProtobuf001.Timestamp(_value.dateGmt)
      : undefined;
    this.slug = _value.slug;
    this.status = _value.status;
    this.type = _value.type;
    this.link = _value.link;
    this.title = _value.title ? new Content(_value.title) : undefined;
    this.content = _value.content ? new Content(_value.content) : undefined;
    this.excerpt = _value.excerpt ? new Content(_value.excerpt) : undefined;
    this.jetpackFeaturedMediaUrl = _value.jetpackFeaturedMediaUrl;
    this.categories = (_value.categories || []).slice();
    this.categoriesData = (_value.categoriesData || []).map(
      m => new BlogCategoryModel(m)
    );
    this.acf = _value.acf ? new BlogContentModel.Acf(_value.acf) : undefined;
    this.file = _value.file
      ? new BlogContentModel.FileBlog(_value.file)
      : undefined;
    BlogContentModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get dateGmt(): googleProtobuf001.Timestamp | undefined {
    return this._dateGmt;
  }
  set dateGmt(value: googleProtobuf001.Timestamp | undefined) {
    this._dateGmt = value;
  }
  get slug(): string {
    return this._slug;
  }
  set slug(value: string) {
    this._slug = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }
  get link(): string {
    return this._link;
  }
  set link(value: string) {
    this._link = value;
  }
  get title(): Content | undefined {
    return this._title;
  }
  set title(value: Content | undefined) {
    this._title = value;
  }
  get content(): Content | undefined {
    return this._content;
  }
  set content(value: Content | undefined) {
    this._content = value;
  }
  get excerpt(): Content | undefined {
    return this._excerpt;
  }
  set excerpt(value: Content | undefined) {
    this._excerpt = value;
  }
  get jetpackFeaturedMediaUrl(): string {
    return this._jetpackFeaturedMediaUrl;
  }
  set jetpackFeaturedMediaUrl(value: string) {
    this._jetpackFeaturedMediaUrl = value;
  }
  get categories(): number[] {
    return this._categories;
  }
  set categories(value: number[]) {
    this._categories = value;
  }
  get categoriesData(): BlogCategoryModel[] | undefined {
    return this._categoriesData;
  }
  set categoriesData(value: BlogCategoryModel[] | undefined) {
    this._categoriesData = value;
  }
  get acf(): BlogContentModel.Acf | undefined {
    return this._acf;
  }
  set acf(value: BlogContentModel.Acf | undefined) {
    this._acf = value;
  }
  get file(): BlogContentModel.FileBlog | undefined {
    return this._file;
  }
  set file(value: BlogContentModel.FileBlog | undefined) {
    this._file = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogContentModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogContentModel.AsObject {
    return {
      id: this.id,
      date: this.date ? this.date.toObject() : undefined,
      dateGmt: this.dateGmt ? this.dateGmt.toObject() : undefined,
      slug: this.slug,
      status: this.status,
      type: this.type,
      link: this.link,
      title: this.title ? this.title.toObject() : undefined,
      content: this.content ? this.content.toObject() : undefined,
      excerpt: this.excerpt ? this.excerpt.toObject() : undefined,
      jetpackFeaturedMediaUrl: this.jetpackFeaturedMediaUrl,
      categories: (this.categories || []).slice(),
      categoriesData: (this.categoriesData || []).map(m => m.toObject()),
      acf: this.acf ? this.acf.toObject() : undefined,
      file: this.file ? this.file.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogContentModel.AsProtobufJSON {
    return {
      id: this.id,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      dateGmt: this.dateGmt ? this.dateGmt.toProtobufJSON(options) : null,
      slug: this.slug,
      status: this.status,
      type: this.type,
      link: this.link,
      title: this.title ? this.title.toProtobufJSON(options) : null,
      content: this.content ? this.content.toProtobufJSON(options) : null,
      excerpt: this.excerpt ? this.excerpt.toProtobufJSON(options) : null,
      jetpackFeaturedMediaUrl: this.jetpackFeaturedMediaUrl,
      categories: (this.categories || []).slice(),
      categoriesData: (this.categoriesData || []).map(m =>
        m.toProtobufJSON(options)
      ),
      acf: this.acf ? this.acf.toProtobufJSON(options) : null,
      file: this.file ? this.file.toProtobufJSON(options) : null
    };
  }
}
export module BlogContentModel {
  /**
   * Standard JavaScript object representation for BlogContentModel
   */
  export interface AsObject {
    id: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    dateGmt?: googleProtobuf001.Timestamp.AsObject;
    slug: string;
    status: string;
    type: string;
    link: string;
    title?: Content.AsObject;
    content?: Content.AsObject;
    excerpt?: Content.AsObject;
    jetpackFeaturedMediaUrl: string;
    categories: number[];
    categoriesData?: BlogCategoryModel.AsObject[];
    acf?: BlogContentModel.Acf.AsObject;
    file?: BlogContentModel.FileBlog.AsObject;
  }

  /**
   * Protobuf JSON representation for BlogContentModel
   */
  export interface AsProtobufJSON {
    id: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    dateGmt: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Content.AsProtobufJSON | null;
    content: Content.AsProtobufJSON | null;
    excerpt: Content.AsProtobufJSON | null;
    jetpackFeaturedMediaUrl: string;
    categories: number[];
    categoriesData: BlogCategoryModel.AsProtobufJSON[] | null;
    acf: BlogContentModel.Acf.AsProtobufJSON | null;
    file: BlogContentModel.FileBlog.AsProtobufJSON | null;
  }

  /**
   * Message implementation for blog.BlogContentModel.Acf
   */
  export class Acf implements GrpcMessage {
    static id = 'blog.BlogContentModel.Acf';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Acf();
      Acf.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Acf) {
      _instance.readingTimeMin = _instance.readingTimeMin || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(_instance: Acf, _reader: BinaryReader) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.readingTimeMin = _reader.readInt32();
            break;
          default:
            _reader.skipField();
        }
      }

      Acf.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Acf, _writer: BinaryWriter) {
      if (_instance.readingTimeMin) {
        _writer.writeInt32(1, _instance.readingTimeMin);
      }
    }

    private _readingTimeMin: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Acf to deeply clone from
     */
    constructor(_value?: RecursivePartial<Acf.AsObject>) {
      _value = _value || {};
      this.readingTimeMin = _value.readingTimeMin;
      Acf.refineValues(this);
    }
    get readingTimeMin(): number {
      return this._readingTimeMin;
    }
    set readingTimeMin(value: number) {
      this._readingTimeMin = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Acf.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Acf.AsObject {
      return {
        readingTimeMin: this.readingTimeMin
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Acf.AsProtobufJSON {
      return {
        readingTimeMin: this.readingTimeMin
      };
    }
  }
  export module Acf {
    /**
     * Standard JavaScript object representation for Acf
     */
    export interface AsObject {
      readingTimeMin: number;
    }

    /**
     * Protobuf JSON representation for Acf
     */
    export interface AsProtobufJSON {
      readingTimeMin: number;
    }
  }

  /**
   * Message implementation for blog.BlogContentModel.FileBlog
   */
  export class FileBlog implements GrpcMessage {
    static id = 'blog.BlogContentModel.FileBlog';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new FileBlog();
      FileBlog.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: FileBlog) {
      _instance.id = _instance.id || 0;
      _instance.title = _instance.title || '';
      _instance.filename = _instance.filename || '';
      _instance.url = _instance.url || '';
      _instance.description = _instance.description || '';
      _instance.date = _instance.date || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: FileBlog,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.id = _reader.readInt32();
            break;
          case 2:
            _instance.title = _reader.readString();
            break;
          case 3:
            _instance.filename = _reader.readString();
            break;
          case 4:
            _instance.url = _reader.readString();
            break;
          case 5:
            _instance.description = _reader.readString();
            break;
          case 6:
            _instance.date = new googleProtobuf001.Timestamp();
            _reader.readMessage(
              _instance.date,
              googleProtobuf001.Timestamp.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      FileBlog.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: FileBlog, _writer: BinaryWriter) {
      if (_instance.id) {
        _writer.writeInt32(1, _instance.id);
      }
      if (_instance.title) {
        _writer.writeString(2, _instance.title);
      }
      if (_instance.filename) {
        _writer.writeString(3, _instance.filename);
      }
      if (_instance.url) {
        _writer.writeString(4, _instance.url);
      }
      if (_instance.description) {
        _writer.writeString(5, _instance.description);
      }
      if (_instance.date) {
        _writer.writeMessage(
          6,
          _instance.date as any,
          googleProtobuf001.Timestamp.serializeBinaryToWriter
        );
      }
    }

    private _id: number;
    private _title: string;
    private _filename: string;
    private _url: string;
    private _description: string;
    private _date?: googleProtobuf001.Timestamp;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of FileBlog to deeply clone from
     */
    constructor(_value?: RecursivePartial<FileBlog.AsObject>) {
      _value = _value || {};
      this.id = _value.id;
      this.title = _value.title;
      this.filename = _value.filename;
      this.url = _value.url;
      this.description = _value.description;
      this.date = _value.date
        ? new googleProtobuf001.Timestamp(_value.date)
        : undefined;
      FileBlog.refineValues(this);
    }
    get id(): number {
      return this._id;
    }
    set id(value: number) {
      this._id = value;
    }
    get title(): string {
      return this._title;
    }
    set title(value: string) {
      this._title = value;
    }
    get filename(): string {
      return this._filename;
    }
    set filename(value: string) {
      this._filename = value;
    }
    get url(): string {
      return this._url;
    }
    set url(value: string) {
      this._url = value;
    }
    get description(): string {
      return this._description;
    }
    set description(value: string) {
      this._description = value;
    }
    get date(): googleProtobuf001.Timestamp | undefined {
      return this._date;
    }
    set date(value: googleProtobuf001.Timestamp | undefined) {
      this._date = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      FileBlog.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): FileBlog.AsObject {
      return {
        id: this.id,
        title: this.title,
        filename: this.filename,
        url: this.url,
        description: this.description,
        date: this.date ? this.date.toObject() : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): FileBlog.AsProtobufJSON {
      return {
        id: this.id,
        title: this.title,
        filename: this.filename,
        url: this.url,
        description: this.description,
        date: this.date ? this.date.toProtobufJSON(options) : null
      };
    }
  }
  export module FileBlog {
    /**
     * Standard JavaScript object representation for FileBlog
     */
    export interface AsObject {
      id: number;
      title: string;
      filename: string;
      url: string;
      description: string;
      date?: googleProtobuf001.Timestamp.AsObject;
    }

    /**
     * Protobuf JSON representation for FileBlog
     */
    export interface AsProtobufJSON {
      id: number;
      title: string;
      filename: string;
      url: string;
      description: string;
      date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for blog.BlogCategoryModel
 */
export class BlogCategoryModel implements GrpcMessage {
  static id = 'blog.BlogCategoryModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogCategoryModel();
    BlogCategoryModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogCategoryModel) {
    _instance.id = _instance.id || 0;
    _instance.link = _instance.link || '';
    _instance.slug = _instance.slug || '';
    _instance.name = _instance.name || '';
    _instance.count = _instance.count || 0;
    _instance.description = _instance.description || '';
    _instance.parent = _instance.parent || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogCategoryModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.link = _reader.readString();
          break;
        case 3:
          _instance.slug = _reader.readString();
          break;
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.count = _reader.readInt32();
          break;
        case 6:
          _instance.description = _reader.readString();
          break;
        case 7:
          _instance.parent = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BlogCategoryModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogCategoryModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.link) {
      _writer.writeString(2, _instance.link);
    }
    if (_instance.slug) {
      _writer.writeString(3, _instance.slug);
    }
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.count) {
      _writer.writeInt32(5, _instance.count);
    }
    if (_instance.description) {
      _writer.writeString(6, _instance.description);
    }
    if (_instance.parent) {
      _writer.writeInt32(7, _instance.parent);
    }
  }

  private _id: number;
  private _link: string;
  private _slug: string;
  private _name: string;
  private _count: number;
  private _description: string;
  private _parent: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogCategoryModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogCategoryModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.link = _value.link;
    this.slug = _value.slug;
    this.name = _value.name;
    this.count = _value.count;
    this.description = _value.description;
    this.parent = _value.parent;
    BlogCategoryModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get link(): string {
    return this._link;
  }
  set link(value: string) {
    this._link = value;
  }
  get slug(): string {
    return this._slug;
  }
  set slug(value: string) {
    this._slug = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get parent(): number {
    return this._parent;
  }
  set parent(value: number) {
    this._parent = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogCategoryModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogCategoryModel.AsObject {
    return {
      id: this.id,
      link: this.link,
      slug: this.slug,
      name: this.name,
      count: this.count,
      description: this.description,
      parent: this.parent
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogCategoryModel.AsProtobufJSON {
    return {
      id: this.id,
      link: this.link,
      slug: this.slug,
      name: this.name,
      count: this.count,
      description: this.description,
      parent: this.parent
    };
  }
}
export module BlogCategoryModel {
  /**
   * Standard JavaScript object representation for BlogCategoryModel
   */
  export interface AsObject {
    id: number;
    link: string;
    slug: string;
    name: string;
    count: number;
    description: string;
    parent: number;
  }

  /**
   * Protobuf JSON representation for BlogCategoryModel
   */
  export interface AsProtobufJSON {
    id: number;
    link: string;
    slug: string;
    name: string;
    count: number;
    description: string;
    parent: number;
  }
}

/**
 * Message implementation for blog.Content
 */
export class Content implements GrpcMessage {
  static id = 'blog.Content';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Content();
    Content.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Content) {
    _instance.rendered = _instance.rendered || '';
    _instance.protected = _instance.protected || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Content,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rendered = _reader.readString();
          break;
        case 2:
          _instance.protected = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    Content.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Content, _writer: BinaryWriter) {
    if (_instance.rendered) {
      _writer.writeString(1, _instance.rendered);
    }
    if (_instance.protected) {
      _writer.writeBool(2, _instance.protected);
    }
  }

  private _rendered: string;
  private _protected: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Content to deeply clone from
   */
  constructor(_value?: RecursivePartial<Content.AsObject>) {
    _value = _value || {};
    this.rendered = _value.rendered;
    this.protected = _value.protected;
    Content.refineValues(this);
  }
  get rendered(): string {
    return this._rendered;
  }
  set rendered(value: string) {
    this._rendered = value;
  }
  get protected(): boolean {
    return this._protected;
  }
  set protected(value: boolean) {
    this._protected = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Content.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Content.AsObject {
    return {
      rendered: this.rendered,
      protected: this.protected
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Content.AsProtobufJSON {
    return {
      rendered: this.rendered,
      protected: this.protected
    };
  }
}
export module Content {
  /**
   * Standard JavaScript object representation for Content
   */
  export interface AsObject {
    rendered: string;
    protected: boolean;
  }

  /**
   * Protobuf JSON representation for Content
   */
  export interface AsProtobufJSON {
    rendered: string;
    protected: boolean;
  }
}

/**
 * Message implementation for blog.BlogTeamModel
 */
export class BlogTeamModel implements GrpcMessage {
  static id = 'blog.BlogTeamModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogTeamModel();
    BlogTeamModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogTeamModel) {
    _instance.id = _instance.id || 0;
    _instance.name = _instance.name || '';
    _instance.position = _instance.position || '';
    _instance.order = _instance.order || 0;
    _instance.description = _instance.description || '';
    _instance.image = _instance.image || '';
    _instance.linkedIn = _instance.linkedIn || '';
    _instance.hasFilter = _instance.hasFilter || false;
    _instance.typeTeam = _instance.typeTeam || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogTeamModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.position = _reader.readString();
          break;
        case 4:
          _instance.order = _reader.readInt32();
          break;
        case 5:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.image = _reader.readString();
          break;
        case 7:
          _instance.linkedIn = _reader.readString();
          break;
        case 8:
          _instance.hasFilter = _reader.readBool();
          break;
        case 9:
          _instance.typeTeam = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BlogTeamModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogTeamModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.position) {
      _writer.writeString(3, _instance.position);
    }
    if (_instance.order) {
      _writer.writeInt32(4, _instance.order);
    }
    if (_instance.description) {
      _writer.writeString(5, _instance.description);
    }
    if (_instance.image) {
      _writer.writeString(6, _instance.image);
    }
    if (_instance.linkedIn) {
      _writer.writeString(7, _instance.linkedIn);
    }
    if (_instance.hasFilter) {
      _writer.writeBool(8, _instance.hasFilter);
    }
    if (_instance.typeTeam) {
      _writer.writeInt32(9, _instance.typeTeam);
    }
  }

  private _id: number;
  private _name: string;
  private _position: string;
  private _order: number;
  private _description: string;
  private _image: string;
  private _linkedIn: string;
  private _hasFilter: boolean;
  private _typeTeam: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogTeamModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogTeamModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.position = _value.position;
    this.order = _value.order;
    this.description = _value.description;
    this.image = _value.image;
    this.linkedIn = _value.linkedIn;
    this.hasFilter = _value.hasFilter;
    this.typeTeam = _value.typeTeam;
    BlogTeamModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get position(): string {
    return this._position;
  }
  set position(value: string) {
    this._position = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get image(): string {
    return this._image;
  }
  set image(value: string) {
    this._image = value;
  }
  get linkedIn(): string {
    return this._linkedIn;
  }
  set linkedIn(value: string) {
    this._linkedIn = value;
  }
  get hasFilter(): boolean {
    return this._hasFilter;
  }
  set hasFilter(value: boolean) {
    this._hasFilter = value;
  }
  get typeTeam(): number {
    return this._typeTeam;
  }
  set typeTeam(value: number) {
    this._typeTeam = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogTeamModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogTeamModel.AsObject {
    return {
      id: this.id,
      name: this.name,
      position: this.position,
      order: this.order,
      description: this.description,
      image: this.image,
      linkedIn: this.linkedIn,
      hasFilter: this.hasFilter,
      typeTeam: this.typeTeam
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogTeamModel.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      position: this.position,
      order: this.order,
      description: this.description,
      image: this.image,
      linkedIn: this.linkedIn,
      hasFilter: this.hasFilter,
      typeTeam: this.typeTeam
    };
  }
}
export module BlogTeamModel {
  /**
   * Standard JavaScript object representation for BlogTeamModel
   */
  export interface AsObject {
    id: number;
    name: string;
    position: string;
    order: number;
    description: string;
    image: string;
    linkedIn: string;
    hasFilter: boolean;
    typeTeam: number;
  }

  /**
   * Protobuf JSON representation for BlogTeamModel
   */
  export interface AsProtobufJSON {
    id: number;
    name: string;
    position: string;
    order: number;
    description: string;
    image: string;
    linkedIn: string;
    hasFilter: boolean;
    typeTeam: number;
  }
}

/**
 * Message implementation for blog.BlogTypeTeamModel
 */
export class BlogTypeTeamModel implements GrpcMessage {
  static id = 'blog.BlogTypeTeamModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogTypeTeamModel();
    BlogTypeTeamModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogTypeTeamModel) {
    _instance.id = _instance.id || 0;
    _instance.link = _instance.link || '';
    _instance.name = _instance.name || '';
    _instance.slug = _instance.slug || '';
    _instance.taxonomy = _instance.taxonomy || '';
    _instance.order = _instance.order || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogTypeTeamModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.link = _reader.readString();
          break;
        case 3:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.slug = _reader.readString();
          break;
        case 5:
          _instance.taxonomy = _reader.readString();
          break;
        case 6:
          _instance.order = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    BlogTypeTeamModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogTypeTeamModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.link) {
      _writer.writeString(2, _instance.link);
    }
    if (_instance.name) {
      _writer.writeString(3, _instance.name);
    }
    if (_instance.slug) {
      _writer.writeString(4, _instance.slug);
    }
    if (_instance.taxonomy) {
      _writer.writeString(5, _instance.taxonomy);
    }
    if (_instance.order) {
      _writer.writeInt32(6, _instance.order);
    }
  }

  private _id: number;
  private _link: string;
  private _name: string;
  private _slug: string;
  private _taxonomy: string;
  private _order: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogTypeTeamModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogTypeTeamModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.link = _value.link;
    this.name = _value.name;
    this.slug = _value.slug;
    this.taxonomy = _value.taxonomy;
    this.order = _value.order;
    BlogTypeTeamModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get link(): string {
    return this._link;
  }
  set link(value: string) {
    this._link = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get slug(): string {
    return this._slug;
  }
  set slug(value: string) {
    this._slug = value;
  }
  get taxonomy(): string {
    return this._taxonomy;
  }
  set taxonomy(value: string) {
    this._taxonomy = value;
  }
  get order(): number {
    return this._order;
  }
  set order(value: number) {
    this._order = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogTypeTeamModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogTypeTeamModel.AsObject {
    return {
      id: this.id,
      link: this.link,
      name: this.name,
      slug: this.slug,
      taxonomy: this.taxonomy,
      order: this.order
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogTypeTeamModel.AsProtobufJSON {
    return {
      id: this.id,
      link: this.link,
      name: this.name,
      slug: this.slug,
      taxonomy: this.taxonomy,
      order: this.order
    };
  }
}
export module BlogTypeTeamModel {
  /**
   * Standard JavaScript object representation for BlogTypeTeamModel
   */
  export interface AsObject {
    id: number;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    order: number;
  }

  /**
   * Protobuf JSON representation for BlogTypeTeamModel
   */
  export interface AsProtobufJSON {
    id: number;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    order: number;
  }
}

/**
 * Message implementation for blog.BlogNotificationModel
 */
export class BlogNotificationModel implements GrpcMessage {
  static id = 'blog.BlogNotificationModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BlogNotificationModel();
    BlogNotificationModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BlogNotificationModel) {
    _instance.id = _instance.id || 0;
    _instance.title = _instance.title || '';
    _instance.textSecondary = _instance.textSecondary || '';
    _instance.extra = _instance.extra || '';
    _instance.popupTitle = _instance.popupTitle || '';
    _instance.popupBody = _instance.popupBody || '';
    _instance.portalLink = _instance.portalLink || '';
    _instance.strategies = _instance.strategies || undefined;
    _instance.tabs = _instance.tabs || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BlogNotificationModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.title = _reader.readString();
          break;
        case 3:
          _instance.textSecondary = _reader.readString();
          break;
        case 4:
          _instance.extra = _reader.readString();
          break;
        case 5:
          _instance.popupTitle = _reader.readString();
          break;
        case 6:
          _instance.popupBody = _reader.readString();
          break;
        case 7:
          _instance.portalLink = _reader.readString();
          break;
        case 8:
          _instance.strategies = new BlogNotificationModel.Strategy();
          _reader.readMessage(
            _instance.strategies,
            BlogNotificationModel.Strategy.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.tabs = new BlogNotificationModel.Strategy();
          _reader.readMessage(
            _instance.tabs,
            BlogNotificationModel.Strategy.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BlogNotificationModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BlogNotificationModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.textSecondary) {
      _writer.writeString(3, _instance.textSecondary);
    }
    if (_instance.extra) {
      _writer.writeString(4, _instance.extra);
    }
    if (_instance.popupTitle) {
      _writer.writeString(5, _instance.popupTitle);
    }
    if (_instance.popupBody) {
      _writer.writeString(6, _instance.popupBody);
    }
    if (_instance.portalLink) {
      _writer.writeString(7, _instance.portalLink);
    }
    if (_instance.strategies) {
      _writer.writeMessage(
        8,
        _instance.strategies as any,
        BlogNotificationModel.Strategy.serializeBinaryToWriter
      );
    }
    if (_instance.tabs) {
      _writer.writeMessage(
        9,
        _instance.tabs as any,
        BlogNotificationModel.Strategy.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _title: string;
  private _textSecondary: string;
  private _extra: string;
  private _popupTitle: string;
  private _popupBody: string;
  private _portalLink: string;
  private _strategies?: BlogNotificationModel.Strategy;
  private _tabs?: BlogNotificationModel.Strategy;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BlogNotificationModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<BlogNotificationModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.title = _value.title;
    this.textSecondary = _value.textSecondary;
    this.extra = _value.extra;
    this.popupTitle = _value.popupTitle;
    this.popupBody = _value.popupBody;
    this.portalLink = _value.portalLink;
    this.strategies = _value.strategies
      ? new BlogNotificationModel.Strategy(_value.strategies)
      : undefined;
    this.tabs = _value.tabs
      ? new BlogNotificationModel.Strategy(_value.tabs)
      : undefined;
    BlogNotificationModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get textSecondary(): string {
    return this._textSecondary;
  }
  set textSecondary(value: string) {
    this._textSecondary = value;
  }
  get extra(): string {
    return this._extra;
  }
  set extra(value: string) {
    this._extra = value;
  }
  get popupTitle(): string {
    return this._popupTitle;
  }
  set popupTitle(value: string) {
    this._popupTitle = value;
  }
  get popupBody(): string {
    return this._popupBody;
  }
  set popupBody(value: string) {
    this._popupBody = value;
  }
  get portalLink(): string {
    return this._portalLink;
  }
  set portalLink(value: string) {
    this._portalLink = value;
  }
  get strategies(): BlogNotificationModel.Strategy | undefined {
    return this._strategies;
  }
  set strategies(value: BlogNotificationModel.Strategy | undefined) {
    this._strategies = value;
  }
  get tabs(): BlogNotificationModel.Strategy | undefined {
    return this._tabs;
  }
  set tabs(value: BlogNotificationModel.Strategy | undefined) {
    this._tabs = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BlogNotificationModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BlogNotificationModel.AsObject {
    return {
      id: this.id,
      title: this.title,
      textSecondary: this.textSecondary,
      extra: this.extra,
      popupTitle: this.popupTitle,
      popupBody: this.popupBody,
      portalLink: this.portalLink,
      strategies: this.strategies ? this.strategies.toObject() : undefined,
      tabs: this.tabs ? this.tabs.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BlogNotificationModel.AsProtobufJSON {
    return {
      id: this.id,
      title: this.title,
      textSecondary: this.textSecondary,
      extra: this.extra,
      popupTitle: this.popupTitle,
      popupBody: this.popupBody,
      portalLink: this.portalLink,
      strategies: this.strategies
        ? this.strategies.toProtobufJSON(options)
        : null,
      tabs: this.tabs ? this.tabs.toProtobufJSON(options) : null
    };
  }
}
export module BlogNotificationModel {
  /**
   * Standard JavaScript object representation for BlogNotificationModel
   */
  export interface AsObject {
    id: number;
    title: string;
    textSecondary: string;
    extra: string;
    popupTitle: string;
    popupBody: string;
    portalLink: string;
    strategies?: BlogNotificationModel.Strategy.AsObject;
    tabs?: BlogNotificationModel.Strategy.AsObject;
  }

  /**
   * Protobuf JSON representation for BlogNotificationModel
   */
  export interface AsProtobufJSON {
    id: number;
    title: string;
    textSecondary: string;
    extra: string;
    popupTitle: string;
    popupBody: string;
    portalLink: string;
    strategies: BlogNotificationModel.Strategy.AsProtobufJSON | null;
    tabs: BlogNotificationModel.Strategy.AsProtobufJSON | null;
  }

  /**
   * Message implementation for blog.BlogNotificationModel.Strategy
   */
  export class Strategy implements GrpcMessage {
    static id = 'blog.BlogNotificationModel.Strategy';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Strategy();
      Strategy.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Strategy) {
      _instance.termId = _instance.termId || 0;
      _instance.name = _instance.name || '';
      _instance.slug = _instance.slug || '';
      _instance.termGroup = _instance.termGroup || '';
      _instance.termTaxonomyId = _instance.termTaxonomyId || '';
      _instance.taxonomy = _instance.taxonomy || '';
      _instance.description = _instance.description || '';
      _instance.parent = _instance.parent || 0;
      _instance.count = _instance.count || 0;
      _instance.filter = _instance.filter || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Strategy,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.termId = _reader.readInt32();
            break;
          case 2:
            _instance.name = _reader.readString();
            break;
          case 3:
            _instance.slug = _reader.readString();
            break;
          case 4:
            _instance.termGroup = _reader.readString();
            break;
          case 5:
            _instance.termTaxonomyId = _reader.readString();
            break;
          case 6:
            _instance.taxonomy = _reader.readString();
            break;
          case 7:
            _instance.description = _reader.readString();
            break;
          case 8:
            _instance.parent = _reader.readInt32();
            break;
          case 9:
            _instance.count = _reader.readInt32();
            break;
          case 10:
            _instance.filter = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      Strategy.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Strategy, _writer: BinaryWriter) {
      if (_instance.termId) {
        _writer.writeInt32(1, _instance.termId);
      }
      if (_instance.name) {
        _writer.writeString(2, _instance.name);
      }
      if (_instance.slug) {
        _writer.writeString(3, _instance.slug);
      }
      if (_instance.termGroup) {
        _writer.writeString(4, _instance.termGroup);
      }
      if (_instance.termTaxonomyId) {
        _writer.writeString(5, _instance.termTaxonomyId);
      }
      if (_instance.taxonomy) {
        _writer.writeString(6, _instance.taxonomy);
      }
      if (_instance.description) {
        _writer.writeString(7, _instance.description);
      }
      if (_instance.parent) {
        _writer.writeInt32(8, _instance.parent);
      }
      if (_instance.count) {
        _writer.writeInt32(9, _instance.count);
      }
      if (_instance.filter) {
        _writer.writeString(10, _instance.filter);
      }
    }

    private _termId: number;
    private _name: string;
    private _slug: string;
    private _termGroup: string;
    private _termTaxonomyId: string;
    private _taxonomy: string;
    private _description: string;
    private _parent: number;
    private _count: number;
    private _filter: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Strategy to deeply clone from
     */
    constructor(_value?: RecursivePartial<Strategy.AsObject>) {
      _value = _value || {};
      this.termId = _value.termId;
      this.name = _value.name;
      this.slug = _value.slug;
      this.termGroup = _value.termGroup;
      this.termTaxonomyId = _value.termTaxonomyId;
      this.taxonomy = _value.taxonomy;
      this.description = _value.description;
      this.parent = _value.parent;
      this.count = _value.count;
      this.filter = _value.filter;
      Strategy.refineValues(this);
    }
    get termId(): number {
      return this._termId;
    }
    set termId(value: number) {
      this._termId = value;
    }
    get name(): string {
      return this._name;
    }
    set name(value: string) {
      this._name = value;
    }
    get slug(): string {
      return this._slug;
    }
    set slug(value: string) {
      this._slug = value;
    }
    get termGroup(): string {
      return this._termGroup;
    }
    set termGroup(value: string) {
      this._termGroup = value;
    }
    get termTaxonomyId(): string {
      return this._termTaxonomyId;
    }
    set termTaxonomyId(value: string) {
      this._termTaxonomyId = value;
    }
    get taxonomy(): string {
      return this._taxonomy;
    }
    set taxonomy(value: string) {
      this._taxonomy = value;
    }
    get description(): string {
      return this._description;
    }
    set description(value: string) {
      this._description = value;
    }
    get parent(): number {
      return this._parent;
    }
    set parent(value: number) {
      this._parent = value;
    }
    get count(): number {
      return this._count;
    }
    set count(value: number) {
      this._count = value;
    }
    get filter(): string {
      return this._filter;
    }
    set filter(value: string) {
      this._filter = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Strategy.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Strategy.AsObject {
      return {
        termId: this.termId,
        name: this.name,
        slug: this.slug,
        termGroup: this.termGroup,
        termTaxonomyId: this.termTaxonomyId,
        taxonomy: this.taxonomy,
        description: this.description,
        parent: this.parent,
        count: this.count,
        filter: this.filter
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Strategy.AsProtobufJSON {
      return {
        termId: this.termId,
        name: this.name,
        slug: this.slug,
        termGroup: this.termGroup,
        termTaxonomyId: this.termTaxonomyId,
        taxonomy: this.taxonomy,
        description: this.description,
        parent: this.parent,
        count: this.count,
        filter: this.filter
      };
    }
  }
  export module Strategy {
    /**
     * Standard JavaScript object representation for Strategy
     */
    export interface AsObject {
      termId: number;
      name: string;
      slug: string;
      termGroup: string;
      termTaxonomyId: string;
      taxonomy: string;
      description: string;
      parent: number;
      count: number;
      filter: string;
    }

    /**
     * Protobuf JSON representation for Strategy
     */
    export interface AsProtobufJSON {
      termId: number;
      name: string;
      slug: string;
      termGroup: string;
      termTaxonomyId: string;
      taxonomy: string;
      description: string;
      parent: number;
      count: number;
      filter: string;
    }
  }
}
