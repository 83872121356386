<mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
  <mat-label>Meta/cuenta</mat-label>
  <mat-select [formControl]="formControl" multiple>
    <mat-option [value]="0">Todo</mat-option>
    @for (goal of goals; track goal) {
      <mat-option [value]="goal.id">
        {{ goal.title }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
