<div class="flex flex-col w-full gap-6 px-0 sm:flex-row sm:flex">
  <!-- Date filter -->
  @if (groupFilterInfo?.timePeriod) {
    <time-period-select
      [initialDate]="groupFilterInfo?.timePeriod?.initialDate"
      [initialValue]="groupFilterInfo?.timePeriod?.value"
      class="w-full sm:w-56"
      (valueChange)="onTimePeriodChange($event)"
      >
    </time-period-select>
  }
  <!-- / Date filter -->

  <!-- Currency filter -->
  @if (groupFilterInfo?.currency) {
    <currency-select
      [initialValue]="groupFilterInfo?.currency?.value"
      class="w-full sm:w-56"
      (valueChange)="onCurrencyChange($event)"
    ></currency-select>
  }
  <!-- / Currency filter -->

  <!-- Operation filter -->
  @if (groupFilterInfo?.operationType) {
    <operation-type-select
      [initialValue]="groupFilterInfo?.operationType?.value"
      class="w-full sm:w-56"
      (valueChange)="onTypeChange($event)"
    ></operation-type-select>
  }
  @if (groupFilterInfo?.goals) {
    <goal-filter-select
      class="w-full sm:w-56"
      [initialValue]="groupFilterInfo?.goals?.value"
      [goals]="groupFilterInfo?.goals?.goals!"
      (valueChange)="onGoalChange($event)"
    ></goal-filter-select>
  }
  <!-- / Operation filter -->
</div>
