import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICustomPortfolioResult } from '@bp-core/src/lib/models/portal/goal/custom-portfolio-result.model';
import { ICustomFund } from '@bp-core/src/lib/models/portal/goal/fund-custom.model';
import { IWhereMyMoney } from '@bp-core/src/lib/models/portal/goal/whereMyMoney.model';
import {
  FinancialEntityModel,
  FundingModel,
  GoalCategoryModel,
  GoalModel,
  InvestmentStrategyModel,
  PortfolioModel,
  RiskLevelModel,
} from '@bp-core/src/lib/proto/common-message.pb';
import { fuseAnimations } from '@fuse/animations';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';
import { ColorService } from 'bp/shared/services/color.service';
import { GoalFundingService } from 'bp/shared/services/goal-funding.service';

@Component({
  selector: 'app-goal-resume',
  templateUrl: './goal-resume.component.html',
  styleUrls: ['./goal-resume.component.scss'],
  animations: fuseAnimations,
})
export class GoalResumeComponent implements OnInit {
  title = 'Resumen del portafolio';
  subtitle: string | undefined;
  fundsArray: IWhereMyMoney[] = [];
  sendEmailToggle = false;
  starredGoalToggle = false;
  @Input() riskLevelRecommended: RiskLevelModel;
  @Input() goal: GoalModel;
  @Input() riskLevelSelected: RiskLevelModel;
  @Input() canChangeRiskLevel = true;
  @Input() portfolio: PortfolioModel;
  @Input() investmentStrategy: InvestmentStrategyModel;
  @Input() isCustomPortfolio: boolean;
  @Input() customPortfolioResult?: ICustomPortfolioResult;
  @Input() goalCategory: GoalCategoryModel;
  @Input() financialEntity: FinancialEntityModel;
  @Input() canSimulate = true;
  @Input() vectorBoxFunding?: FundingModel;
  @Input() isStarred = false;
  @Output() next = new EventEmitter<{ sendEmailToggle: boolean; starredGoalToggle: boolean }>();
  @Output() simulator = new EventEmitter<void>();
  @Output() riskLevelChange = new EventEmitter<void>();
  constructor(
    private goalFundingService: GoalFundingService,
    private colorService: ColorService,
    private bpConfirmationService: BpConfirmationService,
  ) {}

  ngOnInit(): void {
    this.starredGoalToggle = this.isStarred;
    if (this.isCustomPortfolio) {
      this.fundsArray = this.getFundsCustomArray();
    } else {
      this.fundsArray = this.getFundsArray();
    }
  }

  showFavoriteGoalTooltip() {
    this.bpConfirmationService.open({
      title: 'Meta favorita',
      message:
        'Siempre que recibamos un depósito tuyo y no nos hayas indicado a qué meta asignarlo en la plataforma, este será asignado a tu meta favorita de manera automática.',
      icon: {
        show: true,
        name: 'star_outline',
      },
      actions: {
        confirm: {
          label: 'Entendido',
        },
      },
    });
  }

  private getFundsCustomArray() {
    let fundsCustomArray: ICustomFund[] = [];
    let whereMyMoney: IWhereMyMoney[] = [];
    this.customPortfolioResult?.customCategoryFund.forEach(value => {
      fundsCustomArray = [...fundsCustomArray, ...value.customFunds];
    });
    whereMyMoney = fundsCustomArray.map(value => {
      return {
        funding: value.fund.fund!,
        color: value.color!,
        percentage: value.userPercentage!,
      };
    })!;
    whereMyMoney.sort((a, b) => this.goalFundingService.orderWhereMyMoney(a, b));
    whereMyMoney = this.goalFundingService.setWhereMyMoneyColors(whereMyMoney);
    whereMyMoney = whereMyMoney.filter(fund => fund.percentage! > 0);
    // Agregando caja de vector
    if (this.vectorBoxFunding) {
      whereMyMoney.push({
        funding: this.vectorBoxFunding,
        color: this.colorService.getBlue(0),
        percentage: 1,
      });
    }
    return whereMyMoney;
  }
  private getFundsArray() {
    let whereMyMoney: IWhereMyMoney[] = [];
    whereMyMoney = this.portfolio.portfolioFunding?.map(value => {
      return {
        funding: value.funding!,
        percentage: value.percentage!,
        color: '',
      };
    })!;
    whereMyMoney.sort((a, b) => this.goalFundingService.orderWhereMyMoney(a, b));
    whereMyMoney = this.goalFundingService.setWhereMyMoneyColors(whereMyMoney);

    return whereMyMoney;
  }

  get canGoToSimulator() {
    return (
      this.goalCategory?.code !== 'general-investments' &&
      !this.isCustomPortfolio &&
      this.canSimulate
    );
  }
}
