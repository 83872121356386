import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-g-p-p-b-d-table',
  templateUrl: './g-p-p-b-d-table.component.html',
  styleUrls: ['./g-p-p-b-d-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class GPPBDTableComponent implements OnInit, OnChanges {
  @Input()
  irrAccumulated: string;
  @Input()
  irrAnnualized: string;
  @Input()
  irrAccumulatedUSD: string;
  @Input()
  irrAnnualizedUSD: string;
  @Input()
  valueIcon: any;
  isEmpty = false;
  valueSelected = 'accumulated';

  @Input()
  irrCurrencyCode: string = '';

  @Input()
  irrUSDCurrencyCode: string;

  constructor() {}
  ngOnChanges() {
    //  console.log("on change")
    this.detectEmptyTable();
    //   console.log(this.isEmpty)
  }
  ngOnInit() {
    //   console.log("on init")
    this.detectEmptyTable();
    //    console.log(this.isEmpty)
  }

  detectEmptyTable(): void {
    //  console.log(this.irrAccumulated)
    if (this.irrAccumulated) {
      if (this.irrAccumulated === '--') {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    } else if (this.irrAnnualized) {
      if (this.irrAnnualized === '--') {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    }
  }
}
