<app-g-p-balance
  [currency]="currency"
  [balanceInitAmount]="balanceInitAmount"
  [balanceInitDate]="balanceInitDate"
  [balanceTotalAmount]="balanceTotalAmount"
  [balanceTotalDate]="balanceTotalDate"
  [netDeposit]="netDeposit"
  [deposits]="deposits"
  [withdrawals]="withdrawals"
  [totalCurrentCapital]="totalCurrentCapital"
  [balanceSingle]="balanceSingle"
  [balance]="balance"
  [singleChart]="singleChart"
  [hasAllFinancialInstitutions]="hasAllFinancialInstitutions"
  [isLoading]="isLoading"
  [fixedFromDate]="fixedFromDate"
  [fixedToDate]="fixedToDate"
  [showDates]="showDates"
  >
</app-g-p-balance>

<!-- //MOSTRAR ELEMENTO SOLO SI ES UNA META, NO EN MI VIDA -->
@if (goalId) {
  <app-g-p-transactions
    [deposits]="deposits"
    [withdrawals]="withdrawals"
    [countDeposits]="depositsLength"
    [countWithdrawals]="withdrawalsLength"
    [showDates]="showDates"
    [currency]="currency"
    [fixedFromDate]="fixedFromDate"
    [fixedToDate]="fixedToDate"
  ></app-g-p-transactions>
}
