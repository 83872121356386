import { Component, Input, OnInit } from '@angular/core';
import { BackService } from '@bp-core/src/lib/services/back.service';
import { fuseAnimations } from '@fuse/animations';
@Component({
  selector: 'bp-end-flow',
  templateUrl: './bp-end-flow.component.html',
  styleUrls: ['./bp-end-flow.component.scss'],
  animations: fuseAnimations,
  host: {
    class: 'h-screen w-screen flex flex-col items-center justify-center',
  },
})
export class BpEndFlowComponent implements OnInit {
  progress = 0;
  @Input() duration: number = 2000;
  @Input() icon: string = 'heroicons_outline:check-circle';
  intervalId: any;

  constructor(private backService: BackService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.backService.noHeader = true;
    }, 0);
    // this.intervalId = setInterval(() => this.animateProgress(), this.duration / 100);
  }

  animateProgress() {
    // Increment the progress by 1
    this.progress += 1;

    // If the progress reaches 100, clear the interval
    if (this.progress >= 100) {
      this.progress = 100;
      clearInterval(this.intervalId);
    }
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
