@if (
  questionsAndAnswers?.length! > 0 ||
  questionsAndAnswersLink?.length! > 0 ||
  blogQuestions?.length! > 0
  ) {
  <div
    @fadeIn
    >
    @if (blogQuestions?.length! > 0 && componentIsReady) {
      <div>
        @if (areLinkQuestions) {
          <div class="faq">
            <div bp-title-card>Preguntas frecuentes</div>
            <ul class="">
              @for (q of blogQuestions; track q) {
                <div>
                  <li class="py-2 ">
                    <a [href]="q.link" target="_blank" bp-link>
                      {{ q.title?.rendered }}
                    </a>
                  </li>
                </div>
              }
            </ul>
          </div>
        }
        @if (!areLinkQuestions) {
          <div>
            <div bp-title-card>Preguntas frecuentes</div>
            <div class="">
              @for (questionAndAnswer of blogQuestions; track questionAndAnswer) {
                <mat-expansion-panel
                  class="px-2 mx-0 mb-0 bg-transparent shadow-none"
                  togglePosition="before"
                  >
                  <mat-expansion-panel-header class="h-auto px-0 py-2 no-hover">
                    <mat-panel-title>
                      <strong>
                        {{ questionAndAnswer.title?.rendered }}
                      </strong>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p class="m-0" [innerHTML]="questionAndAnswer.content?.rendered"></p>
                </mat-expansion-panel>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (blogQuestions?.length! == 0 && componentIsReady) {
      <div>
        @if (questionsAndAnswersLink) {
          <div class="faq">
            <div bp-title-card>Preguntas frecuentes</div>
            <ul>
              @for (q of questionsAndAnswersLink; track q) {
                <div>
                  @if (q.condition == undefined || q.condition) {
                    <li class="py-2 ">
                      <a [href]="q.link" target="_blank" bp-link>
                        {{ q.question }}
                      </a>
                    </li>
                  }
                </div>
              }
            </ul>
          </div>
        }
        @if (questionsAndAnswers) {
          <div>
            <div bp-title-card>Preguntas frecuentes</div>
            <div class="">
              @for (questionAndAnswer of questionsAndAnswers; track questionAndAnswer) {
                <mat-expansion-panel
                  class="px-2 mx-0 mb-0 bg-transparent shadow-none"
                  togglePosition="before"
                  >
                  <mat-expansion-panel-header class="h-auto px-0 py-2 no-hover">
                    <mat-panel-title>
                      <strong>
                        {{ questionAndAnswer.question }}
                      </strong>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p class="m-0" [innerHTML]="questionAndAnswer.answer"></p>
                </mat-expansion-panel>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
