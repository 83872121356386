<div bp-goal-detail-card>
  <div>
    <div bp-title-card class="runcate">
      Balance
    </div>
    @if (showDates) {
      <div bp-text-values-card class="truncate ">
        {{ fixedFromDate + ' - ' + fixedToDate }}
      </div>
    }
  </div>
  <div class="flex flex-col justify-between">
    <!-- Chart -->
    <div class="flex-1 px-0 pt-0">
      <div style="width: 100%;">
        @if (!isLoading && !singleChart) {
          <app-g-p-b-chart
            [balance]="balance"
            [currency]="currency"
          ></app-g-p-b-chart>
        }
        @if (!isLoading && singleChart) {
          <app-g-p-b-chart-single
            [balance]="balanceSingle"
            [currency]="currency"
          ></app-g-p-b-chart-single>
        }
      </div>
    </div>
    <div>
      @if (hasAllFinancialInstitutions) {
        <p class="py-2 text-left help-text">
          El gráfico muestra los datos hasta el último día en que ambas instituciones tienen el valor
          de sus fondos publicados.
        </p>
      }
    </div>

    <!-- Table -->
    <app-g-p-b-table
      [totalCurrentCapital]="totalCurrentCapital"
      [dateFinalBalance]="dateFinalBalance"
      [hasAllFinancialInstitutions]="hasAllFinancialInstitutions"
      [balanceTotalAmount]="balanceTotalAmount"
      [balanceTotalDate]="balanceTotalDate"
      [deposits]="deposits"
      [withdrawals]="withdrawals"
      [balanceInitDate]="balanceInitDate"
      [netDeposit]="netDeposit"
      [balanceInitAmount]="balanceInitAmount"
      [currency]="currency"
      >
    </app-g-p-b-table>
  </div>
</div>
