import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IChart } from '@bp-core/src/lib/models/portal/chart.model';
import { ITooltip } from '@bp-core/src/lib/models/portal/goal/tooltip.model';
import { environment } from '@environment/environment';
import { ColorService } from 'bp/shared/services/color.service';
import moment from 'moment';
import am4themes_betterplan from 'projects/bp-advisor/src/bp/shared/chart/betterplan-theme-amchart';
import { TooltipChartComponent } from 'projects/bp-advisor/src/bp/shared/components/tooltip-chart/tooltip-chart.component';
import { CurrencyModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
am4core.addLicense(environment.amchartLicence);

am4core.useTheme(am4themes_betterplan);
am4core.options.autoDispose = false;

@Component({
  selector: 'app-g-p-b-chart',
  templateUrl: './g-p-b-chart.component.html',
  styleUrls: ['./g-p-b-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPBChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  constructor(
    private zone: NgZone,
    private currencyPipe: CurrencyPipe,
    private cd: ChangeDetectorRef,
    private colorService: ColorService,
  ) {}
  balanceColor = this.colorService.getBlue(0);
  // depositColor = '#1ecb9b';
  depositColor = this.colorService.getGreen(0);
  private chart: am4charts.XYChart;
  @Input()
  balance: IChart;

  @Input()
  currency: CurrencyModel | undefined;

  @ViewChild('chartdivperformancebalance')
  chartdivperformancebalance: ElementRef;

  @ViewChild('tool') tool: TooltipChartComponent;

  tooltip: ITooltip = {
    title: '',
    values: [],
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart && this.balance && this.currency) {
      this.ngAfterViewInit();
      this.chart.data = this.balance.data;
    }
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        const chart = am4core.create(
          this.chartdivperformancebalance.nativeElement,
          am4charts.XYChart,
        );
        this.chart = chart;
        this.chart.tapToActivate = true;
        this.chart.paddingRight = 0;
        this.chart.paddingLeft = 0;
        this.chart.responsive.enabled = true;
        this.chart.responsive.useDefault = false;
        const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

        const axisTooltip = dateAxis.tooltip!;
        axisTooltip.background.fill = am4core.color('rgba(0, 0, 0, 0)');
        axisTooltip.background.strokeWidth = 0;
        axisTooltip.ignoreBounds = true;
        // axisTooltip.background.cornerRadius = 20;
        axisTooltip.background.pointerLength = 0;
        axisTooltip.dy = -24;

        axisTooltip.html = '<h3 style="color:black">&#8226;</h3>';

        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.line.strokeOpacity = 1;
        dateAxis.renderer.line.strokeWidth = 0.5;
        dateAxis.renderer.cellStartLocation = 0;
        dateAxis.renderer.cellEndLocation = 0;
        dateAxis.dateFormats.setKey('day', 'dd');
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy[/]');
        dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM[/]');
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        //  valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        // valueAxis.numberFormatter.numberFormat = "#.0";
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 0.5;
        valueAxis.renderer.padding(0, 0, 0, 0);
        valueAxis.renderer.margin(0, 0, 0, 0);
        //   valueAxis.min = 0;

        valueAxis.numberFormatter.numberFormat = this.currency?.chartAxisFormatNumber ?? ''; // `'$'#.##!a`;

        this.chart.legend = new am4charts.Legend();
        chart.legend.contentAlign = 'left';
        chart.legend.position = 'top';
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.xAxis = dateAxis;
        this.chart.cursor.lineY.disabled = true;
        this.chart.language.locale = am4lang_es_ES;
        this.chart.legend.useDefaultMarker = true;
        const marker = chart.legend.markers.template.children.getIndex(0);
        (marker as any).cornerRadius(12, 12, 12, 12);
        if (marker) {
          marker.height = 15;
          marker.width = 15;
        }
        const factor =
          Math.min(...this.balance.data.map(obj => obj.balance)) /
          Math.max(...this.balance.data.map(obj => obj.balance));

        let balanceOffset = 1.04 - factor;
        if (factor > 0.9) {
          balanceOffset = 1 - factor;
        }

        const lineSeriesBal = this.chart.series.push(new am4charts.LineSeries());
        lineSeriesBal.dataFields.dateX = 'date';
        lineSeriesBal.dataFields.valueY = 'balance';
        lineSeriesBal.name = 'Balance';
        lineSeriesBal.fillOpacity = 0.7;
        const fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0];
        fillModifier.offsets = [0, balanceOffset];
        fillModifier.gradient.rotation = 90;
        lineSeriesBal.segments.template.fillModifier = fillModifier;
        lineSeriesBal.fill = am4core.color(this.balanceColor);
        lineSeriesBal.stroke = am4core.color(this.balanceColor);
        lineSeriesBal.strokeOpacity = 1;
        lineSeriesBal.strokeWidth = 1.2;

        const lineSeriesNet = this.chart.series.push(new am4charts.LineSeries());
        lineSeriesNet.dataFields.dateX = 'date';
        lineSeriesNet.dataFields.valueY = 'netDeposit';
        lineSeriesNet.name = 'Depósitos';
        lineSeriesNet.fillOpacity = 0.0;
        lineSeriesNet.fill = am4core.color(this.balanceColor);
        lineSeriesNet.stroke = am4core.color(this.balanceColor);
        lineSeriesNet.strokeDasharray = '3,4';
        lineSeriesNet.strokeOpacity = 1;
        lineSeriesNet.strokeWidth = 1.2;

        if (this.chart.series.length > 0) {
          if (this.chart.series.values[0].tooltip) {
            for (let index = 0; index < this.chart.series.values.length; index++) {
              this.chart.series.values[index].tooltip!.getFillFromObject = false;
              this.chart.series.values[index].tooltip!.background.fill = am4core.color('#FFFFFF');
              this.chart.series.values[index].tooltip!.autoTextColor = false;
              this.chart.series.values[index].tooltip!.label.fill = am4core.color('black');
            }
          }
        }
        if (this.balance) {
          this.chart.data = this.balance.data;
        }
        this.addTooltipHtml(lineSeriesBal);
        this.chart.legend.itemContainers.template.events.on('hit', ev => {
          const data = ev.target.dataItem as any;
          if (data.isActive == undefined || data.isActive) {
            data.isActive = false;
            if (data.name == 'Balance') {
              lineSeriesBal.adapter.remove('tooltipHTML');
              lineSeriesBal.tooltip!.disabled = true;
              lineSeriesNet.tooltip!.disabled = false;
              this.addTooltipHtml(lineSeriesNet);
            }
            return;
          }
          data.isActive = true;
          if (data.name == 'Balance') {
            lineSeriesNet.adapter.remove('tooltipHTML');
            lineSeriesNet.tooltip!.disabled = true;
            lineSeriesBal.tooltip!.disabled = false;
            this.addTooltipHtml(lineSeriesBal);

            // this.chart.series.values[0].tooltipHTML = tooltipHtml;
          }
        });
        this.onResize();
      });
    });
  }
  addTooltipHtml(lineSeries: am4charts.LineSeries) {
    if (!lineSeries) {
      return;
    }
    lineSeries.adapter.add('tooltipHTML', (tooltipHTML, target) => {
      const data = target.tooltipDataItem.dataContext as any;
      if (data) {
        const balanceValue = this.currencyPipe.transform(
          data.balance,
          this.currency?.currencyCode,
          this.currency?.display,
          this.currency?.digitsInfo,
        );
        const netDepositValue = this.currencyPipe.transform(
          data.netDeposit,
          this.currency?.currencyCode,
          this.currency?.display,
          this.currency?.digitsInfo,
        );
        const tooltipObj: ITooltip = {
          title: moment(data.date).format('DD MMM yyyy'),
          values: [
            {
              label: 'Balance',
              value: balanceValue!,
              color: this.balanceColor,
            },
            {
              label: 'Depósitos',
              value: netDepositValue!,
              color: this.balanceColor,
            },
          ],
        };
        this.tooltip = { ...tooltipObj };
        this.cd.detectChanges();
      }
      return ` ${this.tool.dom.nativeElement.innerHTML}`;
    });
  }
  /**
   * funcion para cambiar el tamaño de las letras de la gráfica respecto a la pantall
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    let screenWidth;
    if (event) {
      screenWidth = event.target.innerWidth;
    } else {
      screenWidth = window.innerWidth;
    }
    // let fontSizeBase = 13;
    if (this.chart) {
      switch (true) {
        case screenWidth < 576:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'none';
          this.chart.zoomOutButton.disabled = true;
          break;
        case screenWidth < 768:
          this.chart.fontSize = 9;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
        default:
          this.chart.fontSize = 12;
          this.chart.cursor.behavior = 'zoomX';
          this.chart.zoomOutButton.disabled = false;
          break;
      }
    }
  }
}
