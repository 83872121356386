import { Component, ContentChild, OnDestroy, TemplateRef } from '@angular/core';
import { BpHeaderConfig } from 'bp/shared/models/header-config';
import { BpHeaderService } from 'bp/shared/services/bp-header.service';

@Component({
  selector: 'app-bp-header',
  templateUrl: './bp-header.component.html',
  styleUrls: ['./bp-header.component.scss'],
})
export class BpHeaderComponent implements OnDestroy {
  @ContentChild('leading') leading!: TemplateRef<any>;
  @ContentChild('center') center!: TemplateRef<any>;
  @ContentChild('action') action!: TemplateRef<any>;

  constructor(private bpHeaderService: BpHeaderService) {}

  ngAfterViewInit() {
    const config: BpHeaderConfig = {
      leading: this.leading,
      center: this.center,
      action: this.action,
    };
    this.bpHeaderService.setHeaderConfig(config);
  }

  ngOnDestroy() {
    this.bpHeaderService.clearHeaderConfig();
  }
}
