<div bp-goal-detail-card>
  <div>
    <div bp-title-card class="flex items-center gap-2 truncate">
      Rentabilidad de tu portafolio
      <mat-icon
        class=" icon-size-4 text-inherit"
        [svgIcon]="'mat_outline:help_outline'"
        matTooltip="Esta medida de rentabilidad muestra el retorno del portafolio elegido en un periodo de tiempo. No considera los efectos de los depósitos y retiros sobre la rentabilidad. Por esta razón, es el único método que deberías usar para comparar el desempeño de tu portafolio con otras alternativas de inversión."
        (click)="tooltipPortfolio.toggle()"
        #tooltipPortfolio="matTooltip"
      ></mat-icon>
    </div>
    @if (showDates) {
      <div bp-text-values-card class="truncate ">
        <span [bp-skeleton]="!fixedFromDate" skeletonWidth="[20px]" skeletonHeight="7">
          {{ fixedFromDate + ' - ' + fixedToDate }}
        </span>
      </div>
    }
  </div>

  <div class="flex flex-col justify-between pt-0">
    <!-- Chart -->
    <div class="flex-1 px-0 chart">
      <app-g-p-p-e-chart [profitabilities]="profitabilities"></app-g-p-p-e-chart>
    </div>

    <!-- Table -->
    <app-g-p-p-e-table
      [lessThanAYear]="lessThanAYear"
      [profitabilities]="profitabilities"
      [fundings]="fundings"
      (changeComparative)="onChangeComparative($event)"
    ></app-g-p-p-e-table>

    @if (showPortfolioUSDArticle) {
      <div class="flex flex-row gap-4 pt-5" @fadeIn>
        <img class="w-18 h-18 rounded-xl" src="assets/images/img-articulo-dolar.png" />
        <div class="flex flex-col justify-center">
          <span class="text-muted">Lectura recomendada</span>
          <a
            class="text-primary"
            href="https://help.betterplan.cl/es/articles/6155985-como-afectan-las-variaciones-del-tipo-de-cambio-a-mis-inversiones"
            target="_blank"
            rel="noopener noreferrer"
            >{{ titleOfProfitabilityInDollarsArticle }}
          </a>
        </div>
      </div>
    }
  </div>
</div>
