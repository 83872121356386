import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BpActivitiesGroupFilter } from 'bp/shared/models/activities-filters-info';

@Component({
  selector: 'activities-filter-chips',
  templateUrl: './activities-filter-chips.component.html',
  styleUrls: ['./activities-filter-chips.component.scss'],
})
export class ActivitiesFilterChipsComponent implements OnInit, OnChanges {
  filterChips: string[] = [];

  @Input() groupFilterInfo?: BpActivitiesGroupFilter;
  @Output() onRemoveChip = new EventEmitter<BpActivitiesGroupFilter>();
  @Output() onCreateFilterChips = new EventEmitter<string[]>();
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupFilterInfo?.currentValue) {
      this.createFilterChips(changes.groupFilterInfo.currentValue);
    }
  }

  ngOnInit(): void {}
  removeChip(chip: string) {
    for (const key in this.groupFilterInfo) {
      if (Object.prototype.hasOwnProperty.call(this.groupFilterInfo, key)) {
        const element = this.groupFilterInfo[key as keyof BpActivitiesGroupFilter];
        if (element?.labels?.includes(chip)) {
          if (Array.isArray(element?.value)) {
            const index = element?.labels?.indexOf(chip);
            if (index !== undefined && index !== -1) {
              element?.value.splice(index, 1);
            }
          } else {
            if (key === 'timePeriod') {
              this.groupFilterInfo.timePeriod!.dateFrom = undefined;
            }
            element!.value = undefined;
          }
          element.labels = element?.labels?.filter(label => label !== chip);
        }
      }
    }
    this.createFilterChips(this.groupFilterInfo!);
    this.onRemoveChip.emit(this.groupFilterInfo);
    // this.onSendGroupFilter(this.groupFilterInfo);
  }
  private createFilterChips(groupFilter: BpActivitiesGroupFilter) {
    this.filterChips = [];
    for (const key in groupFilter) {
      if (Object.prototype.hasOwnProperty.call(groupFilter, key)) {
        const element = groupFilter[key as keyof BpActivitiesGroupFilter];
        if (element?.labels?.length) {
          this.filterChips = [...this.filterChips, ...element.labels];
        }
      }
    }
    this.onCreateFilterChips.emit(this.filterChips);
  }
}
