<div class="flex grid justify-between grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-2">
  <!-- periodo de tiempo -->
  <div class="flex flex-row items-center sm:col-span-2">
    <mat-form-field class="w-full sm:w-1/3" [subscriptSizing]="'dynamic'">
      <mat-label>Periodo de tiempo</mat-label>
      <mat-select [formControl]="dateRange">
        @for (range of ranges; track range) {
          <mat-option [value]="range.id">
            {{ range.text }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <!-- fechas -->
  @if (dateRange.value == 7) {
    <div @fadeIn>
      <form [formGroup]="form" novalidate>
        <!-- <div class="text-lg font-medium leading-6 tracking-tight truncate">Selecciona las fechas</div> -->
        <div class="flex grid items-center justify-between grid-cols-1 gap-4 sm:grid-cols-2">
          <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
            <mat-label>Fecha inicio</mat-label>
            <input
              matInput
              formControlName="fromDate"
              [matDatepicker]="pickerfromDate"
              readonly
              (click)="pickerfromDate.open()"
              [min]="minFromDate"
              [max]="maxFromDate"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerfromDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerfromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Fecha fin</mat-label>
              <input
                matInput
                formControlName="toDate"
                [matDatepicker]="pickertoDate"
                readonly
                (click)="pickertoDate.open()"
                [min]="minToDate"
                [max]="maxToDate"
                />
                <mat-datepicker-toggle matSuffix [for]="pickertoDate"></mat-datepicker-toggle>
                <mat-datepicker #pickertoDate></mat-datepicker>
              </mat-form-field>
            </div>
          </form>
        </div>
      }
    </div>
