import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GPBalanceTransactionsComponent } from './components/g-p-balance-transactions/g-p-balance-transactions.component';
import { GPBChartSingleComponent } from './components/g-p-balance/components/g-p-b-chart-single/g-p-b-chart-single.component';
import { GPBChartComponent } from './components/g-p-balance/components/g-p-b-chart/g-p-b-chart.component';
import { GPBTableComponent } from './components/g-p-balance/components/g-p-b-table/g-p-b-table.component';
import { GPBalanceComponent } from './components/g-p-balance/g-p-balance.component';
import { GPFilterDateComponent } from './components/g-p-filter-date/g-p-filter-date.component';
import { GPPEChartComponent } from './components/g-p-profitability-evolution/components/g-p-p-e-chart/g-p-p-e-chart.component';

import { GPPEFilterComponent } from './components/g-p-profitability-evolution/components/g-p-p-e-filter/g-p-p-e-filter.component';
import { GPPETableComponent } from './components/g-p-profitability-evolution/components/g-p-p-e-table/g-p-p-e-table.component';
import { GPProfitabilityEvolutionComponent } from './components/g-p-profitability-evolution/g-p-profitability-evolution.component';
import { GPTFilterComponent } from './components/g-p-transactions/components/g-p-t-filter/g-p-t-filter.component';
import { GPTTableComponent } from './components/g-p-transactions/components/g-p-t-table/g-p-t-table.component';
import { GPTransactionsComponent } from './components/g-p-transactions/g-p-transactions.component';

import { CardIconComponent } from './components/card-icon/card-icon.component';
import { CardLinkComponent } from './components/card-link/card-link.component';

import { ExplanationModalComponent } from './components/explanation-modal/explanation-modal.component';
import { GPPBDTableComponent } from './components/g-p-profitability-by-date/components/g-p-p-b-d-table/g-p-p-b-d-table.component';
import { GPProfitabilityByDateComponent } from './components/g-p-profitability-by-date/g-p-profitability-by-date.component';

import { FaqLinkComponent } from './components/faq-link/faq-link.component';

import { TooltipChartComponent } from './components/tooltip-chart/tooltip-chart.component';

// Directives

import { AvatarModule } from './external/ngx-avatar-master/projects/ngx-avatar/src/public_api';

// services
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BpCoreModule } from '@bp-core/src/public-api';
import { FuseAlertComponent } from '../../../../shared/src/template/@fuse/components/alert/alert.component';
import { ActivitiesFilterChipsComponent } from './components/activities-filter-chips/activities-filter-chips.component';
import { ActivitiesGroupFilterComponent } from './components/activities-group-filter/activities-group-filter.component';
import { BpAlertComponent } from './components/alert/bp-alert.component';
import { BlogFaqsComponent } from './components/blog-faqs/blog-faqs.component';
import { BondsStocksGraphComponent } from './components/bonds-stocks-graph/bonds-stocks-graph.component';
import { BpConfirmationModalComponent } from './components/bp-confirmation-modal/bp-confirmation-modal.component';
import { BpEndFlowComponent } from './components/bp-end-flow/bp-end-flow.component';
import { BpFundsCostsComponent } from './components/bp-funds-costs/bp-funds-costs.component';
import { BpFundsResumeComponent } from './components/bp-funds-resume/bp-funds-resume.component';
import { BpHeaderComponent } from './components/bp-header/bp-header.component';
import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
import { CustomPortfolioCardComponent } from './components/custom-portfolio-card/custom-portfolio-card.component';
import { CustomPortfolioModalComponent } from './components/custom-portfolio-modal/custom-portfolio-modal.component';
import { GDPTListComponent } from './components/g-d-p-t-list/g-d-p-t-list.component';
import { GoalActivityComponent } from './components/goal-activity/goal-activity.component';
import { GoalFilterSelectComponent } from './components/goal-filter-select/goal-filter-select.component';
import { GoalResumeComponent } from './components/goal-resume/goal-resume.component';
import { ImageInfoCardComponent } from './components/image-info-card/image-info-card.component';
import { OperationTypeSelectComponent } from './components/operation-type-select/operation-type-select.component';
import { PersistentNotificationsComponent } from './components/persistent-notifications/persistent-notifications.component';
import { PortfolioCompositionGraphComponent } from './components/portfolio-composition-graph/portfolio-composition-graph.component';
import { SimulationChartComponent } from './components/simulation-chart/simulation-chart.component';
import { TimePeriodSelectComponent } from './components/time-period-select/time-period-select.component';
import { StickyClassDirective } from './directives/sticky-class.directive';
import { BpConfirmationService } from './services/bp-confirmation.service';
import { CurrencyIndicatorsService } from './services/currency-indicators.service';
import { FirstStepsService } from './services/first-steps.service';

@NgModule({
  declarations: [
    CustomPortfolioModalComponent,

    CustomPortfolioCardComponent,
    StickyClassDirective,
    BlogFaqsComponent,
    BpConfirmationModalComponent,
    GPProfitabilityEvolutionComponent,
    GPPEChartComponent,
    GPPEFilterComponent,
    GPPETableComponent,
    TooltipChartComponent,
    GPFilterDateComponent,
    GPBalanceComponent,
    GPBChartComponent,
    GPBChartSingleComponent,
    GPBTableComponent,
    GPBalanceTransactionsComponent,
    GPTransactionsComponent,
    GPTFilterComponent,
    GPTTableComponent,
    GPPBDTableComponent,
    GPProfitabilityByDateComponent,
    FaqLinkComponent,
    BondsStocksGraphComponent,
    PortfolioCompositionGraphComponent,
    GDPTListComponent,
    ImageInfoCardComponent,
    ExplanationModalComponent,
    CardIconComponent,
    CardLinkComponent,
    SimulationChartComponent,
    BpHeaderComponent,
    PersistentNotificationsComponent,
    BpAlertComponent,
    BpFundsResumeComponent,
    BpFundsCostsComponent,
    BpEndFlowComponent,
    GoalResumeComponent,
    GoalFilterSelectComponent,
    ActivitiesGroupFilterComponent,
    ActivitiesFilterChipsComponent,
    CurrencySelectComponent,
    TimePeriodSelectComponent,
    OperationTypeSelectComponent,
    GoalActivityComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AvatarModule,
    NgScrollbarModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatDividerModule,
    MatTabsModule,
    BlogFaqsComponent,
    MatSortModule,
    MatTableModule,
    GPProfitabilityEvolutionComponent,
    MatButtonToggleModule,
    GPFilterDateComponent,
    GPBalanceComponent,
    GPBChartComponent,
    GPBChartSingleComponent,
    GPBTableComponent,
    GPBalanceTransactionsComponent,
    GPTransactionsComponent,
    GPTFilterComponent,
    GPTTableComponent,
    GPPBDTableComponent,
    GPProfitabilityByDateComponent,
    FaqLinkComponent,
    BondsStocksGraphComponent,
    PortfolioCompositionGraphComponent,
    GDPTListComponent,
    ImageInfoCardComponent,
    ExplanationModalComponent,
    CardIconComponent,
    CardLinkComponent,
    SimulationChartComponent,
    BpHeaderComponent,
    PersistentNotificationsComponent,
    BpAlertComponent,
    CustomPortfolioCardComponent,
    StickyClassDirective,
    BpFundsResumeComponent,
    BpFundsCostsComponent,
    BpEndFlowComponent,
    GoalResumeComponent,
    TooltipChartComponent,
    GoalFilterSelectComponent,
    ActivitiesGroupFilterComponent,
    ActivitiesFilterChipsComponent,
    CurrencySelectComponent,
    TimePeriodSelectComponent,
    OperationTypeSelectComponent,
    GoalActivityComponent,
  ],
  providers: [CurrencyPipe, BpConfirmationService, FirstStepsService, CurrencyIndicatorsService],
  imports: [
    MatPaginatorModule,
    MatTableModule,
    CommonModule,
    RouterModule,
    FormsModule,
    AvatarModule,
    NgScrollbarModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatDividerModule,
    MatTabsModule,
    BpCoreModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,
    FuseAlertComponent,
    MatSlideToggleModule,
    MatSliderModule,
  ],
})
export class SharedModule {}
