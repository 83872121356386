import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GoalModel } from '@bp-core/src/lib/proto/common-message.pb';
import { Subject } from 'rxjs';

@Component({
  selector: 'goal-filter-select',
  templateUrl: './goal-filter-select.component.html',
  styleUrls: ['./goal-filter-select.component.scss'],
})
export class GoalFilterSelectComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  formControl = new UntypedFormControl(0);
  @Input() goals: GoalModel[] = [];
  @Input() initialValue?: number[] = [0];
  @Output() valueChange = new Subject<{ value: number[]; labels?: string[] }>();
  historyValues: number[] = [0];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      if (this.initialValue && this.initialValue.length > 0) {
        this.formControl.setValue(this.initialValue, { emitEvent: false });
        this.historyValues = this.initialValue;
      } else {
        this.formControl.setValue([0], { emitEvent: false });
        this.historyValues = [0];
      }
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
  ngOnInit(): void {
    if (this.initialValue && this.initialValue.length > 0) {
      this.formControl.setValue(this.initialValue, { emitEvent: false });
      this.historyValues = this.initialValue;
    } else {
      this.formControl.setValue([0], { emitEvent: false });
      this.historyValues = [0];
    }

    this.formControl.valueChanges.subscribe((values: number[]) => {
      if (!values.length) {
        this.historyValues = [0];
        this.formControl.setValue([0]);
      }

      let diff = this.differenceArray(values, this.historyValues);
      if (diff.length > 0) {
        if (diff[0] !== 0) {
          values = values.filter(x => x !== 0);
        } else {
          values = [0];
        }
        this.historyValues = values;
        this.formControl.setValue(values);

        this.valueChange.next({
          value: values,
          labels: this.goals.filter(x => values.includes(x.id)).map(x => x.title),
        });
        return;
      }
    });
  }

  /**
   * Devuelve la diferencia entre dos arreglos bidireccionales
   *
   * @private
   * @param {number[]} array1
   * @param {number[]} array2
   * @return {*}  {number[]}
   * @memberof GoalFilterSelectComponent
   */
  private differenceArray(array1: number[], array2: number[]): number[] {
    let diff1 = array1.filter(x => !array2.includes(x));
    let diff2 = array2.filter(x => !array1.includes(x));
    return diff1.concat(diff2);
  }
}
